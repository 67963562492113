(function () {
  'use strict';

  angular
    .module('app')
    .service('ResumeService', ResumeService);

  /* @ngInject */
  function ResumeService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    ///api/datos-proyectos/resumen/{id}

    service.getProyectResume = function (id) {
      var url = api + "/api/datos-proyectos/resumen";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return resumeMapper(response.data);
        });
      }
      else {
        return false;
      }
    }

    service.getInitiativeResume = function (id) {
      var url = api + "/api/datos-iniciativas/resumen";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return resumeMapper(response.data);
        });
      }
      else {
        return false;
      }
    }

    function resumeMapper(elem) {
      var returnedElem = {};

      returnedElem.codigo = elem.codeProject;
      returnedElem.nombre = elem.nameProject;
      returnedElem.numIniciativas = elem.numIniciativa;
      returnedElem.contribucion = {}

      returnedElem.contribucion.actual = {}
      returnedElem.contribucion.actual.dinero = elem.propio.actual.dineroCambio.importeCambio;
      returnedElem.contribucion.actual.especias = elem.propio.actual.especieCambio.importeCambio;
      returnedElem.contribucion.actual.tiempo = elem.propio.actual.tiempoCambio.importeCambio;
      returnedElem.contribucion.actual.probono = elem.propio.actual.probonoCambio.importeCambio;
      returnedElem.contribucion.actual.costeGestion = elem.propio.actual.costeGestionCambio.importeCambio;
      returnedElem.contribucion.actual.total = elem.totalPropioActualCambio.importeCambio;

      returnedElem.contribucion.anterior = {}
      returnedElem.contribucion.anterior.dinero = elem.propio.anterior.dineroCambio.importeCambio;
      returnedElem.contribucion.anterior.especias = elem.propio.anterior.especieCambio.importeCambio;
      returnedElem.contribucion.anterior.tiempo = elem.propio.anterior.tiempoCambio.importeCambio;
      returnedElem.contribucion.anterior.probono = elem.propio.anterior.probonoCambio.importeCambio;
      returnedElem.contribucion.anterior.costeGestion = elem.propio.anterior.costeGestionCambio.importeCambio;
      returnedElem.contribucion.anterior.total = elem.totalPropioAnteriorCambio.importeCambio

      returnedElem.contribucion.total = {}
      returnedElem.contribucion.total.dinero = elem.propio.totalDineroCambio.importeCambio;
      returnedElem.contribucion.total.especias = elem.propio.totalEspeciasCambio.importeCambio;
      returnedElem.contribucion.total.tiempo = elem.propio.totalTiempoCambio.importeCambio;
      returnedElem.contribucion.total.probono = elem.propio.totalProbonoCambio.importeCambio;
      returnedElem.contribucion.total.costeGestion = elem.propio.totalCosteGestionCambio.importeCambio;
      returnedElem.contribucion.total.total = elem.propio.totalCambio.importeCambio;


      returnedElem.otros = {}

      returnedElem.otros.actual = {}
      returnedElem.otros.actual.dinero = elem.otros.actual.dineroCambio.importeCambio;
      returnedElem.otros.actual.especias = elem.otros.actual.especieCambio.importeCambio;
      returnedElem.otros.actual.tiempo = elem.otros.actual.tiempoCambio.importeCambio;
      returnedElem.otros.actual.probono = elem.otros.actual.probonoCambio.importeCambio;
      returnedElem.otros.actual.costeGestion = elem.otros.actual.costeGestionCambio.importeCambio;
      returnedElem.otros.actual.total = elem.totalOtrosActualCambio.importeCambio;

      returnedElem.otros.anterior = {}
      returnedElem.otros.anterior.dinero = elem.otros.anterior.dineroCambio.importeCambio;
      returnedElem.otros.anterior.especias = elem.otros.anterior.especieCambio.importeCambio;
      returnedElem.otros.anterior.tiempo = elem.otros.anterior.tiempoCambio.importeCambio;
      returnedElem.otros.anterior.probono = elem.otros.anterior.probonoCambio.importeCambio;
      returnedElem.otros.anterior.costeGestion = elem.otros.anterior.costeGestionCambio.importeCambio;
      returnedElem.otros.anterior.total = elem.totalOtrosAnteriorCambio.importeCambio;

      returnedElem.otros.total = {}
      returnedElem.otros.total.dinero = elem.otros.totalDineroCambio.importeCambio;
      returnedElem.otros.total.especias = elem.otros.totalEspeciasCambio.importeCambio;
      returnedElem.otros.total.tiempo = elem.otros.totalTiempoCambio.importeCambio;
      returnedElem.otros.total.probono = elem.otros.totalProbonoCambio.importeCambio;
      returnedElem.otros.total.costeGestion = elem.otros.totalCosteGestionCambio.importeCambio;
      returnedElem.otros.total.total = elem.otros.totalCambio.importeCambio;

      returnedElem.efectoMultiplicador = {};
      returnedElem.efectoMultiplicador.actual = elem.efecto.actualCambio.importeCambio;
      returnedElem.efectoMultiplicador.anterior = elem.efecto.anteriorCambio.importeCambio;
      returnedElem.efectoMultiplicador.total = elem.efecto.totalCambio.importeCambio;

      returnedElem.total = {};
      returnedElem.total.actual = elem.totalActualCambio.importeCambio;
      returnedElem.total.anterior = elem.totalAnteriorCambio.importeCambio;
      returnedElem.total.total = elem.totalContribucionesCambio.importeCambio;

      returnedElem.presupuesto = elem.pproPropioCambio.importeCambio;
      returnedElem.cumplimiento = elem.cuplimientoCambio.importeCambio;
      returnedElem.pendiente = elem.pendienteCambio.importeCambio;

      return returnedElem;
    }

    return service;
  }

})();
