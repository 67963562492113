(function() {
  'use strict';


  angular
    .module('app')
    .component('reportTable', {
      templateUrl: 'components/reportChart/reportTable/reportTable.html',
      controller: ReportTableController,
      controllerAs: '$ctrl',
      bindings: {
        data: '<',
        headers: '<'
      }
    });

  /* @ngInject */
  function ReportTableController() {
    var $ctrl = this;

    ////////////////

    $ctrl.$onInit = function() { };
    $ctrl.$onChanges = function(changesObj) { };
    $ctrl.$onDestroy = function() { };
  }
})();
