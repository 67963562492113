(function() {
  'use strict';

  angular
  .module('app')
  .service('ApiErrorInterceptor', ApiErrorInterceptor);

  /* @ngInject */
  function ApiErrorInterceptor($q, $injector, $rootScope) {
    return {
      responseError: function (res, status, headers) {
        var codTokenExpired = 401;//TODO pendiente de meter el codigo

        var result = $q.reject(res);
        if(res.config.ignoreApiErrorInterceptor) {
          return result;
        }
        var RoleService = $injector.get("RoleService");
        var $state = $injector.get("$state");
        var UserService = $injector.get("UserService");
        var Toaster = $injector.get("Toaster");
        var Translator = $injector.get("Translator");

        var esCoordinadorGrupo = RoleService.hasRole(RoleService.getEnum().COORDINADOR_GRUPO)

        //AuthenticationException	Bad credentials
        if (res.status == codTokenExpired 
			&& res.data.AuthenticationException != "Bad credentials" 
			&& res.data.AuthenticationException != "login.usuario.desactivado"
			&& res.data.AuthenticationException != "login.entidad.desactivado") {
          //TODO comprobar que esto frena la cascada de llamadas
          if ($state.current.name != "login") {
            UserService.logOut();
            $state.go("login");
          }
        } else if (res.status == 410 && !esCoordinadorGrupo) {
          localStorage.setItem("conversionMessage", res.data.message)
          $state.go("error");
        } else if (res.status == 403) {
          $rootScope.$broadcast('noPermission', true);
        } else {
          var translateKey = res.data.message;//TODO res.config.headers.NombreCampoMario
          if (!translateKey) {
            translateKey = res.data.AuthenticationException;
          }

          Translator.translate(translateKey, function(translation) {
            Toaster.simpleToast(translation);
          }, function() {
            Toaster.simpleToast('err.genericError');
          });
        }

        return result;
      }
    }
  }
})();
