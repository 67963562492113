(function() {
  'use strict';

  angular
  .module('app')
  .service('RatingService', RatingService);

  /* @ngInject */
  function RatingService(TokenService, config, $http) {

    var token = TokenService.getToken();
    var headers = {
      Authorization: 'Bearer '+token
    }
    var service = {};

    // unidades de negocio
    service.getAllUdNegocio = function() {
      var url = config.api + "/api/unidad-negocios";
      return $http.get(url, { headers: headers })
      .then(function(res) { return res.data; })
    }
	service.getAllProjectOptions = function(proyectoEstado,proyectoFechaInicioDesde,proyectoFechaInicioHasta,proyectoFechaFinDesde,proyectoFechaFinHasta,unidades,agrupaciones) {
      var url = config.api + "/api/filtro-proyectos?";
	  var added = '';
	  if (proyectoEstado != null)
	  {
		url += "proyecto.estado="+proyectoEstado.id;
		added = '&';
	  }
	  if (proyectoFechaInicioDesde != null)
	  {
		url += added + "proyecto.fechaInicioDesde="+moment(proyectoFechaInicioDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }
	  if (proyectoFechaInicioHasta != null)
	  {
		url += added + "proyecto.fechaInicioHasta="+moment(proyectoFechaInicioHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }
	  if (proyectoFechaFinDesde != null)
	  {
		url += added + "proyecto.fechaFinDesde="+moment(proyectoFechaFinDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }
	  if (proyectoFechaFinHasta != null)
	  {
		url += added + "proyecto.fechaFinHasta="+moment(proyectoFechaFinHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }

	  var proyectoLstUnidadNegocio = '';
	  if (unidades != null)
	  {		  
		  unidades.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				proyectoLstUnidadNegocio = proyectoLstUnidadNegocio + added + 'proyecto.unidadNegocio=' + valor.id;
				added = '&';
			}
		  });
		  url += proyectoLstUnidadNegocio;
	  }
	  
	  var proyectoLstAgrupacionInterna = '';
	  if (agrupaciones != null)
	  {
		  agrupaciones.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				proyectoLstAgrupacionInterna = proyectoLstAgrupacionInterna + added + 'proyecto.agrupacionInterna=' + valor.id;
				added = '&';
			}
		  });
		  url += proyectoLstAgrupacionInterna;
	  }
	  
      return $http.get(url, { headers: headers })
      .then(function(res) { return res.data; })
    }
	
	service.getAllInitiativesOptions = function(byProjects,cumpleLGB,pais,region,areaPrimaria,motivacion,odsPrimario,agrupacionInternaIniciativa,fechaInicioDesde,fechaInicioHasta,fechaFinDesde,fechaFinHasta,iniciativaColaboradores) {
      var url = config.api + "/api/filtro-iniciativas?";
	  var added = '';
	  if (byProjects != null)
	  {
		  var ids = '';
		  byProjects.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += "proyecto.ids="+ids;
		  added = '&';
	  }
	  if (fechaInicioDesde != null)
	  {
		url += added + "iniciativa.fechaInicioDesde="+moment(fechaInicioDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }
	  if (fechaInicioHasta != null)
	  {
		url += added + "iniciativa.fechaInicioHasta="+moment(fechaInicioHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }
	  if (fechaFinDesde != null)
	  {
		url += added + "iniciativa.fechaFinDesde="+moment(fechaFinDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }
	  if (fechaFinHasta != null)
	  {
		url += added + "iniciativa.fechaFinHasta="+moment(fechaFinHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
		added = '&';
	  }

	  if (cumpleLGB != null && cumpleLGB.id != null)
	  {
		url += added + "iniciativa.cumpleLBG="+cumpleLGB.id;
		added = '&';
	  }
	  
	  if (pais != null)
	  {
		  var ids = '';
		  pais.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.pais="+ids;
		  added = '&';
	  }
	  
	  if (region != null)
	  {
		  var ids = '';
		  region.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.nombre + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.region="+ids;
		  added = '&';
	  }
	  
	  if (areaPrimaria != null)
	  {
		  var ids = '';
		  areaPrimaria.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.areaActuacion="+ids;
		  added = '&';
	  }
	  
	  if (motivacion != null)
	  {
		  var ids = '';
		  motivacion.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.motivacion="+ids;
		  added = '&';
	  }
	  
	  if (odsPrimario != null)
	  {
		  var ids = '';
		  odsPrimario.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.ods="+ids;
		  added = '&';
	  }
	  
	  if (agrupacionInternaIniciativa != null)
	  {
		  var ids = '';
		  agrupacionInternaIniciativa.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.agrupacionInterna="+ids;
		  added = '&';
	  }
	  
	  if (iniciativaColaboradores != null)
	  {
		  var ids = '';
		  iniciativaColaboradores.forEach( function(valor, indice, array) {
			if (valor.id != null)
			{
				ids = ids + valor.id + ',';
			}
		  });
		  if (ids != '')
		  {
			  ids = ids.substr(0,ids.length -1);
		  }

		  url += added + "iniciativa.iniciativaColaboradores="+ids;
		  added = '&';
	  }
	  	  
      return $http.get(url, { headers: headers })
      .then(function(res) { return res.data; })
    }
	
	service.getAllUdNegocioAllLangs = function() {
      var url = config.api + "/api/unidad-negocios-all";
      return $http.get(url, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.creaUdNegocio = function(data) {
      var url = config.api + "/api/unidad-negocios";
      return $http.post(url, data, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.editaUdNegocio = function(data) {
      var url = config.api + "/api/unidad-negocios";
      return $http.put(url, data, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.borraUdNegocio = function(udNegocio) {
      var url = config.api + "/api/unidad-negocios/"+udNegocio.id;
      return $http.delete(url, { headers: headers })
      .then(function(res) { return res.data; })
    }

    // agrupaciones internas
    service.getAllAgrInterna = function() {
      var url = config.api + "/api/agrupacion-internas";
      return $http.get(url, {headers: headers})
      .then(function(res) { return res.data; })
    }
	service.getAllAgrInternaAllLangs = function() {
      var url = config.api + "/api/agrupacion-internas-all";
      return $http.get(url, {headers: headers})
      .then(function(res) { return res.data; })
    }
    service.creaAgrInterna = function(data) {
      var url = config.api + "/api/agrupacion-internas";
      return $http.post(url, data, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.editaAgrInterna = function(data) {
      var url = config.api + "/api/agrupacion-internas";
      return $http.put(url, data, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.borraAgrInterna = function(agrInterna) {
      var url = config.api + "/api/agrupacion-internas/"+agrInterna.id;
      return $http.delete(url, { headers: headers })
      .then(function(res) { return res.data; })
    }

    // agrupaciones inicitivas internas
    service.getAllAgrIniciativaInterna = function() {
      var url = config.api + "/api/agrupacion-iniciativa-internas";
      return $http.get(url, {headers: headers})
      .then(function(res) { return res.data; })
    }
	service.getAllIniciativaColaboradores = function() {
      var url = config.api + "/api/colaboradors";
      return $http.get(url, {headers: headers})
      .then(function(res) { return res.data; })
    }
	service.getAllAgrIniciativaInternaAllLangs = function() {
      var url = config.api + "/api/agrupacion-iniciativa-internas-all";
      return $http.get(url, {headers: headers})
      .then(function(res) { return res.data; })
    }
    service.creaAgrIniciativaInterna = function(data) {
      var url = config.api + "/api/agrupacion-iniciativa-internas";
      return $http.post(url, data, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.editaAgrIniciativaInterna = function(data) {
      var url = config.api + "/api/agrupacion-iniciativa-internas";
      return $http.put(url, data, { headers: headers })
      .then(function(res) { return res.data; })
    }
    service.borraAgrIniciativaInterna = function(agrIniciativaInterna) {
      var url = config.api + "/api/agrupacion-iniciativa-internas/"+agrIniciativaInterna.id;
      return $http.delete(url, { headers: headers })
      .then(function(res) { return res.data; })
    }

    return service;

  }
})();
