(function() {
  'use strict';

  angular.module('app')
  .service('Toaster', Toaster);

  /* @ngInject */
  function Toaster(Translator, $mdToast) {
    return {

      /**
       * Displays a simple toast containing the translated message.
       * @param  {string} message Message to be translated. Tipically a translation key.
       * @return {void}
       */
      simpleToast: function(message) {
        Translator.translate(message, function(translation) {
        $mdToast.show(
          $mdToast.simple()
            .textContent(translation)
            .position('bottom right')
            .hideDelay(3000)
        );
      });
      }


      
    };
  }
})();