(function () {
  'use strict';

  angular
    .module('app')
    .service('MeService', MeService);

  /* @ngInject */
  function MeService($http, config, TokenService, UserService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.changePassword = function (oldpass, newpass) {

      var userData = UserService.getUserData();
      if (userData && userData.email) {
        var loginModel = {
          username: userData.email,
          password: oldpass
        }
        return UserService.login(loginModel)
          .then(function (json) {
            var url = api + "/api/account/change_password";
            return $http.post(url, newpass, {headers: headers}).then(function (response) {
              return response;
            });
          })
          .catch(function (res) {
            return res;
          })
      }
      else {
        //TODO no user data (devolver promesa)
      }
    }

    service.validaContraseña = function (password) {
      //contraseña que pide mayusculas, minusculas, numeros, algun caracter extraño y al menos 8 digitos
      // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

      //contraseña que pide mayusculas, minusculas, numeros y al menos 8 digitos
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      return strongRegex.test(password);
    }

    service.setUserData = function (user, emailChanged) {
      var url = api + "/api/usuarios/perfil";
      return $http.put(url, user, {headers: headers}).then(function (response) {

        if (emailChanged) {
          return response.data;
        }
        else {
          return UserService.fetchUserData().then(function (res) {

            UserService.setUserData(res);
            return response.data;

          });
        }

      });
    }

    return service;
  }
})();
