(function() {
  angular.module('app')
  .component('impactSelect', {
    templateUrl: 'shared/impactSelect/impactSelect.html',
    controller: /* @ngInject */
      function ImpactSelectController($translate) {
      var vm = this

      vm.selectSearch = ''
      vm.onClickSelect = function(elem) {
        vm.value = elem ? (elem[vm.displayProperty] || elem.nombre) : '';
		if (vm.customNone == true && (elem.id == -2 || elem.id == null)){vm.value = '';}
        vm.selectSearch = ''
        vm.filter = {};
        vm.onChange({filter: elem})
      }
      vm.onSearch = function() {
        vm.filter = {}
        vm.filter[vm.displayProperty || 'nombre'] = vm.selectSearch
      }

        vm.filterFunction = function (value) {
          if (vm.selectSearch == '' || vm.selectSearch == null) {
            return true
          }
          if (value[vm.displayProperty || 'nombre'].toLowerCase().indexOf(vm.selectSearch.toLowerCase()) > -1) {
            return true;
          }
          if ($translate.instant(value[vm.displayProperty || 'nombre']).toLowerCase().indexOf(vm.selectSearch.toLowerCase()) > -1) {
            return true;
          }
          else {
            return false;
          }
        }
    },
    controllerAs: 'vm',
    bindings: {
      displayProperty: '@',
      placeholder: '@',
      elements: '<',
      value: '<',
      onChange: '&',
      hideNone: '<',
	  customNone: '<'
    }
  })
})();
