(function () {
  'use strict';

  angular
    .module('app')
    .service('ExchangeRateService', ExchangeRateService);

  /* @ngInject */
  function ExchangeRateService($http, $httpParamSerializer, config, TokenService, Upload) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getExchangeRates = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/api/tipo-cambios";


      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroLGB = null;
      if (filters && filters.lgbGroup && filters.lgbGroup.id) {
        filtroLGB = filters.lgbGroup.id;
      }

      var filtroDescripcion = null;
      if (filters && filters.description) {
        filtroDescripcion = filters.description;
      }

      var filtroHasta = null;
      if (filters && filters.endDate) {
        filtroHasta = filters.endDate;
      }

      var filtroDesde = null;
      if (filters && filters.startDate) {
        filtroDesde = filters.startDate;
      }

      /*
       grupoLBG
       nombre
       descripcion
       fechaHasta
       fechaDesde
       */
      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        grupoLBG: filtroLGB,
        nombre: filtroNombre,
        descripcion: filtroDescripcion,
        fechaHasta: filtroHasta,
        fechaDesde: filtroDesde
      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            if (element.fechaDesde) {
              element.fechaDesdeBonita = moment(element.fechaDesde, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
            if (element.fechaHasta) {
              element.fechaHastaBonita = moment(element.fechaHasta, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.createExchangeRates = function (entity, file) {
      var url = api + "/api/tipo-cambios";

      file.upload = Upload.upload({
        url: url,
        headers: headers,
        method: "POST",
        data: {excel: file, tipoCambioDTO: Upload.jsonBlob(entity)},
      });

      return file.upload.then(function (response) {
        return response.data;
      });

      // return $http.post(url, entity, {headers: headers}).then(function (response) {
      //   return response.data;
      // });
    }

    service.getExchangeRate = function (id) {
      var url = api + "/api/tipo-cambios";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.comprobarPeriodo = function () {
      var url = api + "/api/tipo-cambios/comprobar-periodo";
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.updateExchangeRate = function (entity, file) {
      var url = api + "/api/tipo-cambios";

      file.upload = Upload.upload({
        url: url,
        headers: headers,
        method: "PUT",
        data: {excel: file, tipoCambioDTO: Upload.jsonBlob(entity)},
      });

      return file.upload.then(function (response) {
        return response.data;
      });

      // if (entity && entity.id != null) {
      //   // url += "/" + entity.id;
      //   return $http.put(url, entity, {headers: headers}).then(function (response) {
      //     return response.data;
      //   });
      // }
      // else {
      //   return false;
      // }
    }

    service.deleteExchangeRate = function (entity) {
      var url = api + "/api/tipo-cambios";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.activeDesactiveExchangeRates = function (entity) {
      var url = api + "/api/tipo-cambios/active-desactive";
      if (entity && entity.id != null) {
        url += "/" + entity.id;
        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
