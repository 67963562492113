(function() {
  'use strict';

  angular
    .module('app')
    .component('odsChart', {
      templateUrl: 'components/reportChart/odsChart/odsChart.html',
      controller: OdsChartController,
      controllerAs: '$ctrl',
      bindings: {
        data: '<',
      },
    });



  function OdsChartController($window, $element) {
    var $ctrl = this;

    ////////////////

    $ctrl.$onInit = function() { };
    $ctrl.$onChanges = function(changesObj) { };
    $ctrl.$onDestroy = function() { };

  }
})();
