(function() {
  angular.module('app')
  .component('dateFilter', {
    templateUrl: 'shared/dateFilter/dateFilter.html',
    controller: DateFilterController,
    controllerAs: 'vm',
    bindings: {
      ariaLabel: '@',
      placeholder: '@',
      uniqueField: '@',
      dateValue: '@',
      activeSortingField: '@',
      enableDatePicker: '@',
      onSorting: '&',
      onChange: '&',
    },
  });

  function DateFilterController(Translator){

    var vm = this;

    vm.sortAscOrder = false;
    vm.abreCalendario = false;
    vm.selectedDate = null;
  
    vm.$onInit = initialize;
    vm.abrirCalendario = abrirCalendario;

    vm.sort = sort;
    vm.getSortIcon = getSortIcon;
    vm.isSortingActive = isSortingActive;
    vm.getSortIconColor = getSortIconColor;
    vm.updated = updated;



    function initialize(){

      vm.uniqueField = angular.isDefined(vm.uniqueField) ? vm.uniqueField : '';
      vm.dateValue = angular.isDefined(vm.dateValue) ? vm.dateValue : vm.placeholder;
      vm.icon = angular.isDefined(vm.icon) ? vm.icon : 'arrow_drop_down';

    }

    function updated(){
      vm.onChange({field: vm.uniqueField, fecha: vm.selectedDate});
      return true;
    }

    function abrirCalendario() {
      vm.abreCalendario = true;
    }

    
    function isSortingActive(){
      if (vm.activeSortingField == vm.uniqueField){
        return true;
      }
      return false;
    }
    
    function getSortIconColor() {
      if (vm.isSortingActive()) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    function getSortIcon(){
        if (!vm.sortAscOrder || !vm.isSortingActive()) {
          return 'arrow_downward'
        }
        return 'arrow_upward'
    }

    function sort(){
      if (vm.isSortingActive()){
        vm.sortAscOrder = !vm.sortAscOrder;
      }
      vm.onSorting({field: vm.uniqueField, isAsc: vm.sortAscOrder}).then(function(response){
      })
    }
}

})();
