(function () {
    angular
      .module('app')
      .component('activityHistory', {
        controller: ActivityHistoryController,
        controllerAs: 'vm',
        templateUrl: 'shared/activityHistory/activityHistory.html',
        bindings: {
          itemType: '@',
          itemId: '@'
        }
    });

    //TODO: make the view more self-explanatory and detailed

    function ActivityHistoryController(ActivityHistoryService, EnumerationsService, Translator, $scope){

      var vm = this;

      vm.data = [];
      vm.dataLoading = false;
      vm.allDataLoaded = false;
      vm.sortField = 'fecha';
      vm.sortFieldIsAsc = false;
      vm.pageNumber = 0;
      vm.pageSize = 30;

      vm.accessibleItemsInSelector;
      vm.accessibleLogTypes;
      vm.accessibleLogActions = [];
      vm.accessibleAuthors = [];
      vm.selectedItems = [];
      vm.selectedLogTypes = [];
      vm.selectedLogAuthors = [];
      vm.selectedLogActions = [];
      vm.selectedFecha = null;

      vm.$onInit = initialize;

      vm.update = update;
      vm.loadMore = loadMore;
      vm.sort = sort;
      vm.updateAuthorsFilterList = updateAuthorsFilterList;
      vm.getData = getData;
      vm.dataReceived = dataReceived;
      vm.buildItemDescription = buildItemDescription;
      vm.buildActionDescription = buildActionDescription;
      vm.buildInitiativeDescription = buildInitiativeDescription;
      vm.buildContributionDescription = buildContributionDescription;

      vm.buildProjectDescription = buildProjectDescription;
      
      function initialize(){
        vm.accessibleItemsInSelector = ActivityHistoryService.getAccessibleItemsInSelector();
        vm.accessibleLogTypes = ActivityHistoryService.getAccessibleLogTypes();
        EnumerationsService.loadLogActions().then(function(actionTypes){
          Object.keys(actionTypes).forEach(function(key){vm.accessibleLogActions.push({key: key, value: 'activity-history.log-action.' + actionTypes[key].toLowerCase()});});
          });
        vm.getData();
      }

      function buildActionDescription(log){
        if(angular.isDefined(log.accion) && log.accion != null) return Translator.translateInstant('activity-history.log-action.' + log.accion.toLowerCase());
      }

      function buildItemDescription(log){
        if (log.item.toLowerCase() == "proyecto"){
          return vm.buildProjectDescription(log);
        } else if (log.item.toLowerCase() == "iniciativa"){
          return vm.buildInitiativeDescription(log);
        } else if (log.item.toLowerCase() == "contribucion"){
          return vm.buildContributionDescription(log);
        }
        return log.item;
      }

      function buildContributionDescription(log){
        var description = Translator.translateInstant('activity-history.log-item.contribucion');
        if (angular.isUndefined(log.contribucionFecha) && log.contribucionFecha != null) {
          description = description.concat(description.length() > 0 ? ' - ' : '');
          description = description.concat(moment(log.contribucionFecha).format('YYYY-MM-DD') + 'T00:00:00.000Z');
        }
        if (angular.isUndefined(log.contribucionCantidad) && log.contribucionCantidad != null) {
          description = description.concat(description.length() > 0 ? ' - ' : '');
          description = description.concat(log.contribucionCantidad + " " + log.contribucionDivisaCantidadAbreviatura);
        }
        if (angular.isUndefined(log.contribucionDescripcion) && log.contribucionDescripcion != null) {
          description = description.concat(description.length() > 0 ? ' - ' : '');
          description = description.concat(log.contribucionDescripcion);
        }
        return description;
      }

      function buildInitiativeDescription(log){
        if(angular.isUndefined(log.iniciativaName) || log.iniciativaName == null){
          return Translator.translateInstant('activity-history.log-item.iniciativa');
        }
        return Translator.translateInstant('activity-history.log-item.iniciativa') + " ('" + log.iniciativaName + "')"
      }

      function buildProjectDescription(log){
        if(angular.isUndefined(log.proyectoName) || log.proyectoName == null){
          return Translator.translateInstant('activity-history.log-item.proyecto');
        }
        return Translator.translateInstant('activity-history.log-item.proyecto') + " ('" + log.proyectoName + "')"
      }

      function getData(options, callback){
        options = angular.isDefined(options) ? options : {};
        var params = {
          'page': angular.isDefined(options.pageNumber) ? options.pageNumber: vm.pageNumber,
          'size': angular.isDefined(options.pageSize) ? options.pageSize: vm.pageSize,
          'sortField': angular.isDefined(options.sortField) ? options.sortField: vm.sortField,
          'sortFieldIsAsc': angular.isDefined(options.sortFieldIsAsc) ? options.sortFieldIsAsc: vm.sortFieldIsAsc,
          'tipos': angular.isDefined(options.selectedLogTypes) ? options.selectedLogTypes: vm.selectedLogTypes,
          'acciones': angular.isDefined(options.selectedLogActions) ? options.selectedLogActions: vm.selectedLogActions,
          'autores': angular.isDefined(options.selectedLogAuthors) ? options.selectedLogAuthors: vm.selectedLogAuthors,
          'items': angular.isDefined(options.selectedItems) ? options.selectedItems: vm.selectedItems,
          'fecha': moment(angular.isDefined(options.selectedFecha) ? options.selectedFecha: vm.selectedFecha).toISOString(),
        }
        vm.dataLoading = true;
        callback = angular.isDefined(callback) ? callback : vm.dataReceived;
        ActivityHistoryService.getActivityHistory(vm.itemType, vm.itemId, params).then(vm.dataReceived);
      }

      function dataReceived(response){	  
		  
        var send_broadcast = vm.data.length == 0;
        vm.data = vm.data.concat(response);
        vm.updateAuthorsFilterList();
        if (!angular.isArray(response) || response.length == 0){
          vm.allDataLoaded = true;
        }
        if (vm.send_broadcast){
          send_broadcast = false;
          $scope.$broadcast("activity-history-grid-refreshed");
        }
        vm.dataLoading = false;
        return true;
      }

      function updateAuthorsFilterList(){
          vm.data.forEach(function(element){
              if (vm.accessibleAuthors.filter(function(e) { 
                return e.key == element.autorId && e.value == element.autorNombreCompleto; }
                ).length == 0){
                  vm.accessibleAuthors.push({key: element.autorId, value: element.autorNombreCompleto});
              }
          });
      }

      function sort(field, isAsc){
        vm.sortField = field;
        vm.sortFieldIsAsc = isAsc;
        vm.pageNumber = 0;
        vm.data = [];
        return vm.getData();
      }

      function update(field, items){
        if (field == 'tipo'){
          vm.selectedLogTypes = items.map(function(element){return element.key;});;
        } else if (field == 'item'){
          vm.selectedItems = items.map(function(element){return element.key;});
        } else if (field == 'autor'){
          vm.selectedLogAuthors = items.map(function(element){return element.key;});;
        } else if (field == 'accion'){
          vm.selectedLogActions = items.map(function(element){return element.key;});;
        } else if (field == 'fecha'){
          vm.selectedFecha = items;
        }
        vm.pageNumber = 0;
        vm.data = [];
        return vm.getData();
      }

      function loadMore(){
        vm.pageNumber++;
        vm.getData();
      }
    }
})();