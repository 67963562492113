(function () {
  angular
    .module('app')
    .component('user', {
      templateUrl: 'components/user/user.html',
      controller: UserController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UserController($rootScope, $scope, UserComponentService, RoleService, EntityService, config, $mdDialog, SelectService, BreadcrumService, ModalService, Translator) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = 'codInterno';//config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;

    vm.data = [];

    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.surname = "";
    vm.selectedFilters.email = "";
    vm.selectedFilters.role = "";
    vm.selectedFilters.responsibleEntity = {};
    vm.selectedFilters.country = {};
    vm.selectedFilters.active = null;
    vm.selectedFilters.rolBonito = "";

    var destroyLangWatcher = $rootScope.$watch('selectedLang', function() {
      loadCountries();
    });
    $scope.$on('$destroy', function() {
      destroyLangWatcher();
    });

    vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);

    vm.getStateSelectElems = function() {
      switch(vm.selectedFilters.active) {
        case null:
          return ''
        case true:
          return 'Activo'
        case false:
          return 'Inactivo'
        default:
          return ''
      }
    }

    vm.getNumUsers = function () {
      EntityService.getEntityData().then(function (res) {
        vm.numUserData.numUsuarios = res.planUsoContratado.numUsuarios - res.getPlanUsoConsumido.numUsuarios;
      });
    }

    vm.numUserData = {};
    if (vm.isRespOrLess) {
      vm.getNumUsers();
    }

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;

      UserComponentService.getUsers(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
      });
    }

    vm.activeDesactive = function (ev, index) {
      var whenOk = function () {
        UserComponentService.activeDesactiveUser(vm.data[index])
          .then(function (res) {
            if (res) {
              vm.data[index].activo = res.activo;
              vm.getNumUsers();
            }
          });
      };
      ModalService.showConfirm(ev,
        vm.data[index].activo ? 'user.desactivar' : 'user.activar',
        'user.estasSeguro',
        vm.data[index].activo ? 'user.desactivar' : 'user.activar',
        'user.cancelar',
        whenOk);
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.role) {
        if (!selectorObject) {
          vm.selectedFilters.role = null;
          vm.selectedFilters.rolBonito = "";
        }
        else {
          vm.selectedFilters.role = selectorObject.enum;
          vm.selectedFilters.rolBonito = selectorObject.enum;
        }
      }
      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }
      if (selectorType == SelectService.ENUM.responsibleEntity) {
        vm.selectedFilters.responsibleEntity = selectorObject;
      }
      if (selectorType == SelectService.ENUM.active) {
        vm.selectedFilters.active = selectorObject;
      }
      if (selectorType == SelectService.ENUM.representativeEntity) {
        vm.selectedFilters.representante = selectorObject
      }

      vm.getData();

    }

    function fillFilters() {
      loadCountries();

      if (!vm.isRespOrLess) {
        SelectService.getResponsibleEntity().then(function (res) {
          vm.selectorList.responsibleEntity = res;
        })
      }

      SelectService.getRepresentativeEntityEntities().then(function (res) {
        vm.selectorList.representantes = res;
      })

      vm.selectorList.role = SelectService.getRoles();
      vm.selectorList.active = [
        {nombre: 'user.list.filter.active', value: true},
        {nombre: 'user.list.filter.inactive', value: false}
      ];
    }

    function loadCountries() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });
    }

    var auxFilters = BreadcrumService.getFilters("user");
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
      vm.sortAscOrder = auxFilters.sortAscOrder;
      vm.sortField = auxFilters.sortField;
    }
    vm.getData();
    fillFilters();

    vm.saveFilters = function () {
      vm.selectedFilters.sortField = vm.sortField;
      vm.selectedFilters.sortAscOrder = vm.sortAscOrder;
      BreadcrumService.saveFilters(vm.selectedFilters, "user");
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        UserComponentService.deleteUser(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          })
          .catch(function (e) {
            translateErrorMessage(e, function(errorMessage) {
              showAlert(ev, 'Error de Borrando', errorMessage, 'ok');
            });
          });
      };
      ModalService.showConfirm(ev, 'user.borrarUsuario', 'user.estasSeguro', 'user.borrar', 'user.cancelar', whenOk);
    }

    function translateErrorMessage(e, callback) {
      var key = e.data.message ? e.data.message : 'err.genericError';
      Translator.translate(key, function(translation) {
        callback(translation);
      });
    }

    //TODO generalizar
    function showAlert(ev, title, textContent, ok) {
      // TODO: for some reason this isn't implemented.

      // $mdDialog.show(
      //   $mdDialog.alert()
      //   // .parent(angular.element(document.querySelector('#popupContainer')))
      //     .clickOutsideToClose(true)
      //     .title(title)
      //     .textContent(textContent)
      //     .ariaLabel('Alert Dialog Demo')
      //     .ok(ok)
      //     .targetEvent(ev)
      // );
    };

    vm.loadMore = function () {
      /*if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      UserComponentService.getUsers(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        } else {
          vm.page++;
        }
        vm.busy = false;
      });*/
    };

  }
})();
