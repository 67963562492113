(function () {
  angular
    .module('app')
    .component('impactsform', {
      templateUrl: 'components/impacts/form/impactsform.html',
      controller: ImpactsFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ImpactsFormController(SelectService, ImpactsService, UserService, InitiativeService, $state, $stateParams, $rootScope, $scope, RoleService) {
    var vm = this;

    // vm.idproyect = $stateParams.idproyect;
    vm.idInitiative = $stateParams.id;
    vm.editId = $stateParams.editId;//TODO implementar en el path
    vm.duplicate = $stateParams.duplicate;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    vm.proyectClosed = false;

    InitiativeService.getProyectState($stateParams.idproyect).then(function (res) {
      vm.proyectClosed = !res;
    })

    vm.lstQuestionsEmpleados = [
      'impactsform.mejoraronHabilidadesLaborales',
      'impactsform.mejoraronDesarrolloPersonal',
      'impactsform.tuvieronCambioPositivoComportamiento',
    ];

    vm.lstQuestionsEntidades = [
      'impactsform.mejoradoRelacionesCGII',
      'impactsform.mejoradoSistemasGestion',
      'impactsform.aumentadoCapacidadEmplearPersonal',
      'impactsform.experimentadoMejoraServicios',
      'impactsform.generadoIngresos',
      'impactsform.nuevasOportunidadesAlianzas',
      'impactsform.mejoraCredibilidadReputacion',
      'impactsform.mejoraVisibilidadEntidad',
    ];

    vm.selectedEmpleado = null;
    vm.selectedEntidadParticipante = null;

    vm.data = {};
    //inicializamos data

    vm.data.lstImpactoEmpleados = [];
    vm.data.lstImpactoParticipantes = [];

    vm.data.datosIniciativaId = vm.idInitiative;

    vm.data.lstImpactoEmpleadosNew = [];

    vm.selectorList = {};
    vm.selectorList.lstMotivacion = [];

    vm.currentEntityData = {};

    vm.newCollaborator = {};
    vm.collaborators = [];

    vm.newEntityBudget = {};
    vm.entityBudgets = [];

    vm.listImpactoEmpleadoInitial = [];
    vm.listImpactoParticipanteInitial = [];

    vm.numBeneficiarios = null;
    vm.entidadesParticipantes = null;


    var swLanguageChanged = 0;
     var destroyLangWatcher = $rootScope.$watch('selectedLang', function (e) {
      if(swLanguageChanged == 1){
        location.reload();
      }
       swLanguageChanged = 1;
    });


    /*
    // Al cambiar el idioma temenos que hacer algunas peticiones de nuevo a la API. Para recibir los datos en el nuevo idioma.
    var destroyLangWatcher = $rootScope.$watch('selectedLang', function () {
      SelectService.getQuienTiempo(vm.idInitiative).then(function (res) {
        vm.selectorList.quienTiempo = res;
        // vm.data.lstImpactoEmpleados
      });

    });

    $scope.$on('$destroy', function() { // Es importante destruir el watcher cuando el constructor es destruido.
      destroyLangWatcher();
    });

*/
    // esta funcion es llamada por angular cuando se inicia el componente
    // ayuda a mantener el codigo de inicio contenido en una sola funcion
    vm.$onInit = function () {
      if (vm.editId) {
        ImpactsService.getImpact(vm.editId).then(function (resData) {
          SelectService.getQuienTiempo(vm.idInitiative).then(function (res) {
            SelectService.getEntidadParticipantes(vm.idInitiative).then(function (resEntidadParticipante) {
              vm.selectorList.entidadesParticipantes = parseEntidadesParticipantes(resEntidadParticipante);
              vm.selectorList.quienTiempo = res;
              prettyData(resData, vm.duplicate == 'true', function(data) {
                vm.data = data;
                selectFistInLists();
                fillSelectors();
                setPrettyDates();
				vm.initToggleOption();
              });
            })
          });
        });
      }
      fillSelectors();
      ImpactsService.getNumBeneficiarios(vm.idInitiative).then(function (res) {
        //TODO
        vm.numBeneficiarios = res
      })
    }

    function selectFistInLists() {
      if (vm.data.lstImpactoEmpleados.length > 0
      // && vm.selectedEmpleado == null
      ) {
        // vm.selectedEmpleado = 0;
        vm.selectEmpleado(0)
      }

      if (vm.data.lstImpactoParticipantes.length > 0
      // && vm.selectedEntidadParticipante == null
      ) {
        vm.selectedEntidadParticipante = 0;
      }

    }

    function prettyData(data, isDuplicate, callback) {
      if (isDuplicate) {
        data.id = null;

        data.lstImpactoEmpleados.map(function (elem) {
          elem.id = null;
          return elem;
        })
      }

      data.lstImpactoEmpleados.forEach(function (res) {
        if (res.id) {
          vm.listImpactoEmpleadoInitial.push(res.id);
        }
      })

      data.lstImpactoParticipantes.forEach(function (res) {
        if (res.id) {
          vm.listImpactoParticipanteInitial.push(res.id);
        }
      })


      data.datosIniciativaId = vm.idInitiative;

      data.impactoMANombre = data.impactoMA;
      data.cambioPersonaMANombre = data.cambioPersonaMA;

      data.lstImpactoEmpleados.map(function (elem, index) {
        //changlai para casar la lista multi y esta al devolver los datos tenemos que "ponerlos bien"
        elem.idReal = elem.id;
        elem.id = elem.contribuyenteId;

        //TODO convertir aqui los tipos de datos que me llegan a los que uso en la vista
        elem.preguntas = [];
        elem.preguntas[0] = [];
        elem.preguntas[1] = [];
        elem.preguntas[2] = [];

        elem.preguntas[0][0] = elem.habilidadesSinCambios;
        elem.preguntas[0][1] = elem.habilidadesPoco;
        elem.preguntas[0][2] = elem.habilidadesAlgo;
        elem.preguntas[0][3] = elem.habilidadesBastante;

        elem.preguntas[1][0] = elem.personalSinCambios;
        elem.preguntas[1][1] = elem.personalPoco;
        elem.preguntas[1][2] = elem.personalAlgo;
        elem.preguntas[1][3] = elem.personalBastante;

        elem.preguntas[2][0] = elem.actitudSinCambios;
        elem.preguntas[2][1] = elem.actitudPoco;
        elem.preguntas[2][2] = elem.actitudAlgo;
        elem.preguntas[2][3] = elem.actitudBastante;


        vm.selectorList.quienTiempo.forEach(function (elemQuienTiempo) {
          if (elemQuienTiempo.contribuyente.id == elem.contribuyenteId) {

            elem.nombre = elemQuienTiempo.nombre;
            elem.numPersona = elemQuienTiempo.numPersona;
          }
        })

        return elem;
      })

      data.lstImpactoParticipantes.map(function (elem) {
        //changlai para casar la lista multi y esta al devolver los datos tenemos que "ponerlos bien"
        elem.idReal = elem.id;

        if (elem.orgBeneficiariasId) {
          elem.id = "OB-" + elem.orgBeneficiariasId;
          elem.idTemporal = elem.orgBeneficiariasId;
          elem.tipoTemporal = "OB";
        }
        else if (elem.colaboradorId) {
          elem.id = "COL-" + elem.colaboradorId;
          elem.idTemporal = elem.colaboradorId;
          elem.tipoTemporal = "COL";
        }
        else if (elem.entidadId) {
          elem.id = "ENT-" + elem.entidadId;
          elem.idTemporal = elem.entidadId;
          elem.tipoTemporal = "ENT";
        }

        elem['0'] = elem.mejoraRelaciones;
        elem['1'] = elem.mejoraGestion;
        elem['2'] = elem.mejoraCapacidad;
        elem['3'] = elem.mejoraServicios;
        elem['4'] = elem.mejoraIngresos;
        elem['5'] = elem.mejoraOportunidad;
        elem['6'] = elem.mejoraReputacion;
        elem['7'] = elem.mejoraVisibilidad;

        //TODO mover a fill selectors
        data.lstImpactoParticipantes.map(function (elem) {
          vm.selectorList.entidadesParticipantes.forEach(function (elemImpactoParticipantes) {
            if (elem.orgBeneficiariasId) {
              if (elemImpactoParticipantes.tipoTemporal == "OB" && elemImpactoParticipantes.idTemporal == elem.orgBeneficiariasId) {
                elem.nombre = elemImpactoParticipantes.nombre;
                elem.abreviatura = elemImpactoParticipantes.abreviatura;
              }
            }
            else if (elem.colaboradorId) {
              if (elemImpactoParticipantes.tipoTemporal == "COL" && elemImpactoParticipantes.idTemporal == elem.colaboradorId) {
                elem.nombre = elemImpactoParticipantes.nombre;
                elem.abreviatura = elemImpactoParticipantes.abreviatura;
              }
            }
            else if (elem.entidadId) {
              if (elemImpactoParticipantes.tipoTemporal == "ENT" && elemImpactoParticipantes.idTemporal == elem.entidadId) {
                elem.nombre = elemImpactoParticipantes.nombre;
                elem.abreviatura = elemImpactoParticipantes.abreviatura;
              }
            }
          });
        });
        return elem;
      })

      return callback(data);
    }

    function setPrettyDates() {
      if (vm.data.fechaEvaluacion) {
        vm.data.fechaEvaluacion = moment(vm.data.fechaEvaluacion).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
    }

    // vm.clickPreguntaEmpleados = function (selectorObject, index) {
    //   //mejoraHabilidades
    //   if (index == 0) {
    //     vm.data.lstImpactoEmpleados[vm.selectedEmpleado]['0'] = selectorObject.nombre;
    //     vm.data.lstImpactoEmpleados[vm.selectedEmpleado].mejoraHabilidades = selectorObject.enum;
    //   }
    //   //mejoraPersonal
    //   else if (index == 1) {
    //     vm.data.lstImpactoEmpleados[vm.selectedEmpleado]['1'] = selectorObject.nombre;
    //     vm.data.lstImpactoEmpleados[vm.selectedEmpleado].mejoraPersonal = selectorObject.enum;
    //   }
    //   //mejoraActitud
    //   else if (index == 2) {
    //     vm.data.lstImpactoEmpleados[vm.selectedEmpleado]['2'] = selectorObject.nombre;
    //     vm.data.lstImpactoEmpleados[vm.selectedEmpleado].mejoraActitud = selectorObject.enum;
    //   }
    // }

    vm.clickPreguntaEntidades = function (selectorObject, index) {
      /*
       0 mejoraRelaciones;
       1 mejoraGestion;
       2 mejoraCapacidad;
       3 mejoraServicios;
       4 mejoraIngresos;
       5 mejoraOportunidad;
       6 mejoraReputacion;
       7 mejoraVisibilidad;
       */

      //mejoraRelaciones
      if (index == 0) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['0'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraRelaciones = selectorObject.enum;
      }

      //mejoraGestion
      if (index == 1) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['1'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraGestion = selectorObject.enum;
      }

      //mejoraCapacidad
      if (index == 2) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['2'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraCapacidad = selectorObject.enum;
      }

      //mejoraServicios
      if (index == 3) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['3'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraServicios = selectorObject.enum;
      }

      //mejoraIngresos
      if (index == 4) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['4'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraIngresos = selectorObject.enum;
      }

      //mejoraOportunidad
      if (index == 5) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['5'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraOportunidad = selectorObject.enum;
      }

      //mejoraReputacion
      if (index == 6) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['6'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraReputacion = selectorObject.enum;
      }

      //mejoraVisibilidad
      if (index == 7) {
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante]['7'] = selectorObject.enum;
        vm.data.lstImpactoParticipantes[vm.selectedEntidadParticipante].mejoraVisibilidad = selectorObject.enum;
      }
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == "impactoMA") {
        vm.data.impactoMA = selectorObject.enum;
        vm.data.impactoMANombre = selectorObject.nombre;
      }

      if (selectorType == "cambioPersonaMA") {
        vm.data.cambioPersonaMA = selectorObject.enum;
        vm.data.cambioPersonaMANombre = selectorObject.nombre;
      }
    }

    vm.clickSelectorMulti = function (selectorObject, selectorType) {
      if (selectorType == "quienTiempo") {
        vm.selectedEmpleado = null;
        // selectFistInLists(); //TODO esta logica no me gusta
        vm.data.lstImpactoEmpleados = selectorObject;

        if (vm.data.lstImpactoEmpleados.length > 0) {
          vm.selectedEmpleado = 0
        }
      }
      if (selectorType == "entidadesParticipantes") {
        vm.selectedEntidadParticipante = null;

        vm.data.lstImpactoParticipantes = selectorObject;

        if (vm.data.lstImpactoParticipantes.length > 0) {
          vm.selectedEntidadParticipante = 0;
        }
      }
    }

    function fillSelectors() {
      vm.selectorList.grado = SelectService.getTipoMejoria();

      if (!vm.editId) {//esto es para optimizar un poco, si entramos en editar ya se llama mas arriba
        SelectService.getQuienTiempo(vm.idInitiative).then(function (res) {
          vm.selectorList.quienTiempo = res;
        });
        SelectService.getEntidadParticipantes(vm.idInitiative).then(function (res) {
          vm.selectorList.entidadesParticipantes = parseEntidadesParticipantes(res);
        });
      }

    }

    function parseEntidadesParticipantes(entidadesParticipantes) {
      var prettyList = [];
      entidadesParticipantes.lstOrganizacionesBeneficiariasDTO.forEach(function (elem) {
        elem.idTemporal = elem.id;
        elem.tipoTemporal = "OB";
        elem.id = "OB-" + elem.id;

        prettyList.push(elem);
      })

      entidadesParticipantes.lstColaboradorDTO.forEach(function (elem) {
        elem.idTemporal = elem.id;
        elem.tipoTemporal = "COL";
        elem.id = "COL-" + elem.id;

        prettyList.push(elem);
      })

      prettyList.push({
        id: "ENT-" + UserService.getUserData().entidad.id,
        idTemporal: UserService.getUserData().entidad.id,
        tipoTemporal: "ENT",
        nombre: UserService.getUserData().entidad.nombre,
        abreviatura: UserService.getUserData().entidad.abreviatura,
      })

      vm.entidadesParticipantes = prettyList.length;

      return prettyList;
    }

    vm.selectEmpleado = function (index) {
      vm.selectedEmpleado = index;
      if (vm.data.lstImpactoEmpleados[vm.selectedEmpleado].preguntas == null) {
        vm.data.lstImpactoEmpleados[vm.selectedEmpleado].preguntas = []
        vm.data.lstImpactoEmpleados[vm.selectedEmpleado].preguntas[0] = [];
        vm.data.lstImpactoEmpleados[vm.selectedEmpleado].preguntas[1] = [];
        vm.data.lstImpactoEmpleados[vm.selectedEmpleado].preguntas[2] = [];
      }
    }

    vm.selectEntidadParticipante = function (index) {
      vm.selectedEntidadParticipante = index;
    }

    function validateData(data) {
      // data.lstImpactoEmpleados.map(function (elem, index) {
      //   if (elem.idReal) {
      //     elem.id = elem.idReal;
      //   }
      //   else {
      //     elem.id = null;
      //   }
      // });

      data.lstImpactoEmpleados.forEach(function (empleado, indexEmpleado) {
        if (empleado.idReal) {
          empleado.id = empleado.idReal;
        }
        else {
          empleado.id = null;
        }

        if (empleado.preguntas) {
          _.values(empleado.preguntas).forEach(function (pregunta, indexPregunta) {
            // empleado.preguntas.forEach(function (pregunta, indexPregunta) {
            if (pregunta) {
              _.values(pregunta).forEach(function (grado, indexGrado) {
                // pregunta.forEach(function (grado, indexGrado) {
                if (grado != null && grado >= 0) {
                  if (indexPregunta == 0) {
                    if (indexGrado == 0) {
                      data.lstImpactoEmpleados[indexEmpleado].habilidadesSinCambios = grado;
                    }
                    else if (indexGrado == 1) {
                      data.lstImpactoEmpleados[indexEmpleado].habilidadesPoco = grado;
                    }
                    else if (indexGrado == 2) {
                      data.lstImpactoEmpleados[indexEmpleado].habilidadesAlgo = grado;
                    }
                    else if (indexGrado == 3) {
                      data.lstImpactoEmpleados[indexEmpleado].habilidadesBastante = grado;
                    }
                  }
                  else if (indexPregunta == 1) {
                    if (indexGrado == 0) {
                      data.lstImpactoEmpleados[indexEmpleado].personalSinCambios = grado;
                    }
                    else if (indexGrado == 1) {
                      data.lstImpactoEmpleados[indexEmpleado].personalPoco = grado;
                    }
                    else if (indexGrado == 2) {
                      data.lstImpactoEmpleados[indexEmpleado].personalAlgo = grado;
                    }
                    else if (indexGrado == 3) {
                      data.lstImpactoEmpleados[indexEmpleado].personalBastante = grado;
                    }
                  }
                  else if (indexPregunta == 2) {
                    if (indexGrado == 0) {
                      data.lstImpactoEmpleados[indexEmpleado].actitudSinCambios = grado;
                    }
                    else if (indexGrado == 1) {
                      data.lstImpactoEmpleados[indexEmpleado].actitudPoco = grado;
                    }
                    else if (indexGrado == 2) {
                      data.lstImpactoEmpleados[indexEmpleado].actitudAlgo = grado;
                    }
                    else if (indexGrado == 3) {
                      data.lstImpactoEmpleados[indexEmpleado].actitudBastante = grado;
                    }
                  }
                }
              })
            }
          })
        }
      })

      data.lstImpactoParticipantes.map(function (elem) {

        if (vm.editId) {
          elem.impactoId = vm.editId;
        }

        if (elem.idReal) {
          elem.id = elem.idReal;
        }
        else {
          elem.id = null;
        }
        if (elem.tipoTemporal == "OB") {
          elem.orgBeneficiariasId = elem.idTemporal;
        }
        else if (elem.tipoTemporal == "COL") {
          elem.colaboradorId = elem.idTemporal;
        }
        else if (elem.tipoTemporal == "ENT") {
          elem.entidadId = elem.idTemporal;
        }
      });

      //Eliminar los valores en Impacto Empleados que se 'deseleccionen'
      data.lstImpactoEmpleadosABorrar = [];
      vm.listImpactoEmpleadoInitial.forEach(function (elem1) {
        var isInList = false;
        data.lstImpactoEmpleados.forEach(function (elem2) {
          if (elem1 == elem2.id) {
            isInList = true;
          }
        });
        if (!isInList) {
          data.lstImpactoEmpleadosABorrar.push(elem1);
        }
      });

      //Eliminar los valores en Impacto Parcticipante que se 'deseleccionen'
      data.lstImpactoParticipantesABorrar = [];
      vm.listImpactoParticipanteInitial.forEach(function (elem1) {
        var isInList = false;
        data.lstImpactoParticipantes.forEach(function (elem2) {
          if (elem1 == elem2.id) {
            isInList = true;
          }
        });
        if (!isInList) {
          data.lstImpactoParticipantesABorrar.push(elem1);
        }
      });

      return data;
    }

    vm.savingData = false;
    vm.clickSave = function () {
      if (!vm.savingData) {
        vm.savingData = true;
        if (vm.editId && vm.duplicate == 'false') {
          ImpactsService.updateImpact(validateData(vm.data)).then(function (res) {
            vm.savingData = false;
            $state.go("measurement.impacts");
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        } else {
          ImpactsService.createImpact(validateData(vm.data)).then(function (res) {
            vm.savingData = false;
            $state.go("measurement.impacts");
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        }
      }
    }

    vm.clickCancel = function () {
      $state.go("measurement.impacts");
    }

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
      vm.abreCalendarioDesde = true;
    }

	vm.getToggleOption = function(optionName){
		return UserService.getToggleOption(optionName);
	}
	vm.toggleActive = false;
	vm.toggleActive2 = false;
	vm.toggleActive3 = false;
	vm.toggleActive4 = false;
	vm.initToggleOption = function(){		
		if (vm.getToggleOption('impact_beneficiaries') == true)
		{
			if (vm.data.numBeneficiariosMedidos != null && vm.data.numBeneficiariosMedidos != "")
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");
				document.getElementById("beneficiaries1").classList.remove("hidden");
				vm.toggleActive	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
				document.getElementById("beneficiaries1").classList.add("hidden");
				vm.toggleActive = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
			document.getElementById("beneficiaries1").classList.add("hidden");
			vm.toggleActive = false;
		}
		
		if (vm.getToggleOption('impact_environment') == true)
		{
			if (
				(vm.data.impactoMANombre != null && vm.data.impactoMANombre != "")
				|| (vm.data.cambioPersonaMANombre != null && vm.data.cambioPersonaMANombre != "")
			)
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.add("active");
				document.getElementById("environment1").classList.remove("hidden");
				vm.toggleActive2	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.remove("active");
				document.getElementById("environment1").classList.add("hidden");
				vm.toggleActive2 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton2").classList.remove("active");
			document.getElementById("environment1").classList.add("hidden");
			vm.toggleActive2 = false;
		}
		
		if (vm.getToggleOption('impact_employees') == true)
		{
			if (vm.data.lstImpactoEmpleados != null && vm.data.lstImpactoEmpleados.length > 0 && vm.data.lstImpactoEmpleados[0].numEvaluado != null && vm.data.lstImpactoEmpleados[0].numEvaluado != '')
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.add("active");
				document.getElementById("employees1").classList.remove("hidden");
				vm.toggleActive3	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.remove("active");
				document.getElementById("employees1").classList.add("hidden");
				vm.toggleActive3 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton3").classList.remove("active");
			document.getElementById("employees1").classList.add("hidden");
			vm.toggleActive3 = false;
		}
		
		if (vm.getToggleOption('impact_participating_entities') == true)
		{
			if (vm.data.numEntidadesMedidas != null && vm.data.numEntidadesMedidas != '')
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.add("active");
				document.getElementById("participating1").classList.remove("hidden");
				vm.toggleActive4	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.remove("active");
				document.getElementById("participating1").classList.add("hidden");
				vm.toggleActive4 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton4").classList.remove("active");
			document.getElementById("participating1").classList.add("hidden");
			vm.toggleActive4 = false;
		}		
	}
	
	vm.changeToggleOption = function(optionName){
		if (optionName == 'impact_beneficiaries')
		{
			vm.toggleActive = !vm.toggleActive;		
			var actualOption = vm.getToggleOption(optionName);

			if (vm.toggleActive != actualOption)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");
				document.getElementById("beneficiaries1").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
				document.getElementById("beneficiaries1").classList.add("hidden");
			}
		}
		else if (optionName == 'impact_environment')
		{
			vm.toggleActive2 = !vm.toggleActive2;		
			var actualOption2 = vm.getToggleOption(optionName);

			if (vm.toggleActive2 != actualOption2)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive2 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.add("active");
				document.getElementById("environment1").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.remove("active");
				document.getElementById("environment1").classList.add("hidden");
			}
		}
		else if (optionName == 'impact_employees')
		{
			vm.toggleActive3 = !vm.toggleActive3;		
			var actualOption3 = vm.getToggleOption(optionName);

			if (vm.toggleActive3 != actualOption3)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive3 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.add("active");
				document.getElementById("employees1").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.remove("active");
				document.getElementById("employees1").classList.add("hidden");
			}
		}
		else if (optionName == 'impact_participating_entities')
		{
			vm.toggleActive4 = !vm.toggleActive4;		
			var actualOption4 = vm.getToggleOption(optionName);

			if (vm.toggleActive4 != actualOption4)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive4 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.add("active");
				document.getElementById("participating1").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.remove("active");
				document.getElementById("participating1").classList.add("hidden");
			}
		}
	}
	
	
    if (!vm.editId) {
		vm.initToggleOption();
	}
  }
})();
