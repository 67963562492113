(function() {
    'use strict';

    angular
        .module('app')
        .filter('capitalize', capitalizeFilter)
        .filter('logtypecodes', logtypecodesFilter);

    function capitalizeFilter(){
        return function(input){
            return (angular.isString(input) && input.length > 0) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : input;
        }
    }

    function logtypecodesFilter(){
        return function(input){
            switch (input.toLowerCase()) {
                case "create":
                    return "activity-history.log-tipo.create";
                case "read":
                    return "activity-history.log-tipo.read";
                case "update":
                    return "activity-history.log-tipo.update";
                case "delete":
                    return "activity-history.log-tipo.delete";
                case "duplicate":
                    return "activity-history.log-tipo.duplicate";
            }
            console.log('switch didn\'t work... trying something else');
            if(input.toLowerCase() == 'create'){
            } else if(input.toLowerCase() == 'create'){
                return "activity-history.log-tipo.create";
            } else if(input.toLowerCase() == 'read'){
                return "activity-history.log-tipo.read";
            } else if(input.toLowerCase() == 'update'){
                return "activity-history.log-tipo.update";
            } else if(input.toLowerCase() == 'delete'){
                return "activity-history.log-tipo.delete";
            } else if(input.toLowerCase() == 'duplicate'){
                return "activity-history.log-tipo.duplicate";
            }
            console.log("that didn't work.... :(");
            return input;
        }
    }
  })();