(function() {
  'use strict';

  /*
  var app = angular
  .module('app');
  */

    angular.module('app').factory('BearerAuthInterceptor', function ($window, $q, $state) {
    return {
      request: function(config) {

        if (config.url.indexOf('forgotpass') > -1
          || config.url.indexOf('toolbartop') > -1 //TODO esto lo arregla pero falla en otro sitio, buscar otra solucion
        ) {
          return config;
        }

        config.headers = config.headers || {};
        if ($window.localStorage.getItem('token')) {
          // may also use sessionStorage
          config.headers.Authorization = 'Bearer ' + $window.localStorage.getItem('token');
        }
        else if (config.url.indexOf('forgotpass') > -1) {

        }
        else if (config.url.indexOf('password') > -1) {

        }
        else {
          $state.go("login");
        }
        return config || $q.when(config);
      },
      response: function(response) {
        if (response.status === 401) {
          //  Redirect user to login page / signup Page.
          $state.go("login");
        }
        return response || $q.when(response);
      }
    };
  });

})();
