(function() {
  'use strict';

  angular
    .module('app')
    .component('filterReport', {
      templateUrl: 'components/filterReport/filterReport.html',
      controller: FilterReportController,
      controllerAs: '$ctrl'
    });

  /* @ngInject */
  function FilterReportController($q, $window, UserService, SelectService, RatingService, ReportService, Translator) {

    var $ctrl = this;
    var lodash = $window._

	$ctrl.now = new Date().getFullYear();
    $ctrl.firstTimePaisChange = true;
    $ctrl.firstTimeRegionChange = true;
    $ctrl.firstTimeUnidadChange = true;
	$ctrl.firstTimeSituacionChange = true;
	$ctrl.firstTimeTipoChange = true;
	$ctrl.firstTimeProjectChange = true;
	$ctrl.firstTimeInitiativeChange = true;
    $ctrl.firstTimeAgrupacionChange = true;
	$ctrl.firstTimeAgrupacionInternaChange = true;
	$ctrl.firstTimeOniciativaColaboradoresChange = true;
    $ctrl.firstTimeAreaChange = true;
    $ctrl.firstTimeMotivationChange = true;
    $ctrl.firstTimeODSChange = true;
	$ctrl.reportChartLoaded = false;
	$ctrl.filtersChanged = false;

    $ctrl.checks = [
      {checked: false, label: 'filterReport.tipo.contribución'},
      {checked: false, label: 'filterReport.unidad.negocio'},
      {checked: false, label: 'filterReport.agrInterna'}]
    if (UserService.getUserData().entidad.activoIniciativasInternas){
      $ctrl.checks.push({checked: false, label: 'agrIniciativaInterna'});
    }
    $ctrl.checks = $ctrl.checks.concat([
      {checked: false, label: 'filterReport.motivacion'},
      {checked: false, label: 'filterReport.area'},
      {checked: false, label: 'filterReport.ambito.geografico'},
      {checked: false, label: 'filterReport.pais.españa'},
      {checked: false, label: 'filterReport.ODS'},
      {checked: false, label: 'filterReport.empleados.y.voluntarios'},
      {checked: false, label: 'filterReport.efecto.multiplicador'},
      {checked: false, label: 'filterReport.resultados.comunidad'},
      {checked: false, label: 'filterReport.resultados.entidad'}]
      )
    $ctrl.checks.map(function(check, index) {
      check.index = index
      return check
    })
    $ctrl.reportData = null;
    $ctrl.projectStates = [
      {id: null,      nombre: "filterReport.todos"},
      {id: "ABIERTO", nombre: "filterReport.abiertos"},
      {id: "CERRADO", nombre: "filterReport.cerrados"}
    ]
    $ctrl.yesNoSelect = [
      {id: true, nombre: "filterReport.si"},
      {id: false, nombre: "filterReport.no"}
    ]
	$ctrl.yesNoAllSelect = [
	  {id: "all", nombre: "filterReport.todos"},
      {id: "true", nombre: "filterReport.si"},
      {id: "false", nombre: "filterReport.no"}
    ]
    $ctrl.contributionTypeSelect = [
      {id: null,      nombre: "filterReport.todos"},
      {id: "DINERO", nombre: "filterReport.dinero"},
      {id: "ESPECIE", nombre: "filterReport.especie"},
      {id: "TIEMPO", nombre: "filterReport.tiempo"},
      {id: "PROBONO", nombre: "filterReport.probono"},
      {id: "COSTE_GESTION", nombre: "filterReport.coste.gestion"},
    ]
    $ctrl.atributionSelect = [
      {id: null, nombre: "filterReport.todos"},
      {id: 0, nombre: "filterReport.entidad"},
      {id: 1, nombre: "filterReport.efecto.multiplicador"},
      {id: 2, nombre: "filterReport.otros"}
    ]
    $ctrl.validationSelect = [
      {id: null,  nombre: 'filterReport.todos'},
      {id: true,  nombre: "filterReport.validados"},
      {id: false, nombre: "filterReport.no.validados"}
    ]
    $ctrl.lbgSelect = [
      {id: null,  nombre: "filterReport.todas"},
      {id: true,  nombre: "filterReport.si.cumplen"},
      {id: false, nombre: "filterReport.no.cumplen"}
    ]
    $ctrl.filter = {
      proyecto: {
        estado: $ctrl.projectStates[0]
      },
      extraerInformacionProyectos: $ctrl.yesNoSelect[0],
      iniciativa: {
        cumpleLGB: $ctrl.lbgSelect[0]
      },
      extraerInformacionIniciativas: $ctrl.yesNoSelect[0],
	  ley112018: $ctrl.yesNoAllSelect[0],
      extraerInformacionODS: $ctrl.yesNoSelect[0],
      contribucion: {
        validado: $ctrl.validationSelect[0],
        atribucion: $ctrl.atributionSelect[0],
        tipoContribucion: $ctrl.contributionTypeSelect[0]
      },
      logro: {
        validado: $ctrl.validationSelect[0]
      },
      impacto: {
        validado: $ctrl.validationSelect[0]
      }
    }
    ////////////////
	$ctrl.getReportPDF_selectedFilters = function() {
		
		var proyects = {};
		jQuery('report-checker + section > div > div > .filterBox:visible').each(function(){
			var thisValue = '';
			if (jQuery(this).find('.selectInput').length > 0)
				thisValue = jQuery(this).find('.selectInput').val().trim();
			else if (jQuery(this).find('span > span.ng-binding').length > 0)
			{
				var firstTime = true;
				jQuery(this).find('span > span.ng-binding').each(function(){
					if (firstTime == true)
						firstTime = false;
					else
						thisValue += ", ";
					
					thisValue += jQuery(this).text().trim();
				});
			}
		 
			proyects[jQuery(this).find('label').text().trim()] = thisValue;
		});
		
		var initiatives = {};
		jQuery('report-checker + section + section > div > div > .filterBox:visible').each(function(){
			var thisValue = '';
			if (jQuery(this).find('.selectInput').length > 0)
				thisValue = jQuery(this).find('.selectInput').val().trim();
			else if (jQuery(this).find('span > span.ng-binding').length > 0)
			{
				var firstTime = true;
				jQuery(this).find('span > span.ng-binding').each(function(){
					if (firstTime == true)
						firstTime = false;
					else
						thisValue += ", ";
					
					thisValue += jQuery(this).text().trim();
				});
			}
		 
			initiatives[jQuery(this).find('label').text().trim()] = thisValue;
		});
		
		var contributions = {};
		jQuery('report-checker + section + section + section > div > .filterBox:visible').each(function(){
			var thisValue = '';
			if (jQuery(this).find('.selectInput').length > 0)
				thisValue = jQuery(this).find('.selectInput').val().trim();
			else if (jQuery(this).find('span > span.ng-binding').length > 0)
			{
				var firstTime = true;
				jQuery(this).find('span > span.ng-binding').each(function(){
					if (firstTime == true)
						firstTime = false;
					else
						thisValue += ", ";
					
					thisValue += jQuery(this).text().trim();
				});
			}
			else if (jQuery(this).find('.md-datepicker-input').length > 0)
			{
				var firstTime = true;
				thisValue += jQuery(jQuery(this).find('.md-datepicker-input')[0]).val();
			}
		 
			if (jQuery(this).find('label').text().trim() != '' || thisValue != '')
				contributions[jQuery(this).find('label').text().trim()] = thisValue;
		});
		
		var logros = {};
		jQuery('report-checker + section + section + section + section > div > .filterBox:visible').each(function(){
			var thisValue = '';
			if (jQuery(this).find('.selectInput').length > 0)
				thisValue = jQuery(this).find('.selectInput').val().trim();
			else if (jQuery(this).find('span > span.ng-binding').length > 0)
			{
				var firstTime = true;
				jQuery(this).find('span > span.ng-binding').each(function(){
					if (firstTime == true)
						firstTime = false;
					else
						thisValue += ", ";
					
					thisValue += jQuery(this).text().trim();
				});
			}
			else if (jQuery(this).find('.md-datepicker-input').length > 0)
			{
				var firstTime = true;
				thisValue += jQuery(jQuery(this).find('.md-datepicker-input')[0]).val();
			}
		 
			if (jQuery(this).find('label').text().trim() != '' || thisValue != '')
				logros[jQuery(this).find('label').text().trim()] = thisValue;
		});
		
		var impacts = {};
		jQuery('report-checker + section + section + section + section + section > div > .filterBox:visible').each(function(){
			var thisValue = '';
			if (jQuery(this).find('.selectInput').length > 0)
				thisValue = jQuery(this).find('.selectInput').val().trim();
			else if (jQuery(this).find('span > span.ng-binding').length > 0)
			{
				var firstTime = true;
				jQuery(this).find('span > span.ng-binding').each(function(){
					if (firstTime == true)
						firstTime = false;
					else
						thisValue += ", ";
					
					thisValue += jQuery(this).text().trim();
				});
			}
			else if (jQuery(this).find('.md-datepicker-input').length > 0)
			{
				var firstTime = true;
				thisValue += jQuery(jQuery(this).find('.md-datepicker-input')[0]).val();
			}
		 
			if (jQuery(this).find('label').text().trim() != '' || thisValue != '')
				impacts[jQuery(this).find('label').text().trim()] = thisValue;
		});

		var selectedFilters = '{"proyects":' + JSON.stringify(proyects) + ',"initiatives":' + JSON.stringify(initiatives) + ',"contributions":' + JSON.stringify(contributions) + ',"logros":' + JSON.stringify(logros) + ',"impacts":' + JSON.stringify(impacts) + ',"uid":' + angular.fromJson(localStorage.getItem("userData")).id + '}';
		
		return selectedFilters;
	};
	
	var totalCharts = 0;
	var canvasImg = [];
	$ctrl.getReportPDF = function(reportId) {
		$.LoadingOverlay("show");
		$ctrl.loadingPDF = true;
		totalCharts = 0;
		canvasImg = [];
		
		var selectedFilters = $ctrl.getReportPDF_selectedFilters();
	
		setTimeout(function(){
			
			jQuery('section.informe.bs #accordion #' + reportId).each(function(){
				jQuery(this).find('.collapse').addClass("show");
				jQuery(this).find('.si-plus').hide();
				jQuery(this).find('.si-minus').hide();
			}).promise().done( function(){
			
				setTimeout(function(){
					
					totalCharts = 0;
					jQuery('section.informe.bs #accordion #' + reportId).each(function(i){
						var reportId = jQuery(this).attr("id");
						switch(reportId)
						{
							case 'contribType':
								totalCharts = totalCharts +1;
								break;
							case 'udNegocio':
								var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
								
								if (jQuery(table).find("tbody tr").length > 30)
								{
									var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
									totalCharts = totalCharts + pages;
									
									if (jQuery(table).find("tbody tr").length <= 40)
										totalCharts = totalCharts +1;
								}
								else
								{
									totalCharts = totalCharts +1;
									if (jQuery(table).find("tbody tr").length > 6)
										totalCharts = totalCharts +1;
								}
								break;
							case 'agrInterna': 							
								var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
								
								if (jQuery(table).find("tbody tr").length > 30)
								{
									var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
									totalCharts = totalCharts +pages;
									
									if (jQuery(table).find("tbody tr").length <= 40)
										totalCharts = totalCharts +1;
								}
								else
								{
									totalCharts = totalCharts +1;
									
									if (jQuery(table).find("tbody tr").length > 6)
										totalCharts = totalCharts +1;
								}
								
								break;
							case 'agrIniciativaInterna':
							
								var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
								
								if (jQuery(table).find("tbody tr").length > 30)
								{
									var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
									totalCharts = totalCharts +pages;
									
									if (jQuery(table).find("tbody tr").length <= 40)
										totalCharts = totalCharts +1;
								}
								else
								{
									totalCharts = totalCharts +1;
									
									if (jQuery(table).find("tbody tr").length > 6)
										totalCharts = totalCharts +1;
								}
								
								break;
							case 'motivation':
								totalCharts = totalCharts +1;
								break;
							case 'area': 
								totalCharts = totalCharts +1;
								break;
							case 'pais':
								totalCharts = totalCharts +1;							
								break;
							case 'ambitoGeografico': 
								var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
								
								if (jQuery(table).find("tbody tr").length > 30)
								{
									var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
									totalCharts = totalCharts +pages;
									
									if (jQuery(table).find("tbody tr").length <= 40)
										totalCharts = totalCharts +1;
								}
								else
								{
									totalCharts = totalCharts +1;
									
									if (jQuery(table).find("tbody tr").length > 6)
										totalCharts = totalCharts +1;
								}
								break;
							case 'ods':
								totalCharts = totalCharts +2;
								break;
							case 'logeimp': 
								totalCharts = totalCharts +2;
								break;
							case 'efecto': 
								totalCharts = totalCharts +2;
								break;
							case 'rescom': 
								totalCharts = totalCharts +2;
								break;
							case 'resent': 
								totalCharts = totalCharts +1;
								break;
						}
					}).promise().done( function(){
						console.log('totalCharts: ' + totalCharts);
						
						jQuery('section.informe.bs #accordion #' + reportId).each(function(i){
							var reportId = jQuery(this).attr("id");
							switch(reportId)
							{
								/*
								1 contribType
								2 udNegocio
								4 agrIniciativaInterna
								3 agrInterna
								5 motivation
								6 area
								7 pais
								8 ambitoGeografico
								9 ods
								10 logeimp
								11 efecto
								12 rescom
								13 resent
								*/
								case 'contribType':
									html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
										canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
									});
									break;
								case 'udNegocio':
								
									var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
								
									if (jQuery(table).find("tbody tr").length > 6)
										jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).hide();
									
									if (jQuery(table).find("tbody tr").length > 30)
									{
										var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
										var pagePos = 0;
										if(pagePos < pages)
										{
											var minTr = pagePos *  30;
											var maxTr = ((pagePos + 1) * 30) -1;
											
											jQuery(table).find("tbody tr").each(function(ix){
												if (ix >= minTr && ix <= maxTr)
													jQuery(jQuery(table).find("tbody tr")[ix]).show();
												else
													jQuery(jQuery(table).find("tbody tr")[ix]).hide();
											}).promise().done( function(){
												html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
													canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
													
													pagePos++;
													nextPage(pages,pagePos,table,i,reportId);
												});
											});
										}
										else
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
									}
									else
									{
										html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
											
											if (jQuery(table).find("tbody tr").length > 6)
											{
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
												
												html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).then(function (canvas2){
													canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
												});
											}
										});
									}
									break;
								case 'agrInterna':
								
									var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
									
									if (jQuery(table).find("tbody tr").length > 6)
										jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).hide();
									
									if (jQuery(table).find("tbody tr").length > 30)
									{
										var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
										var pagePos = 0;
										if(pagePos < pages)
										{
											var minTr = pagePos *  30;
											var maxTr = ((pagePos + 1) * 30) -1;
											
											jQuery(table).find("tbody tr").each(function(ix){
												if (ix >= minTr && ix <= maxTr)
													jQuery(jQuery(table).find("tbody tr")[ix]).show();
												else
													jQuery(jQuery(table).find("tbody tr")[ix]).hide();
											}).promise().done( function(){
												html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
													canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
													
													pagePos++;
													nextPage(pages,pagePos,table,i,reportId);
												});
											});
										}
										else
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
									}
									else
									{
										html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
											
											if (jQuery(table).find("tbody tr").length > 6)
											{
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
												
												html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).then(function (canvas2){
													canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
												});
											}
										});
									}
									
									break;
								case 'agrIniciativaInterna':
									
									var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
									
									if (jQuery(table).find("tbody tr").length > 6)
										jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).hide();
									
									if (jQuery(table).find("tbody tr").length > 30)
									{
										var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
										var pagePos = 0;
										if(pagePos < pages)
										{
											var minTr = pagePos *  30;
											var maxTr = ((pagePos + 1) * 30) -1;
											
											jQuery(table).find("tbody tr").each(function(ix){
												if (ix >= minTr && ix <= maxTr)
													jQuery(jQuery(table).find("tbody tr")[ix]).show();
												else
													jQuery(jQuery(table).find("tbody tr")[ix]).hide();
											}).promise().done( function(){
												html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
													canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
													
													pagePos++;
													nextPage(pages,pagePos,table,i,reportId);
												});
											});
										}
										else
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
									}
									else
									{
										html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
											
											if (jQuery(table).find("tbody tr").length > 6)
											{
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
												
												html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).then(function (canvas2){
													canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
												});
											}
										});
									}
									
									break;
								case 'motivation':
									html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
										canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
									});
									break;
								case 'area': 
									html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
										canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
									});
									break;
								case 'pais':
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).hide();
								
									html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
										canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
										
										jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
										
										html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).then(function (canvas2){
											canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
										});
									});								
									break;
								case 'ambitoGeografico':
								
									var table = jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard > .card-body > div > div.row > div > div.table-responsive table")[0];
									
									if (jQuery(table).find("tbody tr").length > 6)
										jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).hide();
									
									if (jQuery(table).find("tbody tr").length > 30)
									{
										var pages = Math.ceil(jQuery(table).find("tbody tr").length / 30);
										var pagePos = 0;
										if(pagePos < pages)
										{
											var minTr = pagePos *  30;
											var maxTr = ((pagePos + 1) * 30) -1;
											
											jQuery(table).find("tbody tr").each(function(ix){
												if (ix >= minTr && ix <= maxTr)
													jQuery(jQuery(table).find("tbody tr")[ix]).show();
												else
													jQuery(jQuery(table).find("tbody tr")[ix]).hide();
											}).promise().done( function(){
												html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
													canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
													
													pagePos++;
													nextPage(pages,pagePos,table,i,reportId);
												});
											});
										}
										else
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
									}
									else
									{
										html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
											
											if (jQuery(table).find("tbody tr").length > 6)
											{
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
												
												html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).then(function (canvas2){
													canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
													
													jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
												});
											}
										});
									}
									break;
								case 'ods':
									jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('ods-chart').hide();
									
									html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
										canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
										
										jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('ods-chart').show();
										
										html2canvas(jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('ods-chart'))[0]).then(function (canvas2){
											canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
										});
									});
									break;
								case 'logeimp': 
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[3]).hide();
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[4]).hide();
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[5]).hide();
									
									jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body').each(function(ix){
										html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body')[ix]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
											
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[3]).show();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[4]).show();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[5]).show();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[0]).hide();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[1]).hide();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[2]).hide();
											
											html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body')[ix]).then(function (canvas){
												canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
												
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[0]).show();
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[1]).show();
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[2]).show();
											});
										});
									});
									break;
								case 'efecto': 
									jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div').each(function(ix){
										html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[ix]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
										});
									});
									break;
								case 'rescom':
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[1]).hide();
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[2]).hide();
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[4]).hide();
									jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[6]).hide();
									
									jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body').each(function(ix){
										html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body')[ix]).then(function (canvas){
											canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
											
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[1]).show();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[2]).show();
										
										
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[0]).hide();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[3]).hide();
											jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[5]).hide();
											
											html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body')[ix]).then(function (canvas2){
												canvasImg.push(canvas2.toDataURL("image/png").replace("image/png", "image/octet-stream"));
												
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[1]).hide();
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[2]).hide();
											
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[4]).show();
												jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[6]).show();
												
												html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body')[ix]).then(function (canvas3){
													canvasImg.push(canvas3.toDataURL("image/png").replace("image/png", "image/octet-stream"));
													
													jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[1]).show();
													jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[2]).show();
													
													jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[0]).show();
													jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[3]).show();
													jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find('.thisCard > .card-body > div')[5]).show();
												});
											});
										});
									});
									break;
								case 'resent': 
									html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
										canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
									});
									break;
							}
						}).promise().done( function(){					
							waitToCharsRendered(selectedFilters, reportId);
						});
					});
				}, 750); 
			});
		}, 750); 
	};
	
	function nextPage(pages,pagePos,table,i,reportId)
	{
		if(pagePos < pages)
		{
			var minTr = pagePos *  30;
			var maxTr = ((pagePos + 1) * 30) -1;
			
			jQuery(table).find("tbody tr").each(function(ix){
				if (ix >= minTr && ix <= maxTr)
					jQuery(jQuery(table).find("tbody tr")[ix]).show();
				else
					jQuery(jQuery(table).find("tbody tr")[ix]).hide();
			}).promise().done( function(){
				html2canvas(jQuery('section.informe.bs #accordion #' + reportId)[i]).then(function (canvas){
					canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
					
					pagePos++;
					nextPage(pages,pagePos,table,i,reportId);
				});
			});
		}
		else
		{
			jQuery(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).show();
			
			if (jQuery(table).find("tbody tr").length <= 40)
			{
				html2canvas(jQuery(jQuery('section.informe.bs #accordion #' + reportId)[i]).find(".thisCard .tablePie")[0]).then(function (canvas){
					canvasImg.push(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
				});
			}
		}
	}
	function waitToCharsRendered(selectedFilters, reportId) {
		console.log('canvasImg: ' + canvasImg.length);
		if (canvasImg.length < totalCharts)
		{			
			setTimeout(function(){
				waitToCharsRendered(selectedFilters, reportId);
			}, 500);  
		}
		else
		{
			jQuery('section.informe.bs #accordion #' + reportId).each(function(){
				jQuery(this).find('.collapse').removeClass("show");
				jQuery(this).find('.si-plus').css({ 'display' : ''});
				jQuery(this).find('.si-minus').css({ 'display' : ''});
				jQuery(this).find('.card-header').addClass("collapsed");
			});
			
			$ctrl.loadingPDF = false;
						
			var form = jQuery('<form>', {'method': 'POST', 'action': '/pdf/pdf.php', 'class': 'pdfForm'}).hide();
			form.append(jQuery('<input>', {'type': 'hidden', 'name': 'lang', 'value': Translator.getActualLanguage()}));
			form.append(jQuery('<input>', {'type': 'hidden', 'name': 'filters', 'value': selectedFilters}));
			form.append(jQuery('<input>', {'type': 'hidden', 'name': 'charts', 'value': JSON.stringify(canvasImg)}));

			jQuery('body').append(form);
			
			jQuery(".pdfForm").unbind();
			jQuery(".pdfForm").submit(function(event){
				event.preventDefault();
				
				jQuery.ajax({
					type: "POST",
					url: "/pdf/pdf.php",
					xhrFields: {
						responseType: 'blob'
					},
					data: jQuery(this).serialize(),
					success: function(blob, status, xhr) {

						var filename = "";
						var disposition = xhr.getResponseHeader('Content-Disposition');
						if (disposition && disposition.indexOf('attachment') !== -1) {
							var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
							var matches = filenameRegex.exec(disposition);
							if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
						}

						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(blob, filename);
						} else {
							var URL = window.URL || window.webkitURL;
							var downloadUrl = URL.createObjectURL(blob);

							if (filename) {
								var a = document.createElement("a");
								if (typeof a.download === 'undefined') {
									window.location.href = downloadUrl;
								} else {
									a.href = downloadUrl;
									a.download = filename;
									document.body.appendChild(a);
									a.click();
								}
							} else {
								window.location.href = downloadUrl;
							}

							setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100);
						}
						
						form.remove();
						$.LoadingOverlay("hide");
					}
				})
			});
			
			form.submit();
		}
	}
	
    $ctrl.getReportCharts = function() {
      $ctrl.loading = true;

    if ($ctrl.filter.proyecto.fechaInicioDesde) $ctrl.filter.proyecto.fechaInicioDesde = moment($ctrl.filter.proyecto.fechaInicioDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.proyecto.fechaInicioHasta) $ctrl.filter.proyecto.fechaInicioHasta = moment($ctrl.filter.proyecto.fechaInicioHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.proyecto.fechaFinDesde) $ctrl.filter.proyecto.fechaFinDesde = moment($ctrl.filter.proyecto.fechaFinDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.proyecto.fechaFinHasta) $ctrl.filter.proyecto.fechaFinHasta = moment($ctrl.filter.proyecto.fechaFinHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.iniciativa.fechaInicioDesde) $ctrl.filter.iniciativa.fechaInicioDesde = moment($ctrl.filter.iniciativa.fechaInicioDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.iniciativa.fechaInicioHasta) $ctrl.filter.iniciativa.fechaInicioHasta = moment($ctrl.filter.iniciativa.fechaInicioHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.iniciativa.fechaFinDesde) $ctrl.filter.iniciativa.fechaFinDesde = moment($ctrl.filter.iniciativa.fechaFinDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.iniciativa.fechaFinHasta) $ctrl.filter.iniciativa.fechaFinHasta = moment($ctrl.filter.iniciativa.fechaFinHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.contribucion.fechaDesde) $ctrl.filter.contribucion.fechaDesde = moment($ctrl.filter.contribucion.fechaDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.contribucion.fechaHasta) $ctrl.filter.contribucion.fechaHasta = moment($ctrl.filter.contribucion.fechaHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.logro.fechaDesde) $ctrl.filter.logro.fechaDesde = moment($ctrl.filter.logro.fechaDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.logro.fechaHasta) $ctrl.filter.logro.fechaHasta = moment($ctrl.filter.logro.fechaHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.impacto.fechaDesde) $ctrl.filter.impacto.fechaDesde = moment($ctrl.filter.impacto.fechaDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    if ($ctrl.filter.impacto.fechaHasta) $ctrl.filter.impacto.fechaHasta = moment($ctrl.filter.impacto.fechaHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
	//if ($ctrl.filter.divisaInforme) localStorage.setItem("divisaInforme", angular.toJson($ctrl.filter.divisaInforme))
    if (UserService.getUserData().divisa) localStorage.setItem("divisaInforme", angular.toJson(UserService.getUserData().divisa))


 //.transform('+01MMYYYY'), 'DD/MM/YYYY', false);

      var promises = $ctrl.checks.filter(function(check) {
        return check.checked && check.url
      }).map(function(check) {
        return ReportService.getReport(check.url, $ctrl.getFormQuery())
        .then(function(report) {
          report.index = check.index;
          report.label = check.label;
          return report
        })
      })
      $q.all(promises).then(function(reports) {
        $ctrl.reportData = reports.map(function(report) {
          var mapper = ReportService.getMappers()[report.label] || angular.identity
          return mapper(report)
        });			
      }).finally(function() {
        $ctrl.loading = false;
		$ctrl.reportChartLoaded = true;
		$ctrl.filtersChanged = false;
      });
    }
	
	$ctrl.showGraphic = function(pie) {
		var obj = [];
		var total = 0;
		var thisType = 'pie';
		var thisElementId = '';
		pie.forEach( function(v,i) {
			if (typeof(v.value) !== 'undefined')
			{
				total += parseFloat(v.value);
				obj.push(v);
			}
			if (typeof(v.type) !== 'undefined')
				thisType = v.type;
			
			thisElementId = v.key;
		});		

		var values = $.map(obj,function(e){
			if (e.value == 0 || total == 0)
				return 0;
				
			return parseFloat(parseFloat(((parseFloat(e.value) * 100) / total)).toFixed(1));
		});
		var labels = $.map(obj,function(e){ return e.label;});
		
		if(thisType == 'pie')		
			setTimeout(function(){ cargadonut('graphic' + thisElementId,values,labels);}, 0);
		else if (thisType == 'bar')
			setTimeout(function(){ cargabar('graphic' + thisElementId,values,labels);}, 0);
    }
	
	$ctrl.showBar = function(chart, index) {
		var values = $.map(chart.data,function(e){ return e.label;})
		var labels = $.map(chart.data,function(e){ return e.key;})
		setTimeout(function(){ cargabar('bar' + index,values,labels);}, 0);
    }

    $ctrl.checkAll = function() {
      $ctrl.checks = $ctrl.checks.map(function(check) {
        check.checked = true
        return check
      })
    }
    $ctrl.uncheckAll = function() {
      $ctrl.checks = $ctrl.checks.map(function(check) {
        check.checked = false
        return check
      })
    }
    /*$ctrl.onDivisaSelected = function(filter) {
      $ctrl.filter.divisaInforme = filter
    }*/
    $ctrl.onCountriesSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimePaisChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre!=='filterReport.todos'})
        $ctrl.firstTimePaisChange = false
      }
      else if ((!$ctrl.firstTimePaisChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.countries[0]]
        $ctrl.firstTimePaisChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimePaisChange = false
      }
      $ctrl.filter.iniciativa.pais = filter2
      var promises = filtro
        .filter(function(country) {
          return country.id && country.nombre
        })
        .map(SelectService.getStates)
      $q.all(promises)
        .then(lodash.flatten)
        .then(function(regionsFlat) {
          $ctrl.regions = $ctrl.addOptionAll(regionsFlat, 'filterReport.todas')
        })
    }
    $ctrl.onRegionsSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeRegionChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
        $ctrl.firstTimeRegionChange = false
      }
      else if ((!$ctrl.firstTimeRegionChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.regions[0]]
        $ctrl.firstTimeRegionChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeRegionChange = false
      }
      $ctrl.filter.iniciativa.region = filter2
    }
	$ctrl.onProjectSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeProjectChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeProjectChange = false
      }
      else if ((!$ctrl.firstTimeProjectChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.byProjects[0]]
        $ctrl.firstTimeProjectChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeProjectChange = false
      }
      $ctrl.filter.proyecto.byProject = filter2
	  $ctrl.updateInitiatives();
    }
	$ctrl.onInitiativeSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeInitiativeChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeInitiativeChange = false
      }
      else if ((!$ctrl.firstTimeInitiativeChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.byInitiatives[0]]
        $ctrl.firstTimeInitiativeChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeInitiativeChange = false
      }
      $ctrl.filter.proyecto.byInitiatives = filter2
    }
    $ctrl.onUnidadSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeUnidadChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
        $ctrl.firstTimeUnidadChange = false
      }
      else if ((!$ctrl.firstTimeUnidadChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.udsNegocio[0]]
        $ctrl.firstTimeUnidadChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeUnidadChange = false
      }
      $ctrl.filter.proyecto.unidadNegocio = filter2
	  $ctrl.updateProjects();
    }
    $ctrl.onAgrupacionInterna = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeAgrupacionChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
        $ctrl.firstTimeAgrupacionChange = false
      }
      else if ((!$ctrl.firstTimeAgrupacionChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.agrsInternas[0]]
        $ctrl.firstTimeAgrupacionChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeAgrupacionChange = false
      }
      $ctrl.filter.proyecto.agrupacionInterna = filter2
	  $ctrl.updateProjects();
    }
	$ctrl.onSituacionSelected = function(filtro) {
	  var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeSituacionChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeSituacionChange = false
      }
      else if ((!$ctrl.firstTimeSituacionChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.filter.logro.situacionSelect[0]]
        $ctrl.firstTimeSituacionChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeSituacionChange = false
      }
      $ctrl.filter.logro.situacion = filter2
    }
	$ctrl.onTipoSelected = function(filtro) {
	  var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeTipoChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeTipoChange = false
      }
      else if ((!$ctrl.firstTimeTipoChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.filter.logro.tipoLogroSelect[0]]
        $ctrl.firstTimeTipoChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeTipoChange = false
      }
      $ctrl.filter.logro.tipoLogro = filter2
    }
	$ctrl.onAgrupacionInternaIniciativa = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeAgrupacionInternaChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
        $ctrl.firstTimeAgrupacionInternaChange = false
      }
      else if ((!$ctrl.firstTimeAgrupacionInternaChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.agrsInternasIniciativa[0]]
        $ctrl.firstTimeAgrupacionInternaChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeAgrupacionInternaChange = false
      }
      $ctrl.filter.proyecto.agrupacionInternaIniciativa = filter2
    }
	$ctrl.oniniciativaColaboradores = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeOniciativaColaboradoresChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeOniciativaColaboradoresChange = false
      }
      else if ((!$ctrl.firstTimeOniciativaColaboradoresChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.iniciativaColaboradores[0]]
        $ctrl.firstTimeOniciativaColaboradoresChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeOniciativaColaboradoresChange = false
      }
      $ctrl.filter.proyecto.iniciativaColaboradores = filter2
    }
    $ctrl.onAreaPrimaria = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeAreaChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
        $ctrl.firstTimeAreaChange = false
      }
      else if ((!$ctrl.firstTimeAreaChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.areas[0]]
        $ctrl.firstTimeAreaChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeAreaChange = false
      }
      $ctrl.filter.iniciativa.areaPrimaria = filter2
    }
    $ctrl.onMotivations = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeMotivationChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
        $ctrl.firstTimeMotivationChange = false
      }
      else if ((!$ctrl.firstTimeMotivationChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.motivations[0]]
        $ctrl.firstTimeMotivationChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeMotivationChange = false
      }
      $ctrl.filter.iniciativa.motivacion = filter2
    }
    $ctrl.onODS = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeODSChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeODSChange = false
      }
      else if ((!$ctrl.firstTimeODSChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.odss[0]]
        $ctrl.firstTimeODSChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeODSChange = false
      }
      $ctrl.filter.iniciativa.odsPrimario = filter2
    }
    $ctrl.addOptionAll = function(selectors, label) {
      return [{id: null, nombre: label}].concat(selectors)
    }
    $ctrl.getFormQuery = function() {
      var transformToIdIfId = function(filterItem) {
        /*
        return filterItem && angular.isDefined(filterItem.id) ? filterItem.id : filterItem;
        */

        if(filterItem != null && filterItem.id != null && filterItem.id != "" && filterItem.id != angular.isUndefined){
          return filterItem.id;
        }else if(filterItem != null && filterItem != "" && filterItem != angular.isUndefined) {
          return filterItem;
        }else{
          return "";
        }

      }

      var transformToIdIfArray = function(filterItem) {
        return lodash.isArray(filterItem) ? filterItem.map(lodash.property("id")) : transformToIdIfId(filterItem);
      }

      return {
        tipoInformes: lodash.map(lodash.filter($ctrl.checks, 'checked'), 'index'),
        //divisaInforme: $ctrl.filter.divisaInforme.id,
		divisaInforme: UserService.getUserData().divisa.id,
        proyecto: lodash.mapValues($ctrl.filter.proyecto, transformToIdIfArray),
        extraerInformacionProyectos: transformToIdIfId($ctrl.filter.extraerInformacionProyectos),
        iniciativa: lodash.mapValues($ctrl.filter.iniciativa, transformToIdIfArray),
        extraerInformacionIniciativas: transformToIdIfId($ctrl.filter.extraerInformacionIniciativas),
		ley112018: transformToIdIfId($ctrl.filter.ley112018),
        extraerInformacionODS: transformToIdIfId($ctrl.filter.extraerInformacionODS),
        contribucion: lodash.mapValues($ctrl.filter.contribucion, transformToIdIfId),
        logro: lodash.mapValues($ctrl.filter.logro, transformToIdIfId),
		logroSituacion: $ctrl.filter.logro.situacion.map(function(obj){return obj.id}),
		logroTipo: $ctrl.filter.logro.tipoLogro.map(function(obj){return obj.id}),
        impacto: lodash.mapValues($ctrl.filter.impacto, transformToIdIfId)
      }
    }

	$ctrl.updateProjects = function()
	{
		RatingService.getAllProjectOptions($ctrl.filter.proyecto.estado,$ctrl.filter.proyecto.fechaInicioDesde,$ctrl.filter.proyecto.fechaInicioHasta,$ctrl.filter.proyecto.fechaFinDesde,$ctrl.filter.proyecto.fechaFinHasta,$ctrl.filter.proyecto.unidadNegocio,$ctrl.filter.proyecto.agrupacionInterna)
		  .then(function(projectOption) {
			  
			  RatingService.getAllInitiativesOptions(projectOption,$ctrl.filter.iniciativa.cumpleLGB,$ctrl.filter.iniciativa.pais,$ctrl.filter.iniciativa.region,$ctrl.filter.iniciativa.areaPrimaria,$ctrl.filter.iniciativa.motivacion,$ctrl.filter.iniciativa.odsPrimario,$ctrl.filter.proyecto.agrupacionInternaIniciativa,$ctrl.filter.iniciativa.fechaInicioDesde,$ctrl.filter.iniciativa.fechaInicioHasta,$ctrl.filter.iniciativa.fechaFinDesde,$ctrl.filter.iniciativa.fechaFinHasta,$ctrl.filter.proyecto.iniciativaColaboradores)
			  .then(function(initiativesOption) {
				$ctrl.byInitiatives = $ctrl.addOptionAll(initiativesOption, 'filterReport.todos')
				$ctrl.filter.proyecto.byInitiatives = [$ctrl.byInitiatives[0]]
				$ctrl.byProjects = $ctrl.addOptionAll(projectOption, 'filterReport.todos')
				$ctrl.filter.proyecto.byProject = [$ctrl.byProjects[0]]
			  })
		  })
	}
	
	$ctrl.updateInitiatives = function()
	{
		RatingService.getAllInitiativesOptions($ctrl.filter.proyecto.byProject,$ctrl.filter.iniciativa.cumpleLGB,$ctrl.filter.iniciativa.pais,$ctrl.filter.iniciativa.region,$ctrl.filter.iniciativa.areaPrimaria,$ctrl.filter.iniciativa.motivacion,$ctrl.filter.iniciativa.odsPrimario,$ctrl.filter.proyecto.agrupacionInternaIniciativa,$ctrl.filter.iniciativa.fechaInicioDesde,$ctrl.filter.iniciativa.fechaInicioHasta,$ctrl.filter.iniciativa.fechaFinDesde,$ctrl.filter.iniciativa.fechaFinHasta, $ctrl.filter.proyecto.iniciativaColaboradores)
		  .then(function(initiativesOption) {
			$ctrl.byInitiatives = $ctrl.addOptionAll(initiativesOption, 'filterReport.todos')
			$ctrl.filter.proyecto.byInitiatives = [$ctrl.byInitiatives[0]]
		  })	
	}
	
    function convertArrayToParams(array){

    }

    function getQueryParams(object, paramName, valueAttr){
      if(object != null && object[valueAttr] != null && object[valueAttr] != ""){
        return paramName + "=" + object[valueAttr];
      }else{
        return "";
      }
    }

    $ctrl.getReport = function() {
      $ctrl.loading = true
      ReportService.getExcelReportWithFilter($ctrl.getFormQuery())
      .then(function() {
        $ctrl.loading = false
      })
    }

    $ctrl.$onInit = function() {
		var me = UserService.getUserData()
      /*
      SelectService.getBadges()
      .then(function(divisas) {
        $ctrl.divisas = divisas
        if (me.divisaInforme == null) {
          $ctrl.filter.divisaInforme = divisas[0]
        }
        else {
          $ctrl.filter.divisaInforme = me.divisaInforme
        }

      })*/
      RatingService.getAllAgrInterna()
      .then(function(agrsInternas) {
        $ctrl.agrsInternas = $ctrl.addOptionAll(agrsInternas, 'filterReport.todas')
        $ctrl.filter.proyecto.agrupacionInterna = [$ctrl.agrsInternas[0]]
      })
	  RatingService.getAllAgrIniciativaInterna()
      .then(function(agrsInternasIniciativa) {
        $ctrl.agrsInternasIniciativa = $ctrl.addOptionAll(agrsInternasIniciativa, 'filterReport.todas')
        $ctrl.filter.proyecto.agrupacionInternaIniciativa = [$ctrl.agrsInternasIniciativa[0]]
      })
	  RatingService.getAllIniciativaColaboradores()
      .then(function(iniciativaColaboradores) {
		iniciativaColaboradores.sort(function(a,b){
			return a.nombre - b. nombre;
	    });
        $ctrl.iniciativaColaboradores = $ctrl.addOptionAll(iniciativaColaboradores, 'filterReport.todos')
        $ctrl.filter.proyecto.iniciativaColaboradores = [$ctrl.iniciativaColaboradores[0]]
      }) 
      RatingService.getAllUdNegocio()
      .then(function(udsNegocio) {
        $ctrl.udsNegocio = $ctrl.addOptionAll(udsNegocio, 'filterReport.todas')
        $ctrl.filter.proyecto.unidadNegocio = [$ctrl.udsNegocio[0]]
      })
	  RatingService.getAllProjectOptions(null,null,null,null,null,null,null)
      .then(function(projectOption) {
        $ctrl.byProjects = $ctrl.addOptionAll(projectOption, 'filterReport.todos')
        $ctrl.filter.proyecto.byProject = [$ctrl.byProjects[0]]
      })
	  RatingService.getAllInitiativesOptions(null,null,null,null,null,null,null,null,null,null,null,null)
		  .then(function(initiativesOption) {
			$ctrl.byInitiatives = $ctrl.addOptionAll(initiativesOption, 'filterReport.todos')
			$ctrl.filter.proyecto.byInitiatives = [$ctrl.byInitiatives[0]]
		  })
      SelectService.getCountry()
      .then(function(countries) {
        $ctrl.countries = $ctrl.addOptionAll(countries, 'filterReport.todos')
        $ctrl.filter.iniciativa.pais = [$ctrl.countries[0]]
        $ctrl.filter.iniciativa.region = $ctrl.addOptionAll([], 'filterReport.todas')
      })
      SelectService.getMotivacion(null, "ENTIDAD")
      .then(function(motivations) {
        $ctrl.motivations = $ctrl.addOptionAll(motivations, 'filterReport.todas')
        $ctrl.filter.iniciativa.motivacion = [$ctrl.motivations[0]]
      })
      SelectService.getArea()
      .then(function(areas) {
        $ctrl.areas = $ctrl.addOptionAll(areas, 'filterReport.todas')
        $ctrl.filter.iniciativa.areaPrimaria = [$ctrl.areas[0]]
      })
      SelectService.getOds()
      .then(function(odss)  {
        $ctrl.odss = $ctrl.addOptionAll(odss, 'filterReport.todos')
        $ctrl.filter.iniciativa.odsPrimario = $ctrl.odss.slice(0, 1)
      })
	  
	  SelectService.getSituacion().then(function (res) {
		$ctrl.situaciones = $ctrl.addOptionAll(res, 'filterReport.todos');
        $ctrl.filter.logro.situacionSelect = $ctrl.situaciones;
		$ctrl.filter.logro.situacion =  [$ctrl.filter.logro.situacionSelect[0]];
      })

      SelectService.getTipoLogro().then(function (res) {
		$ctrl.tiposLogros = $ctrl.addOptionAll(res, 'filterReport.todos');
        $ctrl.filter.logro.tipoLogroSelect = $ctrl.tiposLogros;
		$ctrl.filter.logro.tipoLogro =  [$ctrl.filter.logro.tipoLogroSelect[0]];
      })

      $ctrl.atributionSelect[1].nombre = me.entidad.nombre
      $ctrl.regions = $ctrl.addOptionAll([], 'filterReport.todas')
    }
    $ctrl.$onChanges = function(changesObj) { };
    $ctrl.$onDestroy = function() { };
  }
})();
