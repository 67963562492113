(function () {
  'use strict';

  angular
    .module('app')
    .service('BreadcrumService', BreadcrumService);

  /* @ngInject */
  function BreadcrumService($http, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getProyectCode = function (idProyecto, idDatosIniciativa) {
      var url = api + "/apiV2/migapan";
      if (idDatosIniciativa != null) {
        url += "?idDatosIniciativa=" + idDatosIniciativa;
      }
      else if (idProyecto != null) {
        url += "?idProyecto=" + idProyecto;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });

    }

    service.saveFilters = function (filters, entity) {
      localStorage.setItem(entity + "Filters", angular.toJson(filters));
    }

    service.getFilters = function (entity) {
      var filters = null;
      if (localStorage.getItem(entity + "Filters")) {
        filters = angular.fromJson(localStorage.getItem(entity + "Filters"));
      }
      localStorage.removeItem(entity + "Filters");//TODO eliminar si no queremos que se borren los filtros hasta que los borre el usuario
      return filters;
    }

    return service;
  }

})();
