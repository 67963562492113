(function() {
  'use strict';

  angular
    .module('app')
    .service('ReportService', ReportService);

  function getUserData() {
    // return JSON.parse(localStorage.getItem("userData"));
    return angular.fromJson(localStorage.getItem("userData"));
  }

  /* @ngInject */
  function ReportService(TokenService, config, Blob, FileSaver,
                         $http, $httpParamSerializerJQLike, $filter, $translate) {
    var token = TokenService.getToken()
    var headers = {Authorization: "Bearer " + token}
    var service = {}
    var api = config.api+"/api"
    var filterNumber = $filter("impactNumber2")
    var filterNull = $filter("filterNull")
    var percentInforme = $filter("percentInforme")


    service.encodeQuery = function(query) {


      return decodeURIComponent($httpParamSerializerJQLike(query))
        .replace(/\[\]/g, '')
        .replace(/(\w+)\[(\w+)\]/g, '$1.$2');
    }

    service.getExcelReportWithFilter = function(query) {
      var url = api + "/exportData/filter-xls?"+service.encodeQuery(query)
      var mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      return $http.get(url, {headers: headers, responseType: 'blob'})
        .then(function(res) { return res.data })
        .then(function(excel) {
          FileSaver.saveAs(excel, 'informe.xlsx', {type: mimeType})
        })
    }
	service.getExcelReportWithFilterNew = function(query) {
      var url = config.api+"/apiV2/exportData/filter-xls?"+service.encodeQuery(query)
      var mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      return $http.get(url, {headers: headers, responseType: 'blob'})
        .then(function(res) { return res.data })
        .then(function(excel) {
          FileSaver.saveAs(excel, 'informe.xlsx', {type: mimeType})
        })
    }
    service.getReport = function(type, query) {

      //Llamamos a la función para quitar los parámetros nulos y que no se envíen como parámetros
      query = cleanQueryParams(query);

      var url = api+"/informes"+type+"?"+service.encodeQuery(query);
      return $http.get(url, {headers: headers})
        .then(function(res) {
          return res.data
        })
    }


    /**
     * Esta función se encarga de comprobar si hay algún parámetro nulo y si es así lo ignora para que no se envíe
     * en la petición
     *
     * @param queryParams Objeto con todos los filtros seleccionaos por el usuario
     * @returns {Object} Devuelve el objeto ya filtrado (no hay valores nulo en tal objeto)
     */
    function cleanQueryParams(queryParams){

      var queryParamsCleaned = new Object();

      if(queryParams.contribucion != null && queryParams.contribucion.atribucion != null){//OK
        if(queryParamsCleaned.contribucion == null){
          queryParamsCleaned.contribucion = new Object();
        }

        if(queryParams.contribucion.atribucion["id"] != null){
          queryParamsCleaned.contribucion.atribucion = queryParams.contribucion.atribucion["id"];
        }else{
          queryParamsCleaned.contribucion.atribucion = queryParams.contribucion.atribucion;
        }

      }

      if(queryParams.contribucion != null && queryParams.contribucion.tipoContribucion != null){//OK
        if(queryParamsCleaned.contribucion == null){
          queryParamsCleaned.contribucion = new Object();
        }
        queryParamsCleaned.contribucion.tipoContribucion = queryParams.contribucion.tipoContribucion;
      }

      //Contribución validado
      if(queryParams.contribucion != null && queryParams.contribucion.validado != null){//OK
        if(queryParamsCleaned.contribucion == null){
          queryParamsCleaned.contribucion = new Object();
        }

        if(queryParams.contribucion.validado["id"] != null){
          queryParamsCleaned.contribucion.validado = queryParams.contribucion.validado["id"];
        }else{
          queryParamsCleaned.contribucion.validado = queryParams.contribucion.validado;
        }
      }

      //Contribucion fecha desde
      if(queryParams.contribucion != null && queryParams.contribucion.fechaDesde != null){
        if(queryParamsCleaned.contribucion == null){
          queryParamsCleaned.contribucion = new Object();
        }
        queryParamsCleaned.contribucion.fechaDesde = queryParams.contribucion.fechaDesde;
      }

      //Contribucion fecha hasta
      if(queryParams.contribucion != null && queryParams.contribucion.fechaHasta != null){
        if(queryParamsCleaned.contribucion == null){
          queryParamsCleaned.contribucion = new Object();
        }
        queryParamsCleaned.contribucion.fechaHasta = queryParams.contribucion.fechaHasta;
      }



      if(queryParams.divisaInforme != null){//OK
        queryParamsCleaned.divisaInforme = queryParams.divisaInforme;
      }

      if(queryParams.extraerInformacionIniciativas != null){//OK
        queryParamsCleaned.extraerInformacionIniciativas = queryParams.extraerInformacionIniciativas;
      }
	  
	  if(queryParams.ley112018 != null){//OK
        queryParamsCleaned.ley112018 = queryParams.ley112018;
      }

      if(queryParams.extraerInformacionODS != null){//OK
        queryParamsCleaned.extraerInformacionODS = queryParams.extraerInformacionODS;
      }

      if(queryParams.extraerInformacionProyectos != null){//OK
        queryParamsCleaned.extraerInformacionProyectos = queryParams.extraerInformacionProyectos;
      }

      //Impacto fecha desde
      if(queryParams.impacto != null && queryParams.impacto.fechaDesde != null){
        if(queryParamsCleaned.impacto == null){
          queryParamsCleaned.impacto = new Object();
        }
        queryParamsCleaned.impacto.fechaDesde = queryParams.impacto.fechaDesde;
      }

      //Logro fecha hasta
      if(queryParams.impacto != null && queryParams.impacto.fechaHasta != null){
        if(queryParamsCleaned.impacto == null){
          queryParamsCleaned.impacto = new Object();
        }
        queryParamsCleaned.impacto.fechaHasta = queryParams.impacto.fechaHasta;
      }

      //Impacto validación
      if(queryParams.impacto != null && queryParams.impacto.validado != null){//OK

        if(queryParamsCleaned.impacto == null){
          queryParamsCleaned.impacto = new Object();
        }

        if(queryParamsCleaned.impacto.validado == null){
          queryParamsCleaned.impacto.validado = new Object();
        }

        if(queryParams.impacto.validado["id"] != null){
          queryParamsCleaned.impacto.validado = queryParams.impacto.validado["id"];
        }else{
          queryParamsCleaned.impacto.validado = queryParams.impacto.validado;
        }

      }

      if(queryParams.iniciativa.areaPrimaria != null && queryParams.iniciativa.areaPrimaria[0] != null){//OK
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.areaPrimaria = queryParams.iniciativa.areaPrimaria;
      }

      //INICIATIVA LGB
      if(queryParams.iniciativa != null && queryParams.iniciativa.cumpleLGB != null){

        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }

        if(queryParams.iniciativa.cumpleLGB["id"] != null){
          queryParamsCleaned.iniciativa.cumpleLGB = queryParams.iniciativa.cumpleLGB["id"];
        }else{
          queryParamsCleaned.iniciativa.cumpleLGB = queryParams.iniciativa.cumpleLGB;
        }

      }

      if(queryParams.iniciativa != null && queryParams.iniciativa.motivacion != null && queryParams.iniciativa.motivacion[0] != null){//OK
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.motivacion = queryParams.iniciativa.motivacion;
      }

      if(queryParams.iniciativa != null && queryParams.iniciativa.odsPrimario != null && queryParams.iniciativa.odsPrimario[0] != null){//OK
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.odsPrimario = queryParams.iniciativa.odsPrimario;
      }

      if(queryParams.iniciativa != null && queryParams.iniciativa.pais != null && queryParams.iniciativa.pais[0] != null){//OK
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.pais = queryParams.iniciativa.pais;
      }

      if(queryParams.iniciativa != null && queryParams.iniciativa.region != null && queryParams.iniciativa.region[0] != null){//OK
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.region = queryParams.iniciativa.region;
      }

      //Iniciativa fecha inicio desde
      if(queryParams.iniciativa != null && queryParams.iniciativa.fechaInicioDesde != null){
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.fechaInicioDesde = queryParams.iniciativa.fechaInicioDesde;
      }

      //Iniciativa fecha inicio hasta
      if(queryParams.iniciativa != null && queryParams.iniciativa.fechaInicioHasta != null){
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.fechaInicioHasta = queryParams.iniciativa.fechaInicioHasta;
      }

      //Iniciativa fecha fin desde
      if(queryParams.iniciativa != null && queryParams.iniciativa.fechaFinDesde != null){
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.fechaFinDesde = queryParams.iniciativa.fechaFinDesde;
      }

      //Iniciativa fecha inicio hasta
      if(queryParams.iniciativa != null && queryParams.iniciativa.fechaFinHasta != null){
        if(queryParamsCleaned.iniciativa == null){
          queryParamsCleaned.iniciativa = new Object();
        }
        queryParamsCleaned.iniciativa.fechaFinHasta = queryParams.iniciativa.fechaFinHasta;
      }

      if(queryParams.logro != null && queryParams.logro.validado != null){//OK
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }

        if(queryParamsCleaned.logro.validado == null){
          queryParamsCleaned.logro.validado = new Object();
        }

        if(queryParams.logro.validado["id"] != null){
          queryParamsCleaned.logro.validado = queryParams.logro.validado["id"];
        }else{
          queryParamsCleaned.logro.validado = queryParams.logro.validado;
        }
      }
	  
	  if(queryParams.logroSituacion != null){
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }

        queryParamsCleaned.logro.situacion = queryParams.logroSituacion;
      }
	  
	  if(queryParams.logroTipo != null){
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }

        queryParamsCleaned.logro.tipo = queryParams.logroTipo;
      }

      //Logro fecha desde
      if(queryParams.logro != null && queryParams.logro.fechaDesde != null){
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }
        queryParamsCleaned.logro.fechaDesde = queryParams.logro.fechaDesde;
      }

      //Logro fecha hasta
      if(queryParams.logro != null && queryParams.logro.fechaHasta != null){
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }
        queryParamsCleaned.logro.fechaHasta = queryParams.logro.fechaHasta;
      }


      if(queryParams.proyecto != null && queryParams.proyecto.byProject != null && queryParams.proyecto.byProject[0] != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.byProject = queryParams.proyecto.byProject;
      }
	  
	  if(queryParams.proyecto != null && queryParams.proyecto.byInitiatives != null && queryParams.proyecto.byInitiatives[0] != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.byInitiatives = queryParams.proyecto.byInitiatives;
      }
	  
	  if(queryParams.proyecto != null && queryParams.proyecto.agrupacionInterna != null && queryParams.proyecto.agrupacionInterna[0] != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.agrupacionInterna = queryParams.proyecto.agrupacionInterna;
      }
	  
	  if(queryParams.proyecto != null && queryParams.proyecto.agrupacionInternaIniciativa != null && queryParams.proyecto.agrupacionInternaIniciativa[0] != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.agrupacionInternaIniciativa = queryParams.proyecto.agrupacionInternaIniciativa;
      }
	  
	  if(queryParams.proyecto != null && queryParams.proyecto.iniciativaColaboradores != null && queryParams.proyecto.iniciativaColaboradores[0] != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.iniciativaColaboradores = queryParams.proyecto.iniciativaColaboradores;
      }

      if(queryParams.proyecto != null && queryParams.proyecto.estado != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }

        if(queryParamsCleaned.proyecto.estado == null){
          queryParamsCleaned.proyecto.estado = new Object();
        }

        queryParamsCleaned.proyecto.estado = queryParams.proyecto.estado;
      }

      if(queryParams.proyecto != null && queryParams.proyecto.unidadNegocio != null && queryParams.proyecto.unidadNegocio[0] != null){//OK
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.unidadNegocio = queryParams.proyecto.unidadNegocio;
      }

      if(queryParams.proyecto != null && queryParams.proyecto.fechaInicioDesde != null){
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.fechaInicioDesde = queryParams.proyecto.fechaInicioDesde;
      }

      if(queryParams.proyecto != null && queryParams.proyecto.fechaInicioHasta != null){
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.fechaInicioHasta = queryParams.proyecto.fechaInicioHasta;
      }

      if(queryParams.proyecto != null && queryParams.proyecto.fechaFinDesde != null){
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.fechaFinDesde = queryParams.proyecto.fechaFinDesde;
      }

      if(queryParams.proyecto != null && queryParams.proyecto.fechaFinHasta != null){
        if(queryParamsCleaned.proyecto == null){
          queryParamsCleaned.proyecto = new Object();
        }
        queryParamsCleaned.proyecto.fechaFinHasta = queryParams.proyecto.fechaFinHasta;
      }

      if(queryParams.tipoInformes != null){
        queryParamsCleaned.tipoInformes = queryParams.tipoInformes;
      }

      return queryParamsCleaned;

    }

    service.mapReportTipoContribucion = function(report) {
      // TODO: es posible que tenga que cambiar las referencias a cantidadContribucion cuando cambie la API

      var headers = [
        {label: "", key: "tipo"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.proyectosConContribucion", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionItv", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionItv", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]
      var labelMap = {
        DINERO: "reportChart.contrib.dinero",
        TIEMPO: "reportChart.contrib.tiempo",
        ESPECIE: "reportChart.contrib.especie",
        PROBONO: "reportChart.contrib.probono",
        COSTE_GESTION: "reportChart.contrib.costeGestion",
        TOTAL: "reportChart.total"
      }

      /*
       var data = report.contribucionInformeDTOList.concat({
       tipo: "TOTAL",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var data = report.contribucionInformeDTOList

	  var entidadTotal = 0;
	  data.forEach(function(a,i) {
		if (a.tipo != "TOTAL")
			entidadTotal += a.cantidadContribucionEntidad;
      });

      var presentOrder = ["DINERO","TIEMPO","ESPECIE","PROBONO","COSTE_GESTION","TOTAL"]
      var newData = new Array(5)
      data.forEach(function(a,i) {
        var index = presentOrder.indexOf(a.tipo);
		if (a.tipo == "TOTAL")
			a.percentContribucionEntidad = "";
		else
			a.percentContribucionEntidad = parseFloat((parseFloat(a.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        newData[index]=a;
      });
      data.map(function(item) {
        item.tipo =  labelMap[item.tipo]
        return item
      })
      newData=newData.filter(function(n){ return n != angular.isUndefined });
      var pieData = newData.map(function(item) {
        return {
		  type:"pie",
		  key: 'pie1',
          label: $translate.instant(item.tipo),
          value: item.cantidadContribucionEntidad,
        }
      })
      data.map(function(item) {
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)

        return item
      })
      pieData.pop();

      return {
		id: "contribType",
        header: "reportChart.header.contribType",
        tables: [
          {
            data: newData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }
    service.mapReportUdNegocio = function(report) {
      var headers = [
        {label: "", key: "nombreUnidadNegocio"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]

      /*
       var tableData = report.unidadNegocioInformeDTOList.concat({
       nombreUnidadNegocio:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.unidadNegocioInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombreUnidadNegocio=="TOTAL"){
          comparison = 1;
        } else if (b.nombreUnidadNegocio =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })

      var pieData = tableData.map(function(item) {
        return {
		  key: 'pie2',
		  type:"bar",
          label: $translate.instant(item.nombreUnidadNegocio),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

	  var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
		if (a.nombreUnidadNegocio != "TOTAL")
			entidadTotal += a.cantidadContribucionEntidad;
      });
	  
      tableData.map(function(item) {        
		if (item.nombreUnidadNegocio == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = percentInforme(parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1));

		item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
		
        return item
      })

      return {
		id: "udNegocio",
        header: "reportChart.header.udNegocio",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }

    service.mapReportAgrIniciativaInterna = function(report) {
      var headers = [
        {label: "", key: "nombreAgrupacionInterna"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]

      /*
       var tableData = report.agrupacionInternaInformeDTOList.concat({
       nombreAgrupacionInterna:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.agrupacionIniciativaInternaInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombreAgrupacionInterna=="TOTAL"){
          comparison = 1;
        } else if (b.nombreAgrupacionInterna =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })
      var pieData = report.agrupacionIniciativaInternaInformeDTOList.map(function(item) {
        return {
		  key: 'pie3',
		  type:"bar",
          label: $translate.instant(item.nombreAgrupacionInterna),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

	  var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
		if (a.nombreAgrupacionInterna != "TOTAL")
			entidadTotal += a.cantidadContribucionEntidad;
      });

      tableData.map(function(item) {
		if (item.nombreAgrupacionInterna == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        return item
      })

      return {
		id: "agrIniciativaInterna",
        header: "reportChart.header.agrIniciativaInterna",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }

    service.mapReportAgrInterna = function(report) {
      var headers = [
        {label: "", key: "nombreAgrupacionInterna"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]

      /*
       var tableData = report.agrupacionInternaInformeDTOList.concat({
       nombreAgrupacionInterna:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.agrupacionInternaInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombreAgrupacionInterna=="TOTAL"){
          comparison = 1;
        } else if (b.nombreAgrupacionInterna =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })
      var pieData = report.agrupacionInternaInformeDTOList.map(function(item) {
        return {
		  key: 'pie4',
		  type:"bar",
          label: $translate.instant(item.nombreAgrupacionInterna),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

	  var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
	  if (a.nombreAgrupacionInterna != "TOTAL")
			entidadTotal += a.cantidadContribucionEntidad;
	  });
			
      tableData.map(function(item) {
		if (item.nombreAgrupacionInterna == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        return item
      })

      return {
		id: "agrInterna",
        header: "reportChart.header.agrInterna",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }
    service.mapReportMotivacion = function(report) {
      var headers = [
        {label: "", key: "nombreMotivacion"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]

      /*
       var tableData = report.motivacionInformeDTOList.concat({
       nombreMotivacion:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.motivacionInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.map(function(item) {
        item.nombreMotivacion =  item.nombreMotivacion
        return item
      })
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombreMotivacion=="TOTAL"){
          comparison = 1;
        } else if (b.nombreMotivacion =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })
      var pieData = report.motivacionInformeDTOList.map(function(item) {
        return {
		  key: 'pie5',
		  type:"pie",
          label:  $translate.instant(item.nombreMotivacion),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

	  var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
	  if (a.nombreMotivacion != "TOTAL")
		entidadTotal += a.cantidadContribucionEntidad;
	  });
		
      tableData.map(function(item) {
		if (item.nombreMotivacion == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        return item
      })

      return {
		id: "motivation",
        header: "reportChart.header.motivation",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }
    service.mapReportArea = function(report) {
      var headers = [
        {label: "reportChart.areaActuacion", key: "nombreArea"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.areaPrimaria", key: "areaPrimaria"},
        {label: "reportChart.areaSecundaria", key: "areaSecundaria"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]

      /*
       var tableData = report.areaInformeDTOList.concat({
       testClaveSustituida:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       areaPrimaria: report.totalAreaPrimaria,
       areaSecundaria: report.totalAreaSecundaria,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.areaInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.map(function(item) {
        item.areaPrimaria = filterNumber(item.areaPrimaria)
        item.areaSecundaria = filterNumber(item.areaSecundaria)
        item.nombreArea =  item.nombreArea
        return item
      })
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombreArea.toUpperCase()=="TOTAL"){
          comparison = 1;
        } else if (b.nombreArea.toUpperCase() =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })
      var pieData = report.areaInformeDTOList.map(function(item) {
        return {
		  key: 'pie6',
		  type:"bar",
          label:  $translate.instant(item.nombreArea),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

      var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
	  if (a.nombreArea.toUpperCase() != "TOTAL")
			entidadTotal += a.cantidadContribucionEntidad;
	  });
	  
      tableData.map(function(item) {
		if (item.nombreArea.toUpperCase() != "TOTAL")
			item.percentContribucionEntidad = parseFloat(parseFloat(((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal)).toFixed(1));
		else
			item.percentContribucionEntidad = "";
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        return item
      })

      return {
		id: "area",
        header: "reportChart.header.area",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }
    service.mapReportPais = function(report) {
      // TODO
      var headers = [

        {label: "reportChart.nombreComunidad", key: "nombreRegion"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},

      ]

      /*
       var tableData = report.paisEspaniaInformeDTOList.concat({
       nombrePais:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.paisEspaniaInformeDTOList;
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombreRegion && a.nombreRegion =="TOTAL"){
          comparison = 1;
        } else if (b.nombreRegion && b.nombreRegion =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })
      var pieData = report.paisEspaniaInformeDTOList.map(function(item) {
        return {
		  type:"bar",
		  key: 'pie7',
          label:  $translate.instant(item.nombreRegion),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

      var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
	  if (a.nombreRegion != "TOTAL")
		entidadTotal += a.cantidadContribucionEntidad;
	  });
	  
      tableData.map(function(item) {
		if (item.nombreRegion == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        return item
      })

      return {
		id: "pais",
        header: "reportChart.header.pais",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }
    service.mapReportAmbitoGeografico = function(report) {
      var headers = [
        {label: "", key: "nombrePais"},
        {label: "reportChart.proyectos", key: "numProyectoActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]

      /*
       var tableData = report.paisInformeDTOList.concat({
       nombrePais:  "reportChart.total",
       numProyectoActivos: report.totalProyectosActivos,
       numIniciativasActivas: report.totalIniciativasActivas,
       cantidadContribucion: report.totalContribuciones,
       cantidadContribucionEntidad: report.totalContribucionesEntidad,
       })
       */
      var tableData = report.paisInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.map(function(item) {
        item.nombrePais =  item.nombrePais
        return item
      })
      tableData.sort(function(a, b) {
        var genreA = a.cantidadContribucion;
        var genreB = b.cantidadContribucion;
        var comparison = 0;
        if (a.nombrePais=="TOTAL"){
          comparison = 1;
        } else if (b.nombrePais =="TOTAL") {
          comparison = -1;
        } else if (genreA > genreB) {
          comparison = -1;
        } else if (genreA < genreB) {
          comparison = 1;
        }
        return comparison;
      })
      var pieData = report.paisInformeDTOList.map(function(item) {
        return {
		  type:"bar",
		  key: 'pie8',
          label:  $translate.instant(item.nombrePais),
          value: item.cantidadContribucionEntidad,
        }
      })
      pieData.pop()

	  var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
	  if (a.nombrePais != "TOTAL")
		entidadTotal += a.cantidadContribucionEntidad;
	  });
		
      tableData.map(function(item) {
		if (item.nombrePais == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        return item
      })

      return {
		id: "ambitoGeografico",
        header: "reportChart.header.ambitoGeografico",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
        ]
      }
    }
    service.mapReportODS = function(report) {
      var headers = [
        {label: "", key: "nombreODS"},
        {label: "reportChart.contributionProyects", key: "numProyectosContribucion"},
        {label: "reportChart.contributionInitiatives", key: "numIniciativasContribucion"},
        {label: "reportChart.contributions", key: "numContribuciones"},
        {label: "reportChart.contribucionTotal", key: "cantidadContribucion"},
        {label: "reportChart.contribucionEntidad", key: "cantidadContribucionEntidad", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.odsPrimario", key: "contribucionODSPrimarioSum"},
        {label: "reportChart.odsSecundario", key: "contribucionODSSecundarioSum"},
        {label: "reportChart.odsTerciarios", key: "contribucionODSTerciarioSum"},
        {label: "reportChart.porcentajeContribucionEntidad", key: "percentContribucionEntidad", abreviatura: userData.entidad.abreviatura},
      ]
      var pieData = report.odsInformeDTOList.map(function(item) {
        return {
          label:  item.nombreODS,
          value: item.cantidadContribucion,
        }
      })
      pieData.push({type:"pie"})
      var tableData = report.odsInformeDTOList
      if(angular.isUndefined(tableData) || !angular.isArray(tableData) || tableData.length == 0){
        return;
      }
      tableData.sort(function(a, b) {
        if (b.nombreODS=="TOTAL"){
          return -1
        }
        else if (a.nombreODS=="TOTAL"){
          return 1
        }
        else {
          var genreA =  $translate.instant(a.nombreODS);
          genreA = genreA.substring(0,genreA.indexOf("."));
          genreA++;
          var genreB =  $translate.instant(b.nombreODS);
          genreB = genreB.substring(0,genreB.indexOf("."));
          genreB++;
          var comparison = 0;
          if (genreA > genreB) {
            comparison = 1;
          } else if (genreA < genreB) {
            comparison = -1;
          }
          return comparison;
        }
      });
      var percentData = new Array(18)
      var keyval = []
      var datalength = tableData.length-1
      for (var i=0; i<datalength; i++) {
        var resultadoPercent = ((tableData[i]["cantidadContribucionEntidad"]) / (tableData[tableData.length-1]["cantidadContribucionEntidad"])) * 100;
        if (!isNaN(resultadoPercent)) keyval[i] = resultadoPercent;
        else keyval[i] = 0;
      }
	  
	  var entidadTotal = 0;
	  tableData.forEach(function(a,i) {
	  if (a.nombreODS != "TOTAL")
		entidadTotal += a.cantidadContribucionEntidad;
	  });
		
      tableData = tableData.map(function(item) {
		if (item.nombreODS == "TOTAL")
			item.percentContribucionEntidad = "";
		else
			item.percentContribucionEntidad = parseFloat((parseFloat(item.cantidadContribucionEntidad) * 100) / entidadTotal).toFixed(1);
			
        item.cantidadContribucion = filterNumber(item.cantidadContribucion)
        item.cantidadContribucionEntidad = filterNumber(item.cantidadContribucionEntidad)
        item.percentContribucionEntidad = percentInforme(item.percentContribucionEntidad)
        item.contribucionODSPrimarioSum = filterNumber(item.contribucionODSPrimarioSum)
        item.contribucionODSSecundarioSum = filterNumber(item.contribucionODSSecundarioSum)
        item.contribucionODSTerciarioSum = filterNumber(item.contribucionODSTerciarioSum)
        if (item.nombreODS!="TOTAL") {
          item.nombreODS =  $translate.instant(item.nombreODS)
        }
        return item
      })

      var lang = $translate.preferredLanguage();
      var formatter = Intl.NumberFormat(lang);

      for (var i=0; i<datalength; i++) {
        var labelvarall = parseInt(tableData[i]["nombreODS"].substring(0,tableData[i]["nombreODS"].indexOf("."))) - 1
        percentData[labelvarall] = {label: labelvarall, key: formatter.format(keyval[i].toFixed(1))}

      }

      for (var i=0; i<percentData.length; i++) {
        if (angular.isUndefined(percentData[i])) {
          percentData[i] = {label: i, key: "0"}
        }
      }


      return {
		id: "ods",
        header: "reportChart.header.ods",

        tables: [
          {
            data: tableData,
            headers: headers,
			odsChart: [
			  {
				data: percentData,
			  },
			],
			clase: 'withTotal'
          },
        ]
      }
    }

    service.mapReportEmpVol = function(report) {
      var headers1 = [
        {label: "reportChart.Participacion1", key: "tipoContribuyente", subtitle: "reportChart.mapReportEmpVolSubtitle1"},
        {label: "reportChart.proyectosParticipacion", key: "proyectosParticipacion"},
        {label: "reportChart.iniciativasParticipacion", key: "iniciativasParticipacion"},
        {label: "reportChart.numPersonas", key: "numPersonas"},
        {label: "reportChart.numHoras", key: "numHoras"},
        {label: "reportChart.porcentaje", key: "porcentaje"},
        {label: "reportChart.costeHora", key: "coste"},
      ]
      var headers2 = [
        {label: "", key: "tipoContribuyente", subtitle: "reportChart.mapReportEmpVolSubtitle2"},
        {label: "reportChart.proyectosParticipacion", key: "proyectosParticipacion"},
        {label: "reportChart.iniciativasParticipacion", key: "iniciativasParticipacion"},
        {label: "reportChart.numPersonas", key: "numPersonas"},
        {label: "reportChart.numHoras", key: "numHoras"},
        {label: "reportChart.porcentaje", key: "porcentaje"},
        {label: "reportChart.costeHora", key: "coste"},
      ]
      var headers3 = [
        {label: "reportChart.Participacion3", key: "impNombre", bold: "no", subtitle: "reportChart.mapReportEmpVolSubtitle3"},
        {label: "reportChart.emplTotal", key: "emplTotal", bold: "no"},
        {label: "reportChart.emplEval", key: "emplEval", bold: "no"},
        {label: "reportChart.impactoAlto", key: "impactoAlto", bold: "no"},
        {label: "reportChart.impactoMed", key: "impactoMed", bold: "no"},
        {label: "reportChart.impactoBajo", key: "impactoBajo", bold: "no"},
        {label: "reportChart.impactoSinCamb", key: "impactoSinCamb", bold: "no"}
      ]
      var headers4 = [
        {label: "reportChart.Participacion4", key: "tipoContribuyente", subtitle: "reportChart.mapReportEmpVolSubtitle4"},
        {label: "reportChart.proyectosParticipacion", key: "proyectosParticipacion"},
        {label: "reportChart.iniciativasParticipacion", key: "iniciativasParticipacion"},
        {label: "reportChart.numPersonas", key: "numPersonas"},
        {label: "reportChart.numHoras", key: "numHoras"},
        {label: "reportChart.porcentaje", key: "porcentaje"},
        {label: "reportChart.costeHora", key: "coste"},
      ]
      var headers5 = [
        {label: "", key: "tipoContribuyente", subtitle: "reportChart.mapReportEmpVolSubtitle5"},
        {label: "reportChart.proyectosParticipacion", key: "proyectosParticipacion"},
        {label: "reportChart.iniciativasParticipacion", key: "iniciativasParticipacion"},
        {label: "reportChart.numPersonas", key: "numPersonas"},
        {label: "reportChart.numHoras", key: "numHoras"},
        {label: "reportChart.porcentaje", key: "porcentaje"},
        {label: "reportChart.costeHora", key: "coste"},
      ]
      var headers6 = [
        {label: "reportChart.Participacion6", key: "impNombre", bold: "no", subtitle: "reportChart.mapReportEmpVolSubtitle6"},
        {label: "reportChart.emplTotal", key: "emplTotal", bold: "no"},
        {label: "reportChart.emplEval", key: "emplEval", bold: "no"},
        {label: "reportChart.impactoAlto", key: "impactoAlto", bold: "no"},
        {label: "reportChart.impactoMed", key: "impactoMed", bold: "no"},
        {label: "reportChart.impactoBajo", key: "impactoBajo", bold: "no"},
        {label: "reportChart.impactoSinCamb", key: "impactoSinCamb", bold: "no"}
      ]
      var tableData1 = report.empleadosVoluntariosInformeDTO.empleadosVoluntariosLogrosEmpList
      var tableLength1 = tableData1.length;
      tableData1.map(function(item,i) {
        item.coste = filterNumber(item.coste)
        item.numHoras = filterNumber(item.numHoras,true,0)
        item.numPersonas = filterNumber(item.numPersonas,true,0)
        item.tipoContribuyente =  item.tipoContribuyente
        item.porcentaje = percentInforme(item.porcentaje,i,tableLength1)
        return item
      })
      var tableData2 = report.empleadosVoluntariosInformeDTO.empleadosVoluntariosLogrosNoEmpList
      var tableLength2 = tableData2.length;
      tableData2.map(function(item,i) {
        item.coste = filterNumber(item.coste)
        item.numHoras = filterNumber(item.numHoras,true,0)
        item.numPersonas = filterNumber(item.numPersonas,true,0)
        item.tipoContribuyente =  item.tipoContribuyente
        item.porcentaje = percentInforme(item.porcentaje,i,tableLength2)
        return item
      })
      var tableData3 = report.empleadosVoluntariosInformeDTO.empleadosVoluntariosImpactosEmpList
      var tableLength3 = tableData3.length;
      tableData3.map(function(item,i) {
        item.emplTotal = filterNumber(item.emplTotal,true)
        item.emplEval = filterNumber(item.emplEval,true)
        item.impactoAlto = filterNumber(item.impactoAlto,true)
        item.impactoMed =  item.impactoMed,true
        item.impactoBajo = filterNumber(item.impactoBajo,true)
        item.impactoSinCamb = filterNumber(item.impactoSinCamb,true)
        return item
      })
      var tableData4 = report.empleadosVoluntariosInformeDTO.empleadosVoluntariosOtrosColabLogrosEmpList
      var tableLength4 = tableData4.length;
      tableData4.map(function(item,i) {
        item.coste = filterNumber(item.coste)
        item.numHoras = filterNumber(item.numHoras,true,0)
        item.tipoContribuyente =  item.tipoContribuyente
        item.porcentaje = percentInforme(item.porcentaje,i,tableLength4)
        return item
      })
      var tableData5 = report.empleadosVoluntariosInformeDTO.empleadosVoluntariosOtrosColabLogrosNoEmpList
      var tableLength5 = tableData5.length;
      tableData5.map(function(item,i) {
        item.coste = filterNumber(item.coste)
        item.numHoras = filterNumber(item.numHoras,true,0)
        item.tipoContribuyente =  item.tipoContribuyente
        item.porcentaje = percentInforme(item.porcentaje,i,tableLength5)
        return item
      })
      var tableData6 = report.empleadosVoluntariosInformeDTO.empleadosVoluntariosOtrosColabImpactosEmpList
      var tableLength6 = tableData6.length;
      tableData6.map(function(item,i) {
        item.emplTotal = filterNumber(item.emplTotal,true)
        item.emplEval = filterNumber(item.emplEval,true)
        item.impactoAlto = filterNumber(item.impactoAlto,true)
        item.impactoMed =  item.impactoMed,true
        item.impactoBajo = filterNumber(item.impactoBajo,true)
        item.impactoSinCamb = filterNumber(item.impactoSinCamb,true)
        return item
      })

      return {
		id: "logeimp",
        header: "reportChart.header.logeimp",
        tables: [
          {
            data: tableData1,
            headers: headers1,
			clase: 'withTotal'
          },
		  {
            data: tableData2,
            headers: headers2,
			clase: 'withTotal'
          },
		  {
            data: tableData3,
            headers: headers3,
			clase: 'noTotal'
          },
		  {
            data: tableData4,
            headers: headers4,
			clase: 'withTotal'
          },
		  {
            data: tableData5,
            headers: headers5,
			clase: 'withTotal'
          },
		  {
            data: tableData6,
            headers: headers6,
			clase: 'noTotal'
          }
        ]
      }
    }

    service.mapReportEfectoMul = function(report) {
      var headers = [
        {label: "reportChart.efectoMultiplicador", key: "tipoContribuyente", subtitle: "reportChart.mapReportEfectoMul1", entidad: userData.entidad.abreviatura},
        {label: "reportChart.proyectos", key: "numProyectosActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.numProyectosEfectoMult", key: "numProyectosEfectoMult"},
        {label: "reportChart.numIniciativasEfectoMult", key: "numIniciativasEfectoMult"},
        {label: "reportChart.cantidadEfectoMult", key: "cantidadEfectoMult"}
      ]
      var headers2 = [
        {label: "reportChart.efectoMultiplicadorOtros", key: "tipoContribuyente", subtitle: "reportChart.mapReportEfectoMul2"},
        {label: "reportChart.proyectos", key: "numProyectosActivos"},
        {label: "reportChart.iniciativas", key: "numIniciativasActivas"},
        {label: "reportChart.numProyectosEfectoMult", key: "numProyectosEfectoMult"},
        {label: "reportChart.numIniciativasEfectoMult", key: "numIniciativasEfectoMult"},
        {label: "reportChart.cantidadEfectoMult", key: "cantidadEfectoMult"}
      ]
      var tableData = report.efectoMultInformeDTOList.efectoMultInformeEntidadDTOList
        .map(function(item) {
	      item.cantidad = item.cantidadEfectoMult;
          item.cantidadEfectoMult = filterNumber(item.cantidadEfectoMult);
          item.numProyectosActivos = filterNull(item.numProyectosActivos);
          item.numIniciativasActivas = filterNull(item.numIniciativasActivas);
          item.numProyectosEfectoMult = filterNull(item.numProyectosEfectoMult);
          item.numIniciativasEfectoMult = filterNull(item.numIniciativasEfectoMult);
		  if (item.tipoContribuyente == 'reportChart.efectoMultiplicador.entidad.Total')
			item.tipoContribuyente =  'reportChart.mapReportEfectoMul.entidad.Total';
		  else if (item.tipoContribuyente == 'reportChart.efectoMultiplicador.entidad.Otros')
			item.tipoContribuyente =  'reportChart.mapReportEfectoMul.entidad.Otros';
		  else
			item.tipoContribuyente =  item.tipoContribuyente;
			
          return item
        })

      var tableData2 = report.efectoMultInformeDTOList.efectoMultInformeOtrosColabDTOList
        .map(function(item) {
		  item.cantidad = item.cantidadEfectoMult;
          item.cantidadEfectoMult = filterNumber(item.cantidadEfectoMult)
          item.numProyectosActivos = filterNull(item.numProyectosActivos)
          item.numIniciativasActivas = filterNull(item.numIniciativasActivas)
          item.numProyectosEfectoMult = filterNull(item.numProyectosEfectoMult)
          item.numIniciativasEfectoMult = filterNull(item.numIniciativasEfectoMult)
          item.tipoContribuyente =  item.tipoContribuyente
          return item
        })

	  var pieData = [];
	  tableData.forEach(function(v,i) {
		  if (v.tipoContribuyente != 'reportChart.mapReportEfectoMul.entidad.Total')
			pieData.push({label: $translate.instant(v.tipoContribuyente), value: v.cantidad, key: 'pie9', type:"bar" });
	  });

	  var pieData2 = [];
	  tableData2.forEach(function(v,i) {
		  if (v.tipoContribuyente != 'reportChart.efectoMultiplicador.otrosColaboradores.Total')		  
			pieData2.push({label: $translate.instant(v.tipoContribuyente), value: v.cantidad, key: 'pie10', type:"bar" });
	  });

      return {
		id: "efecto",
        header: "reportChart.header.efecto",
        tables: [
          {
            data: tableData,
            headers: headers,
			pie: pieData,
			clase: 'withTotal'
          },
		  {
            data: tableData2,
            headers: headers2,
			pie: pieData2,
			clase: 'withTotal'
          }
        ]
      }
    }

    service.mapReportrescom = function(report) {
      var headers = [
        {label: "reportChart.logros", key: "logro", bold: "no", subtitle: "reportChart.mapReportrescom1"},
        {label: "reportChart.proyectosConLogros", key: "proyectosEvaluados", bold: "no"},
        {label: "reportChart.iniciativasConLogros", key: "iniciativasEvaluadas", bold: "no"},
        {label: "reportChart.totalBeneficiarios", key: "totalBeneficiarios", bold: "no"},
      ]
      var headers2 = [
        {label: "reportChart.impactosGrado", key: "impactosGrado", bold: "no", subtitle: "reportChart.mapReportrescom2"},
        {label: "reportChart.beneficariosTotales", key: "beneficariosTotales", bold: "no"},
        {label: "reportChart.beneficariosEval", key: "beneficariosEval", bold: "no"},
        {label: "reportChart.totalPorGrado", key: "totalPorTipo", bold: "no"}
      ]
      var headers3 = [
        {label: "reportChart.impactosTipo", key: "impactosGrado", bold: "no", subtitle: "reportChart.mapReportrescom3"},
        {label: "reportChart.beneficariosTotales", key: "beneficariosTotales", bold: "no"},
        {label: "reportChart.beneficariosEval", key: "beneficariosEval", bold: "no"},
        {label: "reportChart.totalPorTipo", key: "totalPorTipo", bold: "no"}
      ]
      /*var headers4 = [
        {label: "reportChart.logros", key: "logros", bold: "no", subtitle: "reportChart.mapReportrescom4"},
        {label: "reportChart.proyectosEval", key: "proyectosEval", bold: "no"},
        {label: "reportChart.iniciativasEval", key: "iniciativasEval", bold: "no"},
        {label: "reportChart.totalOrganizaciones", key: "totalOrganizaciones", bold: "no"}
      ]*/
      var headers5 = [
        {label: "reportChart.logrosOrganizaciones", key: "logrosOrganizBenef", bold: "no", subtitle: "reportChart.mapReportrescom5"},
        {label: "reportChart.proyectosEval", key: "proyectosEval", bold: "no"},
        {label: "reportChart.iniciativasEval", key: "iniciativasEval", bold: "no"},
        {label: "reportChart.totalOrganizaciones", key: "totalOrganizaciones", bold: "no"}
      ]
      var headers6 = [
        {label: "reportChart.impactos", key: "nombre", bold: "no", subtitle: "reportChart.mapReportrescom6"},
        {label: "reportChart.organizBenefTotal", key: "organizBenefTotal", bold: "no"},
        {label: "reportChart.organizBenefEval", key: "organizBenefEval", bold: "no"},
        {label: "reportChart.impactoAlto", key: "impactoAlto", bold: "no"},
        {label: "reportChart.impactoMed", key: "impactoMedio", bold: "no"},
        {label: "reportChart.impactoBajo", key: "impactoBajo", bold: "no"},
        {label: "reportChart.impactoSinCamb", key: "sinCambios", bold: "no"}
      ]
      var headers7 = [
        {label: "reportChart.logros", key: "logros", bold: "no", subtitle: "reportChart.mapReportrescom7"},
        {label: "reportChart.iniciativasEval", key: "iniciativasEval", bold: "no"},
        {label: "reportChart.numLogros", key: "numLogros", bold: "no"}
      ]
      var headers8 = [
        {label: "reportChart.impactos", key: "nombre", bold: "no", subtitle: "reportChart.mapReportrescom8"},
        {label: "reportChart.iniciativasSobreMA", key: "iniciativasSobreMA", bold: "no"},
        {label: "reportChart.impactoAlto", key: "impactoAlto", bold: "no"},
        {label: "reportChart.impactoMed", key: "impactoMedio", bold: "no"},
        {label: "reportChart.impactoBajo", key: "impactoBajo", bold: "no"},
        {label: "reportChart.impactoSinCamb", key: "sinCambios", bold: "no"}
      ]
      var tableData = report.resultadosComunidadInformeDTO.resultComuBeneficLogrosList.map(function(item) {
          item.proyectosEvaluados = filterNumber(item.proyectosEvaluados,true)
          item.iniciativasEvaluadas = filterNumber(item.iniciativasEvaluadas,true)
          item.totalBeneficiarios = filterNumber(item.totalBeneficiarios,true)
          item.logro = item.logro	  
          return item
      });
      var tableData2 = report.resultadosComunidadInformeDTO.resultComuBeneficImpactosGradoList.map(function(item) {
		  item.cantidad = item.totalPorTipo;
          item.impactosGrado = item.impactosGrado
          item.beneficariosTotales = filterNumber(item.beneficariosTotales,true)
          item.beneficariosEval = filterNumber(item.beneficariosEval,true)
          item.totalPorTipo = filterNumber(item.totalPorTipo,true)
          return item
      });
      var tableData3 = report.resultadosComunidadInformeDTO.resultComuBeneficImpactosTipoList.map(function(item) {
		  item.cantidad = item.totalPorTipo;
          item.impactosGrado = item.impactosGrado
          item.beneficariosTotales = filterNumber(item.beneficariosTotales,true)
          item.beneficariosEval = filterNumber(item.beneficariosEval,true)
          item.totalPorTipo = filterNumber(item.totalPorTipo,true)
		  return item
      });
      var tableData4 = report.resultadosComunidadInformeDTO.resultComuOrgBenefLogrosList.map(function(item) {
          //item.logros = item.logros
		  item.logrosOrganizBenef = item.logros
          item.proyectosEval = filterNumber(item.proyectosEval,true)
          item.iniciativasEval = filterNumber(item.iniciativasEval,true)
          item.totalOrganizaciones = item.totalOrganizaciones
		  return item
      });
      var tableData5 = report.resultadosComunidadInformeDTO.resultComuOrgBenefLogrosObDTO.map(function(item) {
          item.logrosOrganizBenef = item.logrosOrganizBenef
          item.proyectosEval = filterNumber(item.proyectosEval,true)
          item.iniciativasEval = filterNumber(item.iniciativasEval,true)
          item.totalOrganizaciones = item.totalOrganizaciones
		  return item
      });
	  tableData5 = tableData4.concat(tableData5);
	  
      var tablelength5 = tableData5.length;
      tableData5.map(function(item,i) {
        item.totalOrganizaciones = filterNumber(item.totalOrganizaciones,true,0,i,tablelength5,true)
        return item
      })
      var tableData6 = report.resultadosComunidadInformeDTO.resultComuOrgBenefImpactosObDTO.map(function(item) {
          item.nombre = item.nombre
          item.organizBenefTotal = filterNumber(item.organizBenefTotal,true)
          item.organizBenefEval = filterNumber(item.organizBenefEval,true)
          item.impactoAlto = filterNumber(item.impactoAlto,true)
		  item.impactoMedio = filterNumber(item.impactoMedio,true)
		  item.impactoBajo = filterNumber(item.impactoBajo,true)
		  item.sinCambios = filterNumber(item.sinCambios,true)	  
		  return item
      });
      var tableData7 = report.resultadosComunidadInformeDTO.resultComuMedAmbLogrosDTOList.map(function(item) {
          item.logros = item.logros
          item.iniciativasEval = filterNumber(item.iniciativasEval,true)
          item.numLogros = filterNumber(item.numLogros,true)
		  return item
      });
      var tableData8 = report.resultadosComunidadInformeDTO.resultComuMedAmbienteImpactosDTOList.map(function(item) {
          item.nombre = item.nombre
          item.impactoAlto = filterNumber(item.impactoAlto,true)
          item.impactoMedio = filterNumber(item.impactoMedio,true)
		  item.impactoBajo = filterNumber(item.impactoBajo,true)
		  item.sinCambios = filterNumber(item.sinCambios,true)
		  item.iniciativasSobreMA = filterNumber(item.iniciativasSobreMA,true)
		  return item
      });

	  var pieData2 = [];
	  tableData2.forEach(function(v,i) {
		  if (v.impactosGrado == 'reportChart.logros.grado.sinCambios')
		  {
		  }
		  else if (v.impactosGrado == 'reportChart.logros.grado.sensibilizados')
		  {
			  pieData2.push({label: $translate.instant('reportChart.header.sensibilizados'), value: v.cantidad, key: 'pie11', type:"pie" });
		  }
		  else if (v.impactosGrado == 'reportChart.logros.grado.mejorados')
		  {
			  pieData2.push({label: $translate.instant('reportChart.header.mejorados'), value: v.cantidad, key: 'pie11', type:"pie" });
		  }
		  else if (v.impactosGrado == 'reportChart.logros.grado.transformados')
		  {
			  pieData2.push({label: $translate.instant('reportChart.header.transformados'), value: v.cantidad, key: 'pie11', type:"pie" });
		  }
	  });	  

	  var pieData3 = [];
	  tableData3.forEach(function(v,i) {
		pieData3.push({label: $translate.instant(v.impactosGrado), value: v.cantidad, key: 'pie12', type:"bar" });
	  });	  
	  
      return {
		id: "rescom",
        header: "reportChart.header.rescom",
        tables: [
          {
            data: tableData,
            headers: headers,
			clase: 'noTotal'
          },
		  {
            data: tableData2,
            headers: headers2,
			pie: pieData2,
			clase: 'noTotal'
          },
		  {
            data: tableData3,
            headers: headers3,
			pie: pieData3,
			clase: 'noTotal'
          },
		  /*{
            data: tableData4,
            headers: headers4,
			clase: 'noTotal'
          },*/
		  {
            data: tableData5,
            headers: headers5,
			clase: 'withTotal'
          },
		  {
            data: tableData6,
            headers: headers6,
			clase: 'noTotal'
          },
		  {
            data: tableData7,
            headers: headers7,
			clase: 'noTotal'
          },
		  {
            data: tableData8,
            headers: headers8,
			clase: 'noTotal'
          }
        ]
      }
    }

    service.mapReportresent = function(report) {
      var headers = [
        {label: "reportChart.logrosEntidad", key: "logrosOrganizBenef", bold: "no", subtitle: "reportChart.mapReportresent1", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.iniciativasEval", key: "iniciativasEval", bold: "no"},
        {label: "reportChart.numLogros", key: "numLogros", bold: "no"},
      ]
      var headers2 = [
        {label: "reportChart.impactos", key: "nombre", bold: "no", subtitle: "reportChart.mapReportresent2", abreviatura: userData.entidad.abreviatura},
        {label: "reportChart.iniciativasEval", key: "iniciativasEval", bold: "no"},
        {label: "reportChart.impactoAlto", key: "impactoAlto", bold: "no"},
        {label: "reportChart.impactoMed", key: "impactoMedio", bold: "no"},
        {label: "reportChart.impactoBajo", key: "impactoBajo", bold: "no"},
        {label: "reportChart.impactoSinCamb", key: "sinCambios", bold: "no"}
      ]
      var tableData = report.resultadosEntidadInformeDTO.resultEntidadLogrosList.map(function(item) {
          item.iniciativasEval = item.iniciativasEval
          item.numLogros = item.numLogros
          item.logrosOrganizBenef = item.logrosOrganizBenef
          return item
      });
      var tablelength = tableData.length;
      tableData.map(function(item,i) {
        item.iniciativasEval = filterNumber(item.iniciativasEval,true,0)
        item.numLogros = filterNumber(item.numLogros,true,0,i,tablelength,true)
        return item
      })
      var tableData2 = report.resultadosEntidadInformeDTO.resultEntidadImpactosList.map(function(item) {
          item.iniciativasEval = filterNumber(item.iniciativasEval,true)
          item.impactoAlto = filterNumber(item.impactoAlto,true)
		  item.impactoMedio = filterNumber(item.impactoMedio,true)
		  item.impactoBajo = filterNumber(item.impactoBajo,true)
		  item.sinCambios = filterNumber(item.sinCambios,true)
          item.nombre = item.nombre
          return item
      });
	  
      return {
		id: "resent",
        header: "reportChart.header.resent",
        tables: [
          {
            data: tableData,
            headers: headers,
			clase: 'withTotal'
          },
		  {
            data: tableData2,
            headers: headers2,
			clase: 'noTotal'
          },
        ]
      }
    }

    var userData = getUserData();

    service.getMappers = function() {
       var mapper = {
          'filterReport.tipo.contribución': service.mapReportTipoContribucion,
          'filterReport.unidad.negocio': service.mapReportUdNegocio,
          'filterReport.agrInterna': service.mapReportAgrInterna,
          'filterReport.agrIniciativaInterna': service.mapReportAgrIniciativaInterna,
          'filterReport.motivacion': service.mapReportMotivacion,
          'filterReport.area': service.mapReportArea,
          'filterReport.ambito.geografico': service.mapReportAmbitoGeografico,
          'filterReport.pais.españa': service.mapReportPais,
          'filterReport.ODS': service.mapReportODS,
          'filterReport.empleados.y.voluntarios': service.mapReportEmpVol,
          'filterReport.efecto.multiplicador': service.mapReportEfectoMul,
          'filterReport.resultados.comunidad': service.mapReportrescom,
          'filterReport.resultados.entidad': service.mapReportresent
       }
       return mapper
    };

    return service
  }
})();
