(function () {
  angular
    .module('app')
    .component('me', {
      templateUrl: 'components/me/me.html',
      controller: MeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function MeController(SelectService, UserService, MeService, RoleService, $state, $stateParams, $filter) {
    var vm = this;


    vm.validData = true;
    vm.errorMessage = "";
    vm.dataChanged = false;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";
    vm.data.apellidos = "";
    vm.data.email = "";
    vm.data.pais = {};


    vm.selectorList = {};

    vm.data = UserService.getUserData();

    vm.checkMail = vm.data.email;

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.country) {
        vm.data.pais = selectorObject;
      }
    }

    function fillSelectors(reset) {
      if (reset == null) {
        SelectService.getCountry().then(function (res) {
          vm.selectorList.country = res;
        });
      }
    }

    fillSelectors();
    vm.clickSave = function () {
      vm.emailChanged = vm.checkMail != vm.data.email
      vm.dataChanged = false;
      MeService.setUserData(vm.data, vm.emailChanged).then(function (res) {
        vm.dataChanged = true;
        if (vm.emailChanged) {
          UserService.logOut();
          $state.go("login");
        }
      });
    }
  }
})();
