(function () {
  angular
    .module('app')
    .component('initiativeform', {
      templateUrl: 'components/initiative/form/initiativeform.html',
      controller: InitiativeFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function InitiativeFormController(
    $scope, $rootScope, SelectService, InitiativeService, CollaboratorsService,
    EntityService, $state, $stateParams, $filter, UserService, $mdToast, RoleService
  ) {
    var vm = this;

    vm.idproyect = $stateParams.idproyect;
    vm.editId = $stateParams.id;
	if (vm.editId)
		vm.isUpdate = true;
	else
		vm.isUpdate = false;
	
    vm.duplicate = $stateParams.duplicate;

    vm.setMockODS = setMockODS;
    vm.autofillODSPercentages = autofillODSPercentages;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    vm.proyectClosed

    vm.data = {};
    //inicializamos data
    vm.data.iniciativa = {};
    vm.data.iniciativa.proyectoId = vm.idproyect;
    vm.data.iniciativa.nombre = "";
    vm.data.iniciativa.descripcion = "";
    vm.data.iniciativa.pais = {};
    vm.data.iniciativa.region = "";
    vm.data.iniciativa.ciudad = "";
    vm.data.iniciativa.fechaInicio = "";
    vm.data.iniciativa.areaPrimaria = {};
    vm.data.iniciativa.porcentajeAreaPrimaria = 100;
    vm.data.iniciativa.areaSecundaria = {};
    vm.data.iniciativa.porcentajeAreaSecundaria = 0;

    // if (UserService.getUserData().entidad.isLBG) {
      vm.LbgSelectElements = [{nombre: 'initiativeform.Si', value: true}, {nombre: 'initiativeform.No', value: false}];
    // } else {
    //   vm.LbgSelectElements = [{nombre: 'initiativeform.No', value: false}];
    // }
    vm.data.iniciativa.cumpleLGB = UserService.getUserData().entidad.isLBG;

    vm.data.iniciativa.lstRazons = [];

    vm.data.iniciativa.lstColaboracionIniciativaABorrarDTO = [];
    vm.data.iniciativa.lstOdsIniciativaABorrarDTO = [];
    vm.data.iniciativa.lstAsignacionPtoIniciativaABorrarDTO = [];
    vm.data.iniciativa.lstPrivilegiosIniciativaABorrarDTO = [];

    vm.data.agrupacionIniciativaInterna = {};
    vm.data.iniciativa.metaPrimario = {};
    vm.data.iniciativa.metaSecundario = {};
    vm.data.fechaFin = "";
    vm.data.comentarios = "";

    vm.isSelect = false;

    vm.selectorList = {};
    vm.selectorList.lstMotivacion = [];
    vm.selectorList.lstMetas = [];
	vm.selectorList.proyects = [];

    vm.currentEntityData = {};
    vm.currentEntityDataPresu = {};

    vm.newCollaborator = {};
    vm.collaborators = [];
    vm.odss = [];
    vm.newODS = {};
    vm.suggestPercentages = true;

    vm.newEntityBudget = {};
    vm.entityBudgets = [];

    vm.selectorList.ods = [];
    vm.selectorList.metas = []

    //Por defecto el desplegable de razones es lo contrario de cumpleLGB
    vm.showSelectRazones = UserService.getUserData().entidad.isLBG;

    var destroyLangWatcher = $rootScope.$watch('selectedLang', function() {
      loadCountries();
    });
    $scope.$on('$destroy', function() {
      destroyLangWatcher();
    });

    // esta funcion es llamada por angular cuando se inicia el componente
    // ayuda a mantener el codigo de inicio contenido en una sola funcion
    vm.$onInit = function() {

      InitiativeService.getProyectState(vm.idproyect).then(function (res) {
        vm.proyectClosed = !res;
      })

      vm.selectorList.gradoImplicacion = [
        {id: 1, nombre: 'initiativeform.colaboradorPuntual', value: 'COLABORACION_PUNTUAL'},
        {id: 2, nombre: 'initiativeform.socioColaborador', value: 'SOCIO_COLABORADOR'}
      ]
      vm.selectorList.motivacion = [
        {id: 1, nombre: 'Motivacion 1'},
        {id: 2, nombre: 'Motivacion 2'}
      ];
	
      vm.currentEntityData = UserService.getUserData().entidad;
      vm.currentEntityData.id = null;
      vm.currentEntityDataPresu = angular.copy(vm.currentEntityData);

      if (InitiativeService.getPersistInitiative()) {
        vm.data = InitiativeService.getPersistInitiative();
        fillSelectors();
        setPrettyDates();
        checkIsSelect();
        getListsMotivacion();
        fillCollaborators();
		vm.initToggleOption();
		
        InitiativeService.cleanPersistInitiative();
      }
      else {
        if (vm.editId) {
          if (vm.duplicate == 'false') {
            InitiativeService.getInitiative(vm.editId).then(function (res) {
              if (!UserService.getUserData().entidad.isLBG) {
                res.iniciativa.cumpleLGB = false;
              }
              vm.data = res;
              vm.showSelectRazones = !vm.data.iniciativa.cumpleLGB;
              fillSelectors();
              setPrettyDates();
              checkIsSelect();
              getListsMotivacion();
              fillCollaborators();
			  vm.initToggleOption();
            });
          }
          else {
            InitiativeService.getInitiativeDuplicate(vm.editId).then(function (res) {
              if (!UserService.getUserData().entidad.isLBG) {
                res.iniciativa.cumpleLGB = false;
              }

              vm.data = res;
              fillSelectors();
              setPrettyDates();
              checkIsSelect();
              getListsMotivacion();
              fillCollaborators();
			  vm.initToggleOption();
            })
          }
        }
        else{
          setMockODS(2);
        }
      }
      vm.data.iniciativa.cumpleLGB = (vm.data.iniciativa.cumpleLGB == false || vm.data.iniciativa.cumpleLGB == true) ? vm.data.iniciativa.cumpleLGB : UserService.getUserData().entidad.isLBG;

      CollaboratorsService.getCollaborators(0, 8000,"nombre","asc").then(function(res) {
        vm.selectorList.collaborators = res;
      })
      fillSelectors();
      motivacionesOnInit();
      metasOnInit();
    }

    
    vm.activoIniciativasInternas = function(){
      return UserService.getUserData().entidad.activoIniciativasInternas;
    }
	
	vm.agrupacionInternaIniciativasObligatorio = function(){
      return UserService.getUserData().entidad.activoIniciativasInternas && UserService.getUserData().entidad.obligatorioAgrupacionInternaIniciativa;
    }

    function getListsMotivacion() {
	  if (vm.currentEntityData) {
        vm.selectorList.lstMotivacion[0] = [];
        SelectService.getMotivacion(null, "ENTIDAD").then(function (res) {
          vm.selectorList.lstMotivacion[0] = res;
        });
      }
      if (vm.data.iniciativa.lstColaboracionIniciativaDTO) {
		var pos = 0;
        vm.data.iniciativa.lstColaboracionIniciativaDTO.forEach(function (elem, index) {
          if (elem && elem.colaborador) {
            var id = elem.colaborador.id;			
            SelectService.getMotivacion(id, "COLABORADOR").then(function (res) {
			  pos = pos +1;
              vm.selectorList.lstMotivacion[pos] = res;
            })
          }
        })
      }
    }

    function fillCollaborators() {
      if (vm.data.iniciativa.lstColaboracionIniciativaDTO) {
        vm.data.iniciativa.lstColaboracionIniciativaDTO.forEach(function (element) {
          var gradoImplicacion = null;
          vm.selectorList.gradoImplicacion.forEach(function (gi) {
            if (gi.value == element.gradoImplicacion) {
              gradoImplicacion = gi;
            }
          });

          if (!element.colaborador) {
            var collaborator = {}

            vm.currentEntityData.id = element.id //posible solucion????//TODO eso arregla el no poder editar ????
            vm.currentEntityData.gradoImplicacion = gradoImplicacion;
            vm.currentEntityData.motivacion = element.motivacion;
          }
          else {
            var collaborator = {}

            collaborator.id = element.id;
            collaborator.gradoImplicacion = gradoImplicacion;
            collaborator.motivacion = element.motivacion;
            collaborator.entity = element.colaborador;

            vm.collaborators.push(collaborator);
          }
        })
      }

      if (vm.data.iniciativa.lstOdsIniciativaDTO) {
        vm.data.iniciativa.lstOdsIniciativaDTO.forEach(function (element) {
          element.suggestPercentage = false;
          vm.odss.push(element);
          SelectService.getMeta(element.ods.id).then(function (res) {
            vm.selectorList.lstMetas.push(res);
          })
        });
      }

      if (vm.data.iniciativa.lstAsignacionPtoIniciativaDTO) {
        vm.data.iniciativa.lstAsignacionPtoIniciativaDTO.forEach(function (element) {
          if (element.colaborador) {
            element.entity = element.colaborador
            vm.entityBudgets.push(element)
          }
          else {
            vm.currentEntityDataPresu.id = element.id;
            vm.currentEntityDataPresu.presupuesto = element.presupuesto;
            vm.currentEntityDataPresu.divisa = element.divisa;
            vm.currentEntityDataPresu.pais = element.pais;
            vm.currentEntityDataPresu.descripcion = element.descripcion;
          }
        })
      }
      setMockODS(((2-vm.odss.length) > 0 ? (2-vm.odss.length) : 1), true);
    }

    function setPrettyDates() {
      if (vm.data.iniciativa.fechaInicio) {		
        vm.data.iniciativa.fechaInicio = moment(vm.data.iniciativa.fechaInicio, "YYYY-MM-DD").toDate();
      }
      if (vm.data.fechaFin) {
        vm.data.fechaFin = moment(vm.data.fechaFin, "YYYY-MM-DD").toDate();
      }
    }

    vm.onNewCollabSelect = function(selectedObj, fieldName) {
      if(fieldName === "gradoImplicacion") {
        vm.newCollaborator.gradoImplicacion = selectedObj;
      }
      if(fieldName === "motivacion") {
        vm.newCollaborator.motivacion = selectedObj;
      }
      if(fieldName === "entity") {
        vm.newCollaborator.entity = selectedObj;
        motivacionesOnUpdate();
      }
    }
    vm.onEntityCollabSelect = function(selectedObj, fieldName) {
      if(fieldName === "gradoImplicacion") {
        vm.currentEntityData.gradoImplicacion = selectedObj;
      }
      if(fieldName === "motivacion") {
        vm.currentEntityData.motivacion = selectedObj;
      }
    }
    vm.onCollabTableSelect = function (selectedObj, fieldName, $index) {
      if(fieldName === "gradoImplicacion") {
        vm.collaborators[$index].gradoImplicacion = selectedObj;
      }
      if(fieldName === "motivacion") {
        vm.collaborators[$index].motivacion = selectedObj;
      }
      if(fieldName === "entity") {
        vm.collaborators[$index].entity = selectedObj;
      }
    }
    vm.odsUpdate = function(index, selectedObj, fieldName) {
      if(fieldName === "percent") {
        vm.odss[index].suggestPercentage = false;
      }
    }
    vm.odsChange = function(index, selectedObj, fieldName) {
      if(fieldName === "meta") {
        vm.odss[index].meta = selectedObj;
      }
      if(fieldName === "ods") {
        vm.odss[index].ods = selectedObj;
		if (selectedObj == null)
		{
			vm.removeODS(index);
			return false;
		}
      }
      if(fieldName === "percent") {
        vm.odss[index].suggestPercentage = false;
      }	 
      if (angular.isDefined(vm.odss[index].ods.id ) && vm.odss[index].ods.id > 0){
            vm.autofillODSPercentages();
      }
      if ((angular.isDefined(vm.odss[index].ods.id ) && vm.odss[index].ods.id > 0)
          && (angular.isDefined(vm.odss[index].porcentaje ) && vm.odss[index].porcentaje > 0)){
            setMockODS(1, false);
      }
    }

    function autofillODSPercentages(){
      var targetPercentageSum = 100.00;
      var step = 5;
      var autoPercentEnabled = vm.odss.map(function(element, index){
              if(angular.isDefined(element.ods.id ) && element.ods.id > 0){ //elements with all info
                  if(element.suggestPercentage){ //for the elements which % has to be calculated.
                    element.vmOdssId = index; // store the index so later we can update them.
                    return element;
                  } else {
                    targetPercentageSum -= element.porcentaje; // Aggregating fixed percentajes
                  }
              }
              return null;
          }).filter(function(element){ return element != null;}); // filter elements with fixed percentaje
      var estimatedPercentage = step * Math.round((targetPercentageSum/autoPercentEnabled.length)/step); // rounded average
      for (var index = 0; index < autoPercentEnabled.length; index++) {
        if(index == 0 && (estimatedPercentage * autoPercentEnabled.length) < targetPercentageSum){
          vm.odss[autoPercentEnabled[index].vmOdssId].porcentaje = estimatedPercentage + (targetPercentageSum - estimatedPercentage * autoPercentEnabled.length);
        } else if((index == autoPercentEnabled.length - 1) && (estimatedPercentage * autoPercentEnabled.length) > targetPercentageSum){
          vm.odss[autoPercentEnabled[index].vmOdssId].porcentaje = estimatedPercentage - (targetPercentageSum - estimatedPercentage * autoPercentEnabled.length);
        } else{
          vm.odss[autoPercentEnabled[index].vmOdssId].porcentaje = estimatedPercentage;
        }
      }
    }

    function setMockODS(amount, strict){
      strict = angular.isDefined(strict) ? strict : true;
      vm.odss = vm.odss.filter(function(element){ 
        return ((angular.isDefined(element.ods.id ) || element.ods.id > 0)
            || (angular.isDefined(element.meta.id ) || element.meta.id > 0)
            || (angular.isDefined(element.porcentaje ) || element.porcentaje > 0))
      });
      if (!strict){
        vm.odss = vm.odss.filter(function(element){ 
          return ((angular.isDefined(element.ods.id ) && element.ods.id > 0)
              && (angular.isDefined(element.porcentaje ) && element.porcentaje > 0))
        });
      }
      amount = angular.isDefined(amount) ? amount : 1;
      for (var index = 0; index < amount; index++) {
        vm.odss.push({ods:{id: 0, nombre: ''}, meta:{id: 0, nombre: ''}, porcentaje: 0, suggestPercentage: true});
      }
    }

    function motivacionesOnInit() {
      if (vm.newCollaborator && vm.newCollaborator.entity && vm.newCollaborator.entity.id) {
        vm.selectorList.lstMotivacion[-1] = [];//vaciamos la lista
        SelectService.getMotivacion(vm.newCollaborator.entity.id, "COLABORADOR").then(function (res) {
          vm.selectorList.lstMotivacion[-1] = res;
        })
      }
	  if (vm.currentEntityData) {
        vm.selectorList.lstMotivacion[0] = [];
        SelectService.getMotivacion(null, "ENTIDAD").then(function (res) {
          vm.selectorList.lstMotivacion[0] = res;
        });
      }
    }
    function metasOnInit() {
      if (vm.newODS && vm.newODS.ods && vm.newODS.ods.id) {
        vm.selectorList.lstMetas[-1] = [];//vaciamos la lista
        SelectService.getMeta(vm.newODS.ods.id).then(function (res) {
          vm.selectorList.lstMetas[-1] = res;
        })
      }

      if (vm.currentEntityData) {
        vm.selectorList.lstMetas[0] = [];
        SelectService.getMeta(null).then(function (res) {
          vm.selectorList.lstMetas[0] = res;
        })

      }
    }

    function motivacionesOnAdd() {
      vm.selectorList.lstMotivacion.push(vm.selectorList.lstMotivacion[-1]);
    }

    function motivacionesOnDelete(index) {
      vm.selectorList.lstMotivacion.splice(index, 1);
    }

    function motivacionesOnUpdate() {
      vm.selectorList.lstMotivacion[-1] = [];//vaciamos la lista
      SelectService.getMotivacion(vm.newCollaborator.entity.id, "COLABORADOR").then(function (res) {
        vm.selectorList.lstMotivacion[-1] = res;
      })
    }

    function odsOnAdd() {
      vm.selectorList.lstMetas.push(vm.selectorList.lstMetas[-1]);
    }

    function odsOnDelete(index) {
      vm.selectorList.lstMetas.splice(index, 1);
    }


    vm.addNewCollaborator = function ($form) {
      vm.collaborators.push(vm.newCollaborator);
      vm.newCollaborator = {};
      motivacionesOnAdd();
    }
    vm.removeCollaborator = function ($index) {
      if (vm.collaborators[$index].id) {
        vm.data.iniciativa.lstColaboracionIniciativaABorrarDTO.push(vm.collaborators[$index].id);
      }

      vm.collaborators.splice($index, 1);
      motivacionesOnDelete($index + 1);//TODO probablemente hay que aplicar un offset +1 asi que lo probamos
    }
    vm.addNewODS = function ($form) {
      vm.odss.push(vm.newODS);
      vm.newODS = {};
      odsOnAdd();
    }
    vm.removeODS = function ($index) {
      if (vm.odss[$index].id) {
        vm.data.iniciativa.lstOdsIniciativaABorrarDTO.push(vm.odss[$index].id);
      }
      
      vm.odss.splice($index, 1);
      odsOnDelete($index + 1);
      vm.autofillODSPercentages();
      setMockODS(1, false);

    }
    function getLstColaboracionDTO() {
      return [vm.currentEntityData]
      .concat(vm.collaborators)
      .map(function(collab, index) {
        return {
          id: collab ? collab.id : null,
          gradoImplicacion: collab.gradoImplicacion ? collab.gradoImplicacion.value : null,
          motivacion: collab.motivacion,
          colaborador: index === 0 ? null : collab.entity
        }
      })
    }

    function getLstOdsDTO() {
      return [vm.currentEntityData]
      .concat(vm.odss)
      .map(function(ods, index) {
        return {
          id: ods ? ods.id : null,
          meta: ods.meta ? ods.meta.value : null,
          ods: ods.ods ? ods.ods.value : null,
          porcentaje: ods.porcentaje,
        }
      })
    }

    vm.onNewBudgetSelect = function(selectedObj, fieldName) {
      if(fieldName === "entity") {
        vm.newEntityBudget.entity = selectedObj;
      }
      if(fieldName === "divisa") {
        vm.newEntityBudget.divisa = selectedObj;
      }
      if(fieldName === "pais") {
        vm.newEntityBudget.pais = selectedObj;
      }
    }
    vm.onEntityBudgetSelect = function(selectedObj, fieldName) {
      if(fieldName === "divisa") {
        vm.currentEntityDataPresu.divisa = selectedObj;
      }
      if(fieldName === "pais") {
        vm.currentEntityDataPresu.pais = selectedObj;
      }
    }
    vm.onBudgetTableSelect = function(selectedObj, fieldName, $index) {
      if(fieldName === "entity") {
        vm.entityBudgets[$index].entity = selectedObj;
      }
      if(fieldName === "divisa") {
        vm.entityBudgets[$index].divisa = selectedObj;
      }
      if(fieldName === "pais") {
        vm.entityBudgets[$index].pais = selectedObj;
      }
    }

    vm.addNewBudget = function($form) {
      vm.entityBudgets.push(vm.newEntityBudget);
      vm.newEntityBudget = {};
    }
    vm.removeBudget = function($index) {
      if (vm.entityBudgets[$index].id) {
        vm.data.iniciativa.lstAsignacionPtoIniciativaABorrarDTO.push(vm.entityBudgets[$index].id);
      }

      vm.entityBudgets.splice($index, 1);
    }

    function getBudgetDTO() {
      return [vm.currentEntityDataPresu]
      .concat(vm.entityBudgets)
      .map(function(budget, index) {
        return {
          id: budget ? budget.id : null,
          presupuesto: budget && budget.presupuesto ? budget.presupuesto : null,
          descripcion: budget && budget.descripcion ? budget.descripcion : null,
          divisa: budget && budget.divisa ? budget.divisa : null,
          pais: budget && budget.pais ? budget.pais : null,
          colaborador: index === 0 ? null : budget.entity
        }
      })
    }

    vm.clickSelector = function (selectorObject, selectorType) {

      if (selectorType == SelectService.ENUM.country) {
        vm.data.iniciativa.pais = selectorObject;
        checkIsSelect();
        resetSpecialFields();
      }

      if (selectorType == SelectService.ENUM.agrupacion) {
        // vm.data.agrupacionInterna = selectorObject.id;
        vm.data.agrupacionIniciativaInterna = selectorObject;
      }

      if (selectorType == SelectService.ENUM.state) {
        if (selectorObject) {
          vm.data.iniciativa.region = selectorObject.nombre;
        }
        else {
          vm.data.iniciativa.region = null;
        }

      }

      // if (selectorType == SelectService.ENUM.razon) {
      //   vm.data.iniciativa.region = selectorObject.nombre;
      // }

      if (selectorType == 'areaPrimaria') {
        vm.data.iniciativa.areaPrimaria = selectorObject
      }

      if (selectorType == 'areaSecundaria') {
        vm.data.iniciativa.areaSecundaria = selectorObject
      }

      if (selectorType == 'cumpleLGB') {
        vm.data.iniciativa.cumpleLGB = selectorObject
      }
	  
	  if (selectorType == 'proyect') {
        vm.data.iniciativa.proyectoId = selectorObject.id
      }
    }

    vm.clickSelectorMulti = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.razon) {
        vm.data.iniciativa.lstRazons[0] = selectorObject;
      }
    }

    function checkIsSelect () {
      //TODO estudiar bien la condicion (mayusculas minusculas, diferentes idiomas...)
      if (
              vm.data.iniciativa.pais 
              && vm.data.iniciativa.pais.nombre 
              && (
                vm.data.iniciativa.pais.nombre.toLowerCase() == "pais.nombre.españa"
                || vm.data.iniciativa.pais.nombre.toLowerCase() == "pais.nombre.chile"
              )
      ) {
        vm.isSelect = true;
      }
      else {
        vm.isSelect = false;
      }
    }

    vm.checkPercentArea = function (first, newVal, oldVal, otherPercent) {
      if (newVal + otherPercent > 100) {
        if (first) {
          vm.data.iniciativa.porcentajeAreaPrimaria = Number(oldVal);
        }
        else {
          vm.data.iniciativa.porcentajeAreaSecundaria = Number(oldVal);
        }
      }
    }

    vm.checkPercentOds = function (first, newVal, oldVal, otherPercent) {
      if (newVal + otherPercent > 100) {
        if (first) {
          vm.data.iniciativa.porcentajeODSPrimario = Number(oldVal);
        }
        else {
          vm.data.iniciativa.porcentajeODSSecundario = Number(oldVal);
        }
      }
    }

    function fillSelectors(reset) {
      if (!reset) {
        SelectService.getBadges().then(function(res) {
          vm.selectorList.divisas = res;
        })
        loadCountries();
        SelectService.getArea().then(function (res) {
          vm.selectorList.area = res;
        });
		SelectService.getProyects().then(function (res) {
          vm.selectorList.proyects = res;
		  res.forEach(function(elem){
              if (elem.id == vm.idproyect)
			  {
				  vm.data.proyectSelectedName = elem.nombre;
			  }
            });
        });
        SelectService.getRazon().then(function (res) {
          vm.selectorList.razon = res;
        });
        SelectService.getOds().then(function (res) {
          vm.selectorList.ods = res;
          SelectService.getMeta().then(function(res){
            res.forEach(function(elem){
              var nameElements = elem.nombre.split(".");
              if(angular.isUndefined(vm.selectorList.metas[nameElements[2]])){
                vm.selectorList.metas[nameElements[2]] = [];
              }
              if(angular.isUndefined(vm.selectorList.metas[nameElements[2]][elem.id])){
                vm.selectorList.metas[nameElements[2]][elem.id] = elem;
              }
            });
          })

          
        });
        SelectService.getAgrupacionIniciativa().then(function (res) {
          vm.selectorList.agrupacionIniciativa = res;
        });
      }

      if ((reset == null || reset) && vm.data.iniciativa.pais) {
        if (vm.data.iniciativa.pais && vm.data.iniciativa.pais.id) {
          SelectService.getStates(vm.data.iniciativa.pais).then(function (res) {
            vm.selectorList.state = res;
          });
        }
      }
    }

    function loadCountries() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });
    }

    function resetSpecialFields() {
      vm.selectorList.state = [];
      vm.data.region = "";

      fillSelectors(true);
    }

	vm.isInvalidForm = function () {

      var mensajeError = null;

	  var thisFechaInicio = moment(vm.data.iniciativa.fechaInicio).format('YYYY-MM-DD') + 'T00:00:00.000Z';
	  var thisFechaFin = moment(vm.data.fechaFin).format('YYYY-MM-DD') + 'T00:00:00.000Z';
	  if (thisFechaInicio > thisFechaFin)
		mensajeError = 'Check start & end dates';
	  
      if (mensajeError) {
        $mdToast.show(
          $mdToast.simple()
            .textContent(mensajeError)
            .position('bottom right')
            .hideDelay(3000)
        );
        return true;
      }
      else {
        return false;
      }
    }
	
    vm.savingData = false;
    vm.clickSave = function (shouldGoToContributions) {
      if (!vm.isInvalidForm() && !vm.savingData) {
        vm.savingData = true;
        vm.validData = true;
        vm.data.iniciativa.lstColaboracionIniciativaDTO = getLstColaboracionDTO();
        vm.data.iniciativa.lstOdsIniciativaDTO = vm.odss.filter(function(element){ 
          return ((angular.isDefined(element.ods.id ) && element.ods.id > 0)
              && (angular.isDefined(element.porcentaje ) && element.porcentaje > 0))
        }).map(function(e){delete e.suggestPercentage; delete e.vmOdssId; e.id = angular.isDefined(e.id) ? e.id : null; return e;});
        vm.data.iniciativa.lstAsignacionPtoIniciativaDTO = getBudgetDTO();
        if (vm.editId && vm.duplicate == 'false') {
          InitiativeService.updateInitiative(vm.data).then(function (res) {
            vm.savingData = false;
            var initiative = {id: vm.idproyect};
            if(shouldGoToContributions) {
              $state.go("measurement.contributions", {
                id: vm.editId,
                idproyect: vm.idproyect
              });
            } else {
              $state.go("initiative", initiative);
            }
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        } else {
          InitiativeService.createInitiative(vm.data).then(function (res) {
            vm.savingData = false;
            var initiative = {id: vm.idproyect};
            if (shouldGoToContributions) {
              vm.editId = res.id;
              // vm.editId = res.iniciativa.id;
              $state.go("measurement.contributions", {
                id: vm.editId,
                idproyect: vm.idproyect
              });
            } else {
              $state.go("initiative", initiative);
            }
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        }
      }

    }

    vm.clickCancel = function () {
      var initiative = {id: vm.idproyect};
      InitiativeService.cleanPersistInitiative();
      $state.go("initiative", initiative);
    }

    vm.clickAssign = function () {
      if (InitiativeService.getPersistInitiative()) {
        vm.data.iniciativa.lstPrivilegiosIniciativaDTO = InitiativeService.getPersistInitiative().iniciativa.lstPrivilegiosIniciativaDTO;
        vm.data.iniciativa.lstPrivilegiosIniciativaABorrarDTO = InitiativeService.getPersistInitiative().iniciativa.lstPrivilegiosIniciativaABorrarDTO;
      }
      vm.data.iniciativa.lstColaboracionIniciativaDTO = getLstColaboracionDTO();
      vm.data.iniciativa.lstAsignacionPtoIniciativaDTO = getBudgetDTO();
      InitiativeService.persistInitiative(vm.data);
      $state.go("userassigninitiative", {idproyect: vm.idproyect, id: vm.editId});
    }

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
      vm.abreCalendarioDesde = true;
    }

    vm.abreCalendarioHasta = false;
    vm.abrirCalendarioHasta = function () {
      vm.abreCalendarioHasta = true;
    }

	vm.getToggleOption = function(optionName){
		return UserService.getToggleOption(optionName);
	}
	vm.toggleActive = false;
	vm.initToggleOption = function(){		
		if (vm.getToggleOption('initiative_entity_budget') == true)
		{
			if (
				(vm.currentEntityDataPresu.presupuesto != null && vm.currentEntityDataPresu.presupuesto != "" && vm.currentEntityDataPresu.divisa != null && vm.currentEntityDataPresu.divisa != "")
				|| vm.entityBudgets.length > 0				
			)
			{				
				document.getElementById("toggleEntityBudgetColumnButton").style.borderBottom ="1px solid rgba(0,0,0,.125);";
				document.getElementById("toggleEntityBudgetColumn").classList.remove("hidden");				
				document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");								
				vm.toggleActive	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton").style.borderBottom ="0";
				document.getElementById("toggleEntityBudgetColumn").classList.add("hidden");
				document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
				vm.toggleActive = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton").style.borderBottom ="0";
			document.getElementById("toggleEntityBudgetColumn").classList.add("hidden");
			document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
			vm.toggleActive = false;
		}	
	}
	
	vm.changeToggleOption = function(optionName){
		vm.toggleActive = !vm.toggleActive;		
		var actualOption = vm.getToggleOption('initiative_entity_budget');

		if (vm.toggleActive != actualOption)					
			UserService.changeToggleOption(optionName);
		
		if (vm.toggleActive == true)
		{
			document.getElementById("toggleEntityBudgetColumnButton").style.borderBottom ="1px solid rgba(0,0,0,.125);";
			document.getElementById("toggleEntityBudgetColumn").classList.remove("hidden");
			document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton").style.borderBottom ="0";
			document.getElementById("toggleEntityBudgetColumn").classList.add("hidden");
			document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
		}
	}
	
	
    if (!vm.editId) {
		vm.initToggleOption();
	}
  }
})();
