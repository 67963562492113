(function () {
  'use strict';

  angular
    .module('app')
    .service('ProyectService', ProyectService);

  /* @ngInject */
  function ProyectService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getProyects = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/apiV2/datos-proyectos";

      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroFecha = null;
      if (filters && filters.date) {
        filtroFecha = moment(filters.date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
	  
	  var filtroFechaUpdate = null;
      if (filters && filters.fecha_actualizacion) {
        filtroFechaUpdate = moment(filters.fecha_actualizacion).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
	  
      var filtroDivision = null;
      if (filters && filters.division && filters.division.length > 0) {
        filtroDivision = "";
        filters.division.forEach(function (elem) {
          if (filtroDivision != "") {
            filtroDivision += ",";
          }
          filtroDivision += elem.id;
        });
      }

      var filtroAgrupacion = null;
      if (filters && filters.agrupacion) {
        filters.agrupacion.forEach(function (elem) {
          if (filtroAgrupacion != null) {
            filtroAgrupacion += ","
          }
          filtroAgrupacion += elem.id;
        })
        // filtroAgrupacion = filters.agrupacion.id;
      }

      var filtroEstado = null;
      if (filters && filters.estado) {
        filtroEstado = filters.estado;
      }

      var filtroContribucionTotal = null;
      if (filters && filters.contribucionTotal) {
        filtroContribucionTotal = filters.contribucionTotal
      }

      var filtroContribucionEntidad = null;
      if (filters && filters.contribucionEntidad) {
        filtroContribucionEntidad = filters.contribucionEntidad;
      }

      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        nombre: filtroNombre,
        codigoCorto: filtroCodigo,
        fechaInicio: filtroFecha,
		fecha_actualizacion:filtroFechaUpdate, 
        unidadNegocio: filtroDivision,
        agrupacionInterna: filtroAgrupacion,
        estado: filtroEstado,
        contribucionTotal: filtroContribucionTotal,
        contribucionEntidad: filtroContribucionEntidad,
      });

      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            var fecha = element.proyecto.fechaInicio;
            if (fecha) {
              element.fechaBonita = moment(fecha, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
			
			var fecha = element.proyecto.fecha_actualizacion;
            if (fecha) {
              element.fechaUpdateBonita = moment(fecha, "YYYY-MM-DD").format("DD/MM/YYYY");
            }

            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.createProyect = function (entity, file) {
      var url = api + "/apiV2/datos-proyectos";

      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getProyect = function (id) {
      var url = api + "/apiV2/datos-proyectos";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.getProyectDuplicate = function (id) {
      var url = api + "/apiV2/datos-proyectos/duplicate";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateProyect = function (entity) {
      var url = api + "/apiV2/datos-proyectos";

      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteProyect = function (entity) {
      var url = api + "/apiV2/datos-proyectos";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.activeDesactiveProyect = function (entity) {
      var url = api + "/apiV2/datos-proyectos/close-project";
      if (entity && entity.id != null) {

        var params = $httpParamSerializer({
          idDatosProyecto: entity.id,
        });
        url += "?" + params;
        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.persistProyect = function (proyect) {
      localStorage.setItem("proyect", angular.toJson(proyect));
    }

    service.getPersistProyect = function () {
      return angular.fromJson(localStorage.getItem("proyect"));
    }

    service.cleanPersistProyect = function () {
      localStorage.removeItem("proyect");
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
