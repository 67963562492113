(function () {
  'use strict';

  angular
    .module('app')
    .service('PlanService', PlanService);

  /* @ngInject */
  function PlanService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getPlans = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/api/plan-usos";

      //TODO de momento lo obviamos porque no hay paginacion
      // var params = $httpParamSerializer({
      //   size: pageSize,
      //   page: pageNumber,
      // });
      // url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(getNumUsuarios);
        }
        else {
          return [];
        }

      });
    }

    service.createPlan = function (entity) {
      //post /api/entidads
      var url = api + "/api/plan-usos";
      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getPlan = function (id) {
      var url = api + "/api/plan-usos";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return getNumUsuarios(response.data);
        });
      }
      else {
        return false;
      }
    }

    service.updatePlan = function (entity) {
      var url = api + "/api/plan-usos";
      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deletePlan = function (entity) {
      var url = api + "/api/plan-usos";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.activeDesactivePlan = function (entity) {
      var url = api + "/api/plan-usos/active-desactive";
      if (entity && entity.id != null) {
        url += "/" + entity.id;
        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    return service;
  }

  function getNumUsuarios(plan) {
    return plan;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
