(function () {
  angular
    .module('app')
    .component('userassigninitiative', {
      templateUrl: 'components/initiative/userassign/userassign.html',
      controller: UserAssignInitiativeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UserAssignInitiativeController(UserComponentService, RoleService, InitiativeService, UserService, config, SelectService, $filter, $state, $stateParams) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;
    vm.disableInfinite = false;

    vm.auxInitiative
    vm.isPersisted = false;

    vm.data = [];

    vm.lstPrivilegiosIniciativaABorrarDTO = [];

    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.surname = "";
    vm.selectedFilters.email = "";
    vm.selectedFilters.role = RoleService.getEnum().SUPERVISOR_INICIATIVA + "," + RoleService.getEnum().AGENTE;
    vm.selectedFilters.responsibleEntity = "";
    vm.selectedFilters.country = {};
    vm.selectedFilters.active = null;
    vm.rolBonito = "";

    vm.nombreEntidad = UserService.getUserData().entidad.nombre;

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;
      UserComponentService.getUsers(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
        getInitiative();
      });
    }

    function getInitiative() {
      var initiative = InitiativeService.getPersistInitiative();
      if (initiative) {
        vm.auxInitiative = initiative;
        vm.isPersisted = true;
        InitiativeService.cleanPersistInitiative();
        markSelected(vm.auxInitiative);
      }
      else {
        InitiativeService.getInitiative($stateParams.id).then(function (res) {
          vm.auxInitiative = res;
          vm.isPersisted = false;
          markSelected(vm.auxInitiative);
        });
      }
    }

    function markSelected(initiative) {
      if (initiative && initiative.iniciativa && initiative.iniciativa.lstPrivilegiosIniciativaDTO) {
        var lstUsuarios = [].concat(initiative.iniciativa.lstPrivilegiosIniciativaDTO);
        vm.data.map(function (elemData) {
          lstUsuarios.forEach(function (elemMarked) {
            if (elemData.id == elemMarked.usuarioId) {
              elemData.usuarioId = elemData.id
              elemData.id = elemMarked.id;
              elemData.contribuciones = elemMarked.contribuciones
              elemData.logros = elemMarked.logros
              elemData.impactos = elemMarked.impactos
              elemData.selected = elemData.contribuciones
                || elemData.logros
                || elemData.impactos;
            }
          })
        })

      }

    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }
      vm.getData();
    }

    function muestraPermissions(index) {
      // console.log("contribuciones: " + vm.data[index].contribuciones
      //   + ",logros: " + vm.data[index].logros
      //   + ",impactos: " + vm.data[index].impactos
      //   + ",selected: " + vm.data[index].selected
      // )
    }

    //TODO invocar en su sitio
    function checkDelete(index) {
      if (vm.data[index].id) {
        vm.lstPrivilegiosIniciativaABorrarDTO.push(vm.data[index].id)
      }
    }

    vm.clickAllPermissions = function (index) {
      muestraPermissions(index);
      vm.data[index].contribuciones = !vm.data[index].selected;
      vm.data[index].logros = !vm.data[index].selected;
      vm.data[index].impactos = !vm.data[index].selected;
    }

    vm.clickContribuciones = function (index) {
      muestraPermissions(index);
      if (!vm.data[index].contribuciones && !vm.data[index].selected) {
        vm.data[index].selected = true;
      }
      if (vm.data[index].contribuciones
        && !vm.data[index].logros
        && !vm.data[index].impactos) {
        vm.data[index].selected = false;
      }
    }

    vm.clickLogros = function (index) {
      muestraPermissions(index);
      if (!vm.data[index].logros && !vm.data[index].selected) {
        vm.data[index].selected = true;
      }
      if (!vm.data[index].contribuciones
        && vm.data[index].logros
        && !vm.data[index].impactos) {
        vm.data[index].selected = false;
      }
    }

    vm.clickImpactos = function (index) {
      muestraPermissions(index);
      if (!vm.data[index].impactos && !vm.data[index].selected) {
        vm.data[index].selected = true;
      }
      if (!vm.data[index].contribuciones
        && !vm.data[index].logros
        && vm.data[index].impactos) {
        vm.data[index].selected = false;
      }
    }

    function fillFilters() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });

    }

    vm.getData();
    fillFilters();

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickFinish = function () {
      if (vm.isPersisted) {
        var initiative = vm.parseData(vm.auxInitiative);
        InitiativeService.persistInitiative(initiative);

        var initiativeform = {
          idproyect: initiative.iniciativa.proyectoId,
          id: initiative.id || "",
          duplicate: false
        };
        $state.go("initiativeform", initiativeform);
      }
      else {
        var initiative = vm.parseData(vm.auxInitiative);
		if (initiative.iniciativa.fechaInicio) {		
			initiative.iniciativa.fechaInicio = moment(initiative.iniciativa.fechaInicio, "YYYY-MM-DD").toDate();
		}
		if (initiative.fechaFin) {
			initiative.fechaFin = moment(initiative.fechaFin, "YYYY-MM-DD").toDate();
		}
        InitiativeService.updateInitiative(initiative).then(function (res) {
          var initiativedatas = {id: initiative.iniciativa.proyectoId};
          $state.go("initiative", initiativedatas);
        })
      }
    }

    vm.parseData = function (initiative) {
      if (initiative) {
        //asignamos al proyect los usuarios;
        var listUsuarios = [];
        vm.data.forEach(function (element) {
          if (element.selected) {
            var usuarioId = element.usuarioId;
            if (!usuarioId) {
              usuarioId = element.id;
              element.id = null;

            }
            element.iniciativaId = initiative.iniciativa.id;
            var parsedJson = {
              id: element.id,
              contribuciones: element.contribuciones,
              logros: element.logros,
              impactos: element.impactos,
              usuarioId: usuarioId,
              iniciativaId: element.iniciativaId
            }
            listUsuarios.push(parsedJson);
          }
          if (!element.contribuciones && !element.logros && !element.impactos && element.id) {
            vm.lstPrivilegiosIniciativaABorrarDTO.push(element.id);
          }
        });
        initiative.iniciativa.lstPrivilegiosIniciativaDTO = [].concat(listUsuarios);
        initiative.iniciativa.lstPrivilegiosIniciativaABorrarDTO = [].concat(vm.lstPrivilegiosIniciativaABorrarDTO);
        return initiative
      }
    }

    vm.clickAll = function () {
      vm.data.map(function (element, index) {
        element.selected = false;
        vm.clickAllPermissions(index);
        element.selected = true;
        return element;
      })
    }

    vm.clickAny = function () {
      vm.data.map(function (element, index) {
        element.selected = true;
        vm.clickAllPermissions(index);
        element.selected = false;
        return element;
      })
    }

    vm.loadMore = function () {
      /*if (vm.busy) return;
      vm.busy = true;

      UserComponentService.getUsers(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        vm.busy = false;
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        }
        else {
          vm.page++;
        }

        vm.disableInfinite = vm.busy | vm.allDataLoaded;

      });

      vm.disableInfinite = vm.busy | vm.allDataLoaded;*/

    };

  }
})();
