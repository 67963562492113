(function () {
  angular
    .module('app')
    .component('resume', {
      templateUrl: 'components/resume/resume.html',
      controller: ResumeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ResumeController(ResumeService, UserService, $stateParams, $state) {
    var vm = this;

    if (UserService.getUserData() &&
      UserService.getUserData().entidad &&
      UserService.getUserData().entidad.abreviatura) {
      vm.nombreEntidad = UserService.getUserData().entidad.abreviatura;
    }
    else {
      vm.nombreEntidad = "Entidad";
    }

    vm.id = $stateParams.id;

    vm.data = {};

    if (vm.id) {
      //TODO cambiar por una llamada al servicio real con su then
      // vm.data = ResumeService.getResume(vm.id);

      ResumeService.getProyectResume(vm.id).then(function (res) {
        vm.data = res;
      })
    }

    vm.clickCancel = function () {
      $state.go("proyect");
    }

  }
})();
