(function () {
  angular
    .module('app')
    .component('salarytablesform', {
      templateUrl: 'components/salarytables/form/salarytablesform.html',
      controller: SalaryTablesFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function SalaryTablesFormController(SelectService, SalaryTablesService, $state, $stateParams, Translator, $translate) {
    var vm = this;

    vm.validData = true;
    vm.errorMessage = "";
    vm.loading = true;

    vm.editId = $stateParams.id;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";
    vm.data.descripcion = "";
    vm.data.totalCoste = 0;
    vm.data.divisaTotalCoste = {};
    vm.data.numPersonas = 0;
    vm.data.horas = 0;
    vm.data.costeHora = 0;
    vm.data.divisaCosteHora = {};
    vm.data.lstAgnoAplicacion = [];
    vm.data.agnoAplicacion = "";

    vm.selectedYears = [];
    vm.prettyYears = "";


    vm.selectorList = {};

    if (vm.editId) {
      SalaryTablesService.getSalaryTable(vm.editId).then(function (res) {
        vm.data = res;
        vm.loading = false;
        fillSelectors();
      });
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == "divisaHoras") {
        vm.data.divisaCosteHora = selectorObject;
      }
      if (selectorType == "divisaTotal") {
        vm.data.divisaTotalCoste = selectorObject;
      }
      if (selectorType == SelectService.ENUM.years) {
        vm.data.agnoAplicacion = selectorObject;
      }
    }

    vm.clickSelectorMulti = function (selectorObject, selectorType, index) {
      if (selectorType == SelectService.ENUM.years) {
		if (selectorObject == $translate.instant("filterReport.todos"))
		{
			vm.selectedYears = [];
			vm.selectorList.years.forEach(function (element) {
				vm.selectedYears.push(false);
			});
			vm.selectedYears[index] = true;
			
			vm.data.lstAgnoAplicacion = [];
			vm.data.lstAgnoAplicacion.push(-1);
			
			vm.prettyYears = showYears(vm.data.lstAgnoAplicacion);
		}
		else
		{
			if (vm.data.lstAgnoAplicacion.indexOf(-1) >= 0)
			{
				vm.selectedYears = [];
				vm.data.lstAgnoAplicacion = [];
			}
			
			if (!vm.selectedYears[index]) {
			  //si no esta lo metemos
			  vm.data.lstAgnoAplicacion.push(selectorObject);
			  vm.selectedYears[index] = true;
			}
			else {
			  //si esta lo sacamos
			  var indexToDelete = vm.data.lstAgnoAplicacion.indexOf(selectorObject);
			  vm.data.lstAgnoAplicacion.splice(indexToDelete, 1)
			  vm.selectedYears[index] = false;
			}
			vm.prettyYears = showYears(vm.data.lstAgnoAplicacion);
		}
      }
    }

    function showYears(years) {
      var prettyYears = "";
      if (years) {
        years.forEach(function (year) {
          if (prettyYears != "") {
            prettyYears += ", "
          }
		  if (year == -1)
			  prettyYears += $translate.instant("filterReport.todos")
		  else
			prettyYears += year
        })
      }
      return prettyYears;
    }

    function fillSelectors() {
      vm.selectorList.years = SelectService.getYears();

      //para el multi
      vm.selectedYears = [];
      vm.selectorList.years.forEach(function (element) {
        if (vm.editId && vm.data.lstAgnoAplicacion && vm.data.lstAgnoAplicacion.indexOf(element) != -1) {
          vm.selectedYears.push(true);
        }
        else {
          vm.selectedYears.push(false);
        }
      })
      vm.prettyYears = showYears(vm.data.lstAgnoAplicacion);
      //fin multi


      SelectService.getBadges().then(function (res) {
        vm.selectorList.badges = res;
      });
    }

    fillSelectors();

    vm.clickSave = function () {
      vm.validData = true;
      if (vm.editId) {
        SalaryTablesService.updateSalaryTable(vm.data).then(function (res) {
          $state.go("entitytoolbar.salarytables");
        })
          .catch(function (e) {
            showError(e);
          });
      }
      else {
        SalaryTablesService.createSalaryTable(vm.data).then(function (res) {
          $state.go("entitytoolbar.salarytables");
        })
          .catch(function (e) {
            showError(e);
          });
      }
    }

    //TODO generalizar
    function showError(e) {
      var key = e.data.message ? e.data.message : 'err.genericError';
      Translator.translate(key, function(translation) {
        vm.errorMessage = translation;
      });
    }

    vm.clickCancel = function () {
      $state.go("entitytoolbar.salarytables");
    }
  }
})();
