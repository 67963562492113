(function () {
  angular
    .module('app')
    .component('forgotpassnew', {
      templateUrl: 'components/forgotpass/forgotpassnew/forgotpassnew.html',
      controller: ForgotPassNewController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ForgotPassNewController(ForgotPassService, MeService, $stateParams, Toaster) {
    var vm = this;

    vm.data = {};
    //inicializamos data
    vm.data.newpass = "";
    vm.data.newpass2 = "";

    vm.passChanged = false;

    vm.key = $stateParams.key;

    vm.clickSave = function () {
      if (vm.data.newpass != vm.data.newpass2) {
        Toaster.simpleToast('forgotpassnew.passChangedFail2');
        return;
      }
      if (!MeService.validaContraseña(vm.data.newpass)) {
        Toaster.simpleToast('forgotpassnew.passChangedFail1');
      }
      
      ForgotPassService.resetPassFinish(vm.key, vm.data.newpass).then(function (res) {
        Toaster.simpleToast('forgotpassnew.passChangedOk');
        //TODO mostrar mensaje y ¿loguear? o ¿llevar al login?
        vm.passChanged = true;
      });
    }
  }
})();
