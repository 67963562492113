(function () {
  'use strict';

  angular
    .module('app')
    .service('InitiativeService', InitiativeService);

  /* @ngInject */
  function InitiativeService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getInitiatives = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/apiV2/datos-iniciativas";

      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroFecha = null;
      if (filters && filters.date) {
        filtroFecha = moment(filters.date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
	  
	  var filtroFechaUpdate = null;
      if (filters && filters.fecha_actualizacion) {
        filtroFechaUpdate = moment(filters.fecha_actualizacion).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }

      var filtroPais = null;
      if (filters && filters.country && filters.country.id) {
        filtroPais = filters.country.id;
      }

      var filtroMotivacion = null;
      if (filters && filters.motivacion) {
        filters.motivacion.forEach(function (elem) {
          if (filtroMotivacion != null) {
            filtroMotivacion += ","
          }
          filtroMotivacion += elem.id;
        })
        // filtroMotivacion = filters.motivacion.id;
      }

      var filtroEsLBG = null;
      if (filters && filters.esLBG != null) {
        filtroEsLBG = filters.esLBG;
      }

      var filtroArea = null;
      if (filters && filters.area) {
        filters.area.forEach(function (elem) {
          if (filtroArea != null) {
            filtroArea += ","
          }
          filtroArea += elem.id;
        })
        // filtroArea = filters.area.id;
      }

      var filtroIdProyect = null;
      if (filters && filters.idProyect) {
        filtroIdProyect = filters.idProyect;
      }

      var filtroContribucionTotal = null;
      if (filters && filters.contribucionTotal) {
        filtroContribucionTotal = filters.contribucionTotal;
      }

      var filtroContribucionEntidad = null;
      if (filters && filters.contribucionEntidad) {
        filtroContribucionEntidad = filters.contribucionEntidad;
      }

      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        nombre: filtroNombre,
        codigoCorto: filtroCodigo,
        fechaInicio: filtroFecha,
		fecha_actualizacion: filtroFechaUpdate,
        pais: filtroPais,
        motivacion: filtroMotivacion,
        // LBG: filtroEsLBG,//TODO mirar como enviar el true / false
        area: filtroArea,
        contribucionTotal: filtroContribucionTotal,
        contribucionEntidad: filtroContribucionEntidad,
        proyecto: filtroIdProyect,
      });

      url += "?" + params;

      if (filtroEsLBG != null) {
        url += "&LBG=" + (filtroEsLBG ? 'true' : 'false');
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            var fecha = element.iniciativa.fechaInicio;
            if (fecha) {
              element.fechaBonita = moment(fecha, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
			var fecha = element.iniciativa.fecha_actualizacion;
            if (fecha) {
			  element.fechaUpdateBonita = moment(fecha, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
			
            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.getProyectState = function (idProyect) {
      //http://localhost:8080/api/datos-proyectos/estado/{idProyecto}
      var url = api + "/apiV2/datos-proyectos/estado/";
      if (idProyect != null) {
        url += idProyect
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        var isOpen = false;
        if (response && response.data) {
          if (response.data == "CERRADO") {
            isOpen = false;
          }
          else if (response.data == "ABIERTO") {
            isOpen = true;
          }
          else {
            isOpen = null;
          }
        }

        return isOpen;
      });

    }

    service.createInitiative = function (entity, file) {
      var url = api + "/apiV2/datos-iniciativas";
		
	  if (typeof entity.agrupacionIniciativaInterna != 'undefined')
	  {
		  entity.agrupacionIniciativaInterna = Array.from(entity.agrupacionIniciativaInterna);
		  if (entity.agrupacionIniciativaInterna.length <= 0)
			delete entity.agrupacionIniciativaInterna;
	  }
      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getInitiative = function (id) {
      var url = api + "/apiV2/datos-iniciativas";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.getInitiativeDuplicate = function (id) {
      var url = api + "/apiV2/datos-iniciativas/duplicate";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateInitiative = function (entity) {
      var url = api + "/apiV2/datos-iniciativas";
	
	  if (typeof entity.agrupacionIniciativaInterna != 'undefined')
	  {
		  entity.agrupacionIniciativaInterna = Array.from(entity.agrupacionIniciativaInterna);
		  if (entity.agrupacionIniciativaInterna.length <= 0)
			delete entity.agrupacionIniciativaInterna;
	  }
		
      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteInitiative = function (entity) {
      var url = api + "/apiV2/datos-iniciativas";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.persistInitiative = function (proyect) {
      localStorage.setItem("initiative", angular.toJson(proyect));
    }

    service.getPersistInitiative = function () {
      return angular.fromJson(localStorage.getItem("initiative"));
    }

    service.cleanPersistInitiative = function () {
      localStorage.removeItem("initiative");
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
