angular
  .module('app')
  .config(routesConfig);

/* @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('login', {
      url: '/',
      component: 'login'
    })
    .state('home', {
      url: '/home',
      component: 'home'
    })
    .state('manage', {
      url: '/manage',
      component: 'manage'
    })
    .state('manage.entity', {
      url: '/entity',
      component: 'entity'
    })
    .state('manage.entityform', {
      url: '/entity/form/:id',
      component: 'entityform'
    })
    .state('manage.user', {
      url: '/user',
      component: 'user'
    })
    .state('manage.userform', {
      url: '/user/form/:id',
      component: 'userform'
    })
    .state('manage.plan', {
      url: '/plan',
      component: 'plan'
    })
    .state('manage.planform', {
      url: '/plan/form/:id',
      component: 'planform'
    })
    .state('manage.exchangeRate', {
      url: '/exchangeRate',
      component: 'exchangeRate'
    })
    .state('manage.exchangeRateForm', {
      url: '/exchangeRate/form/:id',
      component: 'exchangeRateForm'
    })
    .state('entitytoolbar', {
      url: '/profile',
      component: 'entitytoolbar'
    })
    .state('entitytoolbar.data', {
      url: '/data',
      component: 'data'
    })
    .state('entitytoolbar.rating', {
      url: '/rating',
      component: 'rating'
    })
    .state('entitytoolbar.salarytables', {
      url: '/salarytables',
      component: 'salarytables'
    })
    .state('entitytoolbar.salarytablesform', {
      url: '/salarytables/:id/form',
      component: 'salarytablesform'
    })
    .state('uploadform', {
      url: '/uploadform?id&tipoEntidad&multi&idProyecto&idIniciativa',
      component: 'uploadform'
    })
    .state('manage.collaborators', {
      url: '/collaborators',
      component: 'collaborators'
    })
    .state('manage.collaboratorsform', {
      url: '/collaborators/:id/form',
      component: 'collaboratorsform'
    })
    .state('proyect', {
      url: '/proyect',
      component: 'proyect'
    })
    .state('proyectform', {
      url: '/proyect/:id/form/:duplicate',
      component: 'proyectform'
    })
    .state('userassign', {
      url: '/proyect/:idproyect/userassign/:duplicate',
      component: 'userassign'
    })
    .state('resume', {
      url: '/proyect/:id/resume/',
      component: 'resume'
    })
    .state('initiative', {
      url: '/proyect/:id/initiative?cleanFilter',
      component: 'initiative'
    })
    .state('initiativeresume', {
      url: '/proyect/:idproyect/initiative/:id/resume',
      component: 'initiativeresume'
    })
    .state('initiativeform', {
      url: '/proyec/:idproyect/initiative/:id/form/:duplicate',
      component: 'initiativeform'
    })
    .state('userassigninitiative', {
      url: '/proyect/:idproyect/initiative/:id/userassign',
      // url: '/userassigninitiative',
      component: 'userassigninitiative'
    })
    .state('measurement', {
      url: '/proyect/:idproyect/initiative/:id/measurement',
      component: 'measurement'
    })
    .state('measurement.contributions', {
      url: '/contributions?cleanFilter',
      component: 'contributions'
    })
    .state('measurement.achievements', {
      url: '/achievements?cleanFilter',
      component: 'achievements'
    })
    .state('measurement.achievementsform', {
      url: '/achievements/form/:editId/:duplicate',
      component: 'achievementsform'
    })
    .state('measurement.impacts', {
      url: '/impacts?cleanFilter',
      component: 'impacts'
    })
    .state('measurement.impactsform', {
      url: '/impacts/form/:editId/:duplicate',
      component: 'impactsform'
    })
    .state('error', {
      url: '/error',
      component: 'error',
    })
    .state('me', {
      url: '/me',
      component: 'me'
    })
    .state('pass', {
      url: '/me/pass',
      component: 'pass'
    })
    .state('forgotpass', {
      url: '/forgotpass',
      component: 'forgotpass'
    })
    .state('forgotpassnew', {
      url: '/forgotpass/new/:key',
      component: 'forgotpassnew'
    })
    .state('contact', {
      url: '/contact',
      component: 'contact'
    })
    .state('filters.filterReport', {
      url: '/filterReport',
      component: 'filterReport'
    })
    .state('filters.reportChart', {
      url: '/reportChart',
      component: 'reportChart'
    })
    .state('filters', {
      url: '/filters',
      component: 'filters'
    })
    .state('filters.sheetReport', {
      url: '/sheetReport',
      component: 'sheetReport'
    })
  ;
}
