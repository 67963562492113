(function () {
  angular
    .module('app')
    .component('initiativeresume', {
      templateUrl: 'components/initiative/resume/resume.html',
      controller: ResumeInitiativeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ResumeInitiativeController(ResumeService, UserService, $stateParams, $state) {
    var vm = this;

    if (UserService.getUserData() &&
      UserService.getUserData().entidad &&
      UserService.getUserData().entidad.abreviatura) {
      vm.nombreEntidad = UserService.getUserData().entidad.abreviatura;
    }
    else {
      vm.nombreEntidad = "Entidad";
    }

    vm.id = $stateParams.id;
    vm.idproyect = $stateParams.idproyect;

    vm.data = {};

    if (vm.id) {
      ResumeService.getInitiativeResume(vm.id).then(function (res) {
        vm.data = res;
      })
    }

    vm.clickCancel = function () {
      $state.go("initiative", {id: vm.idproyect});
    }

  }
})();
