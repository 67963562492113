(function () {
  angular
    .module('app')
    .component('exchangeRateForm', {
      templateUrl: 'components/exchangeRate/form/exchangerateform.html',
      controller: ExchangeRateFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ExchangeRateFormController(SelectService, ExchangeRateService, $state, $stateParams, Translator) {
    var vm = this;

    vm.validData = true;
    vm.errorMessage = "";

    vm.editId = $stateParams.id;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";
    vm.data.descripcion = "";
    vm.data.fechaDesde = null;
    vm.data.fechaHasta = null;
    vm.data.archivo = "";

    vm.data.grupoLBG = {};

    vm.selectorList = {};

    vm.archivo = {};

    if (vm.editId) {
      ExchangeRateService.getExchangeRate(vm.editId).then(function (res) {
        if (res.fechaDesde) {
          res.fechaDesde = new Date(res.fechaDesde);
        }
        if (res.fechaHasta) {
          res.fechaHasta = new Date(res.fechaHasta);
        }

        vm.data = res;
        fillSelectors();
      });
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.data.grupoLBG = selectorObject;
      }
    }

    function fillSelectors(reset) {
      if (reset == null) {
        SelectService.getLGBGroup().then(function (res) {
          vm.selectorList.lgbGroup = res;
          if (res.length > 0 && !vm.data.grupoLBG.id) { // TODO comprobar condicion
            vm.data.grupoLBG = res[0]
            fillSelectors(true);//TODO comprobar que funciona
          }
        });
      }
    }

    fillSelectors();

    vm.clickSave = function () {
      vm.validData = true;
      if (vm.editId) {
        ExchangeRateService.updateExchangeRate(vm.data, vm.archivo).then(function (res) {
          $state.go("manage.exchangeRate");
        })
          .catch(function (e) {
            showError(e);
          });
      }
      else {
        ExchangeRateService.createExchangeRates(vm.data, vm.archivo).then(function (res) {
          $state.go("manage.exchangeRate");
        })
          .catch(function (e) {
            showError(e);
          });
      }
    }

    //TODO the error message is never actually displayed. Since vm.validData is never set to false.
    function showError(e) {
      var transkey = e && e.data && e.data.message ? e.data.message : 'err.genericError';
      Translator.translate(transKey, function(translation) {
        vm.errorMessage = translation;
      })
    }

    vm.clickCancel = function () {
      $state.go("manage.exchangeRate");
    }

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
        vm.abreCalendarioDesde = true;
    }

    vm.abreCalendarioHasta = false;
    vm.abrirCalendarioHasta = function () {
        vm.abreCalendarioHasta = true;
    }

  }
})();
