(function() {
  'use strict';

  angular
    .module('app')
    .filter('impactNumber', impactNumberFilter);
  angular
    .module('app')
    .filter('impactNumber2', impactNumberFilter2);
  angular
    .module('app')
    .filter('percentInforme', percentInforme);
  angular
    .module('app')
    .filter('filterNull', filterNull);
  /* @ngInject */
  function impactNumberFilter(UserService, $translate) {
    return function (input, withoutCurrency) {
      var userData = UserService.getUserData();
      var currencySymbol = userData.divisa.simbolo;
      if (currencySymbol == null) {
        currencySymbol = userData.divisa.abreviatura
      }
      if(!input) {
        var ceroValue = '0,00'
        if (!withoutCurrency) {
          ceroValue += ' ' + currencySymbol;
        }
        return ceroValue;
      }
      var inputFixedDecimals = input.toFixed(2);
      var lang = $translate.preferredLanguage();
	  
      var formatter = Intl.NumberFormat(lang);
      if (withoutCurrency) {
        return formatter.format(inputFixedDecimals)
      }
      else {
        return formatter.format(inputFixedDecimals) + " " + currencySymbol;
      }
    }
  }

  function impactNumberFilter2(UserService, $translate) {
    return function (input, withoutCurrency, decimals, pos, last, currencyLast) {
      var decimalsLength = (decimals) ? decimals:2;
      var userData = UserService.getUserData();
      var divisaStored = angular.fromJson(localStorage.getItem("divisaInforme"));
      var lang = $translate.preferredLanguage();
      var formatter = Intl.NumberFormat(lang);
      if (divisaStored == null) {
        var currencySymbol = userData.divisa.simbolo;
        if (currencySymbol == null) {
          currencySymbol = userData.divisa.abreviatura;
        }
      }
      else {
        var currencySymbol = divisaStored.simbolo;
        if (currencySymbol == null) {
          currencySymbol = divisaStored.abreviatura;
        }
      }

      if(!input) {
          input = 0
      }
      var inputFixedDecimals = input.toFixed(decimalsLength);

      if (!withoutCurrency || (withoutCurrency && currencyLast && (Number.isInteger(pos) && Number.isInteger(last) && pos==(last-1)))) {
        return formatter.format(inputFixedDecimals) + " " + currencySymbol;
      }
      else {
        return formatter.format(inputFixedDecimals)
      }
    }
  }

  function percentInforme(UserService, $translate) {
    return function (input, pos, last, for_all) {
      if(!input) {
        if (for_all || (Number.isInteger(pos) && Number.isInteger(last) && pos<(last-1))) {return '0 %';}
        else {return '';}
      }
      if( "".concat(input).indexOf("%") != -1){
        return input;
      }
      var lang = $translate.preferredLanguage();
      var formatter = Intl.NumberFormat(lang);
      return formatter.format(input) + " %";
    }
  }

  function filterNull(UserService, $translate) {
    return function (input,pos,last,for_all) {
      if(input == null) {
        return '';
      }
      return input;
    }
  }
})();
