(function () {
  angular
    .module('app')
    .component('impacts', {
      templateUrl: 'components/impacts/impacts.html',
      controller: ImpactsController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ImpactsController(ImpactsService, InitiativeService, config, $mdDialog, $stateParams, $state, BreadcrumService, $rootScope, ModalService, RoleService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.id = $stateParams.id;
    vm.idproyect = $stateParams.idproyect;
    vm.filterStorageName = "impacts"
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);

    vm.sortField = "fechaActualizacion"//"fechaActualizado";//config.defaultSortField;
    vm.sortAscOrder = false;//config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;
    vm.disableInfinite = false;

    vm.data = [];
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.description = "";
    vm.selectedFilters.applyYear = "";

    vm.sinPermisos = false;

    vm.proyectClosed = false;

    InitiativeService.getProyectState(vm.idproyect).then(function (res) {
      vm.proyectClosed = !res;
    })

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;
      ImpactsService.getImpacts(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters, vm.id).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
        // $rootScope.$emit('noPermission', false);
        $rootScope.$broadcast('noPermission', false);
      })
        .catch(function (ex) {
          if (ex.status == 403) {
            vm.sinPermisos = true;
          }
        })
      ;
    }

    var auxFilters = BreadcrumService.getFilters(vm.filterStorageName);
    if ($stateParams.cleanFilter == 'true') {
      auxFilters = null;
    }
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
      if (vm.selectedFilters.date) {
        vm.selectedFilters.date = moment(vm.selectedFilters.date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
      vm.sortAscOrder = auxFilters.sortAscOrder;
      vm.sortField = auxFilters.sortField;
    }


    vm.getData();

    vm.saveFilters = function () {
      vm.selectedFilters.sortField = vm.sortField;
      vm.selectedFilters.sortAscOrder = vm.sortAscOrder;
      BreadcrumService.saveFilters(vm.selectedFilters, vm.filterStorageName);
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        ImpactsService.deleteImpact(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          })
        ;
      };
      ModalService.showConfirm(ev, 'impact.borrarImpacto', 'impact.estasSeguro', 'impact.borrar', 'impact.cancelar', whenOk);
    }

    vm.activeDesactive = function (ev, index) {
      if (vm.proyectClosed) {
        return;
      }

      var whenOk = function () {
        ImpactsService.activeDesactiveImpact(vm.data[index])
          .then(function (res) {
            if (res) {
              vm.data[index].validado = res.validado;
            }
          })
        ;
      };
      ModalService.showConfirm(ev,
        vm.data[index].validado ? 'impact.quitarValidacion' : 'impact.validarImpacto', 'impact.estasSeguro',
        vm.data[index].validado ? 'impact.quitarValidacion' : 'impact.validarImpacto', 'impact.cancelar',
        whenOk);//TODO cambiar texto
    }

    vm.goUpload = function (index) {
      vm.saveFilters();
      var element = vm.data[index];
      var uploadFormData = {id: element.id, tipoEntidad: 6, multi: true, idProyecto: vm.idproyect, idIniciativa: vm.id}
      $state.go("uploadform", uploadFormData);
    }

    vm.abreCalendario = false;
    vm.abrirCalendario = function () {
      vm.abreCalendario = true;
    }

    vm.loadMore = function () {
      if (vm.busy) return;
      vm.busy = true;

      ImpactsService.getImpacts(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters, vm.id).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        vm.busy = false;
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        }
        else {
          vm.page++;
        }

        vm.disableInfinite = vm.busy | vm.allDataLoaded;
      })
        .catch(function (ex) {
          if (ex.status == 403) {
            vm.sinPermisos = true;
          }
        })
      ;

      vm.disableInfinite = vm.busy | vm.allDataLoaded;

    };

  }
})();
