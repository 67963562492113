(function () {
  angular
    .module('app')
    .component('login', {
      templateUrl: 'components/login/login.html',
      controller: LoginController,
      controllerAs: 'vm'
    });

  // LoginController.$inject = 'UserService, TokenService, $state'.split(', ')
  // function LoginController(UserService, TokenService, $state) {

  /* @ngInject */
  function LoginController(UserService, RoleService, $state, config, $mdToast, $translate, $rootScope) {
    var vm = this;

    vm.validData = true;

    vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);

    vm.loginModel = {
      username: '',
      password: ''
    }
    vm.login = login;

    function login($form) {
      vm.validData = true;
      UserService.login(vm.loginModel)
        .then(function (json) {
          vm.loginModel = {};

          //TODO Modificado rober
          //TokenService.setToken(json.id_token);

          var ngInjector = angular.injector(['ng']),
          $window = ngInjector.get('$window');

          $window.localStorage.setItem('token', json.id_token);

          $form.$setPristine();
          $form.$setUntouched();

          UserService.fetchUserData().then(function (res) {

			$translate.use(res.idioma.toLowerCase());
			$rootScope.selectedLang = res.idioma;
			document.querySelector('select[name="header-lang"]').value = res.idioma;
			
            UserService.setUserData(res);

            UserService.fetchLogo(res).then(function (res) {
              vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
              if (vm.isRespOrLess) {
                $state.go("proyect")
              }
              else {
                $state.go("manage.entity")
              }
            })
              .catch(function (e) {
                // $state.go("proyect")//TODO NO DEBERIA DEJAR PASAR!!!! ESTO LO TENEMOS QUE QUITAR DE AQUI
                vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
                if (vm.isRespOrLess) {
                  $state.go("proyect")
                }
                else {
                  $state.go("manage.entity")
                }
              });

          })
            .catch(function (e) {
              // $state.go("proyect")//TODO NO DEBERIA DEJAR PASAR!!!! ESTO LO TENEMOS QUE QUITAR DE AQUI
              vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
              if (vm.isRespOrLess) {
                $state.go("proyect")
              }
              else {
                $state.go("manage.entity")
              }
            });

        })
      // .catch(function (e) {
      //   if (e.data.AuthenticationException.indexOf(" was not activated") > -1) {
      //     // vm.validData = false;//TODO de momento con el interceptor
      //   }
      // });
    }

    vm.forgotPass = function () {
      $state.go('forgotpass');
    }

  }

})();
