(function () {
  angular
    .module('app')
    .component('proyectform', {
      templateUrl: 'components/proyect/form/proyectform.html',
      controller: ProyectFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ProyectFormController(SelectService, ProyectService, UserService, RoleService, $state, $stateParams, $mdToast) {
    var vm = this;

    vm.validData = true;
    vm.errorMessage = "";

    vm.editId = $stateParams.id;
    vm.duplicate = $stateParams.duplicate;

    vm.proyectClosed

    vm.data = {};
    //inicializamos data
    vm.data.proyecto = {};
    vm.data.proyecto.nombre = "";
    vm.data.proyecto.descripcion = "";
    vm.data.proyecto.fechaInicio = "";
    vm.data.proyecto.fechaFin = "";
    vm.data.unidadNegocio = {};
    vm.data.agrupacionInterna = {};
    vm.data.proyecto.presupuestoTotal = "";
    vm.data.proyecto.divisaPptoTotalId = "";
    vm.data.presupuestoEntidad = "";
    vm.data.divisaPptoEntidadId = "";

    vm.nombreDivisaTotal = "";
    vm.nombreDivisaEmpresa = "";
    vm.nombreEntidad = UserService.getUserData().entidad.abreviatura;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    vm.selectorList = {};

    if (vm.editId) {
      if (vm.duplicate == 'false') {
        ProyectService.getProyect(vm.editId).then(function (res) {
          vm.data = res;
          vm.proyectClosed = vm.data.estado != "ABIERTO"
          setPrettyDates()
          setPrettyBadgeName();
		  markSelected();
		  initToggleOption();
		  
		  if (ProyectService.getPersistProyect()) {
			var persistedProyect = ProyectService.getPersistProyect();
			ProyectService.cleanPersistProyect();
			vm.data.proyecto.lstUsuarioDTO = persistedProyect.proyecto.lstUsuarioDTO;
		  }
        });

      }
      else {
        ProyectService.getProyectDuplicate(vm.editId).then(function (res) {
          vm.data = res;
          setPrettyDates()
          setPrettyBadgeName();
		  markSelected();
		  initToggleOption();
		  
		  if (ProyectService.getPersistProyect()) {
			var persistedProyect = ProyectService.getPersistProyect();
			ProyectService.cleanPersistProyect();
			vm.data.proyecto.lstUsuarioDTO = persistedProyect.proyecto.lstUsuarioDTO;
		  }
        });
      }
    }
    else {

      if (ProyectService.getPersistProyect()) {
        vm.data = ProyectService.getPersistProyect();
        ProyectService.cleanPersistProyect();

        setPrettyDates()
        setPrettyBadgeName();
		//markSelected();
		initToggleOption();
      }
    }

	function markSelected() {
      if (vm.data && vm.data.proyecto && vm.data.proyecto.lstUsuarioDTO) {
        var lstUsuarios = vm.data.proyecto.lstUsuarioDTO;
          lstUsuarios.forEach(function (elemMarked) {
              elemMarked.selected = true;
          })
      }
    }
	
    function setPrettyBadgeName() {
      if (vm.selectorList.badge && (!vm.nombreDivisaEmpresa || !vm.nombreDivisaTotal)) {
        vm.selectorList.badge.forEach(function (elem) {
          if (elem.id == vm.data.divisaPptoEntidadId) {
            vm.nombreDivisaEmpresa = elem.nombreCompleto;
          }
        });

        vm.selectorList.badge.forEach(function (elem) {
          if (elem.id == vm.data.proyecto.divisaPptoTotalId) {
            vm.nombreDivisaTotal = elem.nombreCompleto;
          }
        });
      }
    }

    function setPrettyDates() {
      if (vm.data.proyecto.fechaInicio) {
        vm.data.proyecto.fechaInicio = moment(vm.data.proyecto.fechaInicio).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
      if (vm.data.proyecto.fechaFin) {
        vm.data.proyecto.fechaFin = moment(vm.data.proyecto.fechaFin).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
    }

    vm.isAgrupacionInternaMandatory = function(){
      return UserService.getUserData().entidad.obligatorioAgrupacionInterna;
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.division) {
        // vm.data.unidadNegocio = selectorObject.id;
        vm.data.unidadNegocio = selectorObject;
      }

      if (selectorType == SelectService.ENUM.agrupacion) {
        // vm.data.agrupacionInterna = selectorObject.id;
        vm.data.agrupacionInterna = selectorObject;
      }

      if (selectorType == "divisaEmpresa") {
        vm.data.divisaPptoEntidadId = selectorObject.id;
        vm.nombreDivisaEmpresa = selectorObject.nombreCompleto;
      }

      if (selectorType == "divisaTotal") {
        vm.data.proyecto.divisaPptoTotalId = selectorObject.id;
        vm.nombreDivisaTotal = selectorObject.nombreCompleto;
      }
    }

    function fillSelectors() {
      SelectService.getDivision().then(function (res) {
        vm.selectorList.division = res;
      });

      SelectService.getAgrupacion().then(function (res) {
        vm.selectorList.agrupacion = res;
      });

      SelectService.getBadges().then(function (res) {
        vm.selectorList.badge = res;
        setPrettyBadgeName();
      });
    }

    fillSelectors();

    vm.isInvalidForm = function () {

      var mensajeError = null;

	  var thisFechaInicio = moment(vm.data.proyecto.fechaInicio).format('YYYY-MM-DD') + 'T00:00:00.000Z';
	  var thisFechaFin = moment(vm.data.proyecto.fechaFin).format('YYYY-MM-DD') + 'T00:00:00.000Z';
	  if (thisFechaInicio > thisFechaFin)
		mensajeError = 'Check start & end dates';
	  
      if (mensajeError) {
        $mdToast.show(
          $mdToast.simple()
            .textContent(mensajeError)
            .position('bottom right')
            .hideDelay(3000)
        );
        return true;
      }
      else {
        return false;
      }
    }

    vm.savingData = false;
    vm.clickSave = function () {
      if (!vm.isInvalidForm() && !vm.savingData) {
        vm.savingData = true;
        vm.validData = true;
        if (vm.editId && vm.duplicate == 'false') {
          ProyectService.updateProyect(vm.data).then(function (res) {
            vm.savingData = false;
            $state.go("proyect");
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        }
        else {
          ProyectService.createProyect(vm.data).then(function (res) {
            vm.savingData = false;
            $state.go("proyect");
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        }
      }

    }

    vm.clickCancel = function () {
      $state.go("proyect");
    }

    vm.clickAssign = function () {
      ProyectService.persistProyect(vm.data);
	  
      $state.go("userassign", {idproyect: vm.editId, duplicate: $stateParams.duplicate});
    }

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
      vm.abreCalendarioDesde = true;
    }

    vm.abreCalendarioHasta = false;
    vm.abrirCalendarioHasta = function () {
      vm.abreCalendarioHasta = true;
    }
	
	vm.toggleActive = false;
	function initToggleOption() {		
		if (UserService.getToggleOption('proyect_entity_budget') == true)
		{
			if (
				(vm.data.presupuestoEntidad != null && vm.data.presupuestoEntidad != "")
				|| (vm.data.presupuestoTotal != null && vm.data.presupuestoTotal != "")
			)
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");								
				vm.toggleActive	= true;
				
				if (document.getElementById("toggleEntityBudgetColumn").style.maxHeight) {
				  document.getElementById("toggleEntityBudgetColumn").style.maxHeight = null;
				} else {
				  document.getElementById("toggleEntityBudgetColumn").style.maxHeight = document.getElementById("toggleEntityBudgetColumn").scrollHeight + "px";
				} 
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
				vm.toggleActive = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
			vm.toggleActive = false;
		}	
	}
	
	vm.changeToggleOption = function(optionName){
		vm.toggleActive = !vm.toggleActive;		
		var actualOption = UserService.getToggleOption('proyect_entity_budget');

		if (vm.toggleActive != actualOption)					
			UserService.changeToggleOption(optionName);
		
		if (vm.toggleActive == true)
		{
			document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
		}
		
		if (document.getElementById("toggleEntityBudgetColumn").style.maxHeight) {
		  document.getElementById("toggleEntityBudgetColumn").style.maxHeight = null;
		} else {
		  document.getElementById("toggleEntityBudgetColumn").style.maxHeight = document.getElementById("toggleEntityBudgetColumn").scrollHeight + "px";
		} 
	}
	
	
    if (!vm.editId) {
		initToggleOption();
	}
  }
})();
