(function () {
  angular
    .module('app')
    .component('entitytoolbar', {
      templateUrl: 'components/entitytoolbar/entitytoolbar.html',
      controller: EntityToolbarController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function EntityToolbarController($state, $scope) {
    var vm = this;

    vm.currentNavItem = 'data';//Entidad a la que entramos por defecto
    //$state.go('entitytoolbar.' + vm.currentNavItem);

    /*
     Datos
     Clasificacion
     Tablas salariales
     */

    vm.arrayButtons = [];

    vm.arrayButtons['data'] = {
      buttonName: '',
      newStateName: '',
      title: 'entitytoolbar.datosMin'
    }
    vm.arrayButtons['rating'] = {
      buttonName: '',
      newStateName: '',
      title: 'entitytoolbar.clasificacionMin'
    }
    vm.arrayButtons['salarytables'] = {
      buttonName: 'entitytoolbar.nuevaTablaSalarial',
      newStateName: 'entitytoolbar.salarytablesform',
      title: 'entitytoolbar.tablaSalarialMin'
    }


    vm.clickNew = function () {
      $state.go(vm.arrayButtons[vm.currentNavItem].newStateName, {id: null});
    }

    $scope.$watch(function () {
      return $state.$current.name
    }, function (newVal, oldVal) {
      vm.isForm = newVal.toLowerCase().indexOf("form") > -1 ? true : false;
      //si accedemos a la raiz llevamos a la entidad por defecto
      if (newVal == "entitytoolbar") {
        $state.go('entitytoolbar.' + vm.currentNavItem);
      }
      else if (vm.isForm) {
        vm.currentNavItem = newVal.toLowerCase().split('form')[0].split('.')[1];
      }
      else if (newVal.split('.').length == 2) {
        vm.currentNavItem = newVal.split('.')[1];
      }
    })
  }
})();
