(function () {
  angular
    .module('app')
    .component('entity', {
      templateUrl: 'components/entity/entity.html',
      controller: EntityController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function EntityController(EntityService, config, SelectService, ConfirmDeleteService, $mdDialog, $filter, BreadcrumService, Translator, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;

    vm.data = [];
    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.type = {};
    vm.selectedFilters.country = {};
    vm.selectedFilters.lgbGroup = {};
    vm.selectedFilters.plan = {};
    vm.selectedFilters.active = null;

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.getStateSelectValue = function() {
      switch(vm.selectedFilters.active) {
        case null:
          return ''
        case true:
          return 'Activo'
        case false:
          return 'Inactivo'
        default:
          return ''
      }
    }

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      EntityService.getEntities(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters)
      .then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
      });
    }

    vm.activeDesactive = function (ev, index) {
      var whenOk = function () {
        EntityService.activeDesactiveEntity(vm.data[index])
          .then(function (res) {
            if (res) {
              vm.data[index].activo = res.activo;
            }
          })
        ;
      };

      Translator.translate(['entity.desactivar', 'entity.activar', 'entity.entidad', 'entity.estasSeguro'], function(translations) {
        var title = vm.data[index].activo ? translations['entity.desactivar'] : translations['entity.activar'] + ' ' + translations['entity.entidad'];
        var text = translations['entity.estasSeguro'];
        ConfirmDeleteService.showDialog(ev, title, text).then(whenOk);
      });

    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.type) {
        vm.selectedFilters.type = selectorObject;
      }
      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }
      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.selectedFilters.lgbGroup = selectorObject;
      }
      if (selectorType == SelectService.ENUM.plan) {
        vm.selectedFilters.plan = selectorObject;
      }
      if (selectorType == SelectService.ENUM.active) {
        vm.selectedFilters.active = selectorObject;
      }

      vm.getData();
    }

    function fillFilters() {
      SelectService.getType().then(function (res) {
        vm.selectorList.type = res;
      });

      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });

      SelectService.getLGBGroup().then(function (res) {
        vm.selectorList.lgbGroup = res;
      });

      SelectService.getPlan().then(function (res) {
        vm.selectorList.plan = res;
      });
      vm.selectorList.active = [
        {nombre: 'Activo', value: true},
        {nombre: 'Inactivo', value: false}
      ]
    }

    var auxFilters = BreadcrumService.getFilters("entity");
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
    }
    vm.getData();
    fillFilters();

    vm.saveFilters = function () {
      BreadcrumService.saveFilters(vm.selectedFilters, "entity");
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        EntityService.deleteEntity(vm.data[index])
          .then(function () {
            vm.getData()
          });
      };

      ModalService.showConfirm(ev, 'entity.borrarEntidad', 'entity.estasSeguro', 'entity.borrar', 'entity.cancelar', whenOk);
    }

    vm.loadMore = function () {
      if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      EntityService.getEntities(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        } else {
          vm.page++;
        }

        vm.busy = false;
      });
    };

  }
})();
