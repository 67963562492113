(function () {
  'use strict';

  angular
    .module('app')
    .service('EntityService', EntityService);

  /* @ngInject */
  function EntityService($http, $httpParamSerializer, config, TokenService, Upload) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getEntities = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/api/entidads";


      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroTipo = null;
      if (filters && filters.type && filters.type.id) {
        filtroTipo = filters.type.id;
      }
      var filtroPais = null;
      if (filters && filters.country && filters.country.id) {
        filtroPais = filters.country.id;
      }

      var filtroActivo = null;
      if (filters && filters.active != null) {
        filtroActivo = filters.active;
      }

      var filtroLGB = null;
      if (filters && filters.lgbGroup && filters.lgbGroup.id) {
        filtroLGB = filters.lgbGroup.id;
      }

      var filtroPlan = null;
      if (filters && filters.plan && filters.plan.id) {
        filtroPlan = filters.plan.id;
      }


      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        tipo: filtroTipo,
        pais: filtroPais,
        // activo: filtroActivo,//TODO preguntar a juan xk el campo siempre se vuelve string
        grupoLBG: filtroLGB,
        planUso: filtroPlan,
        nombre: filtroNombre,
        codigoCorto: filtroCodigo,
      });
      url += "?" + params;

      //TODO si conseguimos arreglar lo de mas arriba podemos quitar esto.
      if (filtroActivo != null) {
        if (url.indexOf("?") > -1) {
          url += "&";
        }
        else {
          url += "?";
        }
        url += "activo=" + filtroActivo;
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data;
        }
        else {
          return [];
        }

      });
    }

    service.getEntityData = function () {
      var url = config.api + "/api/entidads/datos"

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.createEntity = function (entity, file) {
      var url = api + "/api/entidads";

      //TODO changlai CUIDADO CUANDO PASEMOS EL MULTI!!
      // var nuevoTipoCambio = {
      //   id: entity.tipoCambio.id,
      // }
      // entity.tipoCambio = nuevoTipoCambio;
      //fin changlai

      file.upload = Upload.upload({
        url: url,
        headers: headers,
        method: "POST",
        data: {logo: file, entidadDTO: Upload.jsonBlob(entity)},
      });

      return file.upload.then(function (response) {
        return response.data;
      });

      //TODO comentado de momento
      // return $http.post(url, entity, {headers: headers}).then(function (response) {
      //     return response.data;
      // });
    }

    service.getEntity = function (id) {
      var url = api + "/api/entidads";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateEntity = function (entity, file) {
      var url = api + "/api/entidads";

      file.upload = Upload.upload({
        url: url,
        headers: headers,
        method: "PUT",
        data: {logo: file, entidadDTO: Upload.jsonBlob(entity)},
      });

      return file.upload.then(function (response) {
        return response.data;
      });

      // if (entity && entity.id != null) {
      //   // url += "/" + entity.id;
      //   return $http.put(url, entity, {headers: headers}).then(function (response) {
      //     return response.data;
      //   });
      // }
      // else {
      //   return false;
      // }
    }

    service.deleteEntity = function (entity) {
      var url = api + "/api/entidads/";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.activeDesactiveEntity = function (entity) {
      var url = api + "/api/entidads/active-desactive";
      if (entity && entity.id != null) {
        url += "/" + entity.id;
        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
