(function () {
  'use strict';
  angular
    .module('app')
    .controller('AnalyticsController', function ($scope, $location, $window, $document) {
      $scope.$on('$viewContentLoaded', function (event) {
        $window.gtag('config', 'UA-124738026-1', {
          'page_title' : $document[0].title,
          'page_path': $location.url()
        });

      });
    });
})();