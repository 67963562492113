(function() {
  angular.module('app')
  .component('checkboxFilter', {
    templateUrl: 'shared/checkboxFilter/checkboxFilter.html',
    controller: CheckboxFilterController,
    controllerAs: 'vm',
    bindings: {
      ariaLabel: '@',
      placeholder: '@',
      uniqueField: '@',
      activeSortingField: '@',
      noOptionsMessage: '@',
      elements: '<',
      onChange: '&',
      onSorting: '&',
    },
  })
  .filter('shownames', showNamesFilter);

  function CheckboxFilterController(Translator, $scope){

    var vm = this;

    vm.selectAllText = "filterReport.marcarTodos";
    vm.deselectAllText = "filterReport.desmarcarTodos";
    vm.selectAllFlag = false;
    vm.deselectAllFlag = false;
    vm.sortAscOrder = false;
    vm.savedSelection = false;

    vm.$onInit = initialize;

    vm.isSelected = isSelected;
    vm.selectItem = selectItem;
    vm.getOptions = getOptions;
    vm.sort = sort;
    vm.getSortIcon = getSortIcon;
    vm.isSortingActive = isSortingActive;
    vm.getSortIconColor = getSortIconColor;
    vm.deselectAll = deselectAll;
    vm.selectAll = selectAll;



    function initialize(){

      vm.selected = angular.isDefined(vm.selected) ? vm.selected : [];
      vm.uniqueField = angular.isDefined(vm.uniqueField) ? vm.uniqueField : '';
      vm.emptySelection = angular.isDefined(vm.emptySelectionMessage) ? vm.emptySelectionMessage : 'list.empty';
      vm.getOptions();

    }

    $scope.$watch(vm.elements,function(newvalue, oldvalue){
        if(newvalue != oldvalue){
          vm.processed_elements = vm.getOptions();
        }
      }, true);

    
    function isSortingActive(){
      if (vm.activeSortingField == vm.uniqueField){
        return true;
      }
      return false;
    }
    

    function getSortIconColor() {
      if (vm.isSortingActive()) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    function getSortIcon(){
        if (!vm.sortAscOrder || !vm.isSortingActive()) {
          return 'arrow_downward'
        }
        return 'arrow_upward'
    }

    function sort(){
      if (vm.isSortingActive()){
        vm.sortAscOrder = !vm.sortAscOrder;
      }
      vm.onSorting({field: vm.uniqueField, isAsc: vm.sortAscOrder}).then(function(response){
      })
    }

    
    function getOptions(){
      if (angular.isUndefined(vm.elements)){
        return [];
      }
      var elementos = [];
      vm.elements.forEach(function(item){
        var element = {};
        if (angular.isDefined(item.key)){
          element.key = item.key;
        } else if (angular.isDefined(item.id)){
          element.key = item.id;
        } else{
          element.key = vm.elements.indexOf(item);
        } 

        if (angular.isDefined(item.value)){
          element.value = item.value;
        } else{
          element.value = item;
        }
        elementos.push(element); 
      });
      return elementos;
    }

    
    function isSelected(item){
      return vm.selected.filter(function(element){return element.key == item.key && element.value == item.value;}).length > 0;
    }
    

    function selectItem(item) {
      var already_selected = vm.isSelected(item);
      vm.deselectAllFlag = false;
      vm.selectAllFlag = false;
      vm.savedSelection = false;
      if (already_selected){
        vm.selected = vm.selected.filter(function(element){return element.key != item.key || element.value != item.value;});
      } else {
        vm.selected.push(item);
      }
      vm.onChange({field: vm.uniqueField, items: vm.selected});
    }

    function selectAll(){
      if (vm.selectAllFlag && vm.savedSelection){ // if it's already active
        vm.selected = vm.savedSelection;
        vm.savedSelection = false;
        vm.deselectAllFlag = false;
        vm.selectAllFlag = false;
      }else{
        vm.savedSelection = vm.selected;
        vm.selected = vm.getOptions();
        vm.deselectAllFlag = false;
        vm.selectAllFlag = false;
      }
      vm.onChange({field: vm.uniqueField, items: vm.selected});
    }

    function deselectAll(){
      if (vm.deselectAllFlag && vm.savedSelection){ // if it's already active
        vm.selected = vm.savedSelection;
        vm.savedSelection = false;
        vm.deselectAllFlag = false;
        vm.selectAllFlag = false;
      }else{
        vm.savedSelection = vm.selected;
        vm.selected = [];
        vm.deselectAllFlag = false;
        vm.selectAllFlag = false;
      }
      vm.onChange({field: vm.uniqueField, items: vm.selected});
    }
  }

  

  
  function showNamesFilter(Translator){ //filter to show the name of the item
    return function(inputArray){
      if (angular.isUndefined(inputArray) || inputArray.length <= 0)
      {
        return null;
      }
      if (typeof inputArray == 'string'){
        return inputArray;
      }
      inputArray = inputArray.map(function(input){ if (angular.isDefined(input.key)){ return Translator.translateInstant(input.value); }});
      if(inputArray.length == 0){
        return null;
      }
      inputArray = inputArray.join(", ");
      return inputArray.trim().slice(0,30).concat("...");
    }
  }

})();
