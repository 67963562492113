(function () {
  angular
    .module('app')
    .component('salarytables', {
      templateUrl: 'components/salarytables/salarytables.html',
      controller: SalaryTablesController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function SalaryTablesController(SalaryTablesService, config, $mdDialog, BreadcrumService, $state, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;

    vm.data = [];
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.description = "";
    vm.selectedFilters.applyYear = "";

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      SalaryTablesService.getSalaryTables(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
      });
    }

    var auxFilters = BreadcrumService.getFilters("salaryTables");
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
    }

    vm.sort('codigoCorto');

    vm.saveFilters = function () {
      BreadcrumService.saveFilters(vm.selectedFilters, "salaryTables");
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        SalaryTablesService.deleteSalaryTable(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          });
      };
      ModalService.showConfirm(ev, 'salarytables.borrarTablaSalarial', 'salarytables.estasSeguro', 'salarytables.borrar', 'salarytables.cancelar', whenOk);
    }

    vm.goUpload = function (index) {
      vm.saveFilters();
      var element = vm.data[index];
      var uploadFormData = {id: element.id, tipoEntidad: 1, multi: true, idProyecto: null}
      $state.go("uploadform", uploadFormData);
    }

    vm.loadMore = function () {
      if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      SalaryTablesService.getSalaryTables(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        } else {
          vm.page++;
        }
        vm.busy = false;
      });
    };

  }
})();
