(function () {
  'use strict';

  angular
    .module('app')
    .service('UploadFormService', UploadFormService);

  /* @ngInject */
  function UploadFormService($http, $httpParamSerializer, config, TokenService, Upload) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.uploadFile = function (file, entityType, idEntity) {
      var url = api + "/api/documento/upload";
      var upload = Upload.upload({
        url: url,
        headers: headers,
        data: {document: file, entityType: entityType, idEntity: idEntity},
      });

      return upload.then(function (response) {
        return response.data;
      });
    }

    service.getFiles = function (entityType, idEntity) {
      var url = api + "/api/documentos/list/" + entityType + "/" + idEntity;
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.deleteFile = function (entityType, idFile) {
      var url = api + "/api/documentos/list/" + entityType + "/" + idFile;
      return $http.delete(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getUrl = function (id) {
      var url = config.api + "/api/documento/url/" + id;
      return $http.get(url).then(function (response) {
        var url = response.data.url;
        if (url.indexOf("https://") == -1) {
          url = url.replace("http://","https://");
        }
        return url;
      });
    }

    return service;
  }

})();
