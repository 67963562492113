(function() {
  'use strict';

  angular.module('app')
  .service('Translator', Translator);

  /* @ngInject */
  function Translator($translate) {
    return {

      /**
       * Uses the $translate async translate functionality.
       * 
       * @param  {string/array}   translatable key or keys to be translated
       * @param  {Function} callback To be called by the $translate service. It receives the translation or translations as an argument.
       * @param {Function} errorCallback To be called by the $translate if a translation is not found, receives the key as  an argument.
       *                                 If not provided, callback will be called instead.
       * @return {void}
       */
      translate: function(translatable, callback, errorCallback) {
        if (!errorCallback) {
          errorCallback = callback;
        }
        $translate(translatable).then(callback, errorCallback);
      },

      translateInstant: function(translatable, interpolateParams){
        return $translate.instant(translatable, interpolateParams);
      },
	  getActualLanguage: function()
	  {
		return $translate.use();
	  }      
    };
  }
})();