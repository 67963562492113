angular
  .module("app")
  .constant("translations_PT", {
    "yes":"Sim",
	"no":"Não",
	"logout.cerrarSesion":"Terminar a sessão",
	"logout.preguntaCerrarSesion":"¿Deseja terminar a sessão?",
	"location":"ODS_iconsPT",
	"error.internalServerError":"Ocorreu um erro. Por favor entre em contato com o administrador para mais informações.",
	"err.genericError":"Ocorreu um erro. Por favor entre em contato com o administrador para mais informações.",
	"Bad credentials":"Erro de autenticação: Nome de usuário ou senha incorretos",
	"error.accessDenied":"Não tem permissão para realizar esta ação",
	"error.requiredFieldEmpty":"Não foram preenchidos todos os campos obrigatórios",
	"error.notFoundEntity":"O elemento que está tentando acessar não existe",
	"error.noPossibleEditEntity":"Não pode editar o elemento, porque ele está sendo usado",
	"error.duplicateEntity":"Não pode criar o elemento, porque já existe",
	"err.excelRequired":"Não pode ser salvo se nenhum arquivo excel for anexado",
	"err.grupoLBGHasAResposible":"O grupo LBG selecionado já tem um responsável",
	"err.entidadHasAResposible":"A entidade selecionada já tem um responsável",
	"err.planUsoMaxUserExceeded":"O seu plano não tem mais usuários com a função selecionada",
	"err.budgetOverflow":"O orçamento da entidade não deve ser maior do que o orçamento total do projeto",
	"err.notTypeChange":"Não há taxa de câmbio para aplicar a este projeto",
	"err.notValueTypeChange":"Não há valor da taxa de câmbio para este projeto",
	"err.deleteDocument":"Erro ao tentar eliminar um documento",
	"err.DateOutRange":"A data inserida está fora do período",
	"err.percentajeArea":"A soma das porcentagens de área excede 100%",
	"err.percentajeODS":"A soma das porcentagens dos ODS excede 100%",
	"err.requiredFieldColaboration":"Os campos obrigatórios na lista de colaboradores estão vazios",
	"err.FailsBeneficiaryRequirement":"O impacto não atende aos requisitos do limite máximo de beneficiários avaliados",
	"err.EmpEvaluatedGreaterParticipant":"Os empregados avaliados não podem ser mais do que os participantes",
	"err.EntityEvaluatedGreaterParticipant":"As entidades avaliadas não podem ser mais do que as participantes",
	"err.incompleteArea":"Informação da área incompleta",
	"err.incompleteODS":"Informações dos Objetivos incompleta",
	"err.dateIniciativeBeforeDateProject":"A data de início da iniciativa é anterior ao início do projeto",
	"err.dateIniciativeAfterDateProject":"A data de início da iniciativa é após o final do projeto",
	"err.NotDeleteValidContribution":"Não pode eliminar uma contribuição validada",
	"err.sessionExpired":"A sua sessão de usuário expirou, por favor reinsira as suas credenciais",
	"err.NotEditValidContribution":"Não pode editar uma contribuição validada",
	"err.NotEditValidAchievement":"Não pode editar uma realização validada",
	"err.NotEditValidImpact":"Não pode editar um impacto validado",
	"usuario.rol.error.coordinador":"Não é possível mudar a função do usuário. Já existe um coordenador para esse grupo",
	"usuario.rol.error.responsable":"Não é possível mudar a função do usuário. Já existe um responsável por essa entidade",
	"usuario.rol.error.disponibles":"Não é possível mudar a função do usuário. Nenhuma função disponível no plano de uso",
	"resumen.proyecto.no.existe":"O projeto selecionado não existe",
	"resumen.proyecto.usuario.no.existe":"Nenhum usuário em sessão",
	"agrupacion.interna.no.existe":"O agrupamento interno não existe",
	"agrupacion.interna.duplicate":"Já existe um agrupamento com este nome",
	"agrupacion.IniciativaInterna.duplicate":"Já existe um agrupamento com este nome",
	"agrupacion.interna.requerido.nombre":"Deve indicar o nome do agrupamento",
	"colaborador.no.existe":"O colaborador selecionado não existe",
	"colaborador.presupuesto.no.existe":"O orçamento do colaborador não existe",
	"colaborador.representante.no.existe":"O colaborador representativo não existe",
	"colaborador.requerido.nombre":"Deve inserir o nome do colaborador",
	"colaborador.requerido.tipo":"Deve indicar o tipo de colaborador",
	"contribucion.no.existe":"A contribuição selecionada não existe",
	"contribucion.tabla.salarial.no.existe":"O quadro de contribuições não existe",
	"contribucion.fuera.rango":"Contribuição fora do periodo",
	"contribucion.requerido.tipo":"Deve indicar o tipo de contribuição",
	"contribucion.requerido.contribuyente":"Deve indicar o contribuinte",
	"contribucion.requerido.fecha":"Deve indicar a data da contribuição",
	"contribucion.requerido.datos.iniciativa":"Nenhuma iniciativa foi indicada para a contribuição",
	"contribucion.requerido.cantidad.y.divisa":"Deve indicar o valor e a moeda da contribuição",
	"contribucion.requerido.numero.persona.y.numero.horas.persona":"Deve indicar o número de pessoas e o total de horas da contribuição",
	"contribucion.description.255max":"máximo 255 caracteres",
	"iniciativa.no.existe":"A iniciativa não existe",
	"usuario.no.existe":"O usuário selecionado não existe",
	"err.mailExist":"O e-mail indicado já existe",
	"err.usuarioExist":"Já existe um usuário com o endereço de e-mail indicado",
	"usuario.requerido.nombre":"Deve introduzir o nome de usuário",
	"usuario.requerido.apellidos":"Deve introduzir o apelido do usuário",
	"usuario.requerido.email":"Deve introduzir o e-mail do usuário",
	"usuario.requerido.rol":"Deve selecionar a função do usuário",
	"usuario.requerido.entidad.alta":"Deve indicar a entidade de registo do usuário",
	"usuario.requerido.pais":"Deve indicar o país do usuário",
	"usuario.requerido.email.contacto.coordinador.grupo":"Deve indicar o e-mail de contato",
	"usuario.requerido.grupo.lbg":"Deve indicar o grupo LBG do coordenador",
	"proyecto.no.existe":"O projeto selecionado não existe",
	"entidad.no.existe":"A entidade selecionada não existe",
	"entidad.representante.no.existe":"A entidade representativa não existe",
	"entidad.representante.requerido":"Deve indicar a entidade representante",
	"entidad.alta.no.existe":"A entidade de registro não existe",
	"entidad.requerido.nombre":"Deve indicar o nome da entidade",
	"entidad.requerido.abreviatura":"Deve indicar a abreviação da entidade",
	"entidad.requerido.tipo":"Deve indicar o tipo de entidade",
	"entidad.requerido.sector":"Deve indicar o setor LBG",
	"entidad.requerido.plan.uso":"Deve indicar um plano de uso",
	"entidad.requerido.pais":"Deve indicar o país da entidade",
	"entidad.requerido.tipo.cambio":"Deve indicar pelo menos uma taxa de câmbio",
	"entidad.requerido.grupo.lbg":"Deve indicar o grupo LBG ao qual a entidade pertence",
	"impacto.no.existe":"O impacto selecionado não existe",
	"impacto.requerido.fecha.evaluacion":"Deve indicar a data da avaliação",
	"impacto.requerido.datos.iniciativa":"A iniciativa deve ser indicada",
	"impacto.validacion.beneficiarios.evaluados":"O grau total de impacto não pode exceder o número de beneficiários avaliados",
	"impacto.empleado.requerido.contribuyente":"Deve indicar um contribuinte",
	"impacto.empleado.validacion.evaluados.mayor.participantes":"Os participantes avaliados não podem ser mais do que o número de participantes",
	"impacto.participante.requerido.entidad":"Deve indicar a entidade participante",
	"logro.no.existe":"A realização selecionada não existe",
	"logro.requerido.fecha.evaluacion":"Deve indicar a data da avaliação",
	"logro.requerido.datos.iniciativa":"A iniciativa da realização deve ser indicada",
	"plan.uso.no.existe":"O plano de uso selecionado não existe",
	"plan.uso.requerido.nombre":"Deve indicar o nome do plano de uso",
	"plan.uso.requerido.gerente":"Deve indicar o número de gerentes",
	"plan.uso.requerido.supervisor.proyecto":"Deve indicar o número de supervisores do projeto",
	"plan.uso.requerido.supervisor.iniciativa":"Deve indicar o número de supervisores de iniciativa",
	"plan.uso.requerido.agente":"Deve indicar o número de agentes",
	"tabla.salarial.no.existe":"O quadro de contribuições não existe",
	"tabla.salarial.requerido.nombre":"Deve inserir o nome do quadro de contribuições",
	"tabla.salarial.requerido.total.coste":"Deve inserir o custo total",
	"tabla.salarial.requerido.divisa":"Deve indicar a moeda associada com o custo total",
	"tabla.salarial.requerido.agno":"Deve indicar o ano",
	"tabla.salarial.requerido.agno.aplicacion":"Deve indicar o ano de aplicação",
	"unidad.negocio.no.existe":"A unidade de negócios não existe",
	"unidad.negocio.requerido.nombre":"Deve inserir o nome da unidade de negócios",
	"ods.no.existe":"O ODS selecionado não existe",
	"pais.no.existe":"O país selecionado não existe",
	"pais.requerido":"Deve indicar o país",
	"divisa.no.existe":"A moeda selecionada não existe",
	"asignacion.presupuesto.iniciativa.requerido.presupuesto":"Deve indicar um valor do orçamento",
	"asignacion.presupuesto.iniciativa.requerido.divisa":"Deve indicar a moeda do orçamento",
	"datos.iniciativa.requerido.nombre":"Deve indicar o nome da iniciativa",
	"datos.iniciativa.requerido.agrupacionIniciativaInterna":"Você deve indicar o agrupamento de iniciativa interna",
	"datos.iniciativa.requerido.fecha.inicio":"Deve indicar a data de início da iniciativa",
	"datos.iniciativa.requerido.pais":"Deve indicar o país",
	"datos.proyecto.requerido.fecha.inicio":"Deve indicar a data de início do projeto",
	"datos.proyecto.requerido.unidad":"Deve indicar a unidade de negócios para o projeto",
	"datos.proyecto.requerido.agrupacionInterna":"Deve indicar o agrupamento interno",
	"datos.proyecto.requerido.nombre":"Deve indicar o nome do projeto",
	"datos.proyecto.requerido.divisa.ppto.entidad":"Deve indicar a moeda do orçamento da sua entidade",
	"datos.proyecto.requerido.divisa.ppto.total":"Deve indicar a moeda do orçamento total",
	"organizaciones.beneficiarias.requerido.nombre":"Deve indicar o nome da organização",
	"tipo.cambio.requerido.nombre":"Deve indicar o nome da taxa de câmbio",
	"tipo.cambio.requerido.descripcion":"Deve fornecer uma descrição",
	"tipo.cambio.requerido.fecha.desde":"Deve indicar a data a partir da qual a taxa de câmbio entra em vigor",
	"tipo.cambio.requerido.fecha.hasta":"Deve indicar a data até a qual a taxa de câmbio permanece em vigor",
	"tipo.cambio.requerido.grupo.lbg":"Deve indicar o grupo LBG para o qual a taxa de câmbio será aplicada",
	"permiso.denegado":"Não tem privilégios suficientes para executar esta ação",
	"permiso.denegado.iniciativa":"Não tem privilégios suficientes para acessar a iniciativa",
	"permiso.denegado.contribucion":"Não há privilégios suficientes para acessar às contribuições",
	"permiso.denegado.logro":"Não tem privilégios suficientes para acessar às realizações",
	"permiso.denegado.impacto":"Não tem privilégios suficientes para acessar aos impactos",
	"datos.iniciativa.validacion.fuera.rango.proyecto":"A data da iniciativa deve estar compreendida entre a data de início e a data de fim do projeto",
	"colaboracion.iniciativa.requerido.lista.colaboradores.motivacion.propia":"Deve indicar uma motivação para a sua entidade",
	"colaboracion.iniciativa.requerido.lista.colaboradores.grado.implicacion":"Deve indicar um grau de envolvimento",
	"iniciativa.ods.requerido.porcentaje.primario":"Deve inserir a porcentagem do ODS primário",
	"iniciativa.ods.validacion.porcentaje.incompleto":"A porcentagem total de ODS deve ser de 100%",
	"iniciativa.requerido.area.primaria":"Deve indicar a área primária",
	"iniciativa.validacion.area.porcentaje.incompleto":"A porcentagem total de áreas deve ser de 100%",
	"unidad.negocio.validacion.nombre.duplicado":"Já existe uma unidade de negócios com esse nome",
	"tabla.salarial.requerido.divisa.coste.hora":"Deve indicar a moeda associada ao custo por hora",
	"entidad.validacion.nombre.duplicado":"O nome da entidade já existe",
	"contacto.email.campos.requeridos":"Todos os campos são obrigatórios",
	"impacto.empleado.validacion.evaluados.mayor.participantes.actitud":"Os participantes para os quais a atitude foi avaliada não podem ser maiores do que o número de participantes",
	"impacto.empleado.validacion.evaluados.mayor.participantes.habilidad":"Os participantes para os quais as habilidades foram avaliadas não podem ser mais do que o número de participantes",
	"impacto.empleado.validacion.evaluados.mayor.participantes.desarrollo":"Os participantes que foram avaliados para o desenvolvimento pessoal não podem ser mais do que o número de participantes",
	"tipo.cambio.requerido.excel.valores":"Deve anexar um arquivo excel com os valores das taxas de câmbio",
	"tipo.cambio.valor.excel.caracter.invalido":"O excel não pôde ser processado devido a ser inválido",
	"ADMINISTRADOR":"Administrador",
	"COORDINADOR_GRUPO":"Coordenador do Grupo",
	"RESPONSABLE":"Responsável",
	"GERENTE":"Gerente",
	"SUPERVISOR_PROYECTO":"Supervisor do projeto",
	"SUPERVISOR_INICIATIVA":"Supervisor da iniciativa",
	"AGENTE":"Agente",
	"SOLOVISTA":"Gerente somente visualização",
	"BASICO":"Básico",
	"DINERO":"Dinheiro",
	"ESPECIE":"Espécie",
	"TIEMPO":"Tempo",
	"PROBONO":"Pro bono",
	"COSTE_GESTION":"Custo de gestão",
	"ALTO":"Alto",
	"MEDIO":"Médio",
	"BAJO":"Baixo",
	"COLABORADOR":"Colaborador",
	"ORG_BENEFICIARIA":"Organização beneficiária",
	"SI":"Sim",
	"NO":"Não",
	"SIN_CAMBIOS":"Sem mudanças",
	"POCO":"Pouco",
	"ALGO":"Algo",
	"BASTANTE":"Bastante",
	"Nueva Entidad":"Nova entidade",
	"achievementsform.numeroBeneficiarias":"Número de organizações beneficiárias",
	"achievementsform.alianzaColaboHeader":"Alianças e colaborações alcançadas",
	"achievementsform.addGrInteres":"Salvar",
	"achievementsform.deleteGrInteres":"Eliminar parte interessadas",
	"achievementsform.new":"Editar Realização",
	"achievementsform.edit":"Nova Realização",
	"achievementsform.fechaevaluacion":"Data da avaliação:",
	"achievementsform.descripcion":"Descrição:",
	"achievementsform.benefiariosDirectos":"Beneficiários diretos",
	"achievementsform.numBeneficiarios":"Nº de beneficiários:",
	"achievementsform.tipo":"Tipo:",
	"achievementsform.situacion":"Situação:",
	"achievementsform.observaciones":"Observações:",
	"achievementsform.beneficiariosIndirectos":"Beneficiários indiretos",
	"achievementsform.numLogrosMedioambientales":"Nº de realizações ambientais:",
	"achievementsform.organizacionesBeneficiarias":"Organizações beneficiárias",
	"achievementsform.tipoSimple":"Tipo",
	"achievementsform.nombreOrganizacion":"Nome organização",
	"achievementsform.descripcionSimple":"Descrição",
	"achievementsform.beneficiariaPregunta":"Beneficiário?",
	"achievementsform.addNuevaOrganizacion":"Salvar",
	"achievementsform.deleteOrganizacion":"Eliminar Organização",
	"achievementsform.LogrosEntidadesParticipantes":"Realizações para as entidades participantes",
	"achievementsform.logrosGrupoIntereses":"Realizações com as partes interessadas",
	"achievementsform.entidadGrupoIntereses":"Entidade",
	"achievementsform.gruposInteresAlcanzados":"Partes interessadas alcançadas",
	"achievementsform.logrosInteresNumAbrev":"Nº",
	"achievementsform.logrosInteresObservaciones":"Observações",
	"achievementsform.alianzasEntidad":"Entidade",
	"achievementsform.alianzasGenerando":"Alianças geradas",
	"achievementsform.alianzasNum":"Nº",
	"achievementsform.alianzasComentarios":"Observações",
	"achievementsform.addAlianza":"Salvar",
	"achievementsform.deleteAlianza":"Eliminar Aliança",
	"achievementsform.mediosComunicacion":"Meios de comunicação",
	"achievementsform.mediosComunicacionEntidad":"Entidade",
	"achievementsform.mediosComunicacionValor":"Valor gerado nos meios de comunicação",
	"achievementsform.mediosComunicacionDivisa":"Moeda",
	"achievementsform.mediosComunicacionObservaciones":"Observações",
	"achievementsform.addMedioComunicacion":"Salvar",
	"achievementsform.deleteMediosComunicacion":"Eliminar meio de comunicação",
	"achievementsform.cumplimiento":"Cumprimento dos objetivos e análise das iniciativas",
	"achievementsform.cumplimientoEntidad":"Entidade",
	"achievementsform.cumplimientoAlineacion":"Alinhamento com a missão",
	"achievementsform.cumplimientoObservaciones":"Observações",
	"achievementsform.addCumplimiento":"Salvar",
	"achievementsform.deleteCumplimiento":"Eliminar Cumprimento dos objetivos",
	"achievementsform.alineacion":"Alinhamento dos resultados com os objetivos da iniciativa",
	"achievementsform.eficiencia":"Implementação do projeto em eficiência",
	"achievementsform.sostenibilidad":"Sustentabilidade económica",
	"achievementsform.eficacia":"Implementação do projecto em eficácia",
	"achievementsform.conclusionesAlcanzadas":"Conclusões alcançadas:",
	"achievementsform.logrosMedioambientables":"Realizações ambientais",
	"achievementsform.ver":"Ver Realização",
	"achievementsform.guardar":"Salvar",
	"achievementsform.cancelar":"Cancelar",
	"achievementsform.Si":"Sim",
	"achievementsform.No":"Não",
	"achievements.achievement":"Realização",
	"achievements.codigo":"Código",
	"achievements.fecha":"Data",
	"achievements.descripcion":"Descrição",
	"achievements.beneficiariosDirectos":"Beneficiários diretos",
	"achievements.organizacionesBeneficiarias":"Organizações beneficiárias",
	"achievements.logosAmbientales":"Realizações ambientais",
	"achievements.editar":"Editar",
	"achievements.adjuntarArchivo":"Anexar arquivo",
	"achievements.duplicar":"Duplicar",
	"achievements.devalidate":"Remover Validação",
	"achievements.validate":"Validar",
	"achievements.validateAchievement":"Validar Realização",
	"achievements.delete":"Eliminar",
	"achievements.deleteMessage":"Eliminar Realização",
	"achievements.areSure":"Tem a certeza?",
	"achievements.cancel":"Cancelar",
	"achievements.ver":"Ver",
	"breadcrumb.gestion":"GESTÃO",
	"breadcrumb.entidades":"ENTIDADES",
	"breadcrumb.usuarios":"USUÁRIOS",
	"breadcrumb.planesUso":"PLANOS DE USO",
	"breadcrumb.tiposCambio":"TAXAS DE CÂMBIO",
	"breadcrumb.colaboradores":"COLABORADORES",
	"breadcrumb.resumen":"SUMÁRIO",
	"breadcrumb.contribuciones":"CONTRIBUIÇÕES",
	"breadcrumb.logros":"REALIZAÇÕES",
	"breadcrumb.impactos":"IMPACTOS",
	"breadcrumb.iniciativas":"INICIATIVAS",
	"breadcrumb.proyectos":"PROJETOS",
	"breadcrumb.nuevaEntidad":"Nova Entidade",
	"breadcrumb.editarEntidad":"Editar Entidade",
	"collaboratorform.edit":"Editar Colaborador",
	"collaboratorform.new":"Novo Colaborador",
	"collaboratorform.nombre":"Nome:",
	"collaboratorform.descripcion":"Descrição:",
	"collaboratorform.cif":"NIF:",
	"collaboratorform.pais":"País:",
	"collaboratorform.region":"Região:",
	"collaboratorform.tipoEntidad":"Tipo Entidade:",
	"collaboratorform.guardar":"Salvar",
	"collaboratorform.cancelar":"Cancelar",
	"collaboratorform.volver":"Voltar à lista de colaboradores",
	"collaboratorform.agrupacionInternaIniciativasObligatorio":"É obrigatório o preenchimento do campo Agrupamento interno de iniciativas",
	"collaborator.codigo":"Código",
	"collaborator.nombre":"Nome",
	"collaborator.descripcion":"Descrição",
	"collaborator.pais":"País",
	"collaborator.region":"Região",
	"collaborator.tipoEntidad":"Tipo de entidade",
	"collaborator.adjuntar":"Anexar arquivo",
	"collaborator.editar":"Editar",
	"collaborator.borrar":"Eliminar",
	"collaborator.errorBorrando":"Erro de eliminação",
	"collaborator.noPermission":"O seu usuário não tem permissão para realizar esta ação",
	"collaborator.ok":"ok",
	"collaborator.borrarColaborador":"Eliminar Colaborador",
	"collaborator.estasSeguro":"Tem a certeza?",
	"collaborator.cancelar":"Cancelar",
	"contact.titulo":"Contato",
	"contact.mensajeEnviadoCorrectamente":"Mensagem enviada com sucesso",
	"contact.asunto":"Assunto:",
	"contact.comentario":"Comentário:",
	"contact.enviar":"Enviar",
	"contact.volver":"Voltar",
	"contributions.atribucion":"Atribuição",
	"contributions.codigo":"Código",
	"contributions.tipo":"Tipo",
	"contributions.contribuyente":"Contribuinte",
	"contributions.fecha":"Data",
	"contributions.coste":"Custo",
	"contributions.divisa":"Moeda",
	"contributions.descripcion":"Descrição",
	"contributions.aportacionesLey112018":"Fundação ou ONG?",
	"contributions.aportacionesLey112018Tooltip":"É uma doação a uma Fundação/ONG?",
	"contributions.esFundacionONL":"É uma doação a uma Fundação/ONG",
	"contributions.NOesFundacionONL":"Não é uma doação para uma Fundação/ONG",
	"contributions.tablaSalarial":"Tabela",
	"contributions.numPersonas":"Pessoas",
	"contributions.horasTotales":"Horas",
	"contributions.costeHora":"Custo por hora",
	"contributions.eliminar":"Eliminar",
	"contributions.archivoAdjunto":"Anexar arquivo",
	"contributions.duplicar":"Duplicar",
	"contributions.addContribucion":"Nova contribuição",
	"contributions.sumatorio":"Resumo:",
	"contributions.borrarContribucion":"Eliminar Contribuição",
	"contributions.estasSeguro":"Tem a certeza?",
	"contributions.estasSeguroTiempo":"Se continuar, os impactos associados a esta contribuição também serão eliminados. Tem a certeza?",
	"contributions.borrar":"Eliminar",
	"contributions.cancelar":"Cancelar",
	"contributions.quitarValidacion":"Remover validação",
	"contributions.validar":"Validar",
	"contributions.contribucion":"Contribuição",
	"contributions.validarTodos":"Validar tudo",
	"contributions.desvalidarTodos":"Invalidar tudo",
	"contributions.validarTodas":"Validar todas",
	"contributions.desvalidarTodas":"Invalidar todas",
	"contributions.validarContribucion":"Validar Contribuição",
	"contributions.invalidarContribucion":"Invalidar Contribuição",
	"data.tipoEntidad":"Tipo de entidade:",
	"data.sector":"Setor:",
	"data.responsable":"Reponsável:",
	"data.nombre":"Nome:",
	"data.nombreAbreviado":"Nome abreviado:",
	"data.plan":"PLANO",
	"data.totalUsuarios":"TOTAL usuários:",
	"data.numResponsables":"Nº responsáveis:",
	"data.numGerentes":"Nº gerentes:",
	"data.numSupProy":"Nº supervisores de projeto:",
	"data.numSupIni":"Nº supervisores de iniciativa:",
	"data.numAgentes":"Nº agentes:",
	"entityform.nombre":"Nome:",
	"entityform.campoRequerido":"Este campo é obrigatório",
	"entityform.nombreAbreviado":"Nome abreviado:",
	"entityform.cif":"NIF",
	"entityform.pais":"País:",
	"entityform.tipo":"Tipo:",
	"entityform.sectorLBG":"Setor LBG:",
	"entityform.grupoLBG":"Grupo LBG:",
	"entityform.responsable":"Responsável",
	"entityform.plan":"Plano:",
	"entityform.tipoCambio":"Taxa de câmbio",
	"entityform.activo":"Ativo",
	"entityform.activoIniciativasInternas":"Ativar a classificação de iniciativas internas",
	"entityform.obligatorioAgrupacionInterna":"Agrupamento interno obrigatório",
	"entityform.obligatorioAgrupacionInternaIniciativa":"Agrupamento interno obrigatório de iniciativas",
	"entityform.guardar":"Salvar",
	"entityform.cancelar":"Cancelar",
	"entityform.volver":"Volvar à lista de entidades",
	"entityform.isLBG":"Entidade LBG/ONLBG",
	"entityform.activityHistoryActive":"Rastreabilidade (registro de atividades)",
	"entityform.aportacionesLey112018":"Campo Doações Lei 11/2018",
	"entityform.activoIniciativasInternas":"Ativar agrupamento interno das iniciativas",
	"entity.codigo":"Código",
	"entity.nombre":"Nome",
	"entity.tipo":"Tipo",
	"entity.pais":"País",
	"entity.grupoLbg":"Grupo LBG",
	"entity.plan":"Plano",
	"entity.numUsuarios":"Nº usuários",
	"entity.estado":"Estado",
	"entity.editar":"Editar",
	"entity.borrar":"Eliminar",
	"entity.activar":"Ativar",
	"entity.desactivar":"Desativar",
	"entity.entidad":"Entidade",
	"entity.estasSeguro":"Tem a certeza?",
	"entity.borrarEntidad":"Eliminar Entidade",
	"entity.cancelar":"Cancelar",
	"entitytoolbar.perfil":"PERFIL",
	"entitytoolbar.datos":"DADOS",
	"entitytoolbar.clasificacion":"CLASIFICAÇÃO",
	"entitytoolbar.tablasSalarial":"QUADROS DE CONTRIBUIÇÕES",
	"entitytoolbar.datosMin":"Dados",
	"entitytoolbar.clasificacionMin":"Clasificação",
	"entitytoolbar.tablaSalarialMin":"Quadros de contribuições",
	"entitytoolbar.nuevaTablaSalarial":"Novo Quadro",
	"error.mensajeNoAccion":"Não pode realizar esta ação, porque ocorreu um erro na conversão de moeda",
	"error.periodoNoCompletado":"O administrador não completou o período mínimo de câmbio de moeda em USD. O período é de",
	"error.hasta":"Até",
	"error.validation":"Erro de autenticação: Nome de usuário ou senha incorretos",
	"exchangerateform.edit":"Editar a taxa de câmbio",
	"exchangerateform.new":"Nova taxa de câmbio",
	"exchangerateform.nombre":"Nome:",
	"exchangerateform.descripcion":"Descrição:",
	"exchangerateform.desde":"Desde:",
	"exchangerateform.hasta":"Até:",
	"exchangerateform.grupoLBG":"Grupo LBG:",
	"exchangerateform.archivoExcel":"Arquivo Excel:",
	"exchangerateform.guardar":"Salvar",
	"exchangerateform.cancelar":"Cancelar",
	"exchangerateform.volver":"Voltar à lista de taxas de câmbio",
	"exchangerate.desdeCabecera":"O período mínimo para o câmbio da moeda USD não foi completado. O período é de 01/01/2012 a 31/12/2027",
	"exchangerate.hastaCabecera":"Até",
	"exchangerate.nombre":"Nome",
	"exchangerate.descripcion":"Descrição",
	"exchangerate.grupoLGB":"Grupo LBG",
	"exchangerate.desde":"Desde",
	"exchangerate.hasta":"Até",
	"exchangerate.editar":"Editar",
	"exchangerate.borrarEntidad":"Eliminar Entidade",
	"exchangerate.estasSeguro":"Tem a certeza?",
	"exchangerate.borrar":"Eliminar",
	"exchangerate.cancelar":"Cancelar",
	"forgotpassnew.recuperarPass":"Recuperar Senha",
	"forgotpassnew.establecerPass":"Configurar senha",
	"forgotpassnew.requisitos":"Sua senha deve ter pelo menos 8 caracteres e pelo menos uma maiúscula, uma minúscula e um número.",
	"forgotpassnew.newPass":"Nova senha:",
	"forgotpassnew.repetirPass":"Repita a nova senha:",
	"forgotpassnew.guardar":"Salvar",
	"forgotpassnew.cancelar":"Cancelar",
	"forgotpassnew.passChangedOk":"Senha corretamente modificada",
	"forgotpassnew.passChangedFail1":"A nova senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um dígito",
	"forgotpassnew.passChangedFail2":"As senhas não correspondem",
	"forgotpass.olvidoPass":"Esqueceu a sua senha",
	"forgotpass.mailEnviado":"E-mail enviado a",
	"forgotpass.reviseBandeja":"Verifique a sua caixa de entrada",
	"forgotpass.email":"E-mail:",
	"forgotpass.guardar":"Salvar",
	"forgotpass.cancelar":"Cancelar",
	"forgotpass.mailRegistrado":"O e-mail que introduziu não está registrado",
	"forgotpass.mailFormatoIncorrecto":"O e-mail introduzido não se encontra num formato correcto",
	"impactsform.edit":"Editar Impacto",
	"impactsform.new":"Novo Impacto",
	"impactsform.fechaEvaluacion":"Data da avaliação:",
	"impactsform.descripcion":"Descrição:",
	"impactsform.impactosSobreBeneficiarios":"Impacto sobre os beneficiários",
	"impactsform.numBenefDirectos":"Nº de beneficiários diretos:",
	"impactsform.numBenefDirectosEval":"Nº de beneficiários diretos avaliados:",
	"impactsform.gradoImpacto":"Grau de impacto",
	"impactsform.sensibilizados":"Sensibilizados:",
	"impactsform.num":"Nº:",
	"impactsform.observaciones":"Observações:",
	"impactsform.conseguidoMejora":"Conseguiu uma melhoria:",
	"impactsform.transformados":"Transformados:",
	"impactsform.total":"Total:",
	"impactsform.tipoImpacto":"Tipo de impacto",
	"impactsform.cambioPositivo":"Mudança positiva no comportamento:",
	"impactsform.adquiridoNuevasHabilidades":"Adquiriu novas habilidades:",
	"impactsform.impactoPositivoCalidadVida":"Impacto positivo na qualidade de vida:",
	"impactsform.medioAmbiente":"Meio ambiente",
	"impactsform.logrosMedioambientales":"Impactos ambientais",
	"impactsform.laIniciativaHaConsegidoImpactosMedioambientales":"A iniciativa produziu impactos ambientais?",
	"impactsform.cambioComportamientoAmbiental":"A iniciativa mudou o comportamento ambiental das pessoas?",
	"impactsform.impactoEmpleadosVoluntarios":"Impacto sobre os empregados / voluntários",
	"impactsform.seleccionParticipantes":"Seleção dos participantes:",
	"impactsform.numParticipantes":"Nº participantes:",
	"impactsform.impactoSobreEntidadesParticipantes":"Impacto sobre as entidades participantes",
	"impactsform.entidadesAEvaluar":"Entidades a avaliar:",
	"impactsform.entidadesParticipantes":"Entidades participantes:",
	"impactsform.entidadesEvaluadas":"Entidades avaliadas:",
	"impactsform.otrosImpactosConseguidos":"Outros impactos alcançados:",
	"impactsform.guardar":"Salvar",
	"impactsform.cancelar":"Cancelar",
	"impactsform.mejoraronHabilidadesLaborales":"Em quantas pessoas a atividade contribuiu para desenvolver novas habilidades ou melhorar as já existentes, num contexto académico, social ou físico?",
	"impactsform.mejoraronDesarrolloPersonal":"Em quantas pessoas a iniciativa produziu uma mudança no desenvolvimento pessoal?",
	"impactsform.tuvieronCambioPositivoComportamiento":"Em quantas pessoas a atividade contribuiu para mudanças de comportamento que melhoram as vidas delas? A atividade permitiu-lhes mudar atitudes negativas ou preconceitos?",
	"impactsform.mejoradoRelacionesCGII":"Melhorou as relações com as suas partes interessadas?",
	"impactsform.mejoradoSistemasGestion":"Melhorou os seus sistemas de gestão? Houve melhorias operacionais? (RH, Finanças)",
	"impactsform.aumentadoCapacidadEmplearPersonal":"Aumentou a capacidade de empregar mais pessoal ou o número de voluntários?",
	"impactsform.experimentadoMejoraServicios":"Experimentaram uma melhoria nos seus serviços, produtos ou novas ofertas?",
	"impactsform.generadoIngresos":"Foram geradas mais receitas?",
	"impactsform.nuevasOportunidadesAlianzas":"Foram geradas novas oportunidades ou parcerias?",
	"impactsform.mejoraCredibilidadReputacion":"Houve uma melhoria na credibilidade e na reputação?",
	"impactsform.mejoraVisibilidadEntidad":"Foi alcançada alguma melhoria na visibilidade da entidade?",
	"impactsform.numEvaluados":"Nº de participantes avaliados",
	"impactsform.ver":"Ver Impacto",
	"impact.codigo":"Código",
	"impact.fecha":"Data",
	"impact.descripcion":"Descrição",
	"impact.beneficiariosEvaluados":"Beneficiários avaliados",
	"impact.entidadesEvaluadas":"Entidades avaliadas",
	"impact.impactosAmbientales":"Impactos ambientais",
	"impact.editar":"Editar",
	"impact.adjuntarArchivo":"Anexar arquivo",
	"impact.duplicar":"Duplicar",
	"impact.borrar":"Eliminar",
	"impact.quitarValidacion":"Remover Validação",
	"impact.validar":"Validar",
	"impact.impacto":"Impacto",
	"impact.estasSeguro":"Tem a certeza?",
	"impact.cancelar":"Cancelar",
	"impact.ver":"Ver",
	"impact.validarImpacto":"Validar Impacto",
	"impact.borrarImpacto":"Eliminar Impacto",
	"initiativeform.edit":"Editar Iniciativa",
	"initiativeform.new":"Nova Iniciativa",
	"initiativeform.nombre":"Nome:",
	"initiativeform.pais":"País:",
	"initiativeform.region":"Região:",
	"initiativeform.ciudad":"Cidade:",
	"initiativeform.descripcion":"Descrição:",
	"initiativeform.fechaInicio":"Data de início:",
	"initiativeform.fechaFin":"Data Fim:",
	"initiativeform.areaActuacionPrimaria":"Área principal de atuação:",
	"initiativeform.areaActuacionSecundaria":"Área secundária de atuação:",
	"initiativeform.entidadesContribuyenIniciativa":"Colaboradores e entidades que contribuem para a iniciativa",
	"initiativeform.entidades":"Entidades",
	"initiativeform.colaborador":"Colaborador",
	"initiativeform.motivacion":"Motivação",
	"initiativeform.gradoImplicacion":"Grau de envolvimento",
	"initiativeform.borrarColaborador":"Eliminar colaborador",
	"initiativeform.addColaborador":"Salvar",
	"initiativeform.cumpleLBG":"Cumpre com os princípios LBG / ONLBG:",
	"initiativeform.razones":"Razões:",
	"initiativeform.comentarios":"Comentários:",
	"initiativeform.odsPrimario":"Objetivo de desenvolvimento (ODS) primário:",
	"initiativeform.metas":"Metas:",
	"initiativeform.odsSecundario":"Objetivo de desenvolvimento (ODS) secundário:",
	"initiativeform.presupuestoEntidades":"Orçamento das entidades",
	"initiativeform.entidad":"Entidade",
	"initiativeform.presupuesto":"Orçamento",
	"initiativeform.divisa":"Moeda",
	"initiativeform.paisOrigenFondos":"País de origem dos fundos",
	"initiativeform.addPresupuesto":"Salvar",
	"initiativeform.borrarPresupuesto":"Eliminar Orçamento",
	"initiativeform.asignarUsuarios":"Designar Usuários",
	"initiativeform.guardar":"Salvar",
	"initiativeform.guardarYSeguir":"Salvar e acrescentar contribuições",
	"initiativeform.cancelar":"Cancelar",
	"initiativeform.volver":"Voltar à lista de iniciativas",
	"initiativeform.verIniciativa":"Ver Iniciativa",
	"initiativeform.colaboradorPuntual":"Colaboração pontual",
	"initiativeform.socioColaborador":"Socio colaborador",
	"initiativeform.Si":"Sim",
	"initiativeform.No":"Não",
	"initiativeform.agrupacionIniciativaInterna":"Agrupamento interno de iniciativas",
	"initiativeform.odstitle":"Objetivos de desenvolvimento",
	"initiativeform.placeholder.ods":"Objetivos de desenvolvimento...",
	"initiativeform.placeholder.meta":"Meta...",
	"initiativeform.ods":"ODS",
	"initiativeform.meta":"Meta",
	"initiativeform.porcentaje":"Porcentagem",
	"initiativeform.borrarOds":"Eliminar ODS",
	"initiativeform.addOds":"Salvar",
	"initiativeresume.resumen":"Sumário",
	"initiativeresume.numIniciativas":"Nº de iniciativas",
	"initiativeresume.contribucion":"CONTRIBUIÇÃO",
	"initiativeresume.currentYear":"Ano atual",
	"initiativeresume.previousYear":"Anos anteriores",
	"initiativeresume.total":"Total",
	"initiativeresume.dinero":"Dinheiro",
	"initiativeresume.especie":"Espécie",
	"initiativeresume.tiempo":"Tempo",
	"initiativeresume.probono":"Pro bono",
	"initiativeresume.costesGestion":"Custos de gestão",
	"initiativeresume.contribucionTotal":"CONTRIBUIÇÃO TOTAL",
	"initiativeresume.otros":"OUTROS",
	"initiativeresume.otrosTotal":"OUTROS TOTAL",
	"initiativeresume.efectoMultiplicador":"Efeito multiplicador",
	"initiativeresume.totalMay":"TOTAL",
	"initiativeresume.presupuesto":"Orçamento",
	"initiativeresume.cumplimiento":"Cumprimento",
	"initiativeresume.pendiente":"Pendente",
	"initiativeresume.volver":"Voltar",
	"initiativeassign.finalizarAsignacion":"Finalizar designação",
	"initiativeassign.marcarTodos":"Marcar todos",
	"initiativeassign.desmarcarTodos":"Desmarcar todos",
	"initiativeassign.contribuciones":"Contribuições",
	"initiativeassign.logros":"Realizações",
	"initiativeassign.impactos":"Impactos",
	"initiativeassign.todos":"Todos",
	"initiativeassign.codigo":"Código",
	"initiativeassign.nombre":"Nome",
	"initiativeassign.apellidos":"Apelidos",
	"initiativeassign.email":"E-mail",
	"initiativeassign.rol":"Função",
	"initiativeassign.entidadRepresentada":"Entidade representada",
	"initiative.new":"Nova Iniciativa",
	"initiative.contribucionTotal":"Contribuição TOTAL",
	"initiative.contribucion":"Contribuição",
	"initiative.contribuciones":"Contribuições",
	"initiative.logros":"Realizações",
	"initiative.impactos":"Impactos",
	"initiative.editar":"Editar",
	"initiative.adjuntarArchivo":"Anexar arquivo",
	"initiative.duplicar":"Duplicar",
	"initiative.asignarUsuario":"Designar Usuários",
	"initiative.borrar":"Eliminar",
	"initiative.codigo":"Código",
	"initiative.nombre":"Nome",
	"initiative.fecha":"Data",
	"initiative.fecha_actualizacion":"Atualização",
	"initiative.pais":"País",
	"initiative.area":"Área",
	"initiative.motivacion":"Motivação",
	"initiative.lbg":"LBG",
	"initiative.total":"TOTAL",
	"initiative.mediciones":"Medições",
	"initiative.si":"Sim",
	"initiative.no":"Não",
	"initiative.borrarProyecto":"Eliminar Projeto",
	"initiative.estasSeguro":"Tem a certeza?",
	"initiative.cancelar":"Cancelar",
	"initiative.ver":"Ver",
	"initiative.start_date":"Data de início",
	"initiative.update_date":"Data de atualização",
	"login.hasOlvidadoPass":"Esqueceu a sua senha?",
	"login.iniciarSesion":"INICIAR SESSÃO",
	"login.userMail":"Usuário / e-mail",
	"login.pass":"Senha",
	"login.usuario.desactivado":"O seu usuário está atualmente desativado",
	"manage.usuarios":"USUÁRIO",
	"manage.entidades":"ENTIDADES",
	"manage.planesUso":"PLANOS DE USO",
	"manage.tiposCambio":"TAXAS DE CÂMBIO",
	"manage.colaboradores":"COLABORADORES",
	"manage.nuevaEntidad":"Nova Entidade",
	"manage.entidad":"Entidades",
	"manage.nuevoUsuario":"Novo Usuário",
	"manage.usuario":"Usuários",
	"manage.nuevoPlan":"Novo Plano",
	"manage.planUso":"Planos de uso",
	"manage.nuevoTipoCambio":"Nova Taxa de Câmbio",
	"manage.tipoCambio":"Taxas de câmbio",
	"manage.nuevoColaborador":"Novo Colaborador",
	"manage.colaborador":"Colaboradores",
	"mepass.cambiarPass":"Alterar Senha",
	"mepass.passCambiado":"Senha corretamente alterada",
	"mepass.oldPass":"Senha antiga:",
	"mepass.newPass":"Senha Nova:",
	"mepass.newPass2":"Repita a Senha Nova:",
	"mepass.guardar":"Salvar",
	"mepass.cancelar":"Cancelar",
	"mepass.oldPassFail":"A senha antiga não é correta",
	"mepass.oldPassValidate":"A nova senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um dígito",
	"mepass.passNotEqual":"As senhas não correspondem",
	"mepass.todosCamposObligatorios":"Todos os campos são obrigatórios",
	"me.miPerfil":"O Meu Perfil",
	"me.nombre":"Nome:",
	"me.apellidos":"Apelidos:",
	"me.email":"E-mail:",
	"me.pais":"País:",
	"me.cambiarPass":"Alterar Senha",
	"me.guardar":"Salvar",
	"me.dataChanged":"Dados alterados corretamente",
	"measurement.contribuciones":"CONTRIBUIÇÕES",
	"measurement.logros":"REALIZAÇÕES",
	"measurement.impactos":"IMPACTOS",
	"measurement.contribucionesMin":"Contribuições",
	"measurement.nuevoLogro":"Nova Realização",
	"measurement.logrosMin":"Realizações",
	"measurement.nuevoImpacto":"Novo Impacto",
	"measurement.impactosMin":"Impactos",
	"planform.edit":"Editar Plano",
	"planform.new":"Novo Plano",
	"planform.nombre":"Nome:",
	"planform.grupoLbg":"Grupo LBG:",
	"planform.numUsuarios":"Nº de usuários:",
	"planform.numResponsable":"Nº responsável:",
	"planform.numGerentes":"Nº gerentes:",
	"planform.numSupPro":"Nº supervisores de projeto:",
	"planform.numSupIni":"Nº supervisores de iniciativa:",
	"planform.numAgentes":"Nº agentes:",
	"planform.numBasicos":"Nº básicos:",
	"planform.activo":"Ativo",
	"planform.guardar":"Salvar",
	"planform.cancelar":"Cancelar",
	"planform.volver":"Voltar à lista de planos de uso",
	"plan.edit":"Editar",
	"plan.delete":"Eliminar",
	"plan.nombre":"Nome",
	"plan.numUsuarios":"Nº usuários",
	"plan.estado":"Estado",
	"plan.desactivar":"Desativar",
	"plan.activar":"Ativar",
	"plan.plan":"Plano",
	"plan.estasSeguro":"Tem a certeza?",
	"plan.borrarPlan":"Eliminar Plano",
	"plan.borrar":"Eliminar",
	"plan.cancelar":"Cancelar",
	"proyectform.new":"Novo Projeto",
	"proyectform.edit":"Editar Projeto",
	"proyectform.nombre":"Nome:",
	"proyectform.divisionUnidad":"Divisão / Unidade:",
	"proyectform.agrupacionInterna":"Agrupamento interno:",
	"proyectform.descripcion":"Descrição:",
	"proyectform.desde":"Desde:",
	"proyectform.hasta":"Até:",
	"proyectform.presupuesto":"Orçamento",
	"proyectform.divisa":"Moeda:",
	"proyectform.presupuestoTotal":"Orçamento total",
	"proyectform.asignarUsuarios":"Designar Usuários",
	"proyectform.guardar":"Salvar",
	"proyectform.cancelar":"Cancelar",
	"proyectform.volver":"Voltar à lista de projetos",
	"proyectform.mensajeError":"O orçamento total é menor do que o orçamento da entidade",
	"proyectform.ver":"Ver Projeto",
	"proyectassign.finalizarAsignacion":"Finalizar a designação",
	"proyectassign.marcarTodos":"Marcar todos",
	"proyectassign.desmarcarTodos":"Desmarcar todos",
	"proyectassign.codigo":"Código",
	"proyectassign.nombre":"Nome",
	"proyectassign.apellidos":"Apelido",
	"proyectassign.email":"E-mail",
	"proyectassign.rol":"Função",
	"proyectassign.entidadRepresentada":"Entidade representada",
	"proyectassign.pais":"País",
	"proyectassign.seleccionar":"Selecionar",
	"proyectassign.desactivar":"Desativar",
	"proyectassign.activar":"Ativar",
	"proyectassign.usuario":"Usuário",
	"proyectassign.estasSeguro":"Tem a certeza?",
	"proyectassign.cancelar":"Cancelar",
	"proyect.proyectos":"PROJETOS",
	"proyect.nuevoProyecto":"Novo Projeto",
	"proyect.abiertos":"ABERTOS",
	"proyect.cerrados":"FECHADOS",
	"proyect.todos":"TODOS",
	"proyect.fechaInicio":"Data de início",
	"proyect.divisionUnidad":"Divisão / unidade",
	"proyect.ninguno":"Ningum",
	"proyect.contribucionTotal":"Contribuição TOTAL",
	"proyect.contribucion":"Contribuição",
	"proyect.iniciativas":"Iniciativas",
	"proyect.editar":"Editar",
	"proyect.adjuntarArchivo":"Anexar arquivo",
	"proyect.duplicar":"Duplicar",
	"proyect.abrir":"Abrir",
	"proyect.cerrar":"Fechar",
	"proyect.asignarUsuarios":"Designar Usuários",
	"proyect.borrar":"Eliminar",
	"proyect.codigo":"Código",
	"proyect.nombre":"Nome",
	"proyect.fechaInicioPH":"Data de início",
	"proyect.divisionUnidadPH":"Unidade",
	"proyect.agrupacion":"Agrupamento",
	"proyect.total":"TOTAL",
	"proyect.proyecto":"Projeto",
	"proyect.estasSeguro":"Tem a certeza?",
	"proyect.cancelar":"Cancelar",
	"proyect.proyectoCerradoNoReabre":"Um projeto fechado não pode ser editado",
	"proyect.borrarProyecto":"Eliminar Projeto",
	"proyect.proyectoCerradoNoArchivos":"Os arquivos não podem ser anexados a um projeto fechado",
	"proyect.ver":"Ver",
	"proyect.abrirProyecto":"Abrir Projeto",
	"proyect.cerrarProyecto":"Fechar Projeto",
	"rating.unidadAdministrativa":"Unidade administrativa ou de negócio",
	"rating.nombre":"Nome",
	"rating.agrupacionInterna":"Agrupamento interno",
	"rating.agrupacionIniciativaInterna":"Agrupamento interno de iniciativas",
	"rating.nuevaDivision":"Nova divisão administrativa",
	"rating.nuevaAgrupacion":"Novo agrupamento interno",
	"rating.nuevaAgrupacionIniciativa":"Novo agrupamento interno de iniciativas",
	"proyectresume.resumen":"Sumário",
	"proyectresume.numIniciativas":"Nº de iniciativas",
	"proyectresume.contribucion":"CONTRIBUIÇÃO",
	"proyectresume.currentYear":"Ano atual",
	"proyectresume.previousYear":"Anos anteriores",
	"proyectresume.total":"Total",
	"proyectresume.dinero":"Dinheiro",
	"proyectresume.especie":"Espécie",
	"proyectresume.tiempo":"Tempo",
	"proyectresume.probono":"Pro bono",
	"proyectresume.costesGestion":"Custos de gestão",
	"proyectresume.contribucionTotal":"CONTRIBUIÇÃO",
	"proyectresume.otros":"OUTROS",
	"proyectresume.otrosTotal":"OUTROS TOTAL",
	"proyectresume.efectoMultiplicador":"Efeito multiplicador",
	"proyectresume.totalMayus":"TOTAL",
	"proyectresume.presupuesto":"Orçamento",
	"proyectresume.cumplimiento":"Cumprimento",
	"proyectresume.pendiente":"Pendente",
	"proyectresume.volver":"Voltar",
	"salarytablesform.edit":"Editar quadro de contribuições",
	"salarytablesform.new":"Novo quadro",
	"salarytablesform.nombre":"Nome:",
	"salarytablesform.campoRequerido":"Este campo é obrigatório",
	"salarytablesform.costeTotal":"Custo total",
	"salarytablesform.numPersonas":"Nº de pessoas",
	"salarytablesform.horas":"Horas",
	"salarytablesform.costeHora":"Custo por hora",
	"salarytablesform.applicationYear":"Ano de aplicação",
	"salarytablesform.guardar":"Salvar",
	"salarytablesform.cancelar":"Cancelar",
	"salarytablesform.volver":"Voltar à lista de quadros de contribuições",
	"salarytablesform.divisa":"Moeda",
	"salarytables.descripcionPublica":"Descrição do quadro",
	"salarytables.descripcionPrivada":"Informações para a gestão interna",
	"salarytables.aplicationYear":"Ano de aplicação",
	"salarytables.adjuntarArchivo":"Anexar arquivo",
	"salarytables.editar":"Editar",
	"salarytables.borrar":"Eliminar",
	"salarytables.codigo":"Código",
	"salarytables.nombre":"Nome",
	"salarytables.totalCoste":"Custo Total",
	"salarytables.divisa":"Moeda",
	"salarytables.personas":"Pessoas",
	"salarytables.horas":"Horas",
	"salarytables.costeHora":"Custo / hora",
	"salarytables.borrarTablaSalarial":"Eliminar Quadro de contribuições",
	"salarytables.estasSeguro":"Tem a certeza?",
	"salarytables.cancelar":"Cancelar",
	"toolbartop.home":"Home",
	"toolbartop.perfil":"Perfil",
	"toolbartop.gestion":"Gestão",
	"toolbartop.proyectos":"Projetos",
	"toolbartop.benchmarking":"Benchmarking",
	"toolbartop.idioma":"Língua",
	"toolbartop.moneda":"Moeda",
	"toolbartop.cerrarSesion":"Fechar sessão",
	"toolbartop.contacto":"Contato",
	"toolbartop.filterReport":"Relatórios",
	"toolbartop.reportChart":"Estatísticas",
	"uploadform.subirArchivo":"Carregar arquivo",
	"uploadform.archivosSubidos":"Arquivos carregados:",
	"uploadform.cancelar":"Cancelar",
	"uploadform.volver":"Voltar",
	"uploadform.borrarArchivo":"Eliminar arquivo",
	"uploadform.estasSeguro":"Tem a certeza?",
	"uploadform.borrar":"Eliminar",
	"userform.editarUsuario":"Editar Usuário",
	"userform.nuevoUsuario":"Novo Usuário",
	"userform.nombre":"Nome:",
	"userform.apellidos":"Apelidos:",
	"userform.email":"E-mail:",
	"userform.pais":"País:",
	"userform.rol":"Função:",
	"userform.entidadResponsableAlta":"Entidade responsável pelo registro:",
	"userform.entidadRepresentada":"Entidade que representa:",
	"userform.emailSolicitudInfo":"E-mail solicitação informação:",
	"userform.grupoLbgCoordinador":"Grupo LBG coordenador:",
	"userform.activo":"Ativo",
	"userform.guardar":"Salvar",
	"userform.cancelar":"Cancelar",
	"userform.volver":"Voltar à lista de usuários",
	"user.disponiblesGerentes":"Disponivéis: Gerentes",
	"user.supervisoresProyecto":"Supervisores de Projeto",
	"user.supIni":"Supervisores de Iniciativa",
	"user.agentes":"Agentes",
	"user.editar":"Editar",
	"user.borrar":"Eliminar",
	"user.codigo":"Código",
	"user.nombre":"Nome",
	"user.apellidos":"Apelido",
	"user.email":"E-mail",
	"user.rol":"Função",
	"user.entidadResponsable":"Entidade responsável",
	"user.entidadRepresentada":"Entidade representada",
	"user.pais":"País",
	"user.estado":"Estado",
	"user.desactivar":"Desativar",
	"user.activar":"Ativar",
	"user.usuario":"Usuário",
	"user.estasSeguro":"Tem a certeza?",
	"user.cancelar":"Cancelar",
	"user.borrarUsuario":"Eliminar Usuário",
	"confirmdelete.confirmarBorrado":"Confirmar Eliminação",
	"confirmdelete.estasSeguro":"Tem a certeza?",
	"documento.size.excedido":"O tamanho do arquivo é maior do que o tamanho máximo: 40MB",
	"proyectclosed.alert":"Nenhuma ação pode ser tomada sobre um projeto fechado ou o seu conteúdo",
	"list.empty":"Não há elementos para mostrar",
	"list.cargando":"Carregando ...",
	"impactSelect.sinSeleccion":"Nenhuma seleção",
	"meta.ods.1.nombre.1":"1.1 Até 2030, erradicar a pobreza extrema em todos os lugares, atualmente medida como pessoas que vivem com menos de 1,25 dólares por dia",
	"meta.ods.1.nombre.2":"1.2 Até 2030, reduzir pelo menos para metade a proporção de homens, mulheres e crianças, de todas as idades, que vivem na pobreza, em todas as suas dimensões, de acordo com as definições nacionais",
	"meta.ods.1.nombre.3":"1.3 Implementar, a nível nacional, medidas e sistemas de proteção social adequados, para todos, incluindo pisos, e até 2030 atingir uma cobertura substancial dos mais pobres e vulneráveis",
	"meta.ods.1.nombre.4":"1.4 Até 2030, garantir que todos os homens e mulheres, particularmente os mais pobres e vulneráveis, tenham direitos iguais no acesso aos recursos económicos, bem como no acesso aos serviços básicos, à propriedade e controle sobre a terra e outras formas de propriedade, herança, recursos naturais, novas tecnologias e serviços financeiros, incluindo microfinanciamento",
	"meta.ods.1.nombre.5":"1.5 Até 2030, aumentar a resiliência dos mais pobres e em situação de maior vulnerabilidade, e reduzir a exposição e a vulnerabilidade destes aos fenómenos extremos relacionados com o clima e outros choques e desastres económicos, sociais e ambientais",
	"meta.ods.1.nombre.a":"1.a Garantir uma mobilização significativa de recursos a partir de uma variedade de fontes, inclusive por meio do reforço da cooperação para o desenvolvimento, para proporcionar meios adequados e previsíveis para que os países em desenvolvimento (em particular, os países menos desenvolvidos) possam implementar programas e políticas para acabar com a pobreza em todas as suas dimensões",
	"meta.ods.1.nombre.b":"1.b Criar enquadramentos políticos sólidos ao nível nacional, regional e internacional, com base em estratégias de desenvolvimento a favor dos mais pobres e que sejam sensíveis às questão da igualdade do género, para apoiar investimentos acelerados nas ações de erradicação da pobreza",
	"meta.ods.2.nombre.1":"2.1 Até 2030, acabar com a fome e garantir o acesso de todas as pessoas, em particular os mais pobres e pessoas em situações vulneráveis, incluindo crianças, a uma alimentação de qualidade, nutritiva e suficiente durante todo o ano",
	"meta.ods.2.nombre.2":"2.2 Até 2030, acabar com todas as formas de malnutrição, incluindo atingir, até 2025, as metas acordadas internacionalmente sobre nanismo e caquexia em crianças menores de cinco anos, e atender às necessidades nutricionais dos adolescentes, mulheres grávidas e lactantes e pessoas idosas",
	"meta.ods.2.nombre.3":"2.3 Até 2030, duplicar a produtividade agrícola e o rendimento dos pequenos produtores de alimentos, particularmente das mulheres, povos indígenas, agricultores de subsistência, pastores e pescadores, inclusive através de garantia de acesso igualitário à terra e a outros recursos produtivos tais como conhecimento, serviços financeiros, mercados e oportunidades de agregação de valor e de emprego não agrícola",
	"meta.ods.2.nombre.4":"2.4 Até 2030, garantir sistemas sustentáveis de produção de alimentos e implementar práticas agrícolas resilientes, que aumentem a produtividade e a produção, que ajudem a manter os ecossistemas, que fortaleçam a capacidade de adaptação às alterações climáticas, às condições meteorológicas extremas, secas, inundações e outros desastres, e que melhorem progressivamente a qualidade da terra e do solo",
	"meta.ods.2.nombre.5":"2.5 Até 2020, manter a diversidade genética de sementes, plantas cultivadas, animais de criação e domesticados e suas respetivas espécies selvagens, inclusive por meio de bancos de sementes e plantas que sejam diversificados e bem geridos ao nível nacional, regional e internacional, e garantir o acesso e a repartição justa e equitativa dos benefícios decorrentes da utilização dos recursos genéticos e conhecimentos tradicionais associados, tal como acordado internacionalmente",
	"meta.ods.2.nombre.a":"2.a Aumentar o investimento, inclusive via o reforço da cooperação internacional, em infraestrutura rural, pesquisa e extensão de serviços agrícolas, desenvolvimento de tecnologia, e os bancos de genes de plantas e animais, para aumentar a capacidade de produção agrícola nos países em desenvolvimento, em particular nos países menos desenvolvidos",
	"meta.ods.2.nombre.b":"2.b Corrigir e prevenir as restrições ao comércio e distorções nos mercados agrícolas mundiais, incluindo a eliminação em paralelo de todas as formas de subsídios à exportação e todas as medidas de exportação com efeito equivalente, de acordo com o mandato da Ronda de Desenvolvimento de Doha",
	"meta.ods.2.nombre.c":"2.c Adotar medidas para garantir o funcionamento adequado dos mercados de commodities de alimentos e seus derivados, e facilitar o acesso oportuno à informação de mercado, inclusive sobre as reservas de alimentos, a fim de ajudar a limitar a volatilidade extrema dos preços dos alimentos",
	"meta.ods.3.nombre.1":"3.1 Até 2030, reduzir a taxa de mortalidade materna global para menos de 70 mortes por 100.000 nados-vivos",
	"meta.ods.3.nombre.2":"3.2 Até 2030, acabar com as mortes evitáveis de recém-nascidos e crianças menores de 5 anos, com todos os países a tentarem reduzir a mortalidade neonatal para pelo menos 12 por 1.000 nados-vivos e a mortalidade de crianças menores de 5 anos para pelo menos 25 por 1.000 nados-vivos",
	"meta.ods.3.nombre.3":"3.3 Acabar com as epidemias de AIDS, tuberculose, malária e doenças tropicais negligenciadas, e combater a hepatite, doenças transmitidas pela água, e outras doenças transmissíveis",
	"meta.ods.3.nombre.4":"3.4 Até 2030, reduzir num terço a mortalidade prematura por doenças não transmissíveis via prevenção e tratamento, e promover a saúde mental e o bem-estar",
	"meta.ods.3.nombre.5":"3.5 Reforçar a prevenção e o tratamento do abuso de substâncias, incluindo o abuso de drogas entorpecentes e uso nocivo do álcool",
	"meta.ods.3.nombre.6":"3.6 Até 2020, reduzir para metade, a nível global, o número de mortos e feridos devido a acidentes rodoviários",
	"meta.ods.3.nombre.7":"3.7 Até 2030, assegurar o acesso universal aos serviços de saúde sexual e reprodutiva, incluindo o planeamento familiar, informação e educação, bem como a integração da saúde reprodutiva em estratégias e programas nacionais",
	"meta.ods.3.nombre.8":"3.8 Atingir a cobertura universal de saúde, incluindo a proteção do risco financeiro, o acesso a serviços de saúde essenciais de qualidade e o acesso a medicamentos e vacinas essenciais para todos de forma segura, eficaz, de qualidade e a preços acessíveis",
	"meta.ods.3.nombre.9":"3.9 Até 2030, reduzir substancialmente o número de mortes e doenças devido a químicos perigosos, contaminação e poluição do ar, água e solo",
	"meta.ods.3.nombre.a":"3.a Fortalecer a implementação da Convenção-Quadro para o Controle do Tabaco em todos os países, conforme apropriado",
	"meta.ods.3.nombre.b":"3.b Apoiar a pesquisa e o desenvolvimento de vacinas e medicamentos para as doenças transmissíveis e não transmissíveis, que afetam principalmente os países em desenvolvimento, proporcionar o acesso a medicamentos e vacinas essenciais a preços acessíveis, de acordo com a Declaração de Doha, que dita o direito dos países em desenvolvimento de utilizarem plenamente as disposições do acordo TRIPS sobre flexibilidades para proteger a saúde pública e, em particular, proporcionar o acesso a medicamentos para todos",
	"meta.ods.3.nombre.c":"3.c Aumentar substancialmente o financiamento da saúde e o recrutamento, formação, e retenção do pessoal de saúde nos países em desenvolvimento, especialmente nos países menos desenvolvidos e nos pequenos Estados insulares em desenvolvimento",
	"meta.ods.3.nombre.d":"3.d Reforçar a capacidade de todos os países, particularmente os países em desenvolvimento, para o alerta precoce, redução de riscos e gerenciamento de riscos nacionais e globais de saúde",
	"meta.ods.4.nombre.1":"4.1 Até 2030, garantir que todas as meninas e meninos completam o ensino primário e secundário que deve ser de acesso livre, equitativo e de qualidade, e que conduza a resultados de aprendizagem relevantes e eficazes",
	"meta.ods.4.nombre.2":"4.2 Até 2030, garantir que todos as meninas e meninos tenham acesso a um desenvolvimento de qualidade na primeira fase da infância, bem como cuidados e educação pré-escolar, de modo que estejam preparados para o ensino primário",
	"meta.ods.4.nombre.3":"4.3 Até 2030, assegurar a igualdade de acesso para todos os homens e mulheres à educação técnica, profissional e superior de qualidade, a preços acessíveis, incluindo à universidade",
	"meta.ods.4.nombre.4":"4.4 Até 2030, aumentar substancialmente o número de jovens e adultos que tenham habilitações relevantes, inclusive competências técnicas e profissionais, para emprego, trabalho decente e empreendedorismo",
	"meta.ods.4.nombre.5":"4.5 Até 2030, eliminar as disparidades de género na educação e garantir a igualdade de acesso a todos os níveis de educação e formação profissional para os mais vulneráveis, incluindo as pessoas com deficiência, povos indígenas e crianças em situação de vulnerabilidade",
	"meta.ods.4.nombre.6":"4.6 Até 2030, garantir que todos os jovens e uma substancial proporção dos adultos, homens e mulheres, sejam alfabetizados e tenham adquirido o conhecimento básico de matemática",
	"meta.ods.4.nombre.7":"4.7 Até 2030, garantir que todos os alunos adquiram conhecimentos e habilidades necessárias para promover o desenvolvimento sustentável, inclusive, entre outros, por meio da educação para o desenvolvimento sustentável e estilos de vida sustentáveis, direitos humanos, igualdade de género, promoção de uma cultura de paz e da não violência, cidadania global e valorização da diversidade cultural e da contribuição da cultura para o desenvolvimento sustentável",
	"meta.ods.4.nombre.a":"4.a Construir e melhorar instalações físicas para educação, apropriadas para crianças e sensíveis às deficiências e à igualdade de género, e que proporcionem ambientes de aprendizagem seguros e não violentos, inclusivos e eficazes para todos",
	"meta.ods.4.nombre.b":"4.b Até 2020, ampliar substancialmente, a níel global, o número de bolsas de estudo para os países em desenvolvimento, em particular os países menos desenvolvidos, pequenos Estados insulares em desenvolvimento e os países africanos, para o ensino superior, incluindo programas de formação profissional, de tecnologia da informação e da comunicação, técnicos, de engenharia e programas científicos em países desenvolvidos e outros países em desenvolvimento",
	"meta.ods.4.nombre.c":"4.c Até 2030, aumentar substancialmente o contingente de professores qualificados, inclusive por meio da cooperação internacional para a formação de professores, nos países em desenvolvimento, especialmente os países menos desenvolvidos e pequenos Estados insulares em desenvolvimento",
	"meta.ods.5.nombre.1":"5.1 Acabar com todas as formas de discriminação contra todas as mulheres e meninas, em toda parte",
	"meta.ods.5.nombre.2":"5.2 Eliminar todas as formas de violência contra todas as mulheres e meninas nas esferas públicas e privadas, incluindo o tráfico e exploração sexual e de outros tipos",
	"meta.ods.5.nombre.3":"5.3 Eliminar todas as práticas nocivas, como os casamentos prematuros, forçados e envolvendo crianças, bem como as mutilações genitais femininas",
	"meta.ods.5.nombre.4":"5.4 Reconhecer e valorizar o trabalho de assistência e doméstico não remunerado, por meio da disponibilização de serviços públicos, infraestrutura e políticas de proteção social, bem como a promoção da responsabilidade partilhada dentro do lar e da família, conforme os contextos nacionais",
	"meta.ods.5.nombre.5":"5.5 Garantir a participação plena e efetiva das mulheres e a igualdade de oportunidades para a liderança em todos os níveis de tomada de decisão na vida política, económica e pública",
	"meta.ods.5.nombre.6":"5.6 Assegurar o acesso universal à saúde sexual e reprodutiva e os direitos reprodutivos, em conformidade com o Programa de Ação da Conferência Internacional sobre População e Desenvolvimento e com a Plataforma de Ação de Pequim e os documentos resultantes das suas conferências de revisão",
	"meta.ods.5.nombre.a":"5.a Realizar reformas para dar às mulheres direitos iguais aos recursos económicos, bem como o acesso à propriedade e controle sobre a terra e outras formas de propriedade, serviços financeiros, herança e recursos naturais, de acordo com as leis nacionais",
	"meta.ods.5.nombre.b":"5.b Aumentar o uso de tecnologias de base, em particular as tecnologias de informação e comunicação, para promover o empoderamento das mulheres",
	"meta.ods.5.nombre.c":"5.c Adotar e fortalecer políticas sólidas e legislação aplicável para a promoção da igualdade de género e o empoderamento de todas as mulheres e meninas, a todos os níveis",
	"meta.ods.6.nombre.1":"6.1 Até 2030, alcançar o acesso universal e equitativo à água potável e segura para todos",
	"meta.ods.6.nombre.2":"6.2 Até 2030, alcançar o acesso a saneamento e higiene adequados e equitativos para todos, e acabar com a defecação a céu aberto, com especial atenção para as necessidades das mulheres e meninas e daqueles que estão em situação de vulnerabilidade",
	"meta.ods.6.nombre.3":"6.3 Até 2030, melhorar a qualidade da água, reduzindo a poluição, eliminando despejo e minimizando a libertação de produtos químicos e materiais perigosos, reduzindo para metade a proporção de águas residuais não-tratadas e aumentando substancialmente a reciclagem e a reutilização, a nível global",
	"meta.ods.6.nombre.4":"6.4 Até 2030, aumentar substancialmente a eficiência no uso da água em todos os setores e assegurar extrações sustentáveis e o abastecimento de água doce para enfrentar a escassez de água, e reduzir substancialmente o número de pessoas que sofrem com a escassez de água",
	"meta.ods.6.nombre.5":"6.5 Até 2030, implementar a gestão integrada dos recursos hídricos, a todos os níveis, inclusive via cooperação transfronteiriça, conforme apropriado",
	"meta.ods.6.nombre.6":"6.6 Até 2020, proteger e restaurar ecossistemas relacionados com a água, incluindo montanhas, florestas, zonas húmidas, rios, aquíferos e lagos",
	"meta.ods.6.nombre.a":"6.a Até 2030, ampliar a cooperação internacional e o apoio à capacitação para os países em desenvolvimento em atividades e programas relacionados com a água e o saneamento, incluindo extração de água, dessalinização, eficiência no uso da água, tratamento de efluentes, reciclagem e tecnologias de reutilização",
	"meta.ods.6.nombre.b":"6.b Apoiar e fortalecer a participação das comunidades locais, para melhorar a gestão da água e do saneamento",
	"meta.ods.7.nombre.1":"7.1 Até 2030, assegurar o acesso universal, de confiança, moderno e a preços acessíveis aos serviços de energia",
	"meta.ods.7.nombre.2":"7.2 Até 2030, aumentar substancialmente a participação de energias renováveis na matriz energética global",
	"meta.ods.7.nombre.3":"7.3 Até 2030, duplicar a taxa global de melhoria da eficiência energética",
	"meta.ods.7.nombre.a":"7.a Até 2030, reforçar a cooperação internacional para facilitar o acesso à investigação e às tecnologias de energia limpa, incluindo energias renováveis, eficiência energética e tecnologias de combustíveis fósseis avançadas e mais limpas, e promover o investimento em infraestrutura de energia e em tecnologias de energia limpa",
	"meta.ods.7.nombre.b":"7.b Até 2030, expandir a infraestrutura e modernizar a tecnologia para o fornecimento de serviços de energia modernos e sustentáveis para todos nos países em desenvolvimento, particularmente nos países menos desenvolvidos, nos pequenos Estados insulares em desenvolvimento e nos países em desenvolvimento sem litoral, de acordo com seus respetivos programas de apoio",
	"meta.ods.8.nombre.1":"8.1 Sustentar o crescimento económico per capita de acordo com as circunstâncias nacionais e, em particular, um crescimento anual de pelo menos 7% do produto interno bruto [PIB] nos países menos desenvolvidos",
	"meta.ods.8.nombre.2":"8.2 Atingir níveis mais elevados de produtividade das economias através da diversificação, modernização tecnológica e inovação, inclusive através da focalização em setores de alto valor agregado e dos setores de mão-de-obra intensiva",
	"meta.ods.8.nombre.3":"8.3 Promover políticas orientadas para o desenvolvimento que apoiem as atividades produtivas, criação de emprego decente, empreendedorismo, criatividade e inovação, e incentivar a formalização e o crescimento das micro, pequenas e médias empresas, inclusive através do acesso aos serviços financeiros",
	"meta.ods.8.nombre.4":"8.4 Melhorar progressivamente, até 2030, a eficiência dos recursos globais no consumo e na produção, e empenhar-se em dissociar crescimento económico da degradação ambiental, de acordo com o enquadramento decenal de programas sobre produção e consumo sustentáveis, com os países desenvolvidos a assumirem a liderança",
	"meta.ods.8.nombre.5":"8.5 Até 2030, alcançar o emprego pleno e produtivo, e trabalho decente para todas as mulheres e homens, inclusive para os jovens e as pessoas com deficiência, e remuneração igual para trabalho de igual valor",
	"meta.ods.8.nombre.6":"8.6 Até 2020, reduzir substancialmente a proporção de jovens sem emprego, educação ou formação",
	"meta.ods.8.nombre.7":"8.7 Tomar medidas imediatas e eficazes para erradicar o trabalho forçado, acabar com a escravidão moderna e o tráfico de pessoas, e assegurar a proibição e a eliminação das piores formas de trabalho infantil, incluindo recrutamento e utilização de crianças-soldado, e até 2025 acabar com o trabalho infantil em todas as suas formas",
	"meta.ods.8.nombre.8":"8.8 Proteger os direitos do trabalho e promover ambientes de trabalho seguros e protegidos para todos os trabalhadores, incluindo os trabalhadores migrantes, em particular as mulheres migrantes, e pessoas em empregos precários",
	"meta.ods.8.nombre.9":"8.9 Até 2030, elaborar e implementar políticas para promover o turismo sustentável, que cria emprego e promove a cultura e os produtos locais",
	"meta.ods.8.nombre.10":"8.10 Fortalecer a capacidade das instituições financeiras nacionais para incentivar a expansão do acesso aos serviços bancários, de seguros e financeiros para todos",
	"meta.ods.8.nombre.a":"8.a Aumentar o apoio à Iniciativa de Ajuda para o Comércio [Aid for Trade] para os países em desenvolvimento, particularmente os países menos desenvolvidos, inclusive através do Quadro Integrado Reforçado para a Assistência Técnica Relacionada com o Comércio para os países menos desenvolvidos",
	"meta.ods.8.nombre.b":"8.b Até 2020, desenvolver e operacionalizar uma estratégia global para o emprego dos jovens e implementar o Pacto Mundial para o Emprego da Organização Internacional do Trabalho [OIT]",
	"meta.ods.9.nombre.1":"9.1 Desenvolver infraestruturas de qualidade, de confiança, sustentáveis e resilientes, incluindo infraestruturas regionais e transfronteiriças, para apoiar o desenvolvimento económico e o bem-estar humano, focando-se no acesso equitativo e a preços acessíveis para todos",
	"meta.ods.9.nombre.2":"9.2 Promover a industrialização inclusiva e sustentável e, até 2030, aumentar significativamente a participação da indústria no setor do emprego e no PIB, de acordo com as circunstâncias nacionais, e duplicar a sua participação nos países menos desenvolvidos",
	"meta.ods.9.nombre.3":"9.3 Aumentar o acesso das pequenas indústrias e outras empresas, particularmente em países em desenvolvimento, aos serviços financeiros, incluindo ao crédito acessível e à sua integração em cadeias de valor e mercados",
	"meta.ods.9.nombre.4":"9.4 Até 2030, modernizar as infraestrutursa e reabilitar as indústrias para torná-las sustentáveis, com maior eficiência no uso de recursos e maior adoção de tecnologias e processos industriais limpos e ambientalmente corretos; com todos os países atuando de acordo com as suas respectivas capacidades",
	"meta.ods.9.nombre.5":"9.5 Fortalecer a investigação científica, melhorar as capacidades tecnológicas de setores industriais em todos os países, particularmente os países em desenvolvimento, inclusive, até 2030, incentivar a inovação e aumentar substancialmente o número de trabalhadores na área de investigação e desenvolvimento por milhão de pessoas e a despesa pública e privada em investigação e desenvolvimento",
	"meta.ods.9.nombre.a":"9.a Facilitar o desenvolvimento de infraestruturas sustentáveis e resilientes nos países em desenvolvimento, através de maior apoio financeiro, tecnológico e técnico aos países africanos, aos países menos desenvolvidos, aos países em desenvolvimento sem litoral e aos pequenos Estados insulares em desenvolvimento",
	"meta.ods.9.nombre.b":"9.b Apoiar o desenvolvimento tecnológico, a investigação e a inovação nacionais nos países em desenvolvimento, inclusive garantindo um ambiente político propício para, entre outras coisas, a diversificação industrial e a agregação de valor às matérias-primas",
	"meta.ods.9.nombre.c":"9.c Aumentar significativamente o acesso às tecnologias de informação e comunicação e empenhar-se para oferecer acesso universal e a preços acessíveis à internet nos países menos desenvolvidos, até 2020",
	"meta.ods.10.nombre.1":"10.1 Até 2030, progressivamente alcançar, e manter de forma sustentável, o crescimento do rendimento dos 40% da população mais pobre a um ritmo maior do que o da média nacional",
	"meta.ods.10.nombre.2":"10.2 Até 2030, empoderar e promover a inclusão social, económica e política de todos, independentemente da idade, género, deficiência, raça, etnia, origem, religião, condição económica ou outra",
	"meta.ods.10.nombre.3":"10.3 Garantir a igualdade de oportunidades e reduzir as desigualdades de resultados, inclusive através da eliminação de leis, políticas e práticas discriminatórias e da promoção de legislação, políticas e ações adequadas a este respeito",
	"meta.ods.10.nombre.4":"10.4 Adotar políticas, especialmente ao nível fiscal, salarial e de proteção social, e alcançar progressivamente uma maior igualdade",
	"meta.ods.10.nombre.5":"10.5 Melhorar a regulamentação e monitorização dos mercados e instituições financeiras globais e fortalecer a implementação de tais regulamentações",
	"meta.ods.10.nombre.6":"10.6 Assegurar uma representação e voz mais forte dos países em desenvolvimento em tomadas de decisão nas instituições económicas e financeiras internacionais globais, a fim de produzir instituições mais eficazes, credíveis, responsáveis e legítimas",
	"meta.ods.10.nombre.7":"10.7 Facilitar a migração e a mobilidade das pessoas de forma ordenada, segura, regular e responsável, inclusive através da implementação de políticas de migração planeadas e bem geridas",
	"meta.ods.10.nombre.a":"10.a Implementar o princípio do tratamento especial e diferenciado para países em desenvolvimento, em particular para os países menos desenvolvidos, em conformidade com os acordos da Organização Mundial do Comércio",
	"meta.ods.10.nombre.b":"10.b Incentivar a assistência oficial ao desenvolvimento e fluxos financeiros, incluindo o investimento externo direto, para os Estados onde a necessidade é maior, em particular os países menos desenvolvidos, os países africanos, os pequenos Estados insulares em desenvolvimento e os países em desenvolvimento sem litoral, de acordo com os seus planos e programas nacionais",
	"meta.ods.10.nombre.c":"10.c Até 2030, reduzir para menos de 3% os custos de transação de remessas dos migrantes e eliminar os mecanismos de remessas com custos superiores a 5%",
	"meta.ods.11.nombre.1":"11.1 Até 2030, garantir o acesso de todos à habitação segura, adequada e a preço acessível, e aos serviços básicos, e melhorar as condições nos bairros de lata",
	"meta.ods.11.nombre.2":"11.2 Até 2030, proporcionar o acesso a sistemas de transporte seguros, acessíveis, sustentáveis e a preço acessível para todos, melhorando a segurança rodoviária através da expansão da rede de transportes públicos, com especial atenção para as necessidades das pessoas em situação de vulnerabilidade, mulheres, crianças, pessoas com deficiência e idosos",
	"meta.ods.11.nombre.3":"11.3 Até 2030, aumentar a urbanização inclusiva e sustentável, e as capacidades para o planemento e gestão de assentamentos humanos participativos, integrados e sustentáveis, em todos os países",
	"meta.ods.11.nombre.4":"11.4 Fortalecer esforços para proteger e salvaguardar o património cultural e natural do mundo",
	"meta.ods.11.nombre.5":"11.5 Até 2030, reduzir significativamente o número de mortes e o número de pessoas afetadas por catástrofes e diminuir substancialmente as perdas económicas diretas causadas por essa via no produto interno bruto global, incluindo as catástrofes relacionadas com a água, focando-se sobretudo na proteção dos pobres e das pessoas em situação de vulnerabilidade",
	"meta.ods.11.nombre.6":"11.6 Até 2030, reduzir o impacto ambiental negativo per capita nas cidades, inclusive prestando especial atenção à qualidade do ar, gestão de resíduos municipais e outros",
	"meta.ods.11.nombre.7":"11.7 Até 2030, proporcionar o acesso universal a espaços públicos seguros, inclusivos, acessíveis e verdes, particularmente para as mulheres e crianças, pessoas idosas e pessoas com deficiência",
	"meta.ods.11.nombre.a":"11.a Apoiar relações económicas, sociais e ambientais positivas entre áreas urbanas, periurbanas e rurais, reforçando o planemento nacional e regional de desenvolvimento",
	"meta.ods.11.nombre.b":"11.b Até 2020, aumentar substancialmente o número de cidades e assentamentos humanos que adotaram e implementaram políticas e planos integrados para a inclusão, a eficiência dos recursos, mitigação e adaptação às mudanças climáticas, resiliência a desastres; e desenvolver e implementar, de acordo com o Enquadramento para a Redução do Risco de Desastres de Sendai 2015-2030, a gestão holística do risco de desastres, a todos os níveis",
	"meta.ods.11.nombre.c":"11.c Apoiar os países menos desenvolvidos, inclusive por meio de assistência técnica e financeira, nas construções sustentáveis e resilientes, utilizando materiais locais",
	"meta.ods.12.nombre.1":"12.1 Implementar o Plano Decenal de Programas sobre Produção e Consumo Sustentáveis, com todos os países a tomar medidas, e os países desenvolvidos assumindo a liderança, tendo em conta o desenvolvimento e as capacidades dos países em desenvolvimento",
	"meta.ods.12.nombre.2":"12.2 Até 2030, alcançar a gestão sustentável e o uso eficiente dos recursos naturais",
	"meta.ods.12.nombre.3":"12.3 Até 2030, reduzir para metade o desperdício de alimentos per capita a nível mundial, de retalho e do consumidor, e reduzir os desperdícios de alimentos ao longo das cadeias de produção e abastecimento, incluindo os que ocorrem pós-colheita",
	"meta.ods.12.nombre.4":"12.4 Até 2020, alcançar a gestão ambientalmente saudável dos produtos químicos e de todos os resíduos, ao longo de todo o ciclo de vida destes, de acordo com os marcos internacionais acordados, e reduzir significativamente a libertação destes para o ar, água e solo, minimizar os seus impactos negativos sobre a saúde humana e o meio ambiente",
	"meta.ods.12.nombre.5":"12.5 Até 2030, reduzir substancialmente a geração de resíduos por meio da prevenção, redução, reciclagem e reutilização",
	"meta.ods.12.nombre.6":"12.6 Incentivar as empresas, especialmente as de grande dimensão e transnacionais, a adotar práticas sustentáveis e a integrar informação sobre sustentabilidade nos relatórios de atividade",
	"meta.ods.12.nombre.7":"12.7 Promover práticas de compras públicas sustentáveis, de acordo com as políticas e prioridades nacionais",
	"meta.ods.12.nombre.8":"12.8 Até 2030, garantir que as pessoas, em todos os lugares, tenham informação relevante e consciencialização para o desenvolvimento sustentável e estilos de vida em harmonia com a natureza",
	"meta.ods.12.nombre.a":"12.a Apoiar países em desenvolvimento a fortalecer as suas capacidades científicas e tecnológicas para mudarem para padrões mais sustentáveis de produção e consumo",
	"meta.ods.12.nombre.b":"12.b Desenvolver e implementar ferramentas para monitorizar os impactos do desenvolvimento sustentável para o turismo sustentável, que cria emprego, promove a cultura e os produtos locais",
	"meta.ods.12.nombre.c":"12.c Racionalizar subsídios ineficientes nos combustíveis fósseis, que encorajam o consumo exagerado, eliminando as distorções de mercado, de acordo com as circunstâncias nacionais, inclusive através da reestruturação fiscal e da eliminação gradual desses subsídios prejudiciais, caso existam, para refletir os seus impactos ambientais, tendo plenamente em conta as necessidades específicas e condições dos países em desenvolvimento e minimizando os possíveis impactos adversos sobre o seu desenvolvimento de uma forma que proteja os pobres e as comunidades afetadas",
	"meta.ods.13.nombre.1":"13.1 Reforçar a resiliência e a capacidade de adaptação a riscos relacionados com o clima e as catástrofes naturais em todos os países",
	"meta.ods.13.nombre.2":"13.2 Integrar medidas relacionadas com alterações climáticas nas políticas, estratégias e planeamentos nacionais",
	"meta.ods.13.nombre.3":"13.3 Melhorar a educação, aumentar a consciencialização e a capacidade humana e institucional sobre medidas de mitigação, adaptação, redução de impacto e alerta precoce no que respeita às alterações climáticas",
	"meta.ods.13.nombre.a":"13.a Implementar o compromisso assumido pelos países desenvolvidos na ConvençãoQuadro das Nações Unidas sobre Alterações Climáticas [UNFCCC, em inglês] de mobilizarem, em conjunto, 100 mil milhões de dólares por ano, a partir de 2020, a partir de variadas fontes, de forma a responder às necessidades dos países em desenvolvimento, no contexto das ações significativas de mitigação e implementação transparente; e operacionalizar o Fundo Verde para o Clima por meio da sua capitalização o mais cedo possível",
	"meta.ods.13.nombre.b":"13.b Promover mecanismos para a criação de capacidades para o planeamento e gestão eficaz no que respeita às alterações climáticas, nos países menos desenvolvidos e pequenos Estados insulares em desenvolvimento, e que tenham um especial enfoque nas mulheres, jovens, comunidades locais e marginalizadas",
	"meta.ods.14.nombre.1":"14.1 Até 2025, prevenir e reduzir significativamente a poluição marítima de todos os tipos, especialmente a que advém de atividades terrestres, incluindo detritos marinhos e a poluição por nutrientes",
	"meta.ods.14.nombre.2":"14.2 Até 2020, gerir de forma sustentável e proteger os ecossistemas marinhos e costeiros para evitar impactos adversos significativos, inclusive através do reforço da sua capacidade de resiliência, e tomar medidas para a sua restauração, a fim de assegurar oceanos saudáveis e produtivos",
	"meta.ods.14.nombre.3":"14.3 Minimizar e enfrentar os impactos da acidificação dos oceanos, inclusive através do reforço da cooperação científica em todos os níveis",
	"meta.ods.14.nombre.4":"14.4 Até 2020, regular, efetivamente, a extração de recursos, acabar com a sobrepesca e a pesca ilegal, não reportada e não regulamentada e as práticas de pesca destrutivas, e implementar planos de gestão com base científica, para restaurar populações de peixes no menor período de tempo possível, pelo menos para níveis que possam produzir rendimento máximo sustentável, como determinado pelas suas características biológicas",
	"meta.ods.14.nombre.5":"14.5 Até 2020, conservar pelo menos 10% das zonas costeiras e marinhas, de acordo com a legislação nacional e internacional, e com base na melhor informação científica disponível",
	"meta.ods.14.nombre.6":"14.6 Até 2020, proibir certas formas de subsídios à pesca, que contribuem para a sobrecapacidade e a sobrepesca, e eliminar os subsídios que contribuam para a pesca ilegal, não reportada e não regulamentada, e abster-se de introduzir novos subsídios desse tipo, reconhecendo que o tratamento especial e diferenciado adequado e eficaz para os países em desenvolvimento e os países menos desenvolvidos deve ser parte integrante da negociação sobre subsídios à pesca da Organização Mundial do Comércio",
	"meta.ods.14.nombre.7":"14.7 Até 2030, aumentar os benefícios económicos para os pequenos Estados insulares em desenvolvimento e os países menos desenvolvidos, a partir do uso sustentável dos recursos marinhos, inclusive através de uma gestão sustentável da pesca, aquicultura e turismo",
	"meta.ods.14.nombre.a":"14.a Aumentar o conhecimento científico, desenvolver capacidades de investigação e transferir tecnologia marinha, tendo em conta os critérios e orientações sobre a Transferência de Tecnologia Marinha da Comissão Oceanográfica Intergovernamental, a fim de melhorar a saúde dos oceanos e aumentar a contribuição da biodiversidade marinha para o desenvolvimento dos países em desenvolvimento, em particular os pequenos Estados insulares em desenvolvimento e os países menos desenvolvidos",
	"meta.ods.14.nombre.b":"14.b Proporcionar o acesso dos pescadores artesanais de pequena escala aos recursos marinhos e mercados",
	"meta.ods.14.nombre.c":"14.c Assegurar a conservação e o uso sustentável dos oceanos e seus recursos pela implementação do direito internacional, como refletido na UNCLOS [Convenção das Nações Unidas sobre o Direito do Mar], que determina o enquadramento legal para a conservação e utilização sustentável dos oceanos e dos seus recursos, conforme registrado no parágrafo 158 do “Futuro Que Queremos”",
	"meta.ods.15.nombre.1":"15.1 Até 2020, assegurar a conservação, recuperação e uso sustentável de ecossistemas terrestres e de água doce interior e os seus serviços, em especial florestas, zonas húmidas, montanhas e terras áridas, em conformidade com as obrigações decorrentes dos acordos internacionais",
	"meta.ods.15.nombre.2":"15.2 Até 2020, promover a implementação da gestão sustentável de todos os tipos de florestas, travar a deflorestação, restaurar florestas degradadas e aumentar substancialmente os esforços de florestação e reflorestação, a nível global",
	"meta.ods.15.nombre.3":"15.3 Até 2030, combater a desertificação, restaurar a terra e o solo degradados, incluindo terrenos afetados pela desertificação, secas e inundações, e lutar para alcançar um mundo neutro em termos de degradação do solo",
	"meta.ods.15.nombre.4":"15.4 Até 2030, assegurar a conservação dos ecossistemas de montanha, incluindo a sua biodiversidade, para melhorar a sua capacidade de proporcionar benefícios que são essenciais para o desenvolvimento sustentável",
	"meta.ods.15.nombre.5":"15.5 Tomar medidas urgentes e significativas para reduzir a degradação de habitat naturais, travar a perda de biodiversidade e, até 2020, proteger e evitar a extinção de espécies ameaçadas",
	"meta.ods.15.nombre.6":"15.6 Garantir uma repartição justa e equitativa dos benefícios derivados da utilização dos recursos genéticos e promover o acesso adequado aos recursos genéticos",
	"meta.ods.15.nombre.7":"15.7 Tomar medidas urgentes para acabar com a caça ilegal e o tráfico de espécies da flora e fauna protegidas e agir no que respeita tanto a procura quanto a oferta de produtos ilegais da vida selvagem",
	"meta.ods.15.nombre.8":"15.8 Até 2020, implementar medidas para evitar a introdução e reduzir significativamente o impacto de espécies exóticas invasoras nos ecossistemas terrestres e aquáticos, e controlar ou erradicar as espécies prioritárias",
	"meta.ods.15.nombre.9":"15.9 Até 2020, integrar os valores dos ecossistemas e da biodiversidade no planeamento nacional e local, nos processos de desenvolvimento, nas estratégias de redução da pobreza e nos sistemas de contabilidade",
	"meta.ods.15.nombre.a":"15.a Mobilizar e aumentar significativamente, a partir de todas as fontes, os recursos financeiros para a conservação e o uso sustentável da biodiversidade e dos ecossistemas",
	"meta.ods.15.nombre.b":"15.b Mobilizar recursos significativos, a partir de todas as fontes, e a todos os níveis, para financiar a gestão florestal sustentável e proporcionar incentivos adequados aos países em desenvolvimento para promover a gestão florestal sustentável, inclusive para a conservação e o reflorestamento",
	"meta.ods.15.nombre.c":"15.c Reforçar o apoio global para os esforços de combate à caça ilegal e ao tráfico de espécies protegidas, inclusive através do aumento da capacidade das comunidades locais para econtrar outras oportunidades de subsistência sustentável",
	"meta.ods.16.nombre.1":"16.1 Reduzir significativamente todas as formas de violência e as taxas de mortalidade com ela relacionadas, em todos os lugares",
	"meta.ods.16.nombre.2":"16.2 Acabar com o abuso, exploração, tráfico e todas as formas de violência e tortura contra as crianças",
	"meta.ods.16.nombre.3":"16.3 Promover o Estado de Direito, ao nível nacional e internacional, e garantir a igualdade de acesso à justiça para todos",
	"meta.ods.16.nombre.4":"16.4 Até 2030, reduzir significativamente os fluxos ilegais financeiros e de armas, reforçar a recuperação e devolução de recursos roubados e combater todas as formas de crime organizado",
	"meta.ods.16.nombre.5":"16.5 Reduzir substancialmente a corrupção e o suborno em todas as suas formas",
	"meta.ods.16.nombre.6":"16.6 Desenvolver instituições eficazes, responsáveis e transparentes, a todos os níveis",
	"meta.ods.16.nombre.7":"16.7 Garantir a tomada de decisão responsável, inclusiva, participativa e representativa em todos os níveis",
	"meta.ods.16.nombre.8":"16.8 Ampliar e fortalecer a participação dos países em desenvolvimento nas instituições de governanção global",
	"meta.ods.16.nombre.9":"16.9 Até 2030, fornecer identidade legal para todos, incluindo o registo de nascimento",
	"meta.ods.16.nombre.10":"16.10 Assegurar o acesso público à informação e proteger as liberdades fundamentais, em conformidade com a legislação nacional e os acordos internacionais",
	"meta.ods.16.nombre.a":"16.a Fortalecer as instituições nacionais relevantes, inclusive através da cooperação internacional, para a construção de melhor capacidade de resposta, a todos os níveis, em particular nos países em desenvolvimento, para a prevenção da violência e o combate ao terrorismo e ao crime",
	"meta.ods.16.nombre.b":"16.b Promover e fazer cumprir leis e políticas não discriminatórias para o desenvolvimento sustentável",
	"meta.ods.17.nombre.1":"17.1 Fortalecer a mobilização de recursos internos, inclusive através do apoio internacional aos países em desenvolvimento, para melhorar a capacidade nacional de cobrança de impostos e outras fontes de receita",
	"meta.ods.17.nombre.2":"17.2 Assegurar que os países desenvolvidos implementem plenamente os seus compromissos relativamente à ajuda pública ao desenvolvimento, incluindo o compromisso de muitos países desenvolvidos de alcançar o objectivo de 0,7% do rendimento nacional bruto para a ajuda pública ao desenvolvimento aos países em desenvolvimento e 0,15 a 0,20% do rendimento nacional bruto para a ajuda pública ao desenvolvimento aos países menos desenvolvidos; os prestadores de ajuda pública ao desenvolvimento são encorajados a considerar a fixação de um objectivo de pelo menos 0,20% do rendimento nacional bruto para a ajuda pública ao desenvolvimento aos países menos desenvolvidos",
	"meta.ods.17.nombre.3":"17.3 Mobilizar recursos financeiros adicionais para os países em desenvolvimento a partir de múltiplas fontes",
	"meta.ods.17.nombre.4":"17.4 Ajudar os países em desenvolvimento a alcançar a sustentabilidade da dívida de longo prazo através de políticas coordenadas destinadas a promover o financiamento, a redução e a reestruturação da dívida, conforme apropriado, e analisar a dívida externa dos países pobres altamente endividados de forma a reduzir o superendividamento",
	"meta.ods.17.nombre.5":"17.5 Adotar e implementar regimes de promoção de investimentos para os países menos desenvolvidos",
	"meta.ods.17.nombre.6":"17.6 Melhorar a cooperação Norte-Sul, Sul-Sul e triangular ao nível regional e internacional e o acesso à ciência, tecnologia e inovação, e aumentar a partilha de conhecimento em termos mutuamente acordados, inclusive através de uma melhor coordenação entre os mecanismos existentes, particularmente no nível das Nações Unidas, e por meio de um mecanismo de facilitação de tecnologia global",
	"meta.ods.17.nombre.7":"17.7 Promover o desenvolvimento, a transferência, a disseminação e a difusão de tecnologias ambientalmente corretas para os países em desenvolvimento, em condições favoráveis, inclusive em condições concessionais e preferenciais, conforme mutuamente acordado",
	"meta.ods.17.nombre.8":"17.8 Operacionalizar plenamente o Banco de Tecnologia e o mecanismo de capacitação em ciência, tecnologia e inovação para os países menos desenvolvidos até 2017, e aumentar o uso de tecnologias de capacitação, em particular das tecnologias de informação e comunicação",
	"meta.ods.17.nombre.9":"17.9 Reforçar o apoio internacional para a implementação eficaz e orientada da capacitação em países em desenvolvimento, a fim de apoiar os planos nacionais para implementar todos os objetivos de desenvolvimento sustentável, inclusive através da cooperação Norte-Sul, Sul-Sul e triangular",
	"meta.ods.17.nombre.10":"17.10 Promover um sistema multilateral de comércio universal, baseado em regras, aberto, não discriminatório e equitativo no âmbito da Organização Mundial do Comércio, inclusive através da conclusão das negociações no âmbito da Agenda de Desenvolvimento de Doha",
	"meta.ods.17.nombre.11":"17.11 Aumentar significativamente as exportações dos países em desenvolvimento, em particular com o objetivo de duplicar a participação dos países menos desenvolvidos nas exportações globais até 2020",
	"meta.ods.17.nombre.12":"17.12 Concretizar a implementação oportuna de acesso a mercados livres de cotas e taxas, de forma duradoura, para todos os países menos desenvolvidos, de acordo com as decisões da OMC, inclusive através de garantias de que as regras de origem preferencial aplicáveis às importações provenientes de países menos desenvolvidos sejam transparentes e simples, e contribuam para facilitar o acesso ao mercado",
	"meta.ods.17.nombre.13":"17.13 Aumentar a estabilidade macroeconómica global, inclusive através da coordenação e da coerência de políticas",
	"meta.ods.17.nombre.14":"17.14 Aumentar a coerência das políticas para o desenvolvimento sustentável",
	"meta.ods.17.nombre.15":"17.15 Respeitar o espaço político e a liderança de cada país para estabelecer e implementar políticas para a erradicação da pobreza e o desenvolvimento sustentável",
	"meta.ods.17.nombre.16":"17.16 Reforçar a parceria global para o desenvolvimento sustentável, complementada por parcerias multissetoriais que mobilizem e partilhem conhecimento, perícia, tecnologia e recursos financeiros, para apoiar a realização dos objetivos do desenvolvimento sustentável em todos os países, particularmente nos países em desenvolvimento",
	"meta.ods.17.nombre.17":"17.17 Incentivar e promover parcerias públicas, público-privadas e com a sociedade civil que sejam eficazes, a partir da experiência das estratégias de mobilização de recursos dessas parcerias",
	"meta.ods.17.nombre.18":"17.18 Até 2020, reforçar o apoio à capacitação para os países em desenvolvimento, inclusive para os países menos desenvolvidos e pequenos Estados insulares em desenvolvimento, para aumentar significativamente a disponibilidade de dados de alta qualidade, atuais e fidedignos, desagregados ao nível do rendimento, género, idade, raça, etnia, estatuto migratório, deficiência, localização geográfica e outras características relevantes em contextos nacionais",
	"meta.ods.17.nombre.19":"17.19 Até 2030, aumentar as iniciativas existentes para desenvolver medidas do progresso do desenvolvimento sustentável que complementem o produto interno bruto [PIB] e apoiem a capacitação estatística nos países em desenvolvimento",
	"sectorlbg.nombre.consumer":"Consumer goods",
	"sectorlbg.nombre.engieering":"Engineering & Manufacturing",
	"sectorlbg.nombre.financial":"Financial Services",
	"sectorlbg.nombre.food":"Food & Drink",
	"sectorlbg.nombre.legal":"Legal",
	"sectorlbg.nombre.medias":"Media",
	"sectorlbg.nombre.natural":"Natural Resources",
	"sectorlbg.nombre.pharmaceiutical":"Pharmaceuticals",
	"sectorlbg.nombre.professional":"Professional services",
	"sectorlbg.nombre.construction":"Property, Construction, Housing, Facilities",
	"sectorlbg.nombre.retail":"Retail",
	"sectorlbg.nombre.technology":"Technology & Telecoms",
	"sectorlbg.nombre.travel":"Travel & Hospitality",
	"sectorlbg.nombre.utilites":"Utilities & Services",
	"area.nombre.educacion":"Educação",
	"area.nombre.salud":"Saúde",
	"area.nombre.desarrollo":"Desenvolvimento socioeconómico",
	"area.nombre.medio.ambiente":"Meio Ambiente",
	"area.nombre.arte":"Arte e Cultura",
	"area.nombre.social":"Bem-estar Social",
	"area.nombre.ayuda":"Ajuda Humanitária",
	"area.nombre.autonomia":"Autonomia pessoal e vida independente",
	"area.nombre.empleo":"Emprego e empreendedorismo",
	"area.nombre.institucional":"Fortalecimento institucional",
	"area.nombre.ciudadania":"Fortalecimento da cidadania ativa",
	"area.nombre.diversidad":"Diversidade e fortalecimento familiar",
	"area.nombre.otros":"Outros",
	"grupo.interes.nombre.clientes":"Clientes / consumidores",
	"grupo.interes.nombre.proveedores":"Fornecedores / distribuidores",
	"grupo.interes.nombre.donantes":"Doadores / patrocinadores",
	"grupo.interes.nombre.otros":"Outras 'partes interessadas",
	"motivacion.nombre.puntual":"Doação pontual",
	"motivacion.nombre.inversion":"Investimento social",
	"motivacion.nombre.alineada":"Iniciativa alinhada com o negócio",
	"motivacion.nombre.propia":"Própria",
	"motivacion.nombre.compartida":"Partilhada",
	"motivacion.nombre.apoyo":"De apoio",
	"ods.nombre.pobreza":"1. Erradicar a pobreza",
	"ods.nombre.hambre":"2. Erradicar a fome",
	"ods.nombre.salud":"3. Saúde de qualidade",
	"ods.nombre.educacion":"4. Educação de qualidade",
	"ods.nombre.genero":"5. Igualdade de género",
	"ods.nombre.saneamiento":"6. Água potável e saneamento",
	"ods.nombre.energia":"7. Energías renováveis e acessíveis",
	"ods.nombre.trabajo":"8. Trabalho digno e crescimento económico",
	"ods.nombre.industria":"9. Indústria, inovação e infraestruturas",
	"ods.nombre.desigualdad":"10. Reduzir as desigualdades",
	"ods.nombre.sostenibilidad":"11. Cidades e comunidades sustentáveis",
	"ods.nombre.consumo":"12. Produção e consumo sustentáveis",
	"ods.nombre.clima":"13. Ação climática",
	"ods.nombre.vida.submarina":"14. Proteger a vida marinha",
	"ods.nombre.vida.terrestre":"15. Proteger a vida terrestre",
	"ods.nombre.paz":"16. Paz, justiça e instituções eficazes",
	"ods.nombre.alianza":"17. Parcerias para a implementação dos objetivos",
	"razon.nombre.obligacion.legal":"Contribuições obrigação legal",
	"razon.nombre.obligacion.contractual":"Contribuições obrigações contratuais",
	"razon.nombre.dirigidas.empleados":"Contribuições dirigidas específicamente a empregados",
	"razon.nombre.dirigidas.deporte":"Contribuições digiridas ao desporto profissional",
	"razon.nombrer.otros":"Outros",
	"situacion.nombre.desempleo":"Em situação de desemprego",
	"situacion.nombre.desempleo.larga":"Em situação de desemprego de longa duração",
	"situacion.nombre.dificultad.aprendizaje":"Com dificuldades de aprendizagem",
	"situacion.nombre.analfabetos":"Sem formação académica ou analfabetos",
	"situacion.nombre.sin.hogar":"Sem-abrigo",
	"situacion.nombre.inmigrantes":"Imigrantes",
	"situacion.nombre.refugiados":"Refugiados",
	"situacion.nombre.presidiario":"Presidiários o ex-presidiários",
	"situacion.nombre.ingresos.bajos":"Com baixos rendimentos",
	"situacion.nombre.minorias":"Pertencem a minorias étnicas",
	"situacion.nombre.pais.desarrollo":"Vivem em países em desenvolvimento",
	"situacion.nombre.vulnerabilidad":"Em situação de vulnerabilidade",
	"situacion.nombre.desastre.natural":"Vítimas de uma catástrofe ou desastre natural",
	"situacion.nombre.enfermedad":"Pessoas com uma doença",
	"situacion.nombre.discapacidad":"Pessoas com deficiência",
	"situacion.nombre.dependencia":"Pessoas com algum tipo de dependência (toxicodependência, alcoolismo, outros vícios)",
	"situacion.nombre.abusos":"Vítimas de abusos / agressão sexual",
	"situacion.nombre.beneficiario":"Beneficiários do projecto",
	"situacion.nombre.otros":"Outros perfis",
	"tipo.empresa.empresa":"Empresa",
	"tipo.empresa.onl":"Organização sem fins lucrativos",
	"tipo.empresa.fundacion":"Fundação empresarial",
	"tipo.empresa.otras":"Outras",
	"tipo.empresa.ong":"ONG",
	"tipo.logro.mujeres.hombres.todos":"Mulheres e homens de todas as idades",
	"tipo.logro.hombres.todos":"Homens (todas as idades)",
	"tipo.logro.mujeres.todas":"Mulheres (todas as idades)",
	"tipo.logro.niño.niñas.todos":"Crianças ( 0-16 anos )",
	"tipo.logro.niño":"Meninos (0-16 anos)",
	"tipo.logro.niña":"Meninas (0-16 anos)",
	"tipo.logro.jovenes.todos":"Jovens rapazes e raparigas (16 -18 anos)",
	"tipo.logro.joven.chico":"Jovens-rapazes (16 -18 anos)",
	"tipo.logro.joven.chica":"Jovens-raparigas (16 -18 anos)",
	"tipo.logro.hombre.mujer.mayor.18":"Homens e mulheres ( >18 anos )",
	"tipo.logro.hombre.mayor.18":"Homens (>18 anos)",
	"tipo.logro.mujere.mayor.18":"Mulheres (>18 anos)",
	"tipo.logro.mujer.hombre.mayor.45":"Homens e mulheres ( >45 anos )",
	"tipo.logro.hombre.mayor.45":"Homens (>45 anos)",
	"tipo.logro.mujer.mayor.45":"Mulheres (>45 anos)",
	"tipo.logro.mujer.hombre.mayor.65":"Homens e mulheres ( >65 anos )",
	"tipo.logro.hombre.mayor.65":"Homens (>65 anos)",
	"tipo.logro.mujer.mayor.65":"Mulheres (>65 anos)",
	"pais.nombre.españa":"Espanha",
	"pais.nombre.francia":"França",
	"pais.nombre.reini.unido":"Reino Unido",
	"pais.nombre.alemania":"Alemanha",
	"pais.nombre.estados.unidos":"Estados Unidos",
	"pais.nombre.mexico":"México",
	"pais.nombre.abjasia":"Abcásia",
	"pais.nombre.afganistan":"Afeganistão",
	"pais.nombre.albania":"Albânia",
	"pais.nombre.andorra":"Andorra",
	"pais.nombre.angola":"Angola",
	"pais.nombre.anguila":"Anguilha",
	"pais.nombre.antigua":"Antígua e Barbuda",
	"pais.nombre.arabia":"Arábia Saudita",
	"pais.nombre.argelia":"Argélia",
	"pais.nombre.argentina":"Argentina",
	"pais.nombre.armenia":"Arménia",
	"pais.nombre.aruba":"Aruba",
	"pais.nombre.australia":"Austrália",
	"pais.nombre.austria":"Áustria",
	"pais.nombre.azerbaiyan":"Azerbaijão",
	"pais.nombre.bahamas":"Baamas",
	"pais.nombre.bangladesh":"Bangladeche",
	"pais.nombre.barbados":"Barbados",
	"pais.nombre.barein":"Bahrein",
	"pais.nombre.belgica":"Bélgica",
	"pais.nombre.belice":"Belize",
	"pais.nombre.benin":"Benim",
	"pais.nombre.bermudas":"Bermudas",
	"pais.nombre.bielorrusia":"Bielorrússia",
	"pais.nombre.birmania":"Birmânia",
	"pais.nombre.bolivia":"Bolívia",
	"pais.nombre.bosnia":"Bósnia-Herzegovina",
	"pais.nombre.botsuana":"Botsuana",
	"pais.nombre.brasil":"Brasil",
	"pais.nombre.brunei":"Brunei",
	"pais.nombre.bulgaria":"Bulgária",
	"pais.nombre.burkina":"Burquina Faso",
	"pais.nombre.burundi":"Burundi",
	"pais.nombre.butan":"Butão",
	"pais.nombre.cabo":"Cabo Verde",
	"pais.nombre.camboya":"Camboja",
	"pais.nombre.camerun":"Camarões",
	"pais.nombre.canada":"Canadá",
	"pais.nombre.caribe":"Caraíbas holandesas",
	"pais.nombre.chad":"Chade",
	"pais.nombre.chile":"Chile",
	"pais.nombre.china":"China",
	"pais.nombre.chipre":"Chipre",
	"pais.nombre.chipre.septentrional":"Norte de Chipre",
	"pais.nombre.ciudad.vaticano":"Cidade do Vaticano",
	"pais.nombre.colombia":"Colômbia",
	"pais.nombre.comoras":"Comores",
	"pais.nombre.corea.norte":"Coreia do Norte",
	"pais.nombre.corea.sur":"Coreia do Sul",
	"pais.nombre.costa.marfil":"Costa do Marfim",
	"pais.nombre.costa.rica":"Costa Rica",
	"pais.nombre.croacia":"Croácia",
	"pais.nombre.cuba":"Cuba",
	"pais.nombre.curazao":"Curaçao",
	"pais.nombre.dinamarca":"Dinamarca",
	"pais.nombre.dominica":"Domínica",
	"pais.nombre.ecuador":"Equador",
	"pais.nombre.egipto":"Egito",
	"pais.nombre.elsalvador":"El Salvador",
	"pais.nombre.emiratos":"Emirados Árabes Unidos",
	"pais.nombre.eritrea":"Eritreia",
	"pais.nombre.eslovaquia":"Eslováquia",
	"pais.nombre.eslovenia":"Eslovénia",
	"pais.nombre.estados.federados.micronesia":"Estados Federados da Micronésia",
	"pais.nombre.estonia":"Estónia",
	"pais.nombre.etiopia":"Etiópia",
	"pais.nombre.filipinas":"Filipinas",
	"pais.nombre.finlandia":"Finlândia",
	"pais.nombre.fiyi":"Fiji",
	"pais.nombre.gabon":"Gabão",
	"pais.nombre.gambia":"Gâmbia",
	"pais.nombre.georgia":"Geórgia",
	"pais.nombre.ghana":"Gana",
	"pais.nombre.gibraltar":"Gibraltar",
	"pais.nombre.granada":"Granada",
	"pais.nombre.grecia":"Grécia",
	"pais.nombre.groenlandia":"Gronelândia",
	"pais.nombre.guadalupe":"Guadalupe",
	"pais.nombre.guam":"Guam",
	"pais.nombre.guatemala":"Guatemala",
	"pais.nombre.guayana":"Guiana Francesa",
	"pais.nombre.guernsey":"Guernsey",
	"pais.nombre.guinea":"Guiné",
	"pais.nombre.guinea.ecuatorial":"Guiné Equatorial",
	"pais.nombre.guinea.bissau":"Guiné-Bissau",
	"pais.nombre.guyana":"Guiana",
	"pais.nombre.haiti":"Haiti",
	"pais.nombre.honduras":"Honduras",
	"pais.nombre.hong.kong":"Hong Kong",
	"pais.nombre.hungría":"Hungria",
	"pais.nombre.india":"Índia",
	"pais.nombre.indonesia":"Indonésia",
	"pais.nombre.irak":"Iraque",
	"pais.nombre.irán":"Irão",
	"pais.nombre.irlanda":"Irlanda",
	"pais.nombre.isla.ascension":"Ilha da Ascensão",
	"pais.nombre.isla.navidad":"Ilha de Natal",
	"pais.nombre.islandia":"Islândia",
	"pais.nombre.islas.caimán":"Ilhas Caimão",
	"pais.nombre.islas.marshall":"Ilhas Marshall",
	"pais.nombre.islas.salomón":"Ilhas Salomão",
	"pais.nombre.islas.turcas y Caicos":"Ilhas Turcas e Caicos",
	"pais.nombre.islas.virgenes.británicas":"Ilhas Virgens Britânicas",
	"pais.nombre.islas.virgenes.estados.unidos":"Ilhas Virgens dos Estados Unidos",
	"pais.nombre.israel":"Israel",
	"pais.nombre.italia":"Itália",
	"pais.nombre.jamaica":"Jamaica",
	"pais.nombre.japon":"Japão",
	"pais.nombre.jordania":"Jordânia",
	"pais.nombre.kazajistan":"Cazaquistão",
	"pais.nombre.kenia":"Quénia",
	"pais.nombre.kirguistan":"Quirguizistão",
	"pais.nombre.kiribati":"Quiribáti",
	"pais.nombre.kosovo":"Kosovo",
	"pais.nombre.kuwait":"Kuwait",
	"pais.nombre.laos":"Laos",
	"pais.nombre.lesoto":"Lesoto",
	"pais.nombre.letonia":"Letónia",
	"pais.nombre.libano":"Líbano",
	"pais.nombre.liberia":"Libéria",
	"pais.nombre.libia":"Líbia",
	"pais.nombre.liechtenstein":"Liechtenstein",
	"pais.nombre.lituania":"Lituânia",
	"pais.nombre.luxemburgo":"Luxemburgo",
	"pais.nombre.madagascar":"Madagáscar",
	"pais.nombre.malasia":"Malásia",
	"pais.nombre.malaui":"Malavi",
	"pais.nombre.maldivas":"Maldivas",
	"pais.nombre.mali":"Mali",
	"pais.nombre.malta":"Malta",
	"pais.nombre.marruecos":"Marrocos",
	"pais.nombre.martinica":"Martinica",
	"pais.nombre.mauricio":"Maurícia",
	"pais.nombre.mauritania":"Mauritânia",
	"pais.nombre.mayotte":"Mayotte",
	"pais.nombre.moldavia":"Moldávia",
	"pais.nombre.mongolia":"Mongólia",
	"pais.nombre.montserrat":"Monserrate",
	"pais.nombre.mozambique":"Moçambique",
	"pais.nombre.namibia":"Namíbia",
	"pais.nombre.nauru":"Nauru",
	"pais.nombre.nepal":"Nepal",
	"pais.nombre.nicaragua":"Nicarágua",
	"pais.nombre.niger":"Níger",
	"pais.nombre.nigeria":"Nigéria",
	"pais.nombre.niue":"Niue",
	"pais.nombre.noruega":"Noruega",
	"pais.nombre.nueva.zelanda":"Nova Zelândia",
	"pais.nombre.oman":"Omã",
	"pais.nombre.paeses.bajos":"Países Baixos",
	"pais.nombre.pakistan":"Paquistão",
	"pais.nombre.palaos":"Palau",
	"pais.nombre.panama":"Panamá",
	"pais.nombre.papua":"Papuásia-Nova Guiné",
	"pais.nombre.paraguay":"Paraguai",
	"pais.nombre.peru":"Peru",
	"pais.nombre.polonia":"Polónia",
	"pais.nombre.portugal":"Portugal",
	"pais.nombre.puerto.rico":"Porto Rico",
	"pais.nombre.qatar":"Qatar",
	"pais.nombre.republica.centroafricana":"República Centro-Africana",
	"pais.nombre.republica.checa":"República Checa",
	"pais.nombre.republica.macedonia":"República da Macedónia",
	"pais.nombre.republica.congo":"República do Congo",
	"pais.nombre.republica.democratica.congo":"República Democrática do Congo",
	"pais.nombre.republica.dominicana":"República Dominicana",
	"pais.nombre.reunion":"Reunião",
	"pais.nombre.ruanda":"Ruanda",
	"pais.nombre.rumania":"Roménia",
	"pais.nombre.rusia":"Rússia",
	"pais.nombre.sahara.occidental":"Saara Ocidental",
	"pais.nombre.samoa":"Samoa",
	"pais.nombre.san.bartolome":"São Bartolomeu",
	"pais.nombre.san.cristobal":"São Cristóvão e Nevis",
	"pais.nombre.san.marino":"São Marino",
	"pais.nombre.san.martin":"São Martinho",
	"pais.nombre.san.pedro":"São Pedro e Miquelon",
	"pais.nombre.san.vicente":"São Vicente e Granadinas",
	"pais.nombre.santa.helena":"Santa Helena",
	"pais.nombre.santa.lucia":"Santa Lúcia",
	"pais.nombre.santo.tome":"São Tomé e Príncipe",
	"pais.nombre.senegal":"Senegal",
	"pais.nombre.serbia":"Sérvia",
	"pais.nombre.seychelles":"Seychelles",
	"pais.nombre.sierra Leona":"Serra Leoa",
	"pais.nombre.singapur":"Singapura",
	"pais.nombre.siria":"Síria",
	"pais.nombre.somalia":"Somália",
	"pais.nombre.somaliland":"Somalilândia",
	"pais.nombre.sri Lanka":"Sri Lanka",
	"pais.nombre.suazilandia":"Suazilândia",
	"pais.nombre.sudafrica":"África do Sul",
	"pais.nombre.sudan":"Sudão",
	"pais.nombre.sudan.sur":"Sudão do Sul",
	"pais.nombre.suecia":"Suécia",
	"pais.nombre.suiza":"Suíça",
	"pais.nombre.surinam":"Suriname",
	"pais.nombre.tailandia":"Tailândia",
	"pais.nombre.tanzania":"Tanzânia",
	"pais.nombre.tayikistan":"Tajiquistão",
	"pais.nombre.togo":"Togo",
	"pais.nombre.tonga":"Tonga",
	"pais.nombre.trinidad y Tobago":"Trindade e Tobago",
	"pais.nombre.trista.cunha":"Tristão da Cunha",
	"pais.nombre.tunez":"Tunísia",
	"pais.nombre.turkmenistan":"Turquemenistão",
	"pais.nombre.turquia":"Turquia",
	"pais.nombre.tuvalu":"Tuvalu",
	"pais.nombre.ucrania":"Ucrânia",
	"pais.nombre.uganda":"Uganda",
	"pais.nombre.uruguay":"Uruguai",
	"pais.nombre.uzbekistan":"Uzbequistão",
	"pais.nombre.vanuau":"Vanuatu",
	"pais.nombre.venezuela":"Venezuela",
	"pais.nombre.vietnam":"Vietname",
	"pais.nombre.wallis":"Wallis e Futuna",
	"pais.nombre.yemen":"Iémen",
	"pais.nombre.yibuti":"Djibuti",
	"pais.nombre.zambia":"Zâmbia",
	"pais.nombre.zimbabue":"Zimbabué",
	"pais.nombre.islas.turcas":"Ilhas Turcas e Caicos",
	"pais.nombre.sierra.leona":"Serra Leoa",
	"pais.nombre.sri.lanka":"Sri Lanka",
	"pais.nombre.trinidad.tobago":"Trindade e Tobago",
	"login.entidad.desactivado":"A sua entidade está actualmente desativada",
	"filterReport.byProjects":"Por projetos",
	"filterReport.informes":"Relatórios",
	"filterReport.tipoInformes":"Tipos de relatórios",
	"filterReport.marcarTodos":"Marcar todos",
	"filterReport.desmarcarTodos":"Desmarcar todos",
	"filterReport.divisa":"Moeda",
	"filterReport.seleccionaDivisa":"Deve selecionar uma moeda",
	"filterReport.proyectos":"Projetos",
	"filterReport.proyectState":"Estado dos projetos",
	"filterReport.divUdNegocio":"Divisão ou Unidade de negócios",
	"filterReport.agrInterna":"Agrupamento interno",
	"filterReport.agrIniciativaInterna":"Agrupamento interno de iniciativas",
	"filterReport.extractProyecto":"Extrair informações sobre orçamentos",
	"filterReport.proyectDate":"Projetos com uma data de início entre",
	"filterReport.desde":"De",
	"filterReport.hasta":"Até",
	"filterReport.y":"E",
	"filterReport.proyectDateEnd":"Projetos com uma data de fim entre",
	"filterReport.iniciativa":"Iniciativa",
	"filterReport.extraerLBG":"Extrair iniciativas em conformidade com LBG/ONLBG",
	"filterReport.pais":"País",
	"filterReport.region":"Região",
	"filterReport.area":"Área de atuação",
	"filterReport.motivacion":"Motivação",
	"filterReport.extractODS":"Extrair informações sobre ODS",
	"filterReport.ods":"ODS primário",
	"filterReport.odsError":"Deve selecionar um ODS Primário se as informações ODS estiverem definidas como Sim.",
	"filterReport.extractInitiative":"Extrair informações sobre orçamentos",
	"filterReport.initiativeDate":"Projetos com uma data de início entre",
	"filterReport.initiativeDateEnd":"Projetos com uma data de fim entre",
	"filterReport.contributions":"Contribuições",
	"filterReport.validados":"Validados",
	"filterReport.atribucion":"Atribuição",
	"filterReport.contributiontype":"Tipo de contribuição",
	"filterReport.contributionLey112018":"Doações a Fundação / Organisações sem fins lucrativos Lei 11/2018",
	"filterReport.logros":"Realizações",
	"filterReport.impactos":"Impactos",
	"filterReport.generate":"Gerar relatório",
	"filterReport.generateCharts":"Gerar gráficos",
	"filterReport.downloadCharts":"Baixar gráficos",
	"filterReport.divisaDelInforme":"Selecionar a moeda do relatório",
	"filterReport.entre":"Entre",
	"filterReport.tipo.contribución":"Tipo de contribuição",
	"filterReport.unidad.negocio":"Unidade de negócios",
	"filterReport.ambito.geografico":"País",
	"filterReport.pais.españa":"País Espanha",
	"filterReport.ODS":"ODS",
	"filterReport.empleados.y.voluntarios":"Empregados e voluntarios",
	"filterReport.efecto.multiplicador":"Efeito multiplicador",
	"filterReport.resultados.comunidad":"Resultados da comunidade",
	"filterReport.resultados.entidad":"Resultados da entidade",
	"filterReport.dinero":"Dinheiro",
	"filterReport.especie":"Espécie",
	"filterReport.tiempo":"Tempo",
	"filterReport.probono":"Pro bono",
	"filterReport.coste.gestion":"Custo de gestão",
	"filterReport.todos":"Todos",
	"filterReport.abiertos":"Abertos",
	"filterReport.cerrados":"Fechados",
	"filterReport.si":"Sim",
	"filterReport.no":"Não",
	"filterReport.entidad":"Entidade",
	"filterReport.otros":"Outros",
	"filterReport.no.validados":"Não Validados",
	"filterReport.todas":"Todas",
	"filterReport.si.cumplen":"Sim Cumprem",
	"filterReport.no.cumplen":"Não Cumprem",
	"filterReport.descarga":"Descarga de dados",
	"filterReport.graficos":"Relatórios na tela",
	"reportChart.activeProyects":"Projetos ativos",
	"reportChart.activeInitiatives":"Iniciativas ativas",
	"reportChart.contributionProyects":"Projetos com contribuição",
	"reportChart.contributionInitiatives":"Iniciativas com contribuição",
	"reportChart.entityContribution":"Contribuição {{ITV}}",
	"reportChart.total":"Totais",
	"reportChart.header.contribType":"Tipo de contribuição",
	"reportChart.header.udNegocio":"Unidade de negócios",
	"reportChart.header.agrIniciativaInterna":"Agrupamento interno de iniciativas",
	"reportChart.header.agrInterna":"Agrupamento interno",
	"reportChart.header.motivation":"Motivação",
	"reportChart.header.area":"Área de atuação",
	"reportChart.header.ambitoGeografico":"País",
	"reportChart.header.ES":"País Espanha",
	"reportChart.header.ods":"ODS",
	"reportChart.contrib.dinero":"Dinheiro",
	"reportChart.contrib.tiempo":"Tempo",
	"reportChart.contrib.especie":"Em espécie",
	"reportChart.contrib.probono":"Pro bono",
	"reportChart.contrib.costeGestion":"Custos de gestão",
	"reportChart.header.pais":"Espanha",
	"reportChart.proyectos":"Projetos",
	"reportChart.iniciativas":"Iniciativas",
	"reportChart.areaActuacion":"Área de atuação",
	"reportChart.proyectosConContribucion":"Projetos com contribuição",
	"reportChart.contribucionTotal":"Contribuição Total",
	"reportChart.contribucionItv":"Contribuição {{ITV}}",
	"reportChart.porcentajeContribucionItv":"Contribuição {{ITV}} %",
	"reportChart.contribucionEntidad":"Contribuição {{ITV}}",
	"reportChart.porcentajeContribucionEntidad":"Contribuição {{ITV}} %",
	"reportChart.areaPrimaria":"Área primária",
	"reportChart.areaSecundaria":"Área secundária",
	"reportChart.odsPrimario":"Como 1º ODS",
	"reportChart.odsSecundario":"Como 2º ODS",
	"reportChart.odsTerciarios":"Como 3º ODS ou mais",
	"reportChart.contributions":"Contribuções",
	"reportChart.efectoMultiplicador":"Efeito multiplicador {{ITV}}",
	"reportChart.efectoMultiplicadorOtros":"Efeito multiplicador outros colaboradores",
	"reportChart.Participacion1":"Participantes (empregados) por parte da entidade: realizações",
	"reportChart.Participacion2":"Participantes (não empregados) por parte da entidade: realizações",
	"reportChart.Participacion3":"Impactos sobre os empregados da entitade",
	"reportChart.Participacion4":"Participação de outros colaboradores: realizações",
	"reportChart.Participacion6":"Impactos sobre os empregados de outros colaboradores",
	"reportChart.proyectosParticipacion":"Projetos com participação",
	"reportChart.iniciativasParticipacion":"Iniciativas com participação",
	"reportChart.numPersonas":"Pessoas",
	"reportChart.numHoras":"Horas",
	"reportChart.porcentaje":"%",
	"reportChart.costeHora":"Custo",
	"reportChart.emplTotal":"Empregados totais",
	"reportChart.emplEval":"Empregados avaliados",
	"reportChart.impactoAlto":"Impacto alto",
	"reportChart.impactoMed":"Impacto médio",
	"reportChart.impactoBajo":"Impacto baixo",
	"reportChart.impactoSinCamb":"Sem mudanças",
	"reportChart.nombreComunidad":"Comunidades",
	"reportChart.numProyectosEfectoMult":"Projetos com efeito multiplicador",
	"reportChart.numIniciativasEfectoMult":"Iniciativas com efeito multiplicador",
	"reportChart.cantidadEfectoMult":"Efeito multiplicador",
	"reportChart.cantidadEfectoMult":"Efeito multiplicador",
	"reportChart.proyectosConLogros":"Projetos avaliados",
	"reportChart.iniciativasConLogros":"Iniciativas avaliadas",
	"reportChart.totalBeneficiarios":"Nº",
	"reportChart.beneficariosTotales":"Beneficiários totais",
	"reportChart.beneficariosEval":"Beneficiários avaliados",
	"reportChart.totalPorGrado":"Nº",
	"reportChart.totalPorTipo":"Nº",
	"reportChart.proyectosEval":"Projetos avaliados",
	"reportChart.iniciativasEval":"Iniciativas avaliadas",
	"reportChart.totalOrganizaciones":"Nº Organizações",
	"reportChart.organizBenefTotal":"Nº total de organizações",
	"reportChart.organizBenefEval":"Organizações avaliadas",
	"reportChart.numLogros":"Nº",
	"reportChart.iniciativasSobreMA":"Impactos sobre o meio ambiente",
	"reportChart.logros":"Realizações",
	"reportChart.impactosGrado":"Impactos - grau",
	"reportChart.impactosTipo":"Impactos - tipo",
	"reportChart.logrosOrganizaciones":"Realizações das organizações beneficiárias",
	"reportChart.impactos":"Impactos",
	"reportChart.beneficiarios":"Beneficiários",
	"reportChart.organizacionesBeneficiarios":"Organizações Beneficiárias",
	"reportChart.medioAmbiente":"Meio Ambiente",
	"reportChart.logrosEntidad":"Realizações de {{ITV}}",
	"reportChart.empleadosEntidad.tiempoRemunerado":"Empregados da entidade em tempo remunerado",
	"reportChart.empleadosEntidad.tiempoNoRemunerado":"Empregados da entidade em tempo não remunerado",
	"reportChart.empleadosEntidad.probono":"Empregados da entidade em atividades pro bono",
	"reportChart.empleadosEntidad.costesGestion":"Empregados da entidade: custos de gestão da iniciativa",
	"reportChart.empleadosEntidad.participacionTotal":"Participação Total",
	"reportChart.noEmpleadosEntidad.familiares":"Não-empregados (familiares, clientes, outros) entidade",
	"reportChart.noEmpleadosEntidad.voluntariadoEstructural":"Não-empregados (voluntariado estrutural) entidade",
	"reportChart.noEmpleadosEntidad.participacionTotal":"Participação Total (não empregados)",
	"reportChart.noEmpleadosOtros.familiares":"Não-empregados (membros da família, clientes, outros) de outros colaboradores",
	"reportChart.noEmpleadosOtros.participacionTotal":"Participação total (não-empregados) de outros colaboradores",
	"reportChart.impactoEmpleados.habilidades":"Melhoraram as suas habilidades profissionais",
	"reportChart.impactoEmpleados.desarrollo":"Melhoraram o seu desenvolvimento pessoal",
	"reportChart.impactoEmpleados.comportamiento":"Tiveram uma mudança positiva no seu comportamento",
	"reportChart.empleadosOtros.tiempoRemunerado":"Empregados de outros colaboradores em tempo remunerado",
	"reportChart.empleadosOtros.tiempoNoRemunerado":"Empregados de outros colaboradores em tempo não remunerado",
	"reportChart.empleadosOtros.probono":"Empregados de outros colaboradores em actividades de pro bono",
	"reportChart.empleadosOtros.costesGestion":"Empregados de outros colaboradores: custos de gestão",
	"reportChart.empleadosOtros.participacionTotal":"Participação total de outros colaboradores",
	"reportChart.efectoMultiplicador.entidad.empleadosPayrollGiving":"Empregados da entitade - payroll giving",
	"reportChart.efectoMultiplicador.entidad.empleadosOtrasDonaciones":"Empregados da entitade - outras doações em dinheiro",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosCash":"Clientes / Consumidores / Outras fontes em dinheiro da entidade",
	"reportChart.efectoMultiplicador.entidad.empleadosEspecie":"Outras doações em espécie dos empregados da entitade",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosEspecie":"Clientes / Consumidores / Outras fontes em espécie da entidade",
	"reportChart.efectoMultiplicador.entidad.Otros":"Outros (Entidade)",
	"reportChart.efectoMultiplicador.entidad.Total":"Total (Entidade)",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosPayrollGiving":"Empregados Outros colaboradores - payroll giving",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosOtrasDonaciones":"Empregados Outros colaboradores - outras doações em dinheiro",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosCash":"Clientes / Consumidores / Outras fontes de dinheiro de outros colaboradores",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosEspecie":"Outras doações em espécie de empregados de outros colaboradores",
	"reportChart.efectoMultiplicador.entidad.empleadosValorTrabajoVoluntariado":"Empregados da entidade - valor do trabalho voluntário",
	"reportChart.efectoMultiplicador.entidad.noEmpleadosVoluntariadoEstructural":"Não empregados - voluntariado estrutural",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosValorTrabajoVoluntariado":"Empregados Outros colaboradores - valor do trabalho voluntário",
	"reportChart.efectoMultiplicador.otrosColaboradores.noEmpleadosVoluntariadoEstructural":"Não empregados - voluntariado estrutural",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosEspecie":"Clientes / Consumidores / Outras fontes em espécie de outros colaboradores",
	"reportChart.efectoMultiplicador.otrosColaboradores.Otros":"Outros (Outros colaboradores)",
	"reportChart.efectoMultiplicador.otrosColaboradores.Total":"Total (Outros colaboradores)",
	"reportChart.logros.grado.sinCambios":"Não foram sensibilizados",
	"reportChart.logros.grado.sensibilizados":"Foram sensibilizados como resultado da iniciativa",
	"reportChart.logros.grado.mejorados":"Conseguiram uma melhoria como resultado da iniciativa",
	"reportChart.logros.grado.transformados":"Conseguiram uma transformação como resultado da iniciativa",
	"reportChart.logros.tipo.comportamiento":"Experimentaram uma mudança positiva no seu comportamento ou atitude",
	"reportChart.logros.tipo.habilidades":"Adquiriram novas habilidades ou melhoraram o seu desenvolvimento pessoal",
	"reportChart.logros.tipo.calidadDeVida":"Experimentaram um impacto positivo na sua qualidade de vida",
	"reportChart.medioambiente.directos":"Número de iniciativas com benefícios ambientais",
	"reportChart.medioambiente.indirectos":"Número de iniciativas que mudaram o comportamento ambiental das pessoas",
	"salarytables.descripcion":"Descrição",
	"collaborator.form.descripcion":"Descrição",
	"contribuyente.colaborador":"Colaborador",
	"contribuyente.payroll":"Payroll",
	"contribuyente.otras.aportacion.empleado":"Empregado",
	"contribuyente.otras.aportacion.cliente":"Cliente",
	"contribuyente.otros":"Outros",
	"contribuyente.especie":"Espécie",
	"contribuyente.clientes":"Clientes",
	"contribuyente.empleado.remunerado":"Empregado remunerado",
	"contribuyente.empleado.no.remunerado":"Empregado não remunerado",
	"contribuyente.no.empleado.familiar":"Familiar",
	"contribuyente.no.empleado.voluntario":"Voluntário",
	"contribuyente.tabla.salarial":"Quadro de contribuições",
	"contribuyente.probono":"Pro bono",
	"user.list.filter.active":"Ativo",
	"user.list.filter.inactive":"Inativo",
	"placeholder.impact.select.write.here":"Escreva aqui para filtrar",
	"atribution.label.all":"Todos",
	"atribution.label.e":"E",
	"atribution.label.ef":"EF",
	"atribution.label.o":"O",
	"contributions.num.persons":"Número de pessoas",
	"contributions.salary.table":"Quadro de contribuições",
	"contributions.atribution.entity":"Entidade",
	"contributions.atribution.leverage":"Efeito Multiplicador",
	"contributions.atribution.others":"Outros",
	"contributions.atribution.entity.abbreviation":"E",
	"contributions.atribution.others.abbreviation":"O",
	"contributions.atribution.leverage.abbreviation":"EF",
	"contributions.atribution.all":"Todos",
	"contributions.atribution.all.abbreviation":"Todos",
	"manual-altaUsuarios":"https://manual.masimpact.com/pt/3/menu_principal/2/gest%C3%A3o/1/registro_de_usuarios",
	"manual-altaColaboradores":"https://manual.masimpact.com/pt/3/menu_principal/2/gest%C3%A3o/2/registro_de_colaboradores",
	"manual-divisionYAgrupacion":"https://manual.masimpact.com/pt/3/menu_principal/3/projetos/2/divis%C3%A3o_administrativa_e_agrupamento_interno",
	"manual-presupuesto":"https://manual.masimpact.com/pt/3/menu_principal/3/projetos/3/or%C3%A7amento_do_projeto",
	"manual-asignacionUsuarios":"https://manual.masimpact.com/pt/3/menu_principal/3/projetos/4/atribui%C3%A7%C3%A3o_de_usuarios_em_projetos",
	"manual-nuevaIniciativa":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/1/nova_iniciativa",
	"manual-areaActuacion":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/2/area_de_atua%C3%A7%C3%A3o",
	"manual-motivacion":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/3/motiva%C3%A7%C3%A3o_da_entidade",
	"manual-entidadesQueContribuyen":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/4/colaboradores_da_iniciativa",
	"manual-principiosLBG":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/5/principios_de_lbgonlbg",
	"manual-objetivosDesarrollo":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/6/objetivos_de_desenvolvimento_sustentavel",
	"manual-presupuestosEntidades":"https://manual.masimpact.com/pt/3/menu_principal/4/iniciativas/7/or%C3%A7amento_da_entidade",
	"manual-contribuciones":"https://manual.masimpact.com/pt/3/menu_principal/5/contribui%C3%A7%C3%B5es/2/contribuintes",
	"manual-beneficiarios":"https://manual.masimpact.com/pt/3/menu_principal/6/realiza%C3%A7%C3%B5es/2/beneficiarios",
	"manual-logrosMedioAmbiente":"https://manual.masimpact.com/pt/3/menu_principal/6/realiza%C3%A7%C3%B5es/3/realiza%C3%A7%C3%B5es_ambientais",
	"manual-organizacionesBeneficiarias":"https://manual.masimpact.com/pt/3/menu_principal/6/realiza%C3%A7%C3%B5es/4/organiza%C3%A7%C3%B5es_beneficiarias",
	"manual-logrosParaEntidades":"https://manual.masimpact.com/pt/3/menu_principal/6/realiza%C3%A7%C3%B5es/5/realiza%C3%A7%C3%B5es_para_entidades",
	"manual-cumplimientoObjetivos":"https://manual.masimpact.com/pt/3/menu_principal/6/realiza%C3%A7%C3%B5es/6/cumprir_os_objetivos",
	"manual-impactosSobreBeneficiarios":"https://manual.masimpact.com/pt/3/menu_principal/7/impactos/2/impacto_sobre_os_beneficiarios_",
	"manual-impactosSobreMedioAmbiente":"https://manual.masimpact.com/pt/3/menu_principal/7/impactos/3/impacto_ambientais",
	"manual-impactosSobreVoluntarios":"https://manual.masimpact.com/pt/3/menu_principal/7/impactos/4/impacto_sobre_os_empregados_ou_voluntarios",
	"manual-impactosSobreEntidades":"https://manual.masimpact.com/pt/3/menu_principal/7/impactos/5/impactos_sobre_as_entidades",
	"activity-history.log-tipo.create":"Criado",
	"activity-history.log-tipo.read":"Acessado",
	"activity-history.log-tipo.update":"Atualizado",
	"activity-history.log-tipo.delete":"Eliminado",
	"activity-history.log-tipo.duplicate":"Duplicado",
	"activity-history.seleccion-tipo":"Elementos...",
	"activity-history.seleccion-autor":"Usuários...",
	"activity-history.seleccion-acciones":"Ações...",
	"activity-history.seleccion-acciones-detalle":"Ações detalhadas...",
	"activity-history.seleccion-tipo-explicacion":"Filtrar por categoria",
	"activity-history.log-item.entidad":"Entidade",
	"activity-history.log-item.usuario":"Usuário",
	"activity-history.log-item.proyecto":"Projeto",
	"activity-history.log-item.proyecto_adjuntos":"Projeto_anexos",
	"activity-history.log-item.proyecto_usuarios":"Projeto_usuários",
	"activity-history.log-item.iniciativa":"Iniciativa",
	"activity-history.log-item.iniciativa_lbg":"Iniciativa_LBG",
	"activity-history.log-item.iniciativa_adjuntos":"Iniciativa_anexos",
	"activity-history.log-item.iniciativa_usuarios":"Iniciativa_usuários",
	"activity-history.log-item.contribucion":"Contribuição",
	"activity-history.log-item.contribucion_adjuntos":"Contribuição_anexos",
	"activity-history.log-item.contribucion_permisos":"Contribuição_permissões",
	"activity-history.log-item.logro":"Realização",
	"activity-history.log-item.logro_adjuntos":"Realização_anexos",
	"activity-history.log-item.logro_permisos":"Realização_permissões",
	"activity-history.log-item.impacto":"Impacto",
	"activity-history.log-item.impacto_adjuntos":"Impacto_anexos",
	"activity-history.log-item.impacto_permisos":"Impacto_permissões",
	"activity-history.log-item.colaborador":"Colaborador",
	"activity-history.log-item.documento":"Documento",
	"activity-history.log-item.tipocambio":"TaxaCâmbio",
	"activity-history.log-item.tablasalarial":"QuadroContribuições",
	"activity-history.log-action.newproject":"Criado novo projeto",
	"activity-history.log-action.loadproject":"Carregado dados projeto",
	"activity-history.log-action.updateproject":"Atualizado projeto",
	"activity-history.log-action.deleteproject":"Eliminado projeto",
	"activity-history.log-action.closeproject":"Fechado projeto",
	"activity-history.log-action.deleteinitiative":"Eliminado iniciativa",
	"activity-history.log-action.updateinitiative":"Atualizado iniciativa",
	"activity-history.log-action.loadinitiative":"Carregado dados projeto",
	"activity-history.log-action.newinitiative":"Criado nova iniciativa",
	"activity-history.log-action.listallinitiatives":"Carregado lista iniciativas",
	"activity-history.log-action.listallprojects":"Carregado lista projetos",
	"activity-history.log-action.attachfiletoentity":"Anexado arquivo a entidade",
	"activity-history.log-action.attachfiletocollaborator":"Anexado arquivo a colaborador",
	"activity-history.log-action.attachfiletoproject":"Anexado arquivo a projeto",
	"activity-history.log-action.readattachedfile":"Lido arquivo anexo",
	"activity-history.log-action.newcontribution":"Criado nova contribuição",
	"activity-history.log-action.loadcontribution":"Carregado dados contribuição",
	"activity-history.log-action.updatecontribution":"Atualizado contribuição",
	"activity-history.log-action.deletecontribution":"Eliminado contribuição",
	"activity-history.log-action.listallcontributions":"Carregado lista contribuições",
	"activity-history.log-action.listattachedfiles":"Carregado lista anexos",
	"activity-history.log-action.deletefile":"Eliminado arquivo anexo",
	"activity-history.log-action.openproject":"Aberto projeto",
	"activity-history.log-action.attachfiletoinitiative":"Anexado arquivo à iniciativa",
	"activity-history.log-action.attachfiletocontribution":"Anexado arquivo à contribuição",
	"activity-history.log-action.attachfiletooutput":"Anexado arquivo à realização",
	"activity-history.log-action.attachfiletoimpact":"Anexado arquivo ao impacto",
	"activity-history.log-action.attachfiletosalarytable":"Anexado arquivo à tabela salarial",
	"activity-history.log-action.attachfiletosalaryexchangerate":"Anexado arquivo a taxa de câmbio",
	"activity-history.log-action.invalidatecontribution":"Invalidado contribuição",
	"activity-history.log-action.validatecontribution":"Validado contribuição",
	"activity-history.log-action.newoutput":"Criado nova realização",
	"activity-history.log-action.loadoutput":"Carregado dados realização",
	"activity-history.log-action.updateoutput":"Atualizado realização",
	"activity-history.log-action.deleteoutput":"Eliminado realização",
	"activity-history.log-action.listalloutputs":"Carregado lista realizações",
	"activity-history.log-action.validateoutput":"Validado realização",
	"activity-history.log-action.invalidateoutput":"Invalidado realização",
	"activity-history.log-action.newimpact":"Criado novo impacto",
	"activity-history.log-action.loadimpact":"Carregado dados impacto",
	"activity-history.log-action.updateimpact":"Atualizado lista impactos",
	"activity-history.log-action.deleteimpact":"Eliminado impacto",
	"activity-history.log-action.listallimpacts":"Carregado lista impactos",
	"activity-history.log-action.validateimpact":"Validado impacto",
	"activity-history.log-action.invalidateimpact":"Invalidado impacto",
	"activity-history.dialog.title":"Histórico de atividades",
	"descripcion.demasiado.larga":"Descrição muito longa. O número máximo de caracteres é de 16383",
	"reportChart.noEmpleadosOtros.voluntariadoEstructural":"Não-empregados (voluntariado estrutural) de outros colaboradores",
	"session.autoExpire":"A sessão irá expirar automaticamente em",
	"session.autoExpireSeconds":"segundos.",
	"session.autoExpireContinue":"Continue a sessão",
	"contributions.saveContribucion":"Salvar contribuição",
	"contributions.saveContribucions":"Salvar contribuições",
	"contributions.confirmationMessage":"Você tem edições não salvas. Você deseja sair?",
	"contributions.mensajerevisar":"Revise e preencha os campos não preenchidos.",
	"rating.showAllLanguages":"Mostrar todos os idiomas",
	"user.usuariosDisponibles":"Usuários Disponíveis:",
	"plan.uso.requerido.usuarios":"Você deve indicar o número de usuários",
	
	"reportChart.mapReportEfectoMul.entidad.Otros":"Outros {{ITV}}",
	"reportChart.mapReportEfectoMul.entidad.Total":"Total {{ITV}}",
	
	"reportChart.mapReportEmpVolSubtitle1":"Participantes (empregados) da entidade",
	"reportChart.mapReportEmpVolSubtitle2":"Participantes (não-empregados) en nome da entidade",
	"reportChart.mapReportEmpVolSubtitle3":"Impactos sobre os empregados/voluntários da entidade",
	"reportChart.mapReportEmpVolSubtitle4":"Participantes de outros colaboradores",
	"reportChart.mapReportEmpVolSubtitle5":"Participantes externos de outros colaboradores",
	"reportChart.mapReportEmpVolSubtitle6":"Impactos sobre os empregados/voluntários de outros colaboradores",
	"reportChart.mapReportEfectoMul1":"Efeito Multiplicador",
	"reportChart.mapReportEfectoMul2":"Efeito Multiplicador",
	"reportChart.mapReportrescom1":"Realizações: Beneficiários",
	"reportChart.mapReportrescom2":"Impactos - Grau: Beneficiários",
	"reportChart.mapReportrescom3":"Impactos - Tipo: Beneficiários",
	"reportChart.mapReportrescom4":"Realizações: Organizações beneficiárias",
	"reportChart.mapReportrescom5":"Realizações das Organizações beneficiárias",
	"reportChart.mapReportrescom6":"Impactos: Organizações beneficiárias",
	"reportChart.mapReportrescom7":"Realizações: Ambientais",
	"reportChart.mapReportrescom8":"Impactos: Ambientais",
	"reportChart.mapReportresent1":"Realizações: na Entidade",
	"reportChart.mapReportresent2":"Impactos: na Entidade",
	"reportChart.header.logeimp":"Resultados: Empregados e Voluntários",
	"reportChart.header.efecto":"Efeito Multiplicador",
	"reportChart.header.rescom":"Resultados na Comunidade: Realizações e Impactos",
	"reportChart.header.resent":"Resultados da Entidade: Realizações e Impactos",
	"reportChart.header.sensibilizados":"Sensibilizados",
	"reportChart.header.mejorados":"Melhorados",
	"reportChart.header.transformados":"Transformados",
	"manual-descargaDatos":"https://manual.masimpact.com/pt/3/menu_principal/8/relatorios/1/baixar_dados_em_excel",
	"manual-informesPantalla":"https://manual.masimpact.com/pt/3/menu_principal/8/relatorios/2/relatorios_na_tela",
	"pais.nombre.global":"Global",
	"filterReport.byInitiatives":"Por iniciativas",
	"pdf.download":"Baixar PDF",
	"initiativeform.changeproyect":"Mover iniciativa do projeto",
	"contribucions.formajuridica":"Forma legal"
})