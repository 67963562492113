(function () {
  angular
    .module('app')
    .component('breadcrumb', {
      templateUrl: 'components/breadcrumb/breadcrumb.html',
      controller: BreadcrumController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function BreadcrumController($state, $scope, $stateParams, BreadcrumService) {
    var vm = this;

    vm.raiz;
    vm.anidamiento1;
    vm.anidamiento2;
    vm.anidamiento3;

    vm.stateRaiz;
    vm.paramsRaiz;

    vm.stateAnidamiento1;
    vm.paramsAnidamiento1;

    vm.stateAnidamiento2;
    vm.paramsAnidamiento2;

    vm.stateAnidamiento3;
    vm.paramsAnidamiento3;

    vm.idProyect;
    vm.idInitiative;

    vm.proyectCode;
    vm.initiativeCode;

    $scope.$watch(function () {
      return $state.$current.name
    }, function (newVal, oldVal) {
      if (newVal.indexOf("manage") != -1) {
        vm.raiz = "breadcrumb.gestion"
      }

      if (newVal.indexOf("entity") != -1) {
        vm.anidamiento1 = "breadcrumb.entidades";
        vm.anidamiento2 = null;
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento2 = $stateParams.id ? 'breadcrumb.nuevaEntidad' : 'breadcrumb.editarEntidad';
          vm.stateAnidamiento1 = "manage.entity";
          vm.paramsAnidamiento1 = null;
        }
      }

      if (newVal.indexOf("user") != -1) {
        vm.anidamiento1 = "breadcrumb.usuarios";
        vm.anidamiento2 = null;
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento2 = $stateParams.id ? 'userform.editarUsuario' : 'userform.nuevoUsuario'
          vm.stateAnidamiento1 = "manage.user";
          vm.paramsAnidamiento1 = null;
        }
      }

      if (newVal.indexOf("plan") != -1) {
        vm.anidamiento1 = "breadcrumb.planesUso";
        vm.anidamiento2 = null;
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento2 = $stateParams.id ? 'planform.edit' : 'planform.new'
          vm.stateAnidamiento1 = "manage.plan";
          vm.paramsAnidamiento1 = null;
        }
      }

      if (newVal.indexOf("exchangeRate") != -1) {
        vm.anidamiento1 = "breadcrumb.tiposCambio";
        vm.anidamiento2 = null;
        if (newVal.indexOf("Form") != -1) {
          vm.anidamiento2 = $stateParams.id ? 'exchangerateform.edit' : 'exchangerateform.new'
          vm.stateAnidamiento1 = "manage.exchangeRate";
          vm.paramsAnidamiento1 = null;
        }
      }

      if (newVal.indexOf("collaborators") != -1) {
        vm.anidamiento1 = "breadcrumb.colaboradores";
        vm.anidamiento2 = null;
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento2 = $stateParams.id ? 'collaboratorform.edit' : 'collaboratorform.new'
          vm.stateAnidamiento1 = "manage.collaborators";
          vm.paramsAnidamiento1 = null;
        }
      }


      if (newVal.indexOf("salarytables") != -1) {
        vm.raiz = "entitytoolbar.perfil";
        vm.anidamiento1 = 'entitytoolbar.tablasSalarial'
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento2 = $stateParams.id ? 'salarytablesform.edit' : 'salarytablesform.new'
          vm.stateAnidamiento1 = "entitytoolbar.salarytables"
          vm.paramsAnidamiento1 = null;
        }

      }

      if (newVal.indexOf("rating") != -1) {
        vm.raiz = "entitytoolbar.perfil";
        vm.anidamiento1 = 'entitytoolbar.clasificacion'

      }

      if (newVal.indexOf("data") != -1) {
        vm.raiz = "entitytoolbar.perfil";
        vm.anidamiento1 = 'entitytoolbar.datos'
      }


      if (newVal.indexOf("proyectform") != -1) {
        vm.raiz = "breadcrumb.proyectos"
        vm.anidamiento1 = $stateParams.id && $stateParams.duplicate == 'false' ? 'proyectform.edit' : 'proyectform.new'

        vm.stateRaiz = "proyect";
        vm.paramsRaiz = null;

        vm.stateAnidamiento1 = null;
        vm.paramsAnidamiento1 = null;

        vm.stateAnidamiento2 = null;
        vm.paramsAnidamiento2 = null;
      }

      if (newVal.indexOf("initiativeresume") != -1) {
        vm.idProyect = $stateParams.idproyect;
        vm.idInitiative = $stateParams.id;
        vm.getCodes().then(function (res) {
          if (res) {
            vm.raiz = res.codigoProyecto
            vm.anidamiento1 = res.codigoIniciativa
          }
        })
        vm.anidamiento2 = "breadcrumb.resumen";

        vm.stateRaiz = "proyect";
        vm.paramsRaiz = null;

        vm.idProyect = $stateParams.idproyect;

        vm.stateAnidamiento1 = "initiative";
        vm.paramsAnidamiento1 = {id: vm.idProyect};

        vm.stateAnidamiento2 = null;
        vm.paramsAnidamiento2 = null;
      }
      else if (newVal.indexOf("resume") != -1) {

        //TODO revisar, no funciona

        vm.idProyect = $stateParams.id;
        vm.getCodes().then(function (res) {
          if (res) {
            vm.raiz = res.codigoProyecto
          }
        })
        vm.raiz = vm.proyectCode;
        vm.anidamiento1 = "breadcrumb.resumen";

        vm.stateRaiz = "proyect";
        vm.paramsRaiz = null;

        vm.stateAnidamiento1 = null;
        vm.paramsAnidamiento1 = null;

        vm.stateAnidamiento2 = null;
        vm.paramsAnidamiento2 = null;
      }
      else if (newVal.indexOf("initiative") != -1) {
        if (newVal.indexOf("form") != -1) {
          vm.idProyect = $stateParams.idproyect;

          vm.getCodes().then(function (res) {
            if (res) {
              vm.raiz = res.codigoProyecto
            }
          })

          var isEdit = $stateParams.id && $stateParams.duplicate == 'false';

          vm.anidamiento1 = "breadcrumb.iniciativas";
          vm.anidamiento2 = isEdit ? 'initiativeform.edit' : 'initiativeform.new';

          vm.stateRaiz = "proyect";
          vm.paramsRaiz = null;

          vm.stateAnidamiento1 = "initiative";
          vm.paramsAnidamiento1 = {id: vm.idProyect};

          vm.stateAnidamiento2 = null;
          vm.paramsAnidamiento2 = null;

        }
        else {
          vm.idProyect = $stateParams.id;

          vm.getCodes().then(function (res) {
            if (res) {
              vm.raiz = res.codigoProyecto
            }
          })
          vm.anidamiento1 = "breadcrumb.iniciativas";
          vm.anidamiento2 = null;

          vm.stateRaiz = "proyect";
          vm.paramsRaiz = null;

          vm.stateAnidamiento1 = null;
          vm.paramsAnidamiento1 = null;

          vm.stateAnidamiento2 = null;
          vm.paramsAnidamiento2 = null;
        }
      }

      if (newVal.indexOf("measurement") != -1) {
        vm.idProyect = $stateParams.idproyect;
        vm.idInitiative = $stateParams.id;
        vm.getCodes().then(function (res) {
          if (res) {
            vm.raiz = res.codigoProyecto
            vm.anidamiento1 = res.codigoIniciativa
          }
        })
        vm.anidamiento2 = null;

        vm.stateRaiz = "proyect";
        vm.paramsRaiz = null;

        vm.stateAnidamiento1 = "initiative";
        vm.paramsAnidamiento1 = {id: vm.idProyect};

        vm.stateAnidamiento2 = null;
        vm.paramsAnidamiento2 = null;
      }

      if (newVal.indexOf("contributions") != -1) {
        vm.idProyect = $stateParams.idproyect;
        vm.idInitiative = $stateParams.id;
        vm.anidamiento2 = "breadcrumb.contribuciones";
      }

      if (newVal.indexOf("achievements") != -1) {
        vm.idProyect = $stateParams.idproyect;
        vm.idInitiative = $stateParams.id;
        vm.anidamiento2 = "breadcrumb.logros";
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento3 = $stateParams.editId && $stateParams.duplicate == 'false' ? 'achievementsform.new' : 'achievementsform.edit';
          vm.stateAnidamiento2 = "measurement.achievements"
        }
        else {
          vm.anidamiento3 = null;
          vm.stateAnidamiento2 = null;
        }
        vm.paramsAnidamiento2 = null;

      }

      if (newVal.indexOf("impacts") != -1) {
        vm.idProyect = $stateParams.idproyect;
        vm.idInitiative = $stateParams.id;
        vm.anidamiento2 = "breadcrumb.impactos";
        if (newVal.indexOf("form") != -1) {
          vm.anidamiento3 = $stateParams.editId && $stateParams.duplicate == 'false' ? 'impactsform.edit' : 'impactsform.new';
          vm.stateAnidamiento2 = "measurement.impacts"
        }
        else {
          vm.anidamiento3 = null;
          vm.stateAnidamiento2 = null;
        }
        vm.paramsAnidamiento2 = null;
      }

    })

    vm.getCodes = function () {
      return BreadcrumService.getProyectCode(vm.idProyect, vm.idInitiative).then(function (res) {
        return res;
      })
    }

    vm.clickRaiz = function () {
      if (vm.stateRaiz) {
        $state.go(vm.stateRaiz, vm.paramsRaiz);
      }
    }

    vm.clickAnidamiento1 = function () {
      if (vm.stateAnidamiento1) {
        $state.go(vm.stateAnidamiento1, vm.paramsAnidamiento1);
      }
    }

    vm.clickAnidamiento2 = function () {
      if (vm.stateAnidamiento2) {
        $state.go(vm.stateAnidamiento2, vm.paramsAnidamiento2);
      }
    }

    vm.clickAnidamiento3 = function () {
      if (vm.stateAnidamiento3) {
        $state.go(vm.stateAnidamiento3, vm.paramsAnidamiento3);
      }
    }
  }
})();
