(function() {
  'use strict';

  angular.module('app')
  .service('ConfirmDeleteService', ConfirmDeleteService);

  /* @ngInject */
  function ConfirmDeleteService($mdDialog, $translate) {
    return {
      showDialog: showDialog
    }

    function showDialog(event, title, text) {
      var dialogTitle = title || $translate.instant("confirmdelete.confirmarBorrado");
      var dialogText = text || $translate.instant("confirmdelete.estasSeguro");
      var acceptText = $translate.instant("yes");
      var cancelText = $translate.instant("no");
      var dialog = $mdDialog.confirm()
        .title(dialogTitle)
        .textContent(dialogText)
        .ariaLabel(dialogTitle)
        .targetEvent(event)
        .ok(acceptText)
        .cancel(cancelText);

      // esto devuelve una promesa
      // a la que luego puede hacerse un .then()
      return $mdDialog.show(dialog)
    }
  }
})();
