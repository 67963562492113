(function() {
  'use strict';

  angular
    .module('app')
    .component('pieChart', {
      template: '<div class="pie-chart"></div>',
      controller: ControllerController,
      controllerAs: '$ctrl',
      bindings: {
        data: '<',
        options: '<'
      },
    });

  function ControllerController($window, $element) {
    var $ctrl = this;
    var c3 = $window.c3;

    ////////////////

    $ctrl.$onInit = function() {
      var dataVals = $ctrl.data.slice(0)
      var typeVals = dataVals.pop()
      var values = dataVals.map(function(x){return parseInt(x["value"])})
      var total = values.reduce(function(a,b){return a+b},0)
      var newDataVals = values.map(function(x){return (x/total).toFixed(3)})
      var newValues = dataVals.map(function(x,i){return {label:x.label,value:parseInt(newDataVals[i]*10000)}})
      var options = $ctrl.options || {}
      var filetype = transformTypeFormat(typeVals)

      $ctrl.chart = c3.generate({
        bindto: $element[0].querySelector(".pie-chart"),
        size: {
          width: options.width,
          height: options.height
        },
        data: {
          type: transformTypeFormat(typeVals),
          columns: transformToColumnsFormat(newValues,filetype),
          colors: options.colorMap,
          labels: {
            values: newDataVals,
            format: function (x) { return (x/10000).toLocaleString("es", {style: 'percent', minimumFractionDigits: 1})}
          },
        },
        axis: {
          rotated: transformAxis(typeVals),
          y: {
            tick: {
              format: function (x) { return (x/10000).toLocaleString("es", {style: 'percent', minimumFractionDigits: 1})}
              }
            }
          }
      })

    };

    $ctrl.$onChanges = function(changesObj) {
      var dataVals = $ctrl.data.slice(0)
      var typeVals = dataVals.pop()
      var values = dataVals.map(function(x){return parseInt(x["value"])})
      var total = values.reduce(function(a,b){return a+b},0)
      var newDataVals = values.map(function(x){return (x/total).toFixed(3)})
      var newValues = dataVals.map(function(x,i){return {label:x.label,value:parseInt(newDataVals[i]*10000)}})
      var options = $ctrl.options || {}
      var filetype = transformTypeFormat(typeVals)

      $ctrl.chart = c3.generate({
        bindto: $element[0].querySelector(".pie-chart"),
        size: {
          width: options.width,
          height: options.height
        },
        data: {
          type: filetype,
          columns: transformToColumnsFormat(newValues,filetype),
          colors: options.colorMap,
          labels: {
            values: newDataVals,
            format: function (x) { return (x/10000).toLocaleString("es", {style: 'percent'})}
          }
        },
        axis: {
          rotated: transformAxis(typeVals),
          y: {
            tick: {
              format: function (x) { return (x/10000).toLocaleString("es", {style: 'percent'})}

              }
            }
          }
      })
    };
    $ctrl.$onDestroy = function() {
      $ctrl.chart.destroy()
    };

    function transformToColumnsFormat(data,type) {
      if (!data) {
        return []
      }

      return data.map(function(item) {
          return [item.label, item.value]
      })
    }

    function transformTypeFormat(data) {
      if (!data) {
        return "bar"
      }
      return data.type
    }

    function transformAxis(data) {
      if (data["type"] == "bar") {
        return true
      }
      return false
    }

  }
})();
