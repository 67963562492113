(function () {
  'use strict';

  angular
    .module('app')
    .service('UserService', UserService);

  /* @ngInject */
  function UserService($http, config, TokenService) {
    var api = config.api + "/api";

    var service = {
      login: login,
      getUsername: getUsername,
      userIsLogged: userIsLogged,
      userHasRole: userHasRole,
      getUserRole: getUserRole,
      fetchUserData: fetchUserData,
      fetchLogo: fetchLogo,
      getUserData: getUserData,
      setUserData: setUserData,
      getLogo: getLogo,
      logOut: logOut,
      changeDivisa: changeDivisa,
      changeLang: changeLang,
	  changeToggleOption: changeToggleOption,
	  getToggleOption: getToggleOption,
	  getHubspotToken: getHubspotToken
    }

    function login(model) {
      var url = api + "/authenticate";
      return $http.post(url, model)
        .then(function (res) {
          return res.data
        });
    }

    function getUsername() {
      var tokenData = TokenService.getTokenData();
      return userIsLogged() ? tokenData.sub : '';
    }

    function userIsLogged() {
      var tokenData = TokenService.getTokenData();
      if (!tokenData) {
        return false;
      }
      var tokenTimestamp = tokenData.exp * 1000;
      return new Date(tokenTimestamp) > new Date();
    }

    function userHasRole(role) {
      var tokenData = TokenService.getTokenData();
      if (!tokenData) {
        return false;
      }
      var roles = tokenData.auth.split(",");
      if (roles.indexOf("ROLE_ADMIN") !== -1) {
        return true;
      }
      return roles.indexOf(role) !== -1;
    }
	function changeToggleOption(optionName)
	{
		var optionId = 0;
		switch(optionName)
		{
			case 'proyect_entity_budget': optionId = 0; break;
			case 'initiative_entity_budget': optionId = 1; break;
			case 'achievement_indirect_beneficiaries': optionId = 2; break;
			case 'achievement_environmental': optionId = 3; break;
			case 'achievement_ggii': optionId = 4; break;
			case 'achievement_alliances': optionId = 5; break;
			case 'achievement_media': optionId = 6; break;
			case 'achievement_objetives': optionId = 7; break;
			case 'impact_beneficiaries': optionId = 8; break;
			case 'impact_environment': optionId = 9; break;
			case 'impact_employees': optionId = 10; break;
			case 'impact_participating_entities': optionId = 11; break;
		}
		
		var actualOption = getUserData().toggleOptions.split('|')[optionId];
		var newOption = '1';
		if (Boolean(parseInt(actualOption)) == true)
			newOption = '0';
		else
			newOption = '1';
			
		var new_options = "";
		for(var i=0;i<getUserData().toggleOptions.split("|").length;i++) {
			if (i == optionId)
				new_options = new_options + newOption + "|";
			else            
				new_options = new_options + getUserData().toggleOptions.split("|")[i] + "|";
		}
		
		new_options = new_options.substring(0,new_options.length - 1);			
		
		var newUserData = getUserData();
		newUserData.toggleOptions = new_options;
		
		setUserData(newUserData);
		
		var url = config.api + "/api/usuarios/change-toggle-option/" + optionId;
		return $http.put(url).then(function (response) {
			return response.data;
		});
	}
	
	
	function getToggleOption(optionName)
	{
		var toggleOption = false;
		switch(optionName)
		{
			case 'proyect_entity_budget': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[0])); break;
			case 'initiative_entity_budget': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[1])); break;
			case 'achievement_indirect_beneficiaries': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[2])); break;
			case 'achievement_environmental': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[3])); break;
			case 'achievement_ggii': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[4])); break;
			case 'achievement_alliances': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[5])); break;
			case 'achievement_media': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[6])); break;
			case 'achievement_objetives': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[7])); break;
			case 'impact_beneficiaries': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[8])); break;
			case 'impact_environment': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[9])); break;
			case 'impact_employees': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[10])); break;
			case 'impact_participating_entities': toggleOption = Boolean(parseInt(getUserData().toggleOptions.split('|')[11])); break;
		}
		return toggleOption;
	}

    function getUserRole() {
      if (getUserData() && getUserData().rol) {
        return getUserData().rol;
      }
      else {
        //TODO si no tiene nada es administrador?
        return "ADMINISTRADOR";
      }

    }

    function fetchUserData() {
      var url = config.api + "/api/usuarios/who-i-am"
      return $http.get(url).then(function (response) {
        /*
        fetchLogo(response.data).then(function (res) {
          setLogo(res);
        });
        */
        return response.data;
      });
    }

    function getUserData() {
      // return JSON.parse(localStorage.getItem("userData"));
      return angular.fromJson(localStorage.getItem("userData"));
    }
	
	function getHubspotToken() {
		var url = config.api + "/api/usuarios/getHubspotToken";
        return $http.get(url).then(function (response) {
			return response.data;
        });
    }

    function setUserData(data) {
      // localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("userData", angular.toJson(data));
    }

    function fetchLogo(user) {
      if (user.entidad.logoId) {
        var url = config.api + "/api/documento/url/" + user.entidad.logoId;
        return $http.get(url).then(function (response) {
          setLogo(response.data.url);
          return response.data.url;
        });
      }
    }

    function changeDivisa(idDivisa) {
      //api/usuarios/change-divisa/{id}
      if (idDivisa) {
        var url = config.api + "/api/usuarios/change-divisa/" + idDivisa;
        return $http.put(url).then(function (response) {
          var userData = getUserData();
          userData.divisa = response.data.divisa;
          setUserData(userData);
          return response.data;
        });
      }
    }

    function changeLang(idLang) {
      //http://localhost:8080/api/usuarios/change-language?language=EN
      if (idLang) {
        var url = config.api + "/api/usuarios/change-language?language=" + idLang;
        return $http.put(url).then(function (response) {

          //TODO ver que devolver

          return response.data;
        });
      }
    }

    function setLogo(url) {
      //TODO comprobar que en url viene la url y sino sacarla
      localStorage.setItem("logoUrl", url);
    }

    function getLogo() {
      return localStorage.getItem("logoUrl");
    }

    function logOut() {
      localStorage.clear();
    }

    return service;
  }

})();
