(function() {
  'use strict';

  angular.module('app')
  .service('ModalService', ModalService);

  /* @ngInject */
  function ModalService(Translator, $mdDialog) {
    return {

      showConfirm: function showConfirm(ev, title, textContent, okText, cancelText, successCallback) {
        Translator.translate([title, textContent, okText, cancelText], function(translations) {
          var confirm = $mdDialog.confirm()
            .title(translations[title])
            .textContent(translations[textContent])
            .targetEvent(ev)
            .ok(translations[okText])
            .cancel(translations[cancelText]);

          $mdDialog.show(confirm).then(successCallback, function () {
            // No hacemos nada
          });
        });

      }

    };
  }
})();