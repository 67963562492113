(function () {
  angular
    .module('app')
    .component('filters', {
      templateUrl: 'components/filters/filters.html',
      controller: FiltersController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function FiltersController($state, $scope, RoleService) {
    var vm = this;

    vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
    vm.isSupProyOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_PROYECTO);

    vm.isAdministrador = RoleService.hasRole(RoleService.getEnum().ADMINISTRADOR);
    vm.isCoordinadorGrupo = RoleService.hasRole(RoleService.getEnum().COORDINADOR_GRUPO);
    vm.isAgente = RoleService.hasRole(RoleService.getEnum().AGENTE);
    vm.hideCollaborator = vm.isAdministrador | vm.isCoordinadorGrupo | vm.isAgente;

    vm.currentNavItem = 'filterReport';//Entidad a la que entramos por defecto

    vm.arrayButtons = [];
    vm.arrayButtons['descarga'] = {
      buttonName: 'filterReport.descarga',
      newStateName: 'filterReport.descarga',
      title: 'filterReport.descarga'
    }
    vm.arrayButtons['graficos'] = {
      buttonName: 'filterReport.graficos',
      newStateName: 'filterReport.graficos',
      title: 'filterReport.graficos'
    }

    vm.clickNew = function () {
      $state.go(vm.arrayButtons[vm.currentNavItem].newStateName, {id: null});

    };

     $scope.$watch(function(){
          return $state.$current.name
      }, function(newVal, oldVal){
       vm.isForm = newVal.toLowerCase().indexOf("form") > -1 ? true : false;
       vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
       vm.isSupProyOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_PROYECTO);

       //si accedemos a la raiz llevamos a la entidad por defecto
       if (newVal == "filters") {
         $state.go('filters.' + vm.currentNavItem);
       }
       else if (newVal.split('.').length == 2) {
         vm.currentNavItem = newVal.split('.')[1];
       }
      })

  }


})();
