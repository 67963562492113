(function () {
  angular
    .module('app')
    .component('entityform', {
      templateUrl: 'components/entity/form/entityform.html',
      controller: EntityFormController,
      controllerAs: 'vm'
    });



  /* @ngInject */
  function EntityFormController(SelectService, EntityService, $state, $stateParams, Upload, Translator) {
    var vm = this;

    vm.loading = true;
    vm.validData = true;
    vm.errorMessage = "";

    vm.editId = $stateParams.id;

    vm.data = {};
    //inicializamos data
    vm.data.abreviatura = "";
    vm.data.nombre = "";
    vm.data.activo = true;
    vm.data.isLBG = false;
    vm.data.activityHistoryActive = false;
	vm.data.aportacionesLey112018 = false;
    vm.data.cif = "";
    vm.data.creador = {};
    vm.data.grupoLBG = {};
    vm.data.pais = {};
    vm.data.planUso = {};
    vm.data.responsable = {};
    vm.data.sector = {};
    vm.data.tipo = {};
    vm.data.lstTipoCambios = [];//TODO descomentar para el multi
    vm.data.tipoCambio = {};//TODO asi antes

    vm.selectedChanges = [];
    vm.prettyChanges = "";


    vm.archivo = {};

    vm.selectorList = {};

    vm.nombreResponsable = "";

    if (vm.editId) {
      EntityService.getEntity(vm.editId).then(function (res) {
        vm.data = res;
        vm.loading = false;
        fillSelectors();
        if (vm.data.usuarioResponsable && vm.data.usuarioResponsable.nombre && vm.data.usuarioResponsable.apellidos) {
          vm.nombreResponsable = vm.data.usuarioResponsable.nombre + " " + vm.data.usuarioResponsable.apellidos;
        }
      });
    } else {
      vm.loading = false;
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.type) {
        vm.data.tipo = selectorObject;
      }
      if (selectorType == SelectService.ENUM.country) {
        vm.data.pais = selectorObject;
      }
      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.data.grupoLBG = selectorObject;
        resetSpecialFields();
      }
      if (selectorType == SelectService.ENUM.plan) {
        vm.data.planUso = selectorObject;
      }
      if (selectorType == SelectService.ENUM.active) {
        vm.data.activo = selectorObject;
      }
      if (selectorType == SelectService.ENUM.lgbSector) {
        vm.data.sector = selectorObject;
      }

      if (selectorType == SelectService.ENUM.changeType) {
        vm.data.tipoCambio = {id: selectorObject.id, nombre: selectorObject.nombre}; //TODO comprobar si vale estopara quitar el changlai
      }

    }

    vm.clickSelectorMulti = function (selectorObject, selectorType, index) {
      if (selectorType == SelectService.ENUM.changeType) {
        vm.data.lstTipoCambios = selectorObject;
        // if (!vm.selectedChanges[index]) {
        //   //si no esta lo metemos
        //
        //   //TODO changlai para que no pete la llamada
        //   vm.data.lstTipoCambios.push({
        //     id: selectorObject.id,
        //     nombre: selectorObject.nombre
        //   });
        //   vm.selectedChanges[index] = true;
        // } else {
        //   //si esta lo sacamos
        //   var indexToDelete = vm.data.lstTipoCambios.indexOf(selectorObject);
        //   vm.data.lstTipoCambios.splice(indexToDelete, 1)
        //   vm.selectedChanges[index] = false;
        // }
        // vm.prettyChanges = showChanges(vm.data.lstTipoCambios);
      }
    }

    function showChanges(changes) {
      var prettyYears = "";
      changes.forEach(function (change) {
        if (prettyYears != "") {
          prettyYears += ", "
        }
        prettyYears += change.nombre
      })
      return prettyYears;
    }

    function resetSpecialFields() {
      vm.data.planUso = {};
      vm.data.tipoCambio = {};
      vm.data.lstTipoCambio = [];//TODO esto es con el multi

      fillSelectors(true);
    }

    function fillSelectors(reset) {
      if (reset == null) {
        SelectService.getType().then(function (res) {
          vm.selectorList.type = res;
        });
      }

      if (reset == null) {
        SelectService.getCountry().then(function (res) {
          vm.selectorList.country = res;
        });
      }

      if (reset == null) {
        SelectService.getLGBSector().then(function (res) {
          vm.selectorList.lgbSector = res;
        });
      }

      if (reset == null) {
        SelectService.getLGBGroup().then(function (res) {
          vm.selectorList.lgbGroup = res;
          if (res.length > 0 && !vm.data.grupoLBG.id) { // TODO comprobar condicion
            vm.data.grupoLBG = res[0]
            fillSelectors(true);//TODO comprobar que funciona
          }
        });
      }

      if ((reset == null || reset) && vm.data.grupoLBG) {
        SelectService.getPlan(vm.data.grupoLBG).then(function (res) {
          vm.selectorList.plan = res;
        });
      }

      if ((reset == null || reset) && vm.data.grupoLBG) {
        SelectService.getChangeTypes(vm.data.grupoLBG).then(function (res) {
          vm.selectorList.changeType = res;

          //para el multi
          vm.prettyChanges = "";
          vm.selectedChanges = [];
          vm.selectorList.changeType.forEach(function (element) {
            if (vm.editId && vm.data.lstTipoCambios && contentElement(vm.data.lstTipoCambios, element)) {
              vm.selectedChanges.push(true);
            }
            else {
              vm.selectedChanges.push(false);
            }
          })
          vm.prettyChanges = showChanges(vm.data.lstTipoCambios);
          //fin multi
        })
      }


    }

    function contentElement(array, element) {
      var content = false;
      if (array) {
        array.forEach(function (i) {
          if (i.id == element.id) {
            content = true;
          }
        })
      }
      return content;
    }

    fillSelectors();

    vm.clickSave = function () {
      vm.validData = true;
      if (vm.editId) {
        EntityService.updateEntity(vm.data, vm.archivo).then(function (res) {
          $state.go("manage.entity");
        })
          .catch(function (e) {
            showError(e);
          });
      }
      else {
        EntityService.createEntity(vm.data, vm.archivo).then(function (res) {
          $state.go("manage.entity");
        })
          .catch(function (e) {
            showError(e);
          });
      }
    }

    // TODO: This isn't actually displaying anything, since vm.valiData is never set to false.
    function showError(e) {
      var translateKey = e && e.data && e.data.message ? e.data.message : 'err.genericError';
      Translator.translate(translateKey, function(translation) {
        vm.errorMessage = translation;
      });
    }

    vm.clickCancel = function () {
      $state.go("manage.entity");
    };
  }
})();
