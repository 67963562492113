(function () {
  angular
    .module('app')
    .component('contact', {
      templateUrl: 'components/contact/contact.html',
      controller: ContactController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ContactController(ContactService, $state, $scope) {
    var vm = this;
    vm.asunto = "";
    vm.comentario = "";
    vm.backState = null;
    vm.emailSent = false;


    vm.clickBack = function () {
      if (vm.backState && vm.backState != "contact") {
        $state.go(vm.backState);
      }
      else {
        $state.go("proyect")
      }
    }

    vm.clickSend = function () {
      ContactService.sendMail(vm.asunto, vm.comentario).then(function (res) {
        vm.emailSent = true;
      })
    }

    $scope.$watch(function () {
      return $state.$current.name
    }, function (newVal, oldVal) {
      vm.backState = oldVal;
    })

  }
})();
