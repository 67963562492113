(function () {
  'use strict';

  angular
    .module('app')
    .service('SalaryTablesService', SalaryTablesService);

  /* @ngInject */
  function SalaryTablesService($http, $httpParamSerializer, config, TokenService, $translate) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getSalaryTables = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/api/tabla-salarials";


      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroDescripcion = null;
      if (filters && filters.description) {
        filtroDescripcion = filters.description;
      }

      var filtroAgnoAplicacion = null;
      if (filters && filters.applyYear) {
        filtroAgnoAplicacion = filters.applyYear;
      }

      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        nombre: filtroNombre,
        codigoCorto: filtroCodigo,
        descripcion: filtroDescripcion,
        agnoAplicacion: filtroAgnoAplicacion,

      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            var prettyLstAgnoAplicacion = "";
            element.lstAgnoAplicacion.forEach(function (year) {
              if (prettyLstAgnoAplicacion != "") {
                prettyLstAgnoAplicacion += ", ";
              }
			  if (year == -1)
				  prettyLstAgnoAplicacion += $translate.instant("filterReport.todos")
			  else					  
				prettyLstAgnoAplicacion += year
            })
            element.prettyLstAgnoAplicacion = prettyLstAgnoAplicacion
            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.createSalaryTable = function (entity) {
      //post /api/entidads
      var url = api + "/api/tabla-salarials";
      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getSalaryTable = function (id) {
      var url = api + "/api/tabla-salarials";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateSalaryTable = function (entity) {
      var url = api + "/api/tabla-salarials";
      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteSalaryTable = function (entity) {
      var url = api + "/api/tabla-salarials";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
