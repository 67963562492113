(function() {
  angular.module('app')
  .component('infoLink', {
    templateUrl: 'shared/infoLink/infoLink.html',
    controller: /* @ngInject */
      function InfoLinkController() {

    },
    controllerAs: 'vm',
    bindings: {
      urlKey: '@',
    }
  })
})();
