(function () {
  'use strict';


  angular.module('app').directive('checkFileSize', function () {
    return {
      link: function (scope, elem, attr, ctrl) {
        function bindEvent(element, type, handler) {
          if (element.addEventListener) {
            element.addEventListener(type, handler, false);
          } else {
            element.attachEvent('on' + type, handler);
          }
        }

        bindEvent(elem[0], 'change', function () {
          var size = this.files[0].size;
          var maxSize = 40000000; //2mb
          if (size > maxSize) {
            alert('No puede subir archivos de mas de 40mb');
          }
        });
      }
    }
  });

})();
