(function () {
  angular
    .module('app')
    .component('userassign', {
      templateUrl: 'components/proyect/userassign/userassign.html',
      controller: UserAssignController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UserAssignController(UserComponentService, RoleService, ProyectService, UserService, config, SelectService, $state, $stateParams) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;
    vm.disableInfinite = false;

    vm.isPersisted = false;

    vm.data = [];

    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.surname = "";
    vm.selectedFilters.email = "";
    vm.selectedFilters.role = RoleService.getEnum().SUPERVISOR_PROYECTO;
    vm.selectedFilters.responsibleEntity = "";
    vm.selectedFilters.country = {};
    vm.selectedFilters.active = null;
    vm.rolBonito = "";

    vm.nombreEntidad = UserService.getUserData().entidad.nombre;

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;
      UserComponentService.getUsers(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
        getProyect();
      });
    }

    function getProyect() {
      var proyect = ProyectService.getPersistProyect();
	  console.log(proyect);
      if (proyect != null) {
        ProyectService.cleanPersistProyect();
        vm.auxProyect = proyect;
        vm.isPersisted = true;
        markSelected(vm.auxProyect);
      }
      else {
        var idProyect = $stateParams.idproyect;
        if (idProyect) {
          ProyectService.getProyect(idProyect).then(function (res) {
            vm.auxProyect = res;
            vm.isPersisted = false;
            markSelected(vm.auxProyect);
          });
        }
      }
    }

    function markSelected(proyect) {
      if (proyect && proyect.proyecto && proyect.proyecto.lstUsuarioDTO) {
        var lstUsuarios = proyect.proyecto.lstUsuarioDTO;
        vm.data.map(function (elemData) {
          lstUsuarios.forEach(function (elemMarked) {
            if (elemData.id == elemMarked.id 
				&& (
					(vm.isPersisted == true && elemMarked.selected)
					|| vm.isPersisted == false
				)
			)
			{
              elemData.selected = true;
            }
          })
        })
      }
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }
      if (selectorType == SelectService.ENUM.responsibleEntity) {
        vm.selectedFilters.responsibleEntity = selectorObject;
      }
      if (selectorType == SelectService.ENUM.active) {
        vm.selectedFilters.active = selectorObject;
      }

      vm.getData();
    }

    function fillFilters() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });
    }

    vm.getData();
    fillFilters();

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickFinish = function () {
      if (vm.auxProyect && vm.auxProyect.proyecto) {
        vm.auxProyect.proyecto.lstUsuarioDTO = getUserList();
        if (vm.isPersisted) {
          ProyectService.persistProyect(vm.auxProyect);
          var proyectform = {id: $stateParams.idproyect, duplicate: $stateParams.duplicate};
          $state.go("proyectform", proyectform);
        }
        else {
          ProyectService.updateProyect(vm.auxProyect).then(function (res) {
            $state.go("proyect");
          })
        }
      }
    }

    function getUserList() {
      var listUsuarios = [];
      vm.data.forEach(function (element) {
        if (element.selected) {
          listUsuarios.push(element);
        }
      });
      return listUsuarios;
    }

    vm.clickAll = function () {
      vm.data.map(function (element) {
        element.selected = true;
        return element;
      })
    }

    vm.clickAny = function () {
      vm.data.map(function (element) {
        element.selected = false;
        return element;
      })
    }

    vm.loadMore = function () {
      /*if (vm.busy) return;
      vm.busy = true;

      UserComponentService.getUsers(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        vm.busy = false;
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        }
        else {
          vm.page++;
        }

        vm.disableInfinite = vm.busy | vm.allDataLoaded;

      });

      vm.disableInfinite = vm.busy | vm.allDataLoaded;*/

    };

  }
})();
