(function () {
  angular
    .module('app')
    .component('rating', {
      templateUrl: 'components/rating/rating.html',
      controller: RatingController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function RatingController(ConfirmDeleteService, EntityService, RatingService, $filter, UserService, config, Translator) {
    var vm = this;
	
    // inicializamos la entidad del usuario con un mensaje de Cargando
    vm.entity = { abreviado: 'list.cargando' };

	vm.languages = config.availableLanguages;
	vm.thisLanguage = Translator.getActualLanguage();
	vm.langs = [];
	vm.isNotVisibleLang = [];
	vm.isVisibleLang = [];
	if (vm.thisLanguage == 'es')
		vm.isVisibleLang['es'] = true;
	else
		vm.isVisibleLang['es'] = false;
	vm.languages.forEach(function(el,il){
		if (vm.thisLanguage != el)
			vm.isNotVisibleLang[el] = true;
		else
			vm.isNotVisibleLang[el] = false;
			
		if (el !== 'es')
			vm.langs.push(el);
	});
	
	document.querySelector('select[name="header-lang"]').addEventListener("change", function(){
		var selectedLanguage = this.value.toLowerCase();
		vm.thisLanguage = selectedLanguage;
		if (vm.thisLanguage == 'es')
			vm.isVisibleLang['es'] = true;
		else
			vm.isVisibleLang['es'] = false;
			
		vm.languages.forEach(function(el,il){
			if (selectedLanguage != el)
				vm.isNotVisibleLang[el] = true;
			else
				vm.isNotVisibleLang[el] = false;
		});
	});
	
	vm.isActiveAllLanguages = false;
	vm.activeDesactiveAllLanguages = function () {
		if (vm.isActiveAllLanguages == false)
		{
			vm.isActiveAllLanguages = true;
			vm.languages.forEach(function(el,il){
				vm.isNotVisibleLang[el] = false;
			});
			vm.isVisibleLang['es'] = true;
		}
		else
		{
			vm.isActiveAllLanguages = false;
			vm.languages.forEach(function(el,il){
				if (vm.thisLanguage != el)
					vm.isNotVisibleLang[el] = true;
				else
					vm.isNotVisibleLang[el] = false;
			});
			if (vm.thisLanguage != 'es')
				vm.isVisibleLang['es'] = false;
		}
    }
	
    vm.nuevaUdNegocio = "";
    vm.nuevaAgrInterna = "";
    vm.nuevaAgrIniciativaInterna = "";

    vm.udsNegocio = [];
    vm.agrInternas = [];
    vm.agrIniciativaInternas = [];

    vm.reverseUdNegocio = false;
    vm.reverseAgrInterna = false;
    vm.reverseAgrIniciativaInterna = false;

    vm.$onInit = onInit;

    vm.creaUdNegocio = creaUdNegocio;
    vm.editaUdNegocio = editaUdNegocio;
    vm.borraUdNegocio = borraUdNegocio;
    vm.ordenaUdNegocio = ordenaUdNegocio;

    vm.creaAgrInterna = creaAgrInterna;
    vm.editaAgrInterna = editaAgrInterna;
    vm.borraAgrInterna = borraAgrInterna;
    vm.ordenaAgrInterna = ordenaAgrInterna;

    vm.creaAgrIniciativaInterna = creaAgrIniciativaInterna;
    vm.editaAgrIniciativaInterna = editaAgrIniciativaInterna;
    vm.borraAgrIniciativaInterna = borraAgrIniciativaInterna;
    vm.ordenaAgrIniciativaInterna = ordenaAgrIniciativaInterna;
    vm.activoIniciativasInternas = activoIniciativasInternas;

    function onInit() {
      EntityService.getEntityData()
      .then(function(entity) {
        vm.entity = entity;
      })
      RatingService.getAllAgrInternaAllLangs()
      .then(function(agrInternas) {
        vm.agrInternas = agrInternas;
		vm.agrInternas.forEach(function(e,i){
			var pos = 0;
			vm.languages.forEach(function(el,il){
				if (typeof vm.agrInternas[i].nombre.split('|')[pos] !== 'undefined')
					vm.agrInternas[i]['nombreL'+el] = vm.agrInternas[i].nombre.split('|')[pos];
				else
					vm.agrInternas[i]['nombreL'+el] = '';
				
				pos++;
			});
		});
        vm.agrInternas = $filter('orderBy')(vm.agrInternas, 'nombreLes', vm.reverseAgrInterna);
      })
      RatingService.getAllAgrIniciativaInternaAllLangs()
      .then(function(agrIniciativaInternas) {
        vm.agrIniciativaInternas = agrIniciativaInternas;
		vm.agrIniciativaInternas.forEach(function(e,i){
			var pos = 0;
			vm.languages.forEach(function(el,il){
				if (typeof vm.agrIniciativaInternas[i].nombre.split('|')[pos] !== 'undefined')
					vm.agrIniciativaInternas[i]['nombreL'+el] = vm.agrIniciativaInternas[i].nombre.split('|')[pos];
				else
					vm.agrIniciativaInternas[i]['nombreL'+el] = '';
				
				pos++;
			});
		});
        vm.agrIniciativaInternas = $filter('orderBy')(vm.agrIniciativaInternas, 'nombreLes', vm.reverseAgrIniciativaInterna);
      })
      RatingService.getAllUdNegocioAllLangs()
      .then(function(udsNegocio) {
        vm.udsNegocio = udsNegocio;        
		vm.udsNegocio.forEach(function(e,i){
			var pos = 0;
			vm.languages.forEach(function(el,il){
				if (typeof vm.udsNegocio[i].nombre.split('|')[pos] !== 'undefined')
					vm.udsNegocio[i]['nombreL'+el] = vm.udsNegocio[i].nombre.split('|')[pos];
				else
					vm.udsNegocio[i]['nombreL'+el] = '';

				pos++;
			});
		});
		vm.udsNegocio = $filter('orderBy')(vm.udsNegocio, 'nombreLes', vm.reverseUdNegocio);
      })
    }

    function activoIniciativasInternas(){
      return UserService.getUserData().entidad.activoIniciativasInternas;
    }

    function creaUdNegocio() {
      if(!vm.nuevaUdNegocio) {
        return;
      }
	  var newName = vm.nuevaUdNegocio;
	  vm.languages.forEach(function(el,il){
		  if (el != 'es')
			newName += '|' + vm.nuevaUdNegocio;
	  });
	  
      var data = { nombre: newName };	  
		
      RatingService.creaUdNegocio(data)
      .then(function(nuevaUdNegocio) {
        vm.nuevaUdNegocio = '';
		
		var pos = 0;
		vm.languages.forEach(function(el,il){
			if (typeof nuevaUdNegocio.nombre.split('|')[pos] !== 'undefined')
				nuevaUdNegocio['nombreL'+el] = nuevaUdNegocio.nombre.split('|')[pos];
			else
				nuevaUdNegocio['nombreL'+el] = '';
			
			pos++;
		});
				
        vm.udsNegocio.push(nuevaUdNegocio);
        vm.udsNegocio = $filter('orderBy')(vm.udsNegocio, 'nombreLes', vm.reverseUdNegocio)
      })
    }
    function editaUdNegocio(udNegocio) {
	  udNegocio.nombre = udNegocio.nombreLes;
	  vm.languages.forEach(function(el,il){
		if (el != 'es')
			udNegocio.nombre += "|" + udNegocio['nombreL'+el];
	  });
      if (vm.selectedUdNegocio != udNegocio.nombre) {
        udNegocio.loading = true;
        RatingService.editaUdNegocio(udNegocio)
          .then(afterEditt)
          .catch(afterEditt)
        function afterEditt() {
          udNegocio.loading = false;
          vm.udsNegocio = $filter('orderBy')(vm.udsNegocio, 'nombreLes', vm.reverseUdNegocio)
        }
      }
    }

    vm.selectedUdNegocio = "";
    vm.setSelected = function (udNegocio) {
	  udNegocio.nombre = udNegocio.nombreLes;
	  vm.languages.forEach(function(el,il){
		if (el != 'es')
			udNegocio.nombre += "|" + udNegocio['nombreL'+el];
	  });
      vm.selectedUdNegocio = udNegocio.nombre
    }

    function borraUdNegocio(udNegocio) {
      ConfirmDeleteService.showDialog().then(function() {
        RatingService.borraUdNegocio(udNegocio)
        .then(function() {
          vm.udsNegocio = vm.udsNegocio.filter(function(udn) {
            return udn.id !== udNegocio.id;
          })
        })
      })
    }
    function ordenaUdNegocio() {
      vm.reverseUdNegocio = !vm.reverseUdNegocio;
      vm.udsNegocio = $filter('orderBy')(vm.udsNegocio, 'nombreLes', vm.reverseUdNegocio)
    }

    function creaAgrInterna() {
      if(!vm.nuevaAgrInterna) {
        return;
      }
	  var newName = vm.nuevaAgrInterna;
	  vm.languages.forEach(function(el,il){
		  if (el != 'es')
			newName += '|' + vm.nuevaAgrInterna;
	  });
      var data = { nombre: newName };
      RatingService.creaAgrInterna(data)
      .then(function(nuevaAgrInterna) {
		var pos = 0;
		vm.languages.forEach(function(el,il){
			if (typeof nuevaAgrInterna.nombre.split('|')[pos] !== 'undefined')
				nuevaAgrInterna['nombreL'+el] = nuevaAgrInterna.nombre.split('|')[pos];
			else
				nuevaAgrInterna['nombreL'+el] = '';
			
			pos++;
		});
		
        vm.nuevaAgrInterna = '';
        vm.agrInternas.push(nuevaAgrInterna);
        vm.agrInternas = $filter('orderBy')(vm.agrInternas, 'nombreLes', vm.reverseAgrInterna);
      })
    }
    function editaAgrInterna(agrInterna) {
      agrInterna.nombre = agrInterna.nombreLes;
	  vm.languages.forEach(function(el,il){
		if (el != 'es')
			agrInterna.nombre += "|" + agrInterna['nombreL'+el];
	  });
      if (vm.selectedAgrInterna != agrInterna.nombre) {
        agrInterna.loading = true;
        RatingService.editaAgrInterna(agrInterna)
          .then(afterEditt)
          .catch(afterEditt)
        function afterEditt() {
          agrInterna.loading = false;
          vm.agrInternas = $filter('orderBy')(vm.agrInternas, 'nombreLes', vm.reverseAgrInterna);
        }
      }
    }

    vm.selectedAgrInterna = "";
    vm.setSelectedAgrInterna = function (agrInterna) {
      agrInterna.nombre = agrInterna.nombreLes;
	  vm.languages.forEach(function(el,il){
		if (el != 'es')
			agrInterna.nombre += "|" + agrInterna['nombreL'+el];
	  });
      vm.selectedAgrInterna = agrInterna.nombre
    }

    function borraAgrInterna(agrInterna) {
      ConfirmDeleteService.showDialog().then(function() {
        RatingService.borraAgrInterna(agrInterna)
        .then(function() {
          vm.agrInternas = vm.agrInternas.filter(function(agr) {
            return agr.id !== agrInterna.id;
          })
        })
      })
    }
    function ordenaAgrInterna() {
      vm.reverseAgrInterna = !vm.reverseAgrInterna;
      vm.agrInternas = $filter('orderBy')(vm.agrInternas, 'nombreLes', vm.reverseAgrInterna);
    }
///////////////////////////
    function creaAgrIniciativaInterna() {
      if(!vm.nuevaAgrIniciativaInterna) {
        return;
      }
	  var newName = vm.nuevaAgrIniciativaInterna;
	  vm.languages.forEach(function(el,il){
		  if (el != 'es')
			newName += '|' + vm.nuevaAgrIniciativaInterna;
	  });
      var data = { nombre: newName };
      RatingService.creaAgrIniciativaInterna(data)
      .then(function(nuevaAgrIniciativaInterna) {
		var pos = 0;
		vm.languages.forEach(function(el,il){
			if (typeof nuevaAgrIniciativaInterna.nombre.split('|')[pos] !== 'undefined')
				nuevaAgrIniciativaInterna['nombreL'+el] = nuevaAgrIniciativaInterna.nombre.split('|')[pos];
			else
				nuevaAgrIniciativaInterna['nombreL'+el] = '';
			
			pos++;
		});
		
        vm.nuevaAgrIniciativaInterna = '';
        vm.agrIniciativaInternas.push(nuevaAgrIniciativaInterna);
        vm.agrIniciativaInternas = $filter('orderBy')(vm.agrIniciativaInternas, 'nombreLes', vm.reverseAgrIniciativaInterna);
      })
    }

	function editaAgrIniciativaInterna(agrIniciativaInterna) {
      agrIniciativaInterna.nombre = agrIniciativaInterna.nombreLes;
	  vm.languages.forEach(function(el,il){
		if (el != 'es')
			agrIniciativaInterna.nombre += "|" + agrIniciativaInterna['nombreL'+el];
	  });
      if (vm.selectedAgrIniciativaInterna != agrIniciativaInterna.nombre) {
        agrIniciativaInterna.loading = true;
        RatingService.editaAgrIniciativaInterna(agrIniciativaInterna)
          .then(afterEditt)
          .catch(afterEditt)
        function afterEditt() {
          agrIniciativaInterna.loading = false;
          vm.agrIniciativaInternas = $filter('orderBy')(vm.agrIniciativaInternas, 'nombreLes', vm.reverseAgrIniciativaInterna);
        }
      }
    }

    vm.selectedAgrIniciativaInterna = "";
    vm.setSelectedAgrIniciativaInterna = function (agrIniciativaInterna) {
      agrIniciativaInterna.nombre = agrIniciativaInterna.nombreLes;
	  vm.languages.forEach(function(el,il){
		if (el != 'es')
			agrIniciativaInterna.nombre += "|" + agrIniciativaInterna['nombreL'+el];
	  });
      vm.selectedAgrIniciativaInterna = agrIniciativaInterna.nombre
    }

    function borraAgrIniciativaInterna(agrIniciativaInterna) {
      ConfirmDeleteService.showDialog().then(function() {
        RatingService.borraAgrIniciativaInterna(agrIniciativaInterna)
        .then(function() {
          vm.agrIniciativaInternas = vm.agrIniciativaInternas.filter(function(agr) {
            return agr.id !== agrIniciativaInterna.id;
          })
        })
      })
    }
    function ordenaAgrIniciativaInterna() {
      vm.reverseAgrIniciativaInterna = !vm.reverseAgrIniciativaInterna;
      vm.agrIniciativaInternas = $filter('orderBy')(vm.agrIniciativaInternas, 'nombreLes', vm.reverseAgrIniciativaInterna);
    }

  }
})();
