(function () {

  // var api = "http://ceres.dinamicarea.es:9080/impactonline";
  var api = "";

  var defaultSortField = 'codigoCorto';
  var defaultSortOrder = true; //values true:ASC y false:DES
  var defaultPageSize = 30;
  var defaultInitialPage = 0;

  angular.module("app")
    .constant('config', {
      api: api,
      defaultSortField: defaultSortField,
      defaultSortOrder: defaultSortOrder,
      defaultPageSize: defaultPageSize,
      defaultInitialPage: defaultInitialPage,
      logEntityClassification: {
        entidad: 1,
        tabla_salarial: 22,
        tipo_cambio: 21,
        colaborador: 19,
        proyecto: 3,
        iniciativa: 6,
        contribucion: 10,
        logro: 13,
        impacto: 16
      },
      logTypeClassification: {
        create: 1,
        read: 2,
        update: 3,
        delete: 4,
        duplicate: 5
      },
	  availableLanguages: ['es','en','it','pt']
    })
    .config(function ($mdThemingProvider,
                      $mdDateLocaleProvider,
                      $httpProvider,
                      $mdDialogProvider,
                      $translateProvider,
                      translations_ES,
                      translations_EN,
                      translations_IT,
					  translations_PT) {
      'ngInject';

      // translate configuration
      $translateProvider.translations('es', translations_ES)

      $translateProvider.translations('en', translations_EN)

      $translateProvider.translations('it', translations_IT)
	  
	  $translateProvider.translations('pt', translations_PT)
      
      $translateProvider.preferredLanguage('es');

      // interceptors configuration
      $httpProvider.interceptors.push('ApiErrorInterceptor')

      $httpProvider.interceptors.push('BearerAuthInterceptor')

      $httpProvider.defaults.withCredentials = true;//!importante para permitir guardar cookies

      moment.locale('es');
      // datepicker configuration
      $mdDateLocaleProvider.formatDate = function (date) {
        return date ? moment(date).format('DD/MM/YYYY') : '';
      };

      $mdDateLocaleProvider.parseDate = function (dateString) {
        // var m = moment(dateString, 'YYYY-MM-DD', true);
        var m = moment(dateString, 'DD/MM/YYYY', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
      };

      //traducir datepicker
      $mdDateLocaleProvider.months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
      $mdDateLocaleProvider.shortMonths = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
      $mdDateLocaleProvider.days = ['domingo', 'lunes', 'martes', 'miÃ©rcoles', 'jueves', 'viernes', 'sábado'];
      $mdDateLocaleProvider.shortDays = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
      // Can change week display to start on Monday.
      $mdDateLocaleProvider.firstDayOfWeek = 1;

      // In addition to date display, date components also need localized messages
      // for aria-labels for screen-reader users.
      $mdDateLocaleProvider.weekNumberFormatter = function (weekNumber) {
        return 'Semana ' + weekNumber;
      };

      $mdDateLocaleProvider.msgCalendar = 'Calendario';
      $mdDateLocaleProvider.msgOpenCalendar = 'Abrir el calendario';
      //fin traducir datepicker

      // theme configuration
      $mdThemingProvider.definePalette('impactPalete', {
        '50': 'ffebee',
        '100': 'ffcdd2',
        '200': '59d21c',
        '300': '8a6b70',
        '400': 'ef5350',
        '500': 'f44336',
        '600': 'e53935',
        '700': 'd32f2f',
        '800': 'c62828',
        '900': 'b71c1c',
        'A100': 'ff8a80',
        'A200': 'ff5252',
        'A400': 'ff1744',
        'A700': 'd50000',
        'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                            // on this palette should be dark or light

        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
          '200', '300', '400', 'A100'],
        'contrastLightColors': undefined    // could also specify this if default was 'dark'
      });

      $mdThemingProvider.theme('default')
        .accentPalette('impactPalete', {
          'default': '200' // use shade 200 for default, and keep all other shades the same
        })
        .primaryPalette('impactPalete', {
          'default': '300' // use shade 200 for default, and keep all other shades the same
        });
    })

})();
