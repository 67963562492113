(function () {
  angular
    .module('app')
    .component('home', {
      templateUrl: 'components/home/home.html',
      controller: HomeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function HomeController() {

    var vm = this;

    vm.logoUrl = localStorage.getItem("logoUrl");

  }
})();
