var sessionOutInterval;
var sessionOutTimeout;
var counter = 30;
(function () {
  angular
    .module('app')
    .component('toolbartop', {
      templateUrl: 'components/toolbartop/toolbartop.html',
      controller: LoginController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function LoginController($state, $scope, $translate, $rootScope, ConfirmDeleteService,
                           SelectService, UserService, RoleService, Translator, $location, $window) {
    var vm = this;

    vm.logoUrl = "";
    vm.nombreUsuario = "";
    vm.email = "";

    vm.currencies = [];
    vm.selectedCurrency = "";//EUR

    vm.langs = ["ES", "EN", "IT", "PT"];
    vm.selectedLang = "ES";
    $rootScope.selectedLang = "ES";

    vm.currentNavItem = 'home';

    vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
    vm.isSupProOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_PROYECTO);
    vm.isAgenteOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().AGENTE);
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    $scope.$watch(function () {
      return $state.$current.name
    }, function (newVal, oldVal) {

      if (newVal == "home") {
        vm.currentNavItem = "home";
      }
      else if (newVal.split('.').length == 2) {
        vm.currentNavItem = newVal.split('.')[0];
      }
      else if (newVal != "uploadform") {
        vm.currentNavItem = newVal
      }

      vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
      vm.isSupProOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_PROYECTO);
      vm.isSupIniOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_INICIATIVA);
      vm.isAgenteOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().AGENTE);
	  vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	  
      vm.isLogin = newVal.indexOf("login") > -1 ? true : false || newVal.indexOf("forgotpass") > -1 ? true : false;
      if (vm.isLogin && UserService.userIsLogged()) {
        if (vm.isRespOrLess) {
          $state.go("proyect");
        }
        else {
          $state.go("manage.user");
        }
      }
	  if (UserService.userIsLogged())
	  {
		function getCookie(cname) {
		  var name = cname + "=";
		  var decodedCookie = decodeURIComponent(document.cookie);
		  var ca = decodedCookie.split(';');
		  for(var i = 0; i <ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
			  c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
			  return c.substring(name.length, c.length);
			}
		  }
		  return "";
		}
		function setCookie(cname, cvalue, minutes) {
		  var d = new Date();
		  d.setTime(d.getTime() + (minutes*60*1000));
		  var expires = "expires="+ d.toUTCString();
		  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		}
		function openSessionOutModal()
		{
			var cookieValue = getCookie('logoutTimeout');
			if (cookieValue == "")
			{
				document.getElementById("modalSessionOut").style.display = "block";
				sessionOutInterval = setInterval(function(){ 
					if (counter == 0)
						closeSession();
					else
					{
						document.getElementById("sessionOutCounter").textContent = counter;
						counter = counter - 1;
					}
				}, 1000);
			}
			else
			{
				sessionOutTimeout = setTimeout(function(){ 
					openSessionOutModal(); 
				}, 600000);
			}
		}
		function closeSession()
		{
			document.getElementById("modalSessionOut").style.display = "none";
			clearInterval(sessionOutInterval);
			counter = 30;
			document.getElementById("sessionOutCounter").textContent = counter;
			
			document.getElementById("sessionOut").click();
		}
		setCookie('logoutTimeout','1',59);
		clearTimeout(sessionOutTimeout);
		sessionOutTimeout = setTimeout(function(){ 
			openSessionOutModal(); 
		}, 3600000);
		window.onclick = function(event) {
		  if (event.target == document.getElementById("modalSessionOut")) {
			document.getElementById("modalSessionOut").style.display = "none";
			clearInterval(sessionOutInterval);
			counter = 30;
			document.getElementById("sessionOutCounter").textContent = counter;
			setCookie('logoutTimeout','1',59);
			clearTimeout(sessionOutTimeout);
			sessionOutTimeout = setTimeout(function(){ 
				openSessionOutModal(); 
			}, 3600000);
		  }
		}
		document.getElementById("continueSession").onclick = function(event) {
			document.getElementById("modalSessionOut").style.display = "none";
			clearInterval(sessionOutInterval);
			counter = 30;
			document.getElementById("sessionOutCounter").textContent = counter;
			setCookie('logoutTimeout','1',59);
			clearTimeout(sessionOutTimeout);
			sessionOutTimeout = setTimeout(function(){ 
				openSessionOutModal(); 
			}, 3600000);
		}
	  }
      if (!vm.isLogin) {
        if (UserService.getLogo()) {
          vm.logoUrl = UserService.getLogo();
          if (vm.logoUrl.indexOf('https://') == -1) {
              vm.logoUrl = vm.logoUrl.replace("http://","https://");
          }
          SelectService.getBadges().then(function (data) {
            vm.currencies = data;
          })
        }
        else {
          vm.logoUrl = "";
        }
        if (UserService.getUserData()) {
          var userData = UserService.getUserData();
          vm.nombreUsuario = userData.nombre;
          vm.email = userData.email;
          vm.id = userData.id;
          vm.loadIntercom(true,vm.nombreUsuario, vm.email, vm.id);
          if (userData.divisa && userData.divisa.abreviatura) {
            vm.selectedCurrency = userData.divisa.abreviatura;
          }
        }
      }else{
        vm.loadIntercom(false);
      }
    })

    vm.logout = function (ev) {
      Translator.translate(['logout.cerrarSesion', 'logout.preguntaCerrarSesion'], function(translations) {
        ConfirmDeleteService.showDialog(ev, translations['logout.cerrarSesion'], translations['logout.preguntaCerrarSesion']).then(function() {
          UserService.logOut();
          vm.nombreUsuario = "";
          vm.email = "";
          $state.go("login");
        });
      });
    }
	
	vm.forceLogout = function (ev) {
      UserService.logOut();
	  vm.nombreUsuario = "";
	  vm.email = "";
	  $state.go("login");
    }

    vm.onCurrencyChange = function () {
      var id = null;
      var moneda = vm.selectedCurrency;
      vm.currencies.forEach(function (elem) {
        if (elem.abreviatura == moneda) {
          id = elem.id;
        }
      });
      UserService.changeDivisa(id).then(function () {
        $state.reload();
      });
    }

    vm.onLangChange = function () {
	  if (vm.isLogin)
	  {
		$translate.use(vm.selectedLang.toLowerCase());
        $rootScope.selectedLang = vm.selectedLang;
	  }
	  else
	  {
		  UserService.changeLang(vm.selectedLang).then(function () {
			$translate.use(vm.selectedLang.toLowerCase());
			$rootScope.selectedLang = vm.selectedLang;
		  });
	  }
    }

    vm.loadIntercom = function(logged, name, email, user_id){
        logged = false; // FORZADO
		if (false)
		{
			window.hsConversationsSettings = {
				loadImmediately: false
			};
			
			var script = document.createElement('script');
			script.src = "//js-eu1.hs-scripts.com/25758075.js";
			script.onload = function () {
				if (logged == true)
				{
					var cvalue = "";
					
					var token = "";
					var hubspotToken = '; ' + document.cookie;
					var parts = hubspotToken.split('; hubspotToken=');
					if (parts.length === 2) 
						token = parts.pop().split(';').shift();

					if (token == "")
					{
						UserService.getHubspotToken().then(function (t) {
							token = t;
							
							var d = new Date();
							d.setTime(d.getTime() + 11*60*60*1000);
							var expires = "expires=" + d.toGMTString();
							document.cookie = "hubspotToken="+token+"; "+expires;
							
							window.hsConversationsSettings = {
							   identificationEmail: email,
							   identificationToken: token
							};

							window.HubSpotConversations.widget.load();
						});
					}
					else
					{
						window.hsConversationsSettings = {
						   identificationEmail: email,
						   identificationToken: token
						};

						window.HubSpotConversations.widget.load();
					}
				}
				else
				{
					window.HubSpotConversations.widget.load();
				}			
			};
			document.head.appendChild(script);
		}
    }
  }

})();
