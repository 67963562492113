(function () {
  'use strict';

  angular
    .module('app')
    .service('UserComponentService', UserComponentService);

  /* @ngInject */
  function UserComponentService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getUsers = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/apiV2/usuarios";

      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroApellidos = null;
      if (filters && filters.surname) {
        filtroApellidos = filters.surname;
      }

      var filtroEmail = null;
      if (filters && filters.email) {
        filtroEmail = filters.email;
      }

      var filtroRol = null;
      if (filters && filters.role) {
        filtroRol = filters.role;
      }

      var filtroEntidadAlta = null;
      if (filters && filters.responsibleEntity) {
        filtroEntidadAlta = filters.responsibleEntity.id;
      }

      var filtroPais = null;
      if (filters && filters.country && filters.country.id) {
        filtroPais = filters.country.id;
      }

      var filtroActivo = null;
      if (filters && filters.active != null) {
        filtroActivo = filters.active;
      }

      var filtroRepresentanteColaborador = null;
      if (filters && filters.representante != null && filters.representante.tipo == "COLABORADOR") {
        filtroRepresentanteColaborador = filters.representante.id;
      }

      var filtroRepresentanteEntidad = null;
      if (filters && filters.representante != null && filters.representante.tipo == "ENTIDAD") {
        filtroRepresentanteEntidad = filters.representante.id;
      }


      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        pais: filtroPais,
        // activo: filtroActivo,//TODO preguntar a juan xk el campo siempre se vuelve string
        nombre: filtroNombre,
        codigoCorto: filtroCodigo,
        apellidos: filtroApellidos,
        email: filtroEmail,
        rol: filtroRol,
        entidadAlta: filtroEntidadAlta,
        colaboradorRepresentante: filtroRepresentanteColaborador,
        entidadRepresentante: filtroRepresentanteEntidad,
      });
      url += "?" + params;

      //TODO si conseguimos arreglar lo de mas arriba podemos quitar esto.
      if (filtroActivo != null) {
        if (url.indexOf("?") > -1) {
          url += "&";
        }
        else {
          url += "?";
        }
        url += "activo=" + filtroActivo;
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.createUser = function (user) {
      //post /api/entidads
      var url = api + "/api/usuarios";
      return $http.post(url, user, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getUser = function (id) {
      var url = api + "/api/usuarios";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateUser = function (user) {
      var url = api + "/api/usuarios";

      return $http.put(url, user, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.deleteUser = function (user) {
      var url = api + "/api/usuarios/";
      if (user.id != null) {
        url += "/" + user.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.activeDesactiveUser = function (user) {
      var url = api + "/api/usuarios/active-desactive";
      if (user && user.id != null) {
        url += "/" + user.id;
        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
