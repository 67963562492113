(function () {
    angular
        .module('app')
        .controller(
            'ActivityHistoryDialogController', ActivityHistoryDialogController
        ).component('activityHistoryDialog',{
            controller: ActivityHistoryDialogController,
            controllerAs: 'vm'
        })
    /* @ngInject */
    function ActivityHistoryDialogController($mdDialog){
        var vm = this;
        
        vm.hide = hide;
        vm.cancel = cancel;
        vm.answer = answer;


        function hide() {
            $mdDialog.hide();
        };

        function cancel() {
            $mdDialog.cancel();
        };

        function answer(answer) {
            $mdDialog.hide(answer);
        };
    }
})();