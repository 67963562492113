(function () {
  'use strict';

  angular
    .module('app')
    .service('RoleService', RoleService);

  /* @ngInject */
  function RoleService(UserService) {

    var service = {};

    service.roles = roles;
    service.isAdmin = isAdmin;
    service.isGroupCordinator = isGroupCordinator;
    service.isResponsible = isResponsible;
    service.isManager = isManager;
    service.isProjectSupervisor = isProjectSupervisor;
    service.isInitiativeSupervisor = isInitiativeSupervisor;
    service.isAgent = isAgent;
    service.isActivityHistoryEnabled = isActivityHistoryEnabled;

    function isAdmin(){
      return UserService.getUserRole() == roles.administrador
    }
    function isGroupCordinator(){
      return UserService.getUserRole() == roles.coordinador_grupo;
    }
    function isResponsible(){
      return UserService.getUserRole() == roles.responsable;
    }
    function isManager(){
      return UserService.getUserRole() == roles.gerente;
    }
    function isProjectSupervisor(){
      return UserService.getUserRole() == roles.supervisor_proyecto;
    }
    function isInitiativeSupervisor(){
      return UserService.getUserRole() == roles.supervisor_iniciativa;
    }
    function isAgent(){
      return UserService.getUserRole() == roles.agente;
    }
	function isSoloVista(){
      return UserService.getUserRole() == roles.solovista;
    }
    function isActivityHistoryEnabled(){
      return UserService.getUserData().entidad.activityHistoryActive == true ? true : false;
    }


    var roles = {
      administrador: "ADMINISTRADOR",
      coordinador_grupo: "COORDINADOR_GRUPO",
      responsable: "RESPONSABLE",
      gerente: "GERENTE",
      supervisor_proyecto: "SUPERVISOR_PROYECTO",
      supervisor_iniciativa: "SUPERVISOR_INICIATIVA",
      agente: "AGENTE",
	  solovista: "SOLOVISTA"
    }

    service.getEnum = function () {
      var ENUM = {}
      ENUM.ADMINISTRADOR = "ADMINISTRADOR";
      ENUM.COORDINADOR_GRUPO = "COORDINADOR_GRUPO";
      ENUM.RESPONSABLE = "RESPONSABLE";
      ENUM.GERENTE = "GERENTE";
      ENUM.SUPERVISOR_PROYECTO = "SUPERVISOR_PROYECTO";
      ENUM.SUPERVISOR_INICIATIVA = "SUPERVISOR_INICIATIVA";
      ENUM.AGENTE = "AGENTE";
	  ENUM.SOLOVISTA = "SOLOVISTA";
      // ENUM.BASICO = "BASICO";
      return ENUM;
    }

    service.order = [
      "ADMINISTRADOR",
      "COORDINADOR_GRUPO",
      "RESPONSABLE",
      "GERENTE",
      "SUPERVISOR_PROYECTO",
      "SUPERVISOR_INICIATIVA",
      "AGENTE",
	  "SOLOVISTA"
      // "BASICO",
    ]

    service.hasRoleOrLess = function (role) {
      var userRole = UserService.getUserRole();
      var userOrder = service.order.indexOf(userRole);
      var comparationRole = service.order.indexOf(role);
      return userOrder >= comparationRole;
    }

    service.hasMoreRole = function (role) {
      var userRole = UserService.getUserRole();
      var userOrder = service.order.indexOf(userRole);
      var comparationRole = service.order.indexOf(role);
      if (userOrder == 0) {
        return true;
      }
      else {
        return userOrder < comparationRole;
      }
    }

    //ng-if="vm.roleService.hasRole('admin')"

    service.hasRole = function (roles) {
      //tomamos roles como un string de valores separados por comas
      var userRole = UserService.getUserRole();
      if (!userRole) {
        return false;
      }
      if (roles.split(",").indexOf(userRole) !== -1) {
        return true;
      }
      return false;
      // return roles.indexOf(role) !== -1;
    }

    return service;
  }

})();
