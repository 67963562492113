(function () {
  angular
    .module('app')
    .component('planform', {
      templateUrl: 'components/plan/form/planform.html',
      controller: PlanFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function PlanFormController(SelectService, PlanService, RoleService, $state, $stateParams, Translator) {
    var vm = this;

    vm.validData = true;
    vm.errorMessage = "";

    vm.editId = $stateParams.id;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";    
    vm.data.grupoLBGId = 0;
    vm.data.grupoLBG = {};
    vm.data.numUsuarios = 1;
    vm.data.activo = true;

    vm.selectorList = {};

    vm.esCoordinadorGrupo = false;

    if (vm.editId) {
      PlanService.getPlan(vm.editId).then(function (res) {
        vm.data = res;
        fillSelectors()
      });
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.data.grupoLBG = selectorObject;
        vm.data.grupoLBGId = selectorObject.id;
      }
    }

    function fillSelectors() {
      SelectService.getLGBGroup().then(function (res) {
        vm.selectorList.lgbGroup = res;

        //TODO extra
        if (vm.data.grupoLBGId) {
          vm.selectorList.lgbGroup.forEach(function (value) {
            if (value.id == vm.data.grupoLBGId) {
              vm.data.grupoLBG = value;
            }
          })
        }

        if (!vm.data.grupoLBG || !vm.data.grupoLBGId) {
          if (vm.selectorList.lgbGroup && vm.selectorList.lgbGroup[0]) {
            vm.clickSelector(vm.selectorList.lgbGroup[0], SelectService.ENUM.lgbGroup);
          }

        }
      });
    }

    fillSelectors();

    vm.clickSave = function () {
      vm.validData = true;
      if (vm.editId) {
        PlanService.updatePlan(vm.data).then(function (res) {
          $state.go("manage.plan");
        })
          .catch(function (e) {
            showError(e);
          });
      }
      else {
        PlanService.createPlan(vm.data).then(function (res) {
          $state.go("manage.plan");
        })
          .catch(function (e) {
            showError(e);
          });
      }


    }

    //TODO generalizar
    function showError(e) {
      var translationKey = e.data.message ? e.data.message : 'err.genericError';
      Translator.translate(translationKey, function(translation) {
        vm.errorMessage = translation;
        //vm.validData = false;//TODO de momento con el interceptor
      });
    }

    vm.clickCancel = function () {
      $state.go("manage.plan");
    }
  }
})();
