(function () {
  angular
    .module('app')
    .component('proyect', {
      templateUrl: 'components/proyect/proyect.html',
      controller: ProyectController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ProyectController(ProyectService, UserService, RoleService, config, $mdDialog, SelectService, $state, BreadcrumService, $timeout, Toaster, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = "fechaActualizacion";
    vm.sortAscOrder = false;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;

    vm.data = [];

    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.date = "";
    vm.selectedFilters.division = [];
    vm.selectedFilters.agrupacion = [];
    vm.selectedFilters.contribucionTotal = "";
    vm.selectedFilters.contribucionEntidad = "";
    vm.selectedFilters.estado = "ABIERTO" //ABIERTO, CERRADO, TODOS //Estado por defecto al cargar esta pantalla

    vm.prettyDivision = "";
    vm.selectedDivisions = [];

    vm.currentNavItem = vm.selectedFilters.estado;

    vm.isSupIniOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_INICIATIVA);
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);

    if (UserService.getUserData() &&
      UserService.getUserData().entidad &&
      UserService.getUserData().entidad.abreviatura) {
      vm.nombreEntidad = UserService.getUserData().entidad.abreviatura;
    }
    else {
      vm.nombreEntidad = "Entidad";
    }

    vm.isActivityHistoryEnabled = function(){
      return RoleService.isActivityHistoryEnabled();
    }

    vm.clickFilter = function (filter) {
      //abiertos, cerrados, todos
      if (filter == "TODO") {
        vm.selectedFilters.estado = null;
      }
      else {
        vm.selectedFilters.estado = filter;
      }

      vm.getData();
    }
		
	vm.getToggleOption = function(optionName){
		return UserService.getToggleOption(optionName);
	}
	
    vm.clickNew = function () {
      if (!vm.isSupIniOrLess) {
        vm.saveFilters();
        var proyectform = {id: null, duplicate: false};
        $state.go("proyectform", proyectform);
      }
    }

    vm.saveFilters = function () {
      vm.selectedFilters.sortField = vm.sortField;
      vm.selectedFilters.sortAscOrder = vm.sortAscOrder;
      BreadcrumService.saveFilters(vm.selectedFilters, "proyect");
    }

    vm.clickResume = function (id) {
      vm.saveFilters();
      if (!vm.isSupIniOrLess) {
        $state.go("resume", {id: id});
      }
    }

    vm.clickDuplicar = function (id, index) {
      //proyectform({ id: entidad.id, duplicate:true })
      if (vm.isSupIniOrLess) {
        return;
      }
      if (vm.data[index] && vm.data[index].estado != "ABIERTO") {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }
      vm.saveFilters();
      $state.go("proyectform", {id: id, duplicate: true});
    }

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      ProyectService.getProyects(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.data.forEach(function (elem, index) {
          vm.hideInitiativeVector[index] = true;
        })
        vm.dataLoaded = true;
        vm.busy = false;
      });
    }

    vm.activeDesactive = function (ev, index) {
      if (!vm.isSupIniOrLess) {
        var whenOk = function () {
          ProyectService.activeDesactiveProyect(vm.data[index])
            .then(function (res) {
              vm.getData()
            });
        };
        ModalService.showConfirm(ev,
          vm.data[index].estado == 'ABIERTO' ? 'proyect.cerrarProyecto' : 'proyect.abrirProyecto',
          'proyect.estasSeguro',
          vm.data[index].estado == 'ABIERTO' ? 'proyect.cerrarProyecto' : 'proyect.abrirProyecto',
          'proyect.cancelar',
          whenOk);

      }
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.agrupacion) {
        vm.selectedFilters.agrupacion = selectorObject;
      }

      vm.getData();

    }

    vm.clickSelectorMulti = function (selectorObject, selectorType, index) {
      if (selectorType == SelectService.ENUM.division) {
        if (!vm.selectedDivisions[index]) {
          //si no esta lo metemos
		  vm.selectedDivisions[index] = true;
          vm.selectedFilters.division.push({id: selectorObject.id, nombre: selectorObject.nombre});//TODO changlai para que no pete la llamada          
        }
        else {
          //si esta lo sacamos
		  vm.selectedDivisions[index] = false;
		  function checkIndexOfElement(thisElement) {
			  return thisElement.id === selectorObject.id;
		  }
		  var indexToDelete = vm.selectedFilters.division.findIndex(checkIndexOfElement);
          //var indexToDelete = vm.selectedFilters.division.indexOf(selectorObject);		  
          vm.selectedFilters.division.splice(indexToDelete, 1);  
        }
        vm.prettyDivision = showChanges(vm.selectedFilters.division);
        vm.getData();
      }
    }

    vm.hideInitiativeVector = [];
    vm.hoveredInitiative = null;
    vm.position = {}
    vm.showInitiatives = function ($event, $index) {
      vm.position = {
        top: ($event.pageY + 12) + 'px',
        left: ($event.pageX + 10) + 'px'
      }
      vm.hideInitiativeVector[$index] = false;
    }


    vm.hideInitiatives = function ($index) {
      // vm.hideInitiativeVector[$index] = true;
      $timeout(function () {
        if (vm.hoveredInitiative != $index) {
          vm.hideInitiativeVector[$index] = true;
        }
      }, 2000);
    }

    vm.onHoverInitiative = function ($index) {
      vm.hoveredInitiative = $index
    }

    vm.onLeaveInitiative = function ($index) {
      vm.hideInitiativeVector[$index] = true;
      vm.hoveredInitiative = null;
    }

    vm.clickEdit = function (index) {
      if (vm.isSupIniOrLess && !vm.isSoloVista) {
        return;
      }
      vm.saveFilters();
      $state.go('proyectform', {id: vm.data[index].id, duplicate: false})
    }

    function showChanges(elements) {
      var pretty = "";
      elements.forEach(function (element) {
        if (pretty != "") {
          pretty += ", "
        }
        pretty += element.nombre
      })
      return pretty;
    }

    function fillFilters() {
      SelectService.getAgrupacion().then(function (res) {
        vm.selectorList.agrupacion = res;
      });

      SelectService.getDivision().then(function (res) {
        vm.selectorList.division = res;

        //para el multi
        vm.prettyDivision = "";
		vm.prettyDivision = showChanges(vm.selectedFilters.division);
        vm.selectedDivisions = [];
        vm.selectorList.division.forEach(function (element) {
		  var encontradoSeleccionado = false;
		  vm.selectedFilters.division.forEach(function (selectedElement) {
			  if (element.id == selectedElement.id)
				  encontradoSeleccionado = true;
		  });
		  if (encontradoSeleccionado == false)
			vm.selectedDivisions.push(false);
		  else
			vm.selectedDivisions.push(true);  
        })
        //fin multi
      });
    }

    var auxFilters = BreadcrumService.getFilters("proyect");
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
      if (vm.selectedFilters.date) {
        vm.selectedFilters.date = moment(vm.selectedFilters.date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
	  if (vm.selectedFilters.fecha_actualizacion) {
        vm.selectedFilters.fecha_actualizacion = moment(vm.selectedFilters.fecha_actualizacion).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
      vm.currentNavItem = vm.selectedFilters.estado;
      if (!vm.currentNavItem) {
        vm.currentNavItem = "TODO";
      }
      vm.sortAscOrder = auxFilters.sortAscOrder;
      vm.sortField = auxFilters.sortField;
    }
    vm.getData();
    fillFilters();

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      if (vm.isSupIniOrLess) {
        return;
      }

      if (vm.data[index] && vm.data[index].estado != "ABIERTO") {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }

      var whenOk = function () {
        ProyectService.deleteProyect(vm.data[index])
          .then(function () {
            vm.getData()
          });
      };
      ModalService.showConfirm(ev, 'proyect.borrarProyecto', 'proyect.estasSeguro', 'proyect.borrar', 'proyect.cancelar', whenOk);
    }

    vm.abreCalendario = false;
    vm.abrirCalendario = function () {
      vm.abreCalendario = true;
    }
	
	vm.abreCalendarioUpdate = false;
    vm.abrirCalendarioUpdate = function () {
      vm.abreCalendarioUpdate = true;
    }

    vm.goAssign = function (index) {
      /*if (vm.data[index] && vm.data[index].estado != "ABIERTO") {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }*/
      $state.go("userassign", {idproyect: vm.data[index].id, duplicate: false});
    }

    vm.goUpload = function (index) {
      if (vm.isSupIniOrLess && !vm.isSoloVista) {
        return;
      }
      if (vm.data[index] && vm.data[index].estado != "ABIERTO") {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }

      vm.saveFilters();
      var uploadFormData = {id: vm.data[index].proyecto.id, tipoEntidad: 4, multi: true, idProyecto: null}
      $state.go("uploadform", uploadFormData);
    }

    vm.loadMore = function () {
      if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      ProyectService.getProyects(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        } else {
          vm.page++;
        }

        vm.busy = false;
      });
    };

    

    vm.openActivityHistory = function(ev, index){
      $mdDialog.show({
          controller: 'ActivityHistoryDialogController',
          controllerAs: 'vm',
          templateUrl: 'shared/activityHistory/activityHistoryDialog/activityHistoryDialog.html',
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose:true,
          bindToController: true,
          locals:{
              itemType: config.logEntityClassification.proyecto,
              itemId: vm.data[index].proyecto.id
          },
          fullscreen: true // Only for -xs, -sm breakpoints.,
      })
    };	
  }
})();
