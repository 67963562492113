(function() {
  'use strict';

  angular.module('app')
  .directive('validateCif', validateCifDirective)

  /* @ngInject */
  function validateCifDirective(ValidatorService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, el, attrs, ngModelCtrl){
        ngModelCtrl.$validators.validcif = ValidatorService.validateCIF;
      }
    }
  }
})();
