(function () {
  angular
    .module('app')
    .component('initiative', {
      templateUrl: 'components/initiative/initiative.html',
      controller: InitiativeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function InitiativeController(InitiativeService, UserService, RoleService, config, $mdDialog, SelectService, $state, $stateParams, BreadcrumService, $rootScope, Toaster, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.id = $stateParams.id;
    vm.filterStorageName = "initiative";
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    vm.sortField = "iniciativa.fechaActualizacion";//config.defaultSortField;
    vm.sortAscOrder = false;//config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;

    vm.data = [];

    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.idProyect = vm.id
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.date = "";
	vm.selectedFilters.fecha_actualizacion = "";
    vm.selectedFilters.country = {};
    vm.selectedFilters.motivacion = {};
    vm.selectedFilters.esLBG = null;
    vm.selectedFilters.contribucionTotal = "";
    vm.selectedFilters.contribucionEntidad = "";
    vm.selectedFilters.area = [];
    vm.selectedFilters.motivacion = [];

    vm.isAgentOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().AGENTE);
	vm.isSupIniOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_INICIATIVA);

    vm.proyectClosed = false;

    InitiativeService.getProyectState(vm.id).then(function (res) {
      vm.proyectClosed = !res;
    })

    
    vm.isActivityHistoryEnabled = function(){
      return RoleService.isActivityHistoryEnabled();
    }

    if (UserService.getUserData() &&
      UserService.getUserData().entidad &&
      UserService.getUserData().entidad.abreviatura) {
      vm.nombreEntidad = UserService.getUserData().entidad.abreviatura;
    }
    else {
      vm.nombreEntidad = "Entidad";
    }

    vm.getEsLBGSelectValue = function() {
      switch(vm.selectedFilters.esLBG) {
        case null:
          return ''
        case true:
          return 'initiative.si'
        case false:
          return 'initiative.no'
        default:
          return ''
      }
    }

    vm.clickNew = function () {
      vm.saveFilters();

      var initiativeform = {idproyect: vm.id, id: null, duplicate: false};
      InitiativeService.cleanPersistInitiative();
      $state.go("initiativeform", initiativeform);
    }

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;
      InitiativeService.getInitiatives(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
      });
    }

    vm.clickResume = function (idEntidad) {
      vm.saveFilters();
      if (!vm.isAgentOrLess) {
        $state.go("initiativeresume", {idproyect: vm.id, id: idEntidad});
      }
    }


    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.motivacion) {
        vm.selectedFilters.motivacion = selectorObject;
      }

      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }

      if (selectorType == SelectService.ENUM.area) {
        vm.selectedFilters.area = selectorObject;
      }

      //esLBG
      if (selectorType == SelectService.ENUM.esLBG) {
        if (selectorObject == true) {
          vm.selectedFilters.esLBG = true;
        }
        else if (selectorObject == false) {
          vm.selectedFilters.esLBG = false;
        }
        else {
          vm.selectedFilters.esLBG = null;
        }
      }

      vm.getData();

    }

    function fillFilters() {
      SelectService.getMotivacion().then(function (res) {
        vm.selectorList.motivacion = res;
      });

      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });

      SelectService.getArea().then(function (res) {
        vm.selectorList.area = res;
      })
      vm.selectorList.esLBG = [
        {
          nombre: 'initiative.si',
          value: true
        },
        {
          nombre: 'initiative.no',
          value: false
        }
      ]
    }

    var auxFilters = BreadcrumService.getFilters(vm.filterStorageName);
    if ($stateParams.cleanFilter == 'true') {
      auxFilters = null;
    }
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
	  vm.selectedFilters.idProyect = vm.id;
      if (vm.selectedFilters.date) {
        vm.selectedFilters.date = moment(vm.selectedFilters.date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
	  if (vm.selectedFilters.fecha_actualizacion) {
        vm.selectedFilters.fecha_actualizacion = moment(vm.selectedFilters.fecha_actualizacion).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
      vm.sortAscOrder = auxFilters.sortAscOrder;
      vm.sortField = auxFilters.sortField;
    }
    vm.getData();
    fillFilters();

    vm.saveFilters = function () {
      vm.selectedFilters.sortField = vm.sortField;
      vm.selectedFilters.sortAscOrder = vm.sortAscOrder;
      BreadcrumService.saveFilters(vm.selectedFilters, vm.filterStorageName);
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      if (vm.proyectClosed) {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }

      var whenOk = function () {
        InitiativeService.deleteInitiative(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          });
      };
      ModalService.showConfirm(ev, 'initiative.borrarProyecto', 'initiative.estasSeguro', 'initiative.borrar', 'initiative.cancelar', whenOk);
    }

    vm.abreCalendario = false;
    vm.abrirCalendario = function () {
      vm.abreCalendario = true;
    }
	
	vm.abreCalendarioUpdate = false;
    vm.abrirCalendarioUpdate = function () {
      vm.abreCalendarioUpdate = true;
    }

    vm.duplicate = function (index) {
      if (vm.proyectClosed) {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }
      vm.saveFilters();
      $state.go("initiativeform", {idproyect: vm.id, id: vm.data[index].id, duplicate: true})
    }

    vm.goAssign = function (index) {
      if (vm.proyectClosed) {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }
      $state.go("userassigninitiative", {idproyect: $stateParams.id, id: vm.data[index].id});
    }

    vm.goUpload = function (index) {
      if (vm.proyectClosed) {
        Toaster.simpleToast('proyectclosed.alert');
        return;
      }
      vm.saveFilters();
      var element = vm.data[index];
      var uploadFormData = {id: element.id, tipoEntidad: 5, multi: true, idProyecto: vm.id}
      $state.go("uploadform", uploadFormData);
    }

    vm.clickEdit = function (id) {
      vm.saveFilters();
      $state.go('initiativeform', {idproyect: vm.id, id: id, duplicate: false})
    }

    vm.loadMore = function () {
      if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      InitiativeService.getInitiatives(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        } else {
          vm.page++;
        }

        vm.busy = false;
      });
    };

    /*
    vm.loadMore = function () {
      if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      ProyectService.getProyects(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        } else {
          vm.page++;
        }

        vm.busy = false;
      });
    };
    */
    vm.openActivityHistory = function(ev, index){
      $mdDialog.show({
          controller: 'ActivityHistoryDialogController',
          controllerAs: 'vm',
          templateUrl: 'shared/activityHistory/activityHistoryDialog/activityHistoryDialog.html',
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose:true,
          bindToController: true,
          locals:{
              itemType: config.logEntityClassification.iniciativa,
              itemId: vm.data[index].iniciativa.id
          },
          fullscreen: true // Only for -xs, -sm breakpoints.,
      })
    };

  }
})();
