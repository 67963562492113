(function () {
  'use strict';

  angular
    .module('app')
    .service('ContributionsService', ContributionsService);

  /* @ngInject */
  function ContributionsService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getContributions = function (idDatosIniciativa) {
      var url = config.api + "/apiV2/contribucions";
      if (idDatosIniciativa) {
        var params = $httpParamSerializer({
          datosIniciativa: idDatosIniciativa,
        });
        url += "?" + params;
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.createContribution = function (entity) {
      var url = api + "/apiV2/contribucions";

      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }
	
	service.getIfActiveLey112018 = function () {
      var url = api + "/apiV2/contribucions/ley112018";

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.updateContribution = function (entity) {
      var url = api + "/apiV2/contribucions";

      if (entity && entity.id != null) {
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }
	
	service.updateContributionButNoRecalculate = function (entity) {
      var url = api + "/apiV2/contribucions";
	  //var url = api + "/api/contribucions/norecalculate";

      if (entity && entity.id != null) {
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteContribution = function (entity) {
      var url = api + "/apiV2/contribucions";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.validateContribution = function (entity) {
      ///api/contribucions/validate/{id}
      var url = api + "/apiV2/contribucions/validate";
      if (entity && entity.id != null) {

        url += "/" + entity.id;

        return $http.put(url, {}, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.getSumatorio = function (
      idDatosIniciativa, tipo,
      idTipoContribuyente, idColaborador,
      atributionIds
    ) {
      var url = api + "/apiV2/contribucions/sum";
      var params = $httpParamSerializer({
        datosIniciativa: idDatosIniciativa,
        tipo: tipo,
        tipoContribuyente: idTipoContribuyente,
        colaborador: idColaborador,
        atribucion: atributionIds
      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.validateDevalidateAll = function (idDatosIniciativa, validate) {
      //http://localhost:8080/api/contribucions/validate/all?datosIniciativa=34&validar=true
      var url = api + "/api/contribucions/validate/all"
      if (idDatosIniciativa) {
        url += "?datosIniciativa=" + idDatosIniciativa + "&validar=" + (validate ? 'true' : 'false')
      }

      return $http.put(url, {headers: headers}).then(function (response) {
        return response.data
      });

    }

    return service;
  }

})();
