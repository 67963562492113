/* eslint angular/window-service:0 */

(function(angular) {
  angular
    .module('app', [
      'ui.router',
      'ui.mask',
      'ngMaterial',
      'ngAnimate',
      'infinite-scroll',
      'pascalprecht.translate',
      'ngFileUpload',
      'ngFileSaver'
    ]);
})(window.angular);
