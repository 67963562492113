(function () {
  'use strict';

  angular
    .module('app')
    .service('SelectService', SelectService);

  /* @ngInject */
  function SelectService($http, $httpParamSerializer, $q, $translate, $filter, config, TokenService, RoleService, EntityService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};
    service.ENUM = {}

    service.ENUM.type = "type";
    service.getType = function () {
      var url = config.api + "/api/tipo-entidads";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: request_headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.country = "country";
    service.getCountry = function () {
      var url = config.api + "/apiV2/pais";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: request_headers}).then(function (response) {
        return response.data;
      }).then(function(countries) {
        return $q(function (resolve) {
          service.sortCountriesByTranslatedName(countries).then(function(sortedCountries) {
             resolve(sortedCountries);
          });
        });
      });
    }

    service.sortCountriesByTranslatedName = function(countries) {
      var deferred = $q.defer();

      var translationKeys = [];
      angular.forEach(countries, function(country) {
        translationKeys.push(country.nombre);
      });

      $translate(translationKeys).then(function(translation) {
        angular.forEach(countries, function(country) {
          country.nombreTraducido = translation[country.nombre];
        });

        var spain = countries[0];
        countries.splice(0, 1); // remove spain from the array
		
		var global = countries[0];
        countries.splice(0, 1); // remove global from the array

        var sortedCountries = $filter('orderBy')(countries, 'nombreTraducido');
		sortedCountries.unshift(global); // Global goes second regardless of alphabetical order
        sortedCountries.unshift(spain); // Spain goes first regardless of alphabetical order		

        deferred.resolve(sortedCountries);
      });

      return deferred.promise;
    }

    service.ENUM.lgbGroup = "lgbGroup";
    service.getLGBGroup = function () {
      var url = config.api + "/api/grupo-lbgs";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: request_headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.plan = "plan";
    service.getPlan = function (grupoLBG) {
      var url = config.api + "/api/plan-usos";
      if (grupoLBG && grupoLBG.id) {
        var params = $httpParamSerializer({
          grupoLBG: grupoLBG.id,
          activo: true
        });
        url += "?" + params;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.active = "active";

    service.ENUM.lgbSector = "lgbSector";
    service.getLGBSector = function () {
      var url = config.api + "/api/sector-lbgs";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: request_headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.changeType = "changeType";
    service.getChangeTypes = function (grupoLBG) {
      var url = config.api + "/api/tipo-cambios";
      if (grupoLBG && grupoLBG.id) {
        var params = $httpParamSerializer({
          grupoLBG: grupoLBG.id,
        });
        url += "?" + params;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.badge = "badge";
    service.getBadges = function () {
      var url = config.api + "/apiV2/divisas";

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.state = "state";
    service.getStates = function (pais) {
      var url = config.api + "/apiV2/regions";
      if (pais && pais.id) {
        var params = $httpParamSerializer({
          pais: pais.id,
        });
        url += "?" + params;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.agrupacion = "agrupacion";
    service.getAgrupacion = function () {
      var url = config.api + "/apiV2/agrupacion-internas";
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.agrupacion = "agrupacion";
    service.getAgrupacionIniciativa = function () {
      var url = config.api + "/apiV2/agrupacion-iniciativa-internas";
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.division = "division";
    service.getDivision = function () {
      var url = config.api + "/apiV2/unidad-negocios";
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.motivacion = 'motivacion';
    service.getMotivacion = function (idColaborator, tipo) {
      //motivacions/tipo-colaborador?colaborador=12&tipo=ENTIDAD
      //&tipo=ENTIDAD
      var url = config.api + "/apiV2/motivacions";
      if (tipo == "ENTIDAD") {
        url += "/tipo-colaborador?tipo=" + tipo
      }
      else if (tipo == "COLABORADOR") {
        if (idColaborator) {
          //TODO
          url += "/tipo-colaborador?colaborador=" + idColaborator + "&tipo=" + tipo;
        }
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.area = 'area';
    service.getArea = function () {
      var url = config.api + "/apiV2/areas";
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }
	
	service.getProyects = function () {
      var url = config.api + "/apiV2/list-proyectos?estado=ABIERTO";
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.razon = 'razon';
    service.getRazon = function () {
      var url = config.api + "/apiV2/razons";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.ods = 'ods';
    service.getOds = function () {
      var url = config.api + "/api/o-ds";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.meta = 'meta';
    service.getMeta = function (idOds) {
      var url = config.api + "/apiV2/metas";
      if (idOds) {
        url += "/ods?ods=" + idOds;
      }
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }
      return $http.get(url, {headers: request_headers}).then(function (response) {
        return response.data;
      });
    }

    service.ENUM.quien = 'quien';
    service.getQuien = function (tipo, datosIniciativa) {
      var url = config.api + "/apiV2/contribucions/quien";
      var params = $httpParamSerializer({
        tipo: tipo,
        datosIniciativa: datosIniciativa,
      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });

    }

    service.ENUM.salaryTables = 'salaryTables';
    service.getSalaryTables = function (idDatosIniciativa) {
      var url = config.api + "/apiV2/tabla-salarials";

      if (idDatosIniciativa) {
        url += "?datosIniciativa=" + idDatosIniciativa;
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.ENUM.situacion = 'situacion';
    service.getSituacion = function () {
      var url = config.api + "/api/situacions";
      var request_headers = {cache: true};
      for (var attrname in headers) { request_headers[attrname] = headers[attrname]; }

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.ENUM.tipoLogro = 'tipoLogro';
    service.getTipoLogro = function () {
      var url = config.api + "/api/tipo-logroes";

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.ENUM.colaboracionIniciativa = 'colaboracionIniciativa';
    service.getColaboracionIniciativa = function (idDatosIniciativa) {
      var url = config.api + "/api/colaboracion-iniciativas/datos-iniciativa";

      if (idDatosIniciativa) {
        url += "/" + idDatosIniciativa;
      }

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
          .map(function (elem) {
            if (elem.colaborador) {
              elem.nombre = elem.colaborador.nombre;
            }
            return elem;
          })
          .filter(function (elem) {
            if (elem.colaborador) {
              return true;
            }
            else {
              return false;
            }
          })
      });
    }

    service.ENUM.grupoInteres = 'grupoInteres';
    service.getGrupoInteres = function () {
      var url = config.api + "/api/grupo-interes";

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.ENUM.quienTiempo = 'grupoInteres';
    service.getQuienTiempo = function (idDatosIniciativa) {
      var url = config.api + "/api/contribucions/quien-tiempo";
      if (idDatosIniciativa) {
        url += "?datosIniciativa=" + idDatosIniciativa;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data.map(function (elem) {
          if (elem.contribuyente) {
            elem.id = elem.contribuyente.id;
            elem.contribuyenteId = elem.id;
          }
          return elem;
        })
      });
    }

    //http://localhost:8080/api/organizaciones-beneficiarias/datos-iniciativa?datosIniciativa=15
    service.ENUM.entidadesParticipantes = 'entidadesParticipantes';
    service.getEntidadParticipantes = function (idDatosIniciativa) {
      var url = config.api + "/api/organizaciones-beneficiarias/datos-iniciativa";
      if (idDatosIniciativa) {
        url += "?datosIniciativa=" + idDatosIniciativa;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }


    service.ENUM.esLBG = 'esLBG'

    service.ENUM.years = "years";
    service.getYears = function () {
      var years = [];
      var currentYear = moment().year();
	  years.push($translate.instant("filterReport.todos"));
      years.push(currentYear - 7);
	  years.push(currentYear - 6);
	  years.push(currentYear - 5);
      years.push(currentYear - 4);
      years.push(currentYear - 3);
      years.push(currentYear - 2);
      years.push(currentYear - 1);
      years.push(currentYear);
      years.push(currentYear + 1);
      years.push(currentYear + 2);
      years.push(currentYear + 3);
      years.push(currentYear + 4);
      years.push(currentYear + 5);
	  years.push(currentYear + 6);
	  years.push(currentYear + 7);
      return years;
    }

    service.ENUM.role = "role";
    service.getRoles = function () {
      var roles = [];

      if (RoleService.hasMoreRole(RoleService.getEnum().ADMINISTRADOR)) {
        roles.push({
          enum: RoleService.getEnum().ADMINISTRADOR
        })
      }

      if (RoleService.hasMoreRole(RoleService.getEnum().COORDINADOR_GRUPO)) {
        roles.push({
          enum: RoleService.getEnum().COORDINADOR_GRUPO
        })
      }

      if (RoleService.hasMoreRole(RoleService.getEnum().RESPONSABLE)) {
        roles.push({
          enum: RoleService.getEnum().RESPONSABLE
        })
      }

      if (RoleService.hasMoreRole(RoleService.getEnum().GERENTE)) {
        roles.push({
          enum: RoleService.getEnum().GERENTE
        })
      }

      if (RoleService.hasMoreRole(RoleService.getEnum().SUPERVISOR_PROYECTO)) {
        roles.push({
          enum: RoleService.getEnum().SUPERVISOR_PROYECTO
        })
      }

      if (RoleService.hasMoreRole(RoleService.getEnum().SUPERVISOR_INICIATIVA)) {
        roles.push({
          enum: RoleService.getEnum().SUPERVISOR_INICIATIVA
        })
      }

      if (RoleService.hasMoreRole(RoleService.getEnum().AGENTE)) {
        roles.push({
          enum: RoleService.getEnum().AGENTE
        })
      }

	  if (RoleService.hasMoreRole(RoleService.getEnum().SUPERVISOR_PROYECTO)) {
		  roles.push({
			  enum: RoleService.getEnum().SOLOVISTA
		  })
	  }

      return roles;
    }

    service.getAllRoles = function () {
      return [
        { enum: RoleService.getEnum().ADMINISTRADOR },
        { enum: RoleService.getEnum().COORDINADOR_GRUPO },
        { enum: RoleService.getEnum().RESPONSABLE },
        { enum: RoleService.getEnum().GERENTE },
        { enum: RoleService.getEnum().SUPERVISOR_PROYECTO },
        { enum: RoleService.getEnum().SUPERVISOR_INICIATIVA },
        { enum: RoleService.getEnum().AGENTE },
		{ enum: RoleService.getEnum().SOLOVISTA },
      ];
    }

    service.ENUM.como = "como";
    service.getComo = function () {
      return [
        { enum: 'DINERO' },
        { enum: 'ESPECIE' },
        { enum: 'TIEMPO' },
        { enum: 'PROBONO' },
        { enum: 'COSTE_GESTION' },
      ];
    }

    service.ENUM.grado = "grado";
    service.getGrado = function () {
      return [
       { enum: 'ALTO' },
       { enum: 'MEDIO' },
       { enum: 'BAJO' },
      ];
    }

    service.ENUM.tipoMejoria = "tipoMejoria";
    service.getTipoMejoria = function () {
      return [
        { enum: 'SIN_CAMBIOS' },
        { enum: 'POCO' },
        { enum: 'ALGO' },
        { enum: 'BASTANTE' },
      ];
    }

    service.ENUM.tipoOrgBeneficiaria = "tipoOrgBeneficiaria ";
    service.getTipoOrgBeneficiaria = function () {
      return [
        { enum: 'COLABORADOR' },
        { enum: 'ORG_BENEFICIARIA' },
      ];
    }

    service.ENUM.representativeEntity = "representativeEntity";
    service.getRepresentativeEntityEntities = function () {
      //return EntityService.getEntities();
      var url = config.api + "/api/usuarios/lista/representante";

      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data
      });
    }

    service.ENUM.responsibleEntity = "responsibleEntity";
    service.getResponsibleEntity = function () {
      return EntityService.getEntities(0,100000);
    }

    return service;
  }

})();
