(function () {
  'use strict';

  angular
    .module('app')
    .service('HomeService', HomeService);

  /* @ngInject */
  function HomeService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    return service;
  }


})();
