(function () {
  angular
    .module('app')
    .component('plan', {
      templateUrl: 'components/plan/plan.html',
      controller: PlanController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function PlanController(PlanService, config, $mdDialog, SelectService, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.allDataLoaded = false;
    vm.disableInfinite = false;

    vm.data = [];
    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.type = {};
    vm.selectedFilters.country = {};
    vm.selectedFilters.lgbGroup = {};
    vm.selectedFilters.plan = {};
    vm.selectedFilters.active = null;

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      PlanService.getPlans(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
      });
    }

    vm.activeDesactive = function (ev, index) {
      var whenOk = function () {
        PlanService.activeDesactivePlan(vm.data[index])
          .then(function (res) {
            if (res) {
              vm.data[index].activo = res.activo;
            }
            //vm.getData()
          })
        // .catch(function (e) {
        //   showAlert(ev, 'Error ' + vm.data[index].activo ? 'desactivando' : 'activando', getErrorMessage(e), 'ok');
        // })
        ;
      };
      ModalService.showConfirm(ev,
        vm.data[index].activo ? 'plan.desactivar' : 'plan.activar',
        'plan.estasSeguro',
        vm.data[index].activo ? 'plan.desactivar' : 'plan.activar',
        'plan.cancelar',
        whenOk);
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.type) {
        vm.selectedFilters.type = selectorObject;
      }
      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }
      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.selectedFilters.lgbGroup = selectorObject;
      }
      if (selectorType == SelectService.ENUM.plan) {
        vm.selectedFilters.plan = selectorObject;
      }
      if (selectorType == SelectService.ENUM.active) {
        vm.selectedFilters.active = selectorObject;
      }

      vm.getData();

    }

    function fillFilters() {
      SelectService.getType().then(function (res) {
        vm.selectorList.type = res;
      });

      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });

      SelectService.getLGBGroup().then(function (res) {
        vm.selectorList.lgbGroup = res;
      });

      SelectService.getPlan().then(function (res) {
        vm.selectorList.plan = res;
      });
    }

    vm.getData();
    fillFilters();

    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor";
      }
      else {
        return "ng-binding material-icons";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        PlanService.deletePlan(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          })
        // .catch(function (e) {
        //   showAlert(ev, 'Error de Borrando', getErrorMessage(e), 'ok');
        // })
        ;
      };
      ModalService.showConfirm(ev, 'plan.borrarPlan', 'plan.estasSeguro', 'plan.borrar', 'plan.cancelar', whenOk);
    }

    vm.loadMore = function () {
      //TODO de momento lo obviamos porque no hay paginacion
      // if (vm.busy) return;
      // vm.busy = true;
      //
      // PlanService.getPlans(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder).then(function (res) {
      //   res.forEach(function (element) {
      //     vm.data.push(element);
      //   });
      //   vm.busy = false;
      //   if (res.length < vm.size) {
      //     vm.allDataLoaded = true;
      //   }
      //   else {
      //     vm.page++;
      //   }
      //
      //   vm.disableInfinite = vm.busy | vm.allDataLoaded;
      // });
      //
      // vm.disableInfinite = vm.busy | vm.allDataLoaded;

    };

  }
})();
