(function () {
    'use strict';

    angular
        .module('app')
        .service('EnumerationsService', EnumerationsService);

    /* @ngInject */
    function EnumerationsService($http, $httpParamSerializer, config, $q) {

        var service = {};

        service.log_activity_url = config.api + "/api/enumerations";

        service.logTypes = [];
        service.logItems = [];

        service.loadLogTypes = loadLogTypes;
        service.getLogTypes = getLogTypes;
        service.loadLogItems = loadLogItems;
        service.getLogItems = getLogItems;
        service.loadLogActions = loadLogActions;
        service.getLogActions = getLogActions;


        function loadLogTypes() {
            var url = service.log_activity_url + "/activity-log/log-types";
            return $http.get(url, { cache: true, headers: service.headers}).then(function (enumerators) {
                if (angular.isDefined(enumerators.data)){
                    service.logTypes = enumerators.data;
                    return enumerators.data;
                }
            });
        }
        function getLogTypes() {
            return service.logTypes;
        }

        function loadLogItems() {
            var url = service.log_activity_url + "/activity-log/log-items";
            return $http.get(url, { cache: true, headers: service.headers}).then(function (enumerators) {
                if (angular.isDefined(enumerators.data)){
                    service.logItems = enumerators.data;
                    return enumerators.data;
                }
            });
        }
        function getLogItems() {
            return service.logItems;
        }

        function loadLogActions() {
            var url = service.log_activity_url + "/activity-log/log-actions";
            return $http.get(url, { cache: true, headers: service.headers}).then(function (enumerators) {
                if (angular.isDefined(enumerators.data)){
                    service.logActions = enumerators.data;
                    return enumerators.data;
                }
            });
        }
        function getLogActions() {
            return service.logActions;
        }

        service.loadLogActions();
        service.loadLogItems();
        service.loadLogTypes();
        return service;
    }
})();