(function () {
  angular
    .module('app')
    .component('manage', {
      templateUrl: 'components/manage/manage.html',
      controller: ManageController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ManageController($state, $scope, RoleService) {
    var vm = this;

    vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
    vm.isSupProyOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_PROYECTO);

    vm.isAdministrador = RoleService.hasRole(RoleService.getEnum().ADMINISTRADOR);
    vm.isCoordinadorGrupo = RoleService.hasRole(RoleService.getEnum().COORDINADOR_GRUPO);
    vm.isAgente = RoleService.hasRole(RoleService.getEnum().AGENTE);
    vm.hideCollaborator = vm.isAdministrador | vm.isCoordinadorGrupo | vm.isAgente;

    vm.currentNavItem = 'user';//Entidad a la que entramos por defecto

    vm.arrayButtons = [];
    vm.arrayButtons['entity'] = {
      buttonName: 'manage.nuevaEntidad',
      newStateName: 'manage.entityform',
      title: 'manage.entidad'
    }
    vm.arrayButtons['user'] = {
      buttonName: 'manage.nuevoUsuario',
      newStateName: 'manage.userform',
      title: 'manage.usuario'
    }
    vm.arrayButtons['plan'] = {
      buttonName: 'manage.nuevoPlan',
      newStateName: 'manage.planform',
      title: 'manage.planUso'
    }
    vm.arrayButtons['exchangeRate'] = {
      buttonName: 'manage.nuevoTipoCambio',
      newStateName: 'manage.exchangeRateForm',
      title: 'manage.tipoCambio'
    }
    vm.arrayButtons['collaborators'] = {
      buttonName: 'manage.nuevoColaborador',
      newStateName: 'manage.collaboratorsform',
      title: 'manage.colaborador'
    }

    vm.clickNew = function () {
      $state.go(vm.arrayButtons[vm.currentNavItem].newStateName, {id: null});

    };

     $scope.$watch(function(){
          return $state.$current.name
      }, function(newVal, oldVal){
       vm.isForm = newVal.toLowerCase().indexOf("form") > -1 ? true : false;
       vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
       vm.isSupProyOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().SUPERVISOR_PROYECTO);
       //si accedemos a la raiz llevamos a la entidad por defecto
       if (newVal == "manage") {
         $state.go('manage.' + vm.currentNavItem);
       }
       else if (vm.isForm) {
         vm.currentNavItem = newVal.toLowerCase().split('form')[0].split('.')[1];
       }
       else if (newVal.split('.').length == 2) {
         vm.currentNavItem = newVal.split('.')[1];
       }
      })

  }


})();
