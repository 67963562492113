(function () {
  'use strict';

  angular
    .module('app')
    .service('ForgotPassService', ForgotPassService);

  /* @ngInject */
  function ForgotPassService($http, config, TokenService, UserService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.resetPassInit = function (email) {
      var url = api + "/api/account/reset_password/init";
      return $http.post(url, email, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.resetPassFinish = function (key, newPass) {
      var url = api + "/api/account/reset_password/finish";
      var data = {
        key: key,
        newPassword: newPass
      };
      return $http.post(url, data, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    return service;
  }
})();
