angular
  .module("app")
  .constant("translations_IT", {
    "yes":"Sí",
	"no":"No",
	"logout.cerrarSesion":"Chiudi Sessione",
	"logout.preguntaCerrarSesion":"Chiudere sessione?",
	"location":"ODS_iconsIT",
	"error.internalServerError":"Si è verificato un errore. Rivolgersi all'Amministratore per ulteriori informazioni.",
	"err.genericError":"Si è verificato un errore. Rivolgersi all'Amministratore per ulteriori informazioni.",
	"Bad credentials":"Errore di autenticazione: Utente o password non corretti",
	"error.accessDenied":"Non si dispone dei permessi richiesti per eseguire questa azione",
	"error.requiredFieldEmpty":"Non sono stati compilati tutti i campi richiesti",
	"error.notFoundEntity":"L'elemento al quale si sta cercando di accedere non esiste",
	"error.noPossibleEditEntity":"Impossibile modificare questo elemento, perché è attualmente in uso",
	"error.duplicateEntity":"Impossibile creare l'elemento, perchè esiste già",
	"err.excelRequired":"Impossibile salvare se non si allega un file excel",
	"err.grupoLBGHasAResposible":"Al gruppo LBG selezionato è già stato assegnato un responsabile",
	"err.entidadHasAResposible":"Alla società selezionata è già stato assegnato un responsabile",
	"err.planUsoMaxUserExceeded":"Il piano non dispone di altri utenti con il ruolo selezionato",
	"err.budgetOverflow":"Il budget della società non deve essere maggiore del budget totale assegnato al progetto",
	"err.notTypeChange":"Non ci sono tipi di cambiamento applicabili al progetto",
	"err.notValueTypeChange":"Non è stato indicato alcun valore nella tipologia di cambiamento per questo progetto",
	"err.deleteDocument":"Errore durante l'eliminazione del documento",
	"err.DateOutRange":"La data inserita non è compresa nell'intervallo predefinito",
	"err.percentajeArea":"La somma delle percentuali di area è superiore al 100%",
	"err.percentajeODS":"La somma delle percentuali di SDG è superiore al 100%",
	"err.requiredFieldColaboration":"I campi richiesti dell'elenco dei collaboratori sono vuoti",
	"err.FailsBeneficiaryRequirement":"L’impatto supera la soglia massima di beneficiari previsti",
	"err.EmpEvaluatedGreaterParticipant":"I dipendenti valutati non possono superare i partecipanti",
	"err.EntityEvaluatedGreaterParticipant":"Le entità valutate non possono superare le entità participanti",
	"err.incompleteArea":"Informazioni incomplete sull'Area",
	"err.incompleteODS":"Informazioni incomplete sugli Obiettivi",
	"err.dateIniciativeBeforeDateProject":"La data di inizio progetto è successiva alla data di fine progetto",
	"err.dateIniciativeAfterDateProject":"La data di inizio iniziativa è successiva alla data di fine progetto",
	"err.NotDeleteValidContribution":"Impossibile eliminare un contributo convalidato",
	"err.sessionExpired":"La sessione è scaduta, inserire nuovamente le credenziali di accesso",
	"err.NotEditValidContribution":"Impossibile modificare un contributo convalidato",
	"err.NotEditValidAchievement":"Impossibile modificare un risultato convalidato",
	"err.NotEditValidImpact":"Impossibile modificare un impatto convalidato",
	"usuario.rol.error.coordinador":"Impossibile modificare il ruolo dell'utente. È gia stato assegnato un coordinatore al gruppo",
	"usuario.rol.error.responsable":"Impossibile modificare il ruolo dell'utente. È gia stato assegnato un responsabile all'entità",
	"usuario.rol.error.disponibles":"Impossibile modificare il ruolo dell'utente. Non ci sono ruoli disponibili nel piano di utilizzo",
	"resumen.proyecto.no.existe":"Il progetto selezionato non esiste",
	"resumen.proyecto.usuario.no.existe":"Non vi sono sessioni attive di utenti",
	"agrupacion.interna.no.existe":"Non esistono gruppi di lavoro",
	"agrupacion.interna.duplicate":"Esiste già un gruppo con questo nome",
	"agrupacion.IniciativaInterna.duplicate":"Esiste già un gruppo con questo nome",	
	"agrupacion.interna.requerido.nombre ":"Indicare il nome del gruppo",
	"colaborador.no.existe":"Il collaboratore selezionato non esiste",
	"colaborador.presupuesto.no.existe":"Il budget del collaboratore non esiste",
	"colaborador.representante.no.existe":"Il collaboratore rappresentante non esiste",
	"colaborador.requerido.nombre":"Si deve inserire il nome del collaboratore",
	"colaborador.requerido.tipo":"Si deve indicare la tipologia di collaboratore",
	"contribucion.no.existe":"Il contributo selezionato non esiste",
	"contribucion.tabla.salarial.no.existe":"La tabella dei contribut non esiste",
	"contribucion.fuera.rango":"Contributo non compreso nell'intervallo predefinito",
	"contribucion.requerido.tipo":"Si deve indicare la tipologia di contributo",
	"contribucion.requerido.contribuyente":"Si deve indicare il contribuente",
	"contribucion.requerido.fecha":"Si deve indicare la data del contributo",
	"contribucion.requerido.datos.iniciativa":"Non sono state indicate iniziative per il contributo",
	"contribucion.requerido.cantidad.y.divisa":"Si deve indicare la quantità e la valuta del contributo",
	"contribucion.requerido.numero.persona.y.numero.horas.persona":"Si deve indicare il numero di persone e il totale delle ore del contributo",
	"contribucion.description.255max":"255 caratteri max",
	"iniciativa.no.existe":"L'iniziativa non esiste",
	"usuario.no.existe":"L'utente selezionato non esiste",
	"err.mailExist ":"L'email indicata esiste già",
	"err.usuarioExist":"Esiste già un utente con l'email indicata",
	"usuario.requerido.nombre":"Si deve inserire il nome dell'utente",
	"usuario.requerido.apellidos":"Si deve inserire il cognome dell'utente",
	"usuario.requerido.email":"Si deve inserire l'email dell'utente",
	"usuario.requerido.rol":"Si deve selezionare il ruolo dell'utente",
	"usuario.requerido.entidad.alta":"Si deve indicare l'entità di appartenenza dell'utente",
	"usuario.requerido.pais":"Si deve indicare il paese dell'utente",
	"usuario.requerido.email.contacto.coordinador.grupo":"Si deve indicare l'email di contatto",
	"usuario.requerido.grupo.lbg":"Si deve indicare il gruppo LBG del coordinatore",
	"proyecto.no.existe":"Il progetto selezionato non esiste",
	"entidad.no.existe":"L'entità selezionata non esiste",
	"entidad.representante.no.existe":"L'entità rappresentante non esiste",
	"entidad.representante.requerido":"Si deve indicare l'entità rappresentante",
	"entidad.alta.no.existe":"L'entità di appartenenza non esiste",
	"entidad.requerido.nombre":"Si deve indicare il nome dell'entità",
	"entidad.requerido.abreviatura":"Si deve indicare l'abbreviazione dell'entità",
	"entidad.requerido.tipo":"Si deve indicare la tipologia di entità",
	"entidad.requerido.sector":"Si deve indicare il settore LBG",
	"entidad.requerido.plan.uso":"Si deve indicare un piano di utilizzo",
	"entidad.requerido.pais":"Si deve indicare il paese dell'entità",
	"entidad.requerido.tipo.cambio":"Si deve indicare almeno una tipologia di cambiamento",
	"entidad.requerido.grupo.lbg":"Si deve indicare il gruppo LBG d'appartenenza dell'entità",
	"impacto.no.existe":"L'impatto selezionato non esiste",
	"impacto.requerido.fecha.evaluacion":"Si deve indicare la data di valutazione",
	"impacto.requerido.datos.iniciativa":"Si deve indicare l'iniziativa",
	"impacto.validacion.beneficiarios.evaluados":"Il totale del livello di impatto non può superare il numero di beneficiari valutati",
	"impacto.empleado.requerido.contribuyente":"Si deve indicare un contribuente",
	"impacto.empleado.validacion.evaluados.mayor.participantes":"Il numero dei partecipanti valutati non può superare quello dei partecipanti",
	"impacto.participante.requerido.entidad":"Si deve indicare l'entità participante",
	"logro.no.existe":"Il risultato selezionato non esiste",
	"logro.requerido.fecha.evaluacion":"Si deve indicare la data di valutazione",
	"logro.requerido.datos.iniciativa":"Si deve indicare l'iniziativa per questo risultato",
	"plan.uso.no.existe":"Il piano di utilizzo selezionato non esiste",
	"plan.uso.requerido.nombre":"Si deve indicare il nome del piano di utilizzo",
	"plan.uso.requerido.gerente":"Si deve indicare il numero dei gestori",
	"plan.uso.requerido.supervisor.proyecto":"Si deve indicare il numero di supervisori del progetto",
	"plan.uso.requerido.supervisor.iniciativa":"Si deve indicare il numero di supervisori dell'iniziativa",
	"plan.uso.requerido.agente":"Si deve indicare il numero di agenti",
	"tabla.salarial.no.existe":"La tabella dei contribut non esiste",
	"tabla.salarial.requerido.nombre":"Si deve inserire  il nome della tabella dei contribut",
	"tabla.salarial.requerido.total.coste":"Si deve inserire il costo totale",
	"tabla.salarial.requerido.divisa":"Si deve indicare la valuta associata al costo totale",
	"tabla.salarial.requerido.agno":"Si deve indicare l'anno",
	"tabla.salarial.requerido.agno.aplicacion":"Si deve indicare l'anno di applicazione",
	"unidad.negocio.no.existe":"L'unità operativa non esiste",
	"unidad.negocio.requerido.nombre":"Si deve inserire il nome dell'unità operativa",
	"ods.no.existe":"L'SDG selezionato non esiste",
	"pais.no.existe":"Il paese selezionato non esiste",
	"pais.requerido":"Si deve indicare il paese",
	"divisa.no.existe":"La valuta selezionata non esiste",
	"asignacion.presupuesto.iniciativa.requerido.presupuesto":"Si deve indicare un importo per il budget",
	"asignacion.presupuesto.iniciativa.requerido.divisa":"Si deve indicare la valuta del budget",
	"datos.iniciativa.requerido.nombre":"Si deve indicare il nome dell'iniziativa",
	"datos.iniciativa.requerido.agrupacionIniciativaInterna":"Devi indicare il raggruppamento di iniziativa interno",
	"datos.iniciativa.requerido.fecha.inicio":"Si deve indicare la data di inizio dell'iniziativa",
	"datos.iniciativa.requerido.pais":"Si deve indicare il paese",
	"datos.proyecto.requerido.fecha.inicio":"Si deve indicare la data di inizio del progetto",
	"datos.proyecto.requerido.unidad":"Si deve indicare l'unità operativa per il progetto",
	"datos.proyecto.requerido.nombre":"Si deve indicare il nome del progetto",
	"datos.proyecto.requerido.divisa.ppto.entidad":"Si deve indicare la valuta del budget della sua entità",
	"datos.proyecto.requerido.divisa.ppto.total":"Si deve indicare la valuta del budget total",
	"organizaciones.beneficiarias.requerido.nombre":"Si deve indicare il nome dell'organizzazione",
	"tipo.cambio.requerido.nombre":"Si deve indicare il nome del tasso di cambio",
	"tipo.cambio.requerido.descripcion":"Si deve inserire una descrizione",
	"tipo.cambio.requerido.fecha.desde":"Si deve indicare la data di entrata in vigore del tasso di cambio",
	"tipo.cambio.requerido.fecha.hasta":"Si deve indicare la data di scadenza del tasso di cambio",
	"tipo.cambio.requerido.grupo.lbg":"Si deve indicare il gruppo LBG al quale sarà applicato il tasso di cambio",
	"permiso.denegado":"Non si dispone delle autorizzazioni necessarie per eseguire questa azione",
	"permiso.denegado.iniciativa":"Non si dispone delle autorizzazioni necessarie per accedere all'iniziativa",
	"permiso.denegado.contribucion":"Non si dispone delle autorizzazioni necessarie per accedere a contributi",
	"permiso.denegado.logro":"Non si dispone delle autorizzazioni necessarie per accedere a risultati",
	"permiso.denegado.impacto":"Non si dispone delle autorizzazioni necessarie per accedere a impatti",
	"datos.iniciativa.validacion.fuera.rango.proyecto":"La data dell'iniziativa deve essere compresa fra la data di inizio e quella di completamento del progetto",
	"colaboracion.iniciativa.requerido.lista.colaboradores.motivacion.propia":"Si deve indicare una motivazione per la sua entità",
	"colaboracion.iniciativa.requerido.lista.colaboradores.grado.implicacion":"Si deve indicare un livello di coinvolgimento",
	"iniciativa.ods.requerido.porcentaje.primario":"Si deve inserire la percentuale dell'SDG primario",
	"iniciativa.ods.validacion.porcentaje.incompleto":"La percentuale totale dell'SDG deve corrispondere al 100%",
	"iniciativa.requerido.area.primaria":"Si deve indicare l'area primaria",
	"iniciativa.validacion.area.porcentaje.incompleto":"La percentuale totale delle aree deve corrispondere al 100%",
	"unidad.negocio.validacion.nombre.duplicado":"Esiste già un'unità operativa con questo nome",
	"tabla.salarial.requerido.divisa.coste.hora":"Si deve indicare la valuta associata al costo per ora",
	"entidad.validacion.nombre.duplicado":"Il nome dell'entità esiste già",
	"contacto.email.campos.requeridos":"Tutti i campi sono obbligatori",
	"impacto.empleado.validacion.evaluados.mayor.participantes.actitud":"Il numero dei partecipanti di cui si è valutato l'atteggiamento non può essere maggiore di quello dei partecipanti",
	"impacto.empleado.validacion.evaluados.mayor.participantes.habilidad":"Il numero dei partecipanti di cui si sono valutate le capacità non può essere maggiore di quello dei partecipanti",
	"impacto.empleado.validacion.evaluados.mayor.participantes.desarrollo":"Il numero dei partecipanti di cui si è valutato lo sviluppo personale non può essere maggiore di quello dei partecipanti",
	"tipo.cambio.requerido.excel.valores":"È necessario allegare un file excel con il tasso di cambio",
	"tipo.cambio.valor.excel.caracter.invalido":"Impossibile elaborare il file excel: carattere non valido",
	"ADMINISTRADOR":"Amministratore",
	"COORDINADOR_GRUPO":"Coordinatore di gruppo",
	"RESPONSABLE":"Responsabile",
	"GERENTE":"Gestore",
	"SUPERVISOR_PROYECTO":"Supervisore del progetto",
	"SUPERVISOR_INICIATIVA":"Supervisore dell'iniziativa",
	"AGENTE":"Agente",
	"SOLOVISTA":"Gestore visualizzare solamente",
	"BASICO":"Addetto di base",
	"DINERO":"Denaro",
	"ESPECIE":"Natura",
	"TIEMPO":"Tempo",
	"PROBONO":"Pro bono",
	"COSTE_GESTION":"Costo di gestione",
	"ALTO":"Alto",
	"MEDIO":"Medio",
	"BAJO":"Basso",
	"COLABORADOR":"Collaboratore",
	"ORG_BENEFICIARIA":"Organizzazione destinataria",
	"SI":"Sì",
	"NO":"No",
	"SIN_CAMBIOS":"Situazione invariata",
	"POCO":"Scarso",
	"ALGO":"Moderato",
	"BASTANTE":"Abbastanza significativo",
	"Nueva Entidad":"Nuova Entità",
	"achievementsform.numeroBeneficiarias":"Numero di organizzazioni beneficiarie",
	"achievementsform.alianzaColaboHeader":"Partenariati e collaborazioni instaurate",
	"achievementsform.addGrInteres":"Save",
	"achievementsform.deleteGrInteres":"Elimina Gruppo di interesse",
	"achievementsform.new":"Modifica Risultato",
	"achievementsform.edit":"Nuovo Risultato",
	"achievementsform.fechaevaluacion":"Data valutazione:",
	"achievementsform.descripcion":"Descrizione:",
	"achievementsform.benefiariosDirectos":"Beneficiari diretti",
	"achievementsform.numBeneficiarios":"Nº beneficiari:",
	"achievementsform.tipo":"Tipologia:",
	"achievementsform.situacion":"Situazione:",
	"achievementsform.observaciones":"Osservazioni:",
	"achievementsform.beneficiariosIndirectos":"Beneficiari indiretti",
	"achievementsform.numLogrosMedioambientales":"Nº risultati ambientali:",
	"achievementsform.organizacionesBeneficiarias":"Organizzazioni beneficiarie",
	"achievementsform.tipoSimple":"Tipologia",
	"achievementsform.nombreOrganizacion":"Nome Organizzazione",
	"achievementsform.descripcionSimple":"Descrizione",
	"achievementsform.beneficiariaPregunta":"Beneficiaria?",
	"achievementsform.addNuevaOrganizacion":"Aggiungi organizzazione",
	"achievementsform.deleteOrganizacion":"Elimina organizzazione",
	"achievementsform.LogrosEntidadesParticipantes":"Risultati per le entità partecipanti",
	"achievementsform.logrosGrupoIntereses":"Risultati rispetto ai gruppi target",
	"achievementsform.entidadGrupoIntereses":"Entità",
	"achievementsform.gruposInteresAlcanzados":"Gruppi target raggiunti",
	"achievementsform.logrosInteresNumAbrev":"Nº",
	"achievementsform.logrosInteresObservaciones":"Osservazioni",
	"achievementsform.alianzasEntidad":"Entità",
	"achievementsform.alianzasGenerando":"Creazione di partenariati in corso",
	"achievementsform.alianzasNum":"Nº",
	"achievementsform.alianzasComentarios":"Osservazioni",
	"achievementsform.addAlianza":"Aggiungi partenariato",
	"achievementsform.deleteAlianza":"Elimina partenariato",
	"achievementsform.mediosComunicacion":"Mezzi di comunicazione",
	"achievementsform.mediosComunicacionEntidad":"Entità",
	"achievementsform.mediosComunicacionValor":"Performance nei media",
	"achievementsform.mediosComunicacionDivisa":"Valuta",
	"achievementsform.mediosComunicacionObservaciones":"Osservazioni",
	"achievementsform.addMedioComunicacion":"Aggiungi mezzo di comunicazione",
	"achievementsform.deleteMediosComunicacion":"Elimina mezzo di comunicazione",
	"achievementsform.cumplimiento":"Raggiungimento degli obiettivi e analisi delle iniziative",
	"achievementsform.cumplimientoEntidad":"Entità",
	"achievementsform.cumplimientoAlineacion":"Allineamento rispetto agli obiettivi dell'intervento",
	"achievementsform.cumplimientoObservaciones":"Osservazioni",
	"achievementsform.addCumplimiento":"Aggiungi raggiungimento obiettivi",
	"achievementsform.deleteCumplimiento":"Elimina raggiungimento obiettivi",
	"achievementsform.alineacion":"Allineamento dei risultati rispetto agli obiettivi dell'intervento",
	"achievementsform.eficiencia":"Esecuzione del progetto in termini di efficienza",
	"achievementsform.sostenibilidad":"Sostenibilità economica",
	"achievementsform.eficacia":"Esecuzione del progetto in termini di efficacia",
	"achievementsform.conclusionesAlcanzadas":"Conclusioni raggiunte:",
	"achievementsform.logrosMedioambientables":"Risultati Ambientali",
	"achievementsform.ver":"Vedi Risultato",
	"achievementsform.guardar":"Salva",
	"achievementsform.cancelar":"Annulla",
	"achievementsform.Si":"Sì",
	"achievementsform.No":"No",
	"achievements.achievement":"Risultato raggiunto",
	"achievements.codigo":"Codice",
	"achievements.fecha":"Data",
	"achievements.descripcion":"Descrizione",
	"achievements.beneficiariosDirectos":"Beneficiari diretti",
	"achievements.organizacionesBeneficiarias":"Organizzazioni beneficiarie",
	"achievements.logosAmbientales":"Risultati ambientali",
	"achievements.editar":"Modifica",
	"achievements.adjuntarArchivo":"Aggiungi File",
	"achievements.duplicar":"Duplica",
	"achievements.devalidate":"Rimuovi convalida",
	"achievements.validate":"Convalida",
	"achievements.validateAchievement":"Convalida risultato",
	"achievements.delete":"Elimina",
	"achievements.deleteMessage":"Elimina risultato",
	"achievements.areSure":"Continuare?",
	"achievements.cancel":"Annulla",
	"achievements.ver":"Vedi",
	"breadcrumb.gestion":"GESTIONE",
	"breadcrumb.entidades":"ENTITÀ",
	"breadcrumb.usuarios":"UTENTI",
	"breadcrumb.planesUso":"PIANI DI UTILIZZO",
	"breadcrumb.tiposCambio":"TASSI DI CAMBIO",
	"breadcrumb.colaboradores":"COLLABORATORI",
	"breadcrumb.resumen":"RIEPILOGO",
	"breadcrumb.contribuciones":"CONTRIBUTI",
	"breadcrumb.logros":"RISULTATI RAGGIUNTI",
	"breadcrumb.impactos":"IMPATTI",
	"breadcrumb.iniciativas":"INIZIATIVE",
	"breadcrumb.proyectos":"PROGETTI",
	"breadcrumb.nuevaEntidad":"Nuova Entità",
	"breadcrumb.editarEntidad":"Modifica Entità",
	"collaboratorform.edit":"Modifica Collaboratore",
	"collaboratorform.new":"Nuovovo Collaboratore",
	"collaboratorform.nombre":"Nome:",
	"collaboratorform.descripcion":"Descrizione:",
	"collaboratorform.cif":"CF:",
	"collaboratorform.pais":"Paese",
	"collaboratorform.region":"Regione:",
	"collaboratorform.tipoEntidad":"Tipologia Entità:",
	"collaboratorform.guardar":"Salva",
	"collaboratorform.cancelar":"Annulla",
	"collaboratorform.volver":"Torna all'elenco dei collaboratori",
	"collaboratorform.agrupacionInternaIniciativasObligatorio":"È obbligatorio compilare il campo Initiative classification",
	"collaborator.codigo":"Codice",
	"collaborator.nombre":"Nome",
	"collaborator.descripcion":"Descrizione",
	"collaborator.pais":"Paese",
	"collaborator.region":"Regione",
	"collaborator.tipoEntidad":"Tipologia Entità",
	"collaborator.adjuntar":"Allega File",
	"collaborator.editar":"Modifica",
	"collaborator.borrar":"Elimina",
	"collaborator.errorBorrando":"Errore durante l'eliminazione",
	"collaborator.noPermission":"L'utente non ha il permesso per eseguire questa azione",
	"collaborator.ok":"ok",
	"collaborator.borrarColaborador":"Elimina Collaboratore",
	"collaborator.estasSeguro":"Continuare?",
	"collaborator.cancelar":"Annulla",
	"contact.titulo":"Contatto",
	"contact.mensajeEnviadoCorrectamente":"Messaggio inviato correttamente",
	"contact.asunto":"Oggetto:",
	"contact.comentario":"Commento:",
	"contact.enviar":"Invia",
	"contact.volver":"Torna indietro",
	"contributions.atribucion":"Assegnazione",
	"contributions.codigo":"Codice",
	"contributions.tipo":"Tipologia",
	"contributions.contribuyente":"Contribuente",
	"contributions.fecha":"Data",
	"contributions.coste":"Costo",
	"contributions.divisa":"Valuta",
	"contributions.descripcion":"Descrizione",
	"contributions.aportacionesLey112018":"Fondazione\no ONLUS?",
	"contributions.aportacionesLey112018Tooltip":"È un contributo a una fondazione o ONLUS?",
	"contributions.esFundacionONL":"È un contributo a una foundazione/ONLUS",	
	"contributions.NOesFundacionONL":"Non è un contributo a una foundazione/ONLUS",
	"contributions.tablaSalarial":"Tabella",
	"contributions.numPersonas":"Persone",
	"contributions.horasTotales":"Ore",
	"contributions.costeHora":"Costo ora",
	"contributions.eliminar":"Elimina",
	"contributions.archivoAdjunto":"Aggiungi File",
	"contributions.duplicar":"Duplica",
	"contributions.addContribucion":"Nuovo contributo",
	"contributions.sumatorio":"Somma:",
	"contributions.borrarContribucion":"Elimina Contributo",
	"contributions.estasSeguro":"Continuare?",
	"contributions.estasSeguroTiempo":"Se si continua saranno eliminati anche gli impatti associati a questo contributo. Continuare comunque?",
	"contributions.borrar":"Elimina",
	"contributions.cancelar":"Annulla",
	"contributions.quitarValidacion":"Rimuovi convalida",
	"contributions.validar":"Convalida",
	"contributions.contribucion":"Contributo",
	"contributions.validarTodos":"Convalida Tutti",
	"contributions.desvalidarTodos":"Invalida Tutti",
	"contributions.validarTodas":"Convalida Tutte",
	"contributions.desvalidarTodas":"Invalida Tutte",
	"contributions.validarContribucion":"Convalida Contributo",
	"contributions.invalidarContribucion":"Invalida Contributo",
	"data.tipoEntidad":"Tipologia entità:",
	"data.sector":"Settore:",
	"data.responsable":"Responsabile:",
	"data.nombre":"Nome:",
	"data.nombreAbreviado":"Nome breve:",
	"data.plan":"PIANO",
	"data.totalUsuarios":"TOTALE Utenti: ",
	"data.numResponsables":"Nº responsabili: ",
	"data.numGerentes":"Nº gestori: ",
	"data.numSupProy":"Nº supervisori del progetto: ",
	"data.numSupIni":"Nº supervisori dell'iniziativa: ",
	"data.numAgentes":"Nº agenti: ",
	"entityform.nombre":"Nome:",
	"entityform.campoRequerido":"Campo obbligatorio",
	"entityform.nombreAbreviado":"Nome breve:",
	"entityform.cif":"DATI FISCALI",
	"entityform.pais":"Paese: ",
	"entityform.tipo":"Tipologia: ",
	"entityform.sectorLBG":"Settore LBG: ",
	"entityform.grupoLBG":"Gruppo LBG: ",
	"entityform.responsable":"Responsabile",
	"entityform.plan":"Piano: ",
	"entityform.tipoCambio":"Tasso di cambio: ",
	"entityform.activo":"Attivo",
	"entityform.guardar":"Salva",
	"entityform.cancelar":"Annulla",
	"entityform.volver":"Torna a elenco entità",
	"entityform.isLBG":"Entità LBG/ONLBG",
	"entityform.activityHistoryActive":"Enable trazability visualization",
	"entityform.aportacionesLey112018":"Casella di contribuzione Legge 11/2018",
	"entityform.activoIniciativasInternas":"Activate initiatives classification",
	"entity.codigo":"Codice",
	"entity.nombre":"Nome",
	"entity.tipo":"Tipologia",
	"entity.pais":"Paese",
	"entity.grupoLbg":"Gruppo LBG",
	"entity.plan":"Piano",
	"entity.numUsuarios":"Nº utenti",
	"entity.estado":"Stato",
	"entity.editar":"Modifica",
	"entity.borrar":"Elimina",
	"entity.activar":"Attiva",
	"entity.desactivar":"Disattiva",
	"entity.entidad":"Ente",
	"entity.estasSeguro":"Continuare?",
	"entity.borrarEntidad":"Elimina ente",
	"entity.cancelar":"Annulla",
	"entitytoolbar.perfil":"PROFILO",
	"entitytoolbar.datos":"DATI",
	"entitytoolbar.clasificacion":"CLASSIFICAZIONE",
	"entitytoolbar.tablasSalarial":"TABELLE DEI CONTRIBUTI",
	"entitytoolbar.datosMin":"Dati",
	"entitytoolbar.clasificacionMin":"Classificazione",
	"entitytoolbar.tablaSalarialMin":"Tabelle dei contributi",
	"entitytoolbar.nuevaTablaSalarial":"Nuova Tabella",
	"error.mensajeNoAccion":"Impossibile eseguire l'azione: errore nella conversione valutaria",
	"error.periodoNoCompletado":"L'Amministratore non ha inserito il periodo minimo per i cambi di valuta USD. Il periodo va dal",
	"error.hasta":"al",
	"error.validation":"Errore di autenticazione: Utente o password non corretti",
	"exchangerateform.edit":"Modifica tasso di cambio",
	"exchangerateform.new":"Nuovo tasso di cambio",
	"exchangerateform.nombre":"Nome:",
	"exchangerateform.descripcion":"Descrizione:",
	"exchangerateform.desde":"Da:",
	"exchangerateform.hasta":"A:",
	"exchangerateform.grupoLBG":"Gruppo LBG:",
	"exchangerateform.archivoExcel":"File Excel:",
	"exchangerateform.guardar":"Salva",
	"exchangerateform.cancelar":"Annulla",
	"exchangerateform.volver":"Torna all'elenco dei tassi di cambio",
	"exchangerate.desdeCabecera":"Non è stato inserito il periodo minimo per i cambi di valuta USD. Il periodo compreso va dal 01/01/2012 al 31/12/2027",
	"exchangerate.hastaCabecera":"al",
	"exchangerate.nombre":"Nome",
	"exchangerate.descripcion":"Descrizione",
	"exchangerate.grupoLGB":"Gruppo LBG",
	"exchangerate.desde":"Dal",
	"exchangerate.hasta":"Al",
	"exchangerate.editar":"Modifica",
	"exchangerate.borrarEntidad":"Elimina ente",
	"exchangerate.estasSeguro":"Continuare?",
	"exchangerate.borrar":"Elimina",
	"exchangerate.cancelar":"Annulla",
	"forgotpassnew.recuperarPass":"Reimposta Password",
	"forgotpassnew.establecerPass":"Impostare la password",
	"forgotpassnew.requisitos":"La password deve contenere almeno 8 caratteri e almeno un carattere maiuscolo, uno minuscolo e un numero.",
	"forgotpassnew.newPass":"Nuova Password:",
	"forgotpassnew.repetirPass":"Ripeti Nuova Password:",
	"forgotpassnew.guardar":"Salva",
	"forgotpassnew.cancelar":"Annulla",
	"forgotpassnew.passChangedOk":"La password è stata modificata correttamente",
	"forgotpassnew.passChangedFail1":"La nuova password deve avere almeno 8 caratteri e comprendere lettere maiuscole, minuscole e caratteri numerici",
	"forgotpassnew.passChangedFail2":"Le password inserite non coincidono",
	"forgotpass.olvidoPass":"Hai dimenticato la password",
	"forgotpass.mailEnviado":"Email inviata a",
	"forgotpass.reviseBandeja":"Cerca il messaggio nella Posta in arrivo",
	"forgotpass.email":"Email:",
	"forgotpass.guardar":"Salva",
	"forgotpass.cancelar":"Annulla",
	"forgotpass.mailRegistrado":"L'email inserito non risulta registrato",
	"forgotpass.mailFormatoIncorrecto":"Il formato dell'email inserito non è corretto",
	"impactsform.edit":"Modifica Impatto",
	"impactsform.new":"Nuovo Impatto",
	"impactsform.fechaEvaluacion":"Data valutazione:",
	"impactsform.descripcion":"Descrizione:",
	"impactsform.impactosSobreBeneficiarios":"Impatti sui beneficiari",
	"impactsform.numBenefDirectos":"Nº beneficiari diretti:",
	"impactsform.numBenefDirectosEval":"Nº beneficiari diretti valutati:",
	"impactsform.gradoImpacto":"Livello dell'impatto",
	"impactsform.sensibilizados":"Sensibilizzati:",
	"impactsform.num":"Nº:",
	"impactsform.observaciones":"Osservazioni:",
	"impactsform.conseguidoMejora":"Miglioramento ottenuto:",
	"impactsform.transformados":"Trasformati:",
	"impactsform.total":"Totale:",
	"impactsform.tipoImpacto":"Tipologia impatto",
	"impactsform.cambioPositivo":"Cambiamento positivo nel comportamento:",
	"impactsform.adquiridoNuevasHabilidades":"Acquisizione di nuove capacità:",
	"impactsform.impactoPositivoCalidadVida":"Impatto positivo sulla qualità di vita:",
	"impactsform.medioAmbiente":"Ambiente",
	"impactsform.logrosMedioambientales":"Impatti ambientali",
	"impactsform.laIniciativaHaConsegidoImpactosMedioambientales":"L'iniziativa ha ottenuto impatti sull'ambiente?",
	"impactsform.cambioComportamientoAmbiental":"L'iniziativa ha comportato un cambiamento nel modello di comportamento ambientale delle persone?",
	"impactsform.impactoEmpleadosVoluntarios":"Impatto su dipendenti/volontari",
	"impactsform.seleccionParticipantes":"Selezione dei partecipanti:",
	"impactsform.numParticipantes":"Nº partecipanti:",
	"impactsform.impactoSobreEntidadesParticipantes":"Impatto sugli enti partecipanti",
	"impactsform.entidadesAEvaluar":"Enti da valutare:",
	"impactsform.entidadesParticipantes":"Enti partecipanti:",
	"impactsform.entidadesEvaluadas":"Enti valutate:",
	"impactsform.otrosImpactosConseguidos":"Altri impatti ottenuti:",
	"impactsform.guardar":"Salva",
	"impactsform.cancelar":"Annulla",
	"impactsform.mejoraronHabilidadesLaborales":"Valutare per quante persone l'attività ha contribuito allo sviluppo di nuove capacità o al miglioramento di quelle già esistenti, in ambito accademico, sociale o fisico",
	"impactsform.mejoraronDesarrolloPersonal":"Per quante persone l'iniziativa ha comportato un cambiamento a livello di sviluppo personale?",
	"impactsform.tuvieronCambioPositivoComportamiento":"Per quante persone ha contribuito a generare cambiamenti di comportamento in grado di migliorare la loro qualità di vita? L'attività ha permesso di modificare atteggiamenti negativi o pregiudizi?",
	"impactsform.mejoradoRelacionesCGII":"Ha migliorato i rapporti con i gruppi target?",
	"impactsform.mejoradoSistemasGestion":"Ha migliorato i loro sistemi di gestione? Si sono compiuti progressi a livello operativo? (Gestione risorse umane, finanziarie)",
	"impactsform.aumentadoCapacidadEmplearPersonal":"È aumentata la capacità di dare impiego a dipendenti o si è incrementato il numero dei volontari?",
	"impactsform.experimentadoMejoraServicios":"Si sono introdotti miglioramenti nei servizi o prodotti di cui dispongono o si sono offerti nuovi servizi?",
	"impactsform.generadoIngresos":"Si sono generati introiti?",
	"impactsform.nuevasOportunidadesAlianzas":"Si sono creati nuovi partenariati o nuove opportunità?",
	"impactsform.mejoraCredibilidadReputacion":"Si sono apportati miglioramenti in termini di credibilità e reputazione?",
	"impactsform.mejoraVisibilidadEntidad":"Si sono ottenuti miglioramenti sotto il profilo della visibilità dell'ente?",
	"impactsform.numEvaluados":"Nº dei partecipanti valutati",
	"impactsform.ver":"Vedi Impatto",
	"impact.codigo":"Codice",
	"impact.fecha":"Data",
	"impact.descripcion":"Descrizione",
	"impact.beneficiariosEvaluados":"Beneficiari valutati",
	"impact.entidadesEvaluadas":"Enti valutati",
	"impact.impactosAmbientales":"Impatti ambientali",
	"impact.editar":"Modifica",
	"impact.adjuntarArchivo":"Allega file",
	"impact.duplicar":"Duplica",
	"impact.borrar":"Elimina",
	"impact.quitarValidacion":"Rimuovi convalida",
	"impact.validar":"Convalida",
	"impact.impacto":"Impatto",
	"impact.estasSeguro":"Continuare?",
	"impact.cancelar":"Annulla",
	"impact.ver":"Vedi",
	"impact.validarImpacto":"Convalida impatto",
	"impact.borrarImpacto":"Elimina impatto",
	"initiativeform.edit":"Modifica Iniziativa",
	"initiativeform.new":"Nuova Iniziativa",
	"initiativeform.nombre":"Nome:",
	"initiativeform.pais":"Paese: ",
	"initiativeform.region":"Regione:",
	"initiativeform.ciudad":"Città:",
	"initiativeform.descripcion":"Descrizione:",
	"initiativeform.fechaInicio":"Data Inizio:",
	"initiativeform.fechaFin":"Data Fine:",
	"initiativeform.areaActuacionPrimaria":"Area di intervento primario:",
	"initiativeform.areaActuacionSecundaria":"Area di intervento secondario:",
	"initiativeform.entidadesContribuyenIniciativa":"Collaboratori e ente che forniscono il loro contributo all'iniziativa",
	"initiativeform.entidades":"Ente",
	"initiativeform.colaborador":"Collaboratore",
	"initiativeform.motivacion":"Motivazione",
	"initiativeform.gradoImplicacion":"Livello di coinvolgimento",
	"initiativeform.borrarColaborador":"Elimina collaboratore",
	"initiativeform.addColaborador":"Aggiungi nuovo collaboratore",
	"initiativeform.cumpleLBG":"È conforme ai principi LBG/ ONLBG:",
	"initiativeform.razones":"Motivi:",
	"initiativeform.comentarios":"Commenti:",
	"initiativeform.odsPrimario":"Obiettivo di sviluppo(SDG) primario:",
	"initiativeform.metas":"Traguardi:",
	"initiativeform.odsSecundario":"Obiettivo di sviluppo(SDG) secondario:",
	"initiativeform.presupuestoEntidades":"Budget ente",
	"initiativeform.entidad":"Ente",
	"initiativeform.presupuesto":"Budget",
	"initiativeform.divisa":"Valuta",
	"initiativeform.paisOrigenFondos":"Paese di origine dei fondi",
	"initiativeform.addPresupuesto":"Aggiungi budget",
	"initiativeform.borrarPresupuesto":"Elimina budget",
	"initiativeform.asignarUsuarios":"Assegna Utenti",
	"initiativeform.guardar":"Salva",
	"initiativeform.guardarYSeguir":"Salva e aggiungi contributi",
	"initiativeform.cancelar":"Annulla",
	"initiativeform.volver":"Torna all'elenco iniziative",
	"initiativeform.verIniciativa":"Vedi Iniziativa",
	"initiativeform.colaboradorPuntual":"Collaborazione saltuaria",
	"initiativeform.socioColaborador":"Socio collaboratore",
	"initiativeform.Si":"Sì",
	"initiativeform.No":"No",
	"initiativeform.agrupacionIniciativaInterna":"Initiative classification.",
	"initiativeform.odstitle":"Obiettivo di sviluppo",
	"initiativeform.placeholder.ods":"Obiettivo di sviluppo...",
	"initiativeform.placeholder.meta":"Traguardo...",
	"initiativeform.ods":"SDG",
	"initiativeform.meta":"Traguardo",
	"initiativeform.porcentaje":"Porcentuale",
	"initiativeform.borrarOds":"Elimina SDG",
	"initiativeform.addOds":"Salva",
	"initiativeresume.resumen":"Riepilogo",
	"initiativeresume.numIniciativas":"Nº iniziative",
	"initiativeresume.contribucion":"CONTRIBUTO",
	"initiativeresume.currentYear":"Anno in corso",
	"initiativeresume.previousYear":"Anni precedenti",
	"initiativeresume.total":"Totale",
	"initiativeresume.dinero":"Denaro",
	"initiativeresume.especie":"Natura",
	"initiativeresume.tiempo":"Tempo",
	"initiativeresume.probono":"Pro bono",
	"initiativeresume.costesGestion":"Costi di gestione",
	"initiativeresume.contribucionTotal":"CONTRIBUTO TOTALE",
	"initiativeresume.otros":"ALTRI",
	"initiativeresume.otrosTotal":"TOTALE ALTRI",
	"initiativeresume.efectoMultiplicador":"Effetto moltiplicatore",
	"initiativeresume.totalMay":"TOTALE",
	"initiativeresume.presupuesto":"Budget",
	"initiativeresume.cumplimiento":"Adempimento",
	"initiativeresume.pendiente":"In sospeso",
	"initiativeresume.volver":"Torna indietro",
	"initiativeassign.finalizarAsignacion":"Concludi assegnazione",
	"initiativeassign.marcarTodos":"Seleziona tutti",
	"initiativeassign.desmarcarTodos":"Deseleziona tutti",
	"initiativeassign.Contributi":"Contributi",
	"initiativeassign.logros":"Risultati",
	"initiativeassign.impactos":"Impatti",
	"initiativeassign.todos":"Tutti",
	"initiativeassign.codigo":"Codice",
	"initiativeassign.nombre":"Nome",
	"initiativeassign.apellidos":"Cognome",
	"initiativeassign.email":"Email",
	"initiativeassign.rol":"Ruolo ricoperto",
	"initiativeassign.entidadRepresentada":"Ente rappresentata",
	"initiative.new":"Nuova Iniziativa",
	"initiative.contribucionTotal":"Contributo TOTALE",
	"initiative.contribucion":"Contributo",
	"initiative.contribuciones":"Contributi",
	"initiative.logros":"Risultati",
	"initiative.impactos":"Impatti",
	"initiative.editar":"Modifica",
	"initiative.adjuntarArchivo":"Allega File",
	"initiative.duplicar":"Duplica",
	"initiative.asignarUsuario":"Assegna Utenti",
	"initiative.borrar":"Elimina",
	"initiative.codigo":"Codice",
	"initiative.nombre":"Nome",
	"initiative.fecha":"Data",
	"initiative.fecha_actualizacion":"Attualizzazione",
	"initiative.pais":"Paese",
	"initiative.area":"Area",
	"initiative.motivacion":"Motivazione",
	"initiative.lbg":"LBG",
	"initiative.total":"TOTALE",
	"initiative.mediciones":"Misurazioni",
	"initiative.si":"Sì",
	"initiative.no":"No",
	"initiative.borrarProyecto":"Elimina progetto",
	"initiative.estasSeguro":"Continuare?",
	"initiative.cancelar":"Annulla",
	"initiative.ver":"Vedi",
	"initiative.start_date":"Data Inizio",
	"initiative.update_date":"Fecha de attualizzazione",
	"login.hasOlvidadoPass":"Hai dimenticato la password?",
	"login.iniciarSesion":"LOGIN",
	"login.userMail":"Utente / email",
	"login.pass":"Password",
	"login.usuario.desactivado":"L'Utente inserito è stato disattivato",
	"manage.usuarios":"UTENTI",
	"manage.entidades":"ENTI",
	"manage.planesUso":"PIANI DI UTILIZZO",
	"manage.tiposCambio":"TASSI DI CAMBIO",
	"manage.colaboradores":"COLLABORATORI",
	"manage.nuevaEntidad":"Nuova ente",
	"manage.entidad":"ente",
	"manage.nuevoUsuario":"Nuovo Utente",
	"manage.usuario":"utenti",
	"manage.nuevoPlan":"Nuovo Piano",
	"manage.planUso":"piani di utilizzo",
	"manage.nuevoTipoCambio":"Nuovo Tasso di Cambio",
	"manage.tipoCambio":"tassi di cambio",
	"manage.nuevoColaborador":"Nuovo Collaboratore",
	"manage.colaborador":"collaboratori",
	"mepass.cambiarPass":"Modifica Password",
	"mepass.passCambiado":"La password è stata modificata correttamente",
	"mepass.oldPass":"Password precedente:",
	"mepass.newPass":"Nuova Password:",
	"mepass.newPass2":"Ripeti Nuova Password:",
	"mepass.guardar":"Salva",
	"mepass.cancelar":"Annulla",
	"mepass.oldPassFail":"La password precedente non è corretta",
	"mepass.oldPassValidate":"La nuova password deve avere almeno 8 caratteri e comprendere lettere maiuscole, minuscole e caratteri numerici",
	"mepass.passNotEqual":"Le password inserite non coincidono",
	"mepass.todosCamposObligatorios":"Tutti i campi sono obbligatori",
	"me.miPerfil":"Profilo",
	"me.nombre":"Nome:",
	"me.apellidos":"Cognome:",
	"me.email":"Email:",
	"me.pais":"Paese:",
	"me.cambiarPass":"Modifica Password",
	"me.guardar":"Salva",
	"me.dataChanged":"Dati modificati correttamente",
	"measurement.contribuciones":"CONTRIBUTI",
	"measurement.logros":"RISULTATI",
	"measurement.impactos":"IMPATTI",
	"measurement.contribucionesMin":"Contributi",
	"measurement.nuevoLogro":"Nuovo Risultato",
	"measurement.logrosMin":"risultati",
	"measurement.nuevoImpacto":"Nuovo Impatto",
	"measurement.impactosMin":"impatti",
	"planform.edit":"Modifica piano",
	"planform.new":"Nuovo piano",
	"planform.nombre":"Nome:",
	"planform.grupoLbg":"Gruppo LBG:",
	"planform.numUsuarios":"Nº utenti:",
	"planform.numResponsable":"Nº responsabili:",
	"planform.numGerentes":"Nº gestori:",
	"planform.numSupPro":"Nº supervisori progetto:",
	"planform.numSupIni":"Nº supervisori iniziativa:",
	"planform.numAgentes":"Nº agenti:",
	"planform.numBasicos":"Nº addetti di base:",
	"planform.activo":"Attivo",
	"planform.guardar":"Salva",
	"planform.cancelar":"Annulla",
	"planform.volver":"Torna all'elenco dei Piani di utilizzo",
	"plan.edit":"Modifica",
	"plan.delete":"Elimina",
	"plan.nombre":"Nome",
	"plan.numUsuarios":"Nº utenti",
	"plan.estado":"Stato",
	"plan.desactivar":"Disattiva",
	"plan.activar":"Attiva",
	"plan.plan":"Piano",
	"plan.estasSeguro":"Continuare?",
	"plan.borrarPlan":"Elimina Piano",
	"plan.borrar":"Elimina",
	"plan.cancelar":"Annulla",
	"proyectform.new":"Nuovo progetto",
	"proyectform.edit":"Modifica progetto",
	"proyectform.nombre":"Nome:",
	"proyectform.divisionUnidad":"Divisione / Unità:",
	"proyectform.agrupacionInterna":"Gruppo di lavoro:",
	"proyectform.descripcion":"Descrizione:",
	"proyectform.desde":"Dal:",
	"proyectform.hasta":"Al:",
	"proyectform.presupuesto":"Budget",
	"proyectform.divisa":"Valuta:",
	"proyectform.presupuestoTotal":"Budget total",
	"proyectform.asignarUsuarios":"Assegna Utenti",
	"proyectform.guardar":"Salva",
	"proyectform.cancelar":"Annulla",
	"proyectform.volver":"Torna al elenco dei progetti",
	"proyectform.mensajeError":"Il budget totale è inferiore a quello dell'ente",
	"proyectform.ver":"Vedi progetto",
	"proyectassign.finalizarAsignacion":"Concludi assegnazione",
	"proyectassign.marcarTodos":"Seleziona tutti",
	"proyectassign.desmarcarTodos":"Deseleziona tutti",
	"proyectassign.codigo":"Codice",
	"proyectassign.nombre":"Nome",
	"proyectassign.apellidos":"Cognome",
	"proyectassign.email":"Email",
	"proyectassign.rol":"Ruolo",
	"proyectassign.entidadRepresentada":"Ente rappresentato",
	"proyectassign.pais":"Paese",
	"proyectassign.seleccionar":"Seleziona",
	"proyectassign.desactivar":"Disattiva",
	"proyectassign.activar":"Attiva",
	"proyectassign.usuario":"Utente",
	"proyectassign.estasSeguro":"Continuare?",
	"proyectassign.cancelar":"Annulla",
	"proyect.proyectos":"PROGETTO",
	"proyect.nuevoProyecto":"Nuovo Progetto",
	"proyect.abiertos":"APERTI",
	"proyect.cerrados":"CHIUSI",
	"proyect.todos":"TUTTI",
	"proyect.fechaInicio":"Data inizio",
	"proyect.divisionUnidad":"Divisione / Unità",
	"proyect.ninguno":"Nessuno",
	"proyect.contribucionTotal":"Contributo TOTALE",
	"proyect.contribucion":"Contributo",
	"proyect.iniciativas":"Iniziative",
	"proyect.editar":"Modifica",
	"proyect.adjuntarArchivo":"Allega file",
	"proyect.duplicar":"Duplica",
	"proyect.abrir":"Apri",
	"proyect.cerrar":"Chiudi",
	"proyect.asignarUsuarios":"Assegna Utente",
	"proyect.borrar":"Elimina",
	"proyect.codigo":"Codice",
	"proyect.nombre":"Nome",
	"proyect.fechaInicioPH":"Data inizio",
	"proyect.divisionUnidadPH":"Unità",
	"proyect.agrupacion":"Gruppo di lavoro",
	"proyect.total":"TOTALE",
	"proyect.proyecto":"progetto",
	"proyect.estasSeguro":"Continuare?",
	"proyect.cancelar":"Annulla",
	"proyect.proyectoCerradoNoReabre":"Impossibile modificare un progetto chiuso",
	"proyect.borrarProyecto":"Elimina progetto",
	"proyect.proyectoCerradoNoArchivos":"Impossibile allegare file se il progetto è chiuso",
	"proyect.ver":"Vedi",
	"proyect.abrirProyecto":"Apri progetto",
	"proyect.cerrarProyecto":"Chiudi progetto",
	"rating.unidadAdministrativa":"Unità amministrativa o operativa",
	"rating.nombre":"Nome",
	"rating.agrupacionInterna":"Gruppi di lavoro",
	"rating.nuevaDivision":"Nuova divisione amministrativa",
	"rating.nuevaAgrupacion":"Nuovo gruppi di lavoro",
	"proyectresume.resumen":"Riepilogo",
	"proyectresume.numIniciativas":"Nº iniziative",
	"proyectresume.contribucion":"CONTRIBUTO",
	"proyectresume.currentYear":"Anno in corso",
	"proyectresume.previousYear":"Anni precedenti",
	"proyectresume.total":"Totale",
	"proyectresume.dinero":"Denaro",
	"proyectresume.especie":"Natura",
	"proyectresume.tiempo":"Tempo",
	"proyectresume.probono":"Pro bono",
	"proyectresume.costesGestion":"Costi di gestione",
	"proyectresume.contribucionTotal":"CONTRIBUTO TOTALE",
	"proyectresume.otros":"ALTRI",
	"proyectresume.otrosTotal":"TOTALE ALTRI",
	"proyectresume.efectoMultiplicador":"Effetto moltiplicatore",
	"proyectresume.totalMayus":"TOTALE",
	"proyectresume.presupuesto":"Budget",
	"proyectresume.cumplimiento":"Adempimento",
	"proyectresume.pendiente":"In sospeso",
	"proyectresume.volver":"Torna indietro",
	"salarytablesform.edit":"Modifica tabella dei contribut",
	"salarytablesform.new":"Nuova tabella",
	"salarytablesform.nombre":"Nome:",
	"salarytablesform.campoRequerido":"Campo obbligatorio",
	"salarytablesform.costeTotal":"Costo totale",
	"salarytablesform.numPersonas":"Nº persone",
	"salarytablesform.horas":"Ore",
	"salarytablesform.costeHora":"Costo per ora",
	"salarytablesform.applicationYear":"Anno di applicazione",
	"salarytablesform.guardar":"Salva",
	"salarytablesform.cancelar":"Annulla",
	"salarytablesform.volver":"Torna a elenco delle tabelle dei contribut",
	"salarytablesform.divisa":"Valuta",
	"salarytables.descripcionPublica":"Descrizione della tabella",
	"salarytables.descripcionPrivada":"Informazioni a fini di gestione interna",
	"salarytables.aplicationYear":"Anno applicazione",
	"salarytables.adjuntarArchivo":"Allega file",
	"salarytables.editar":"Modifica",
	"salarytables.borrar":"Elimina",
	"salarytables.codigo":"Codice",
	"salarytables.nombre":"Nome",
	"salarytables.totalCoste":"Totale Costo",
	"salarytables.divisa":"Valuta",
	"salarytables.personas":"Persone",
	"salarytables.horas":"Ore",
	"salarytables.costeHora":"Costo / Ora",
	"salarytables.borrarTablaSalarial":"Elimina Tabelle dei contribut",
	"salarytables.estasSeguro":"Continuare?",
	"salarytables.cancelar":"Annulla",
	"toolbartop.home":"Home",
	"toolbartop.perfil":"Profilo",
	"toolbartop.gestion":"Gestione",
	"toolbartop.proyectos":"Progetti",
	"toolbartop.benchmarking":"Benchmarking",
	"toolbartop.idioma":"Lingua",
	"toolbartop.moneda":"Valuta",
	"toolbartop.cerrarSesion":"Chiudi sessione",
	"toolbartop.contacto":"Contatto",
	"toolbartop.filterReport":"Reports",
	"toolbartop.reportChart":"Statistiche",
	"uploadform.subirArchivo":"Carica file",
	"uploadform.archivosSubidos":"File caricati:",
	"uploadform.cancelar":"Annulla",
	"uploadform.volver":"Torna indietro",
	"uploadform.borrarArchivo":"Elimina File",
	"uploadform.estasSeguro":"Continuare?",
	"uploadform.borrar":"Elimina",
	"userform.editarUsuario":"Modifica Utente",
	"userform.nuevoUsuario":"Nuovo Utente",
	"userform.nombre":"Nome:",
	"userform.apellidos":"Cognome:",
	"userform.email":"Email:",
	"userform.pais":"Paese:",
	"userform.rol":"Ruolo ricoperto:",
	"userform.entidadResponsableAlta":"Ente di appartenenza:",
	"userform.entidadRepresentada":"Ente rappresentata:",
	"userform.emailSolicitudInfo":"Email richiesta informazioni:",
	"userform.grupoLbgCoordinador":"Gruppo LBG coordinatore:",
	"userform.activo":"Attivo",
	"userform.guardar":"Salva",
	"userform.cancelar":"Annulla",
	"userform.volver":"Torna all'elenco Utenti",
	"user.disponiblesGerentes":"Disponibili: Gestori",
	"user.supervisoresProyecto":"Supervisori Progetto",
	"user.supIni":"Supervisori Iniziativa",
	"user.agentes":"Agenti",
	"user.editar":"Modifica",
	"user.borrar":"Elimina",
	"user.codigo":"Codice",
	"user.nombre":"Nome",
	"user.apellidos":"Cognome",
	"user.email":"Email",
	"user.rol":"Ruolo",
	"user.entidadResponsable":"Ente responsabile",
	"user.entidadRepresentada":"Ente rappresentata",
	"user.pais":"Paese",
	"user.estado":"Stato",
	"user.desactivar":"Disattiva",
	"user.activar":"Attiva",
	"user.usuario":"Utente",
	"user.estasSeguro":"Continuare?",
	"user.cancelar":"Annulla",
	"user.borrarUsuario":"Elimina Utente",
	"confirmdelete.confirmarBorrado":"Conferma eliminazione",
	"confirmdelete.estasSeguro":"Continuare?",
	"documento.size.excedido":"La dimensione del file eccede il limite massimo consentito: 40MB",
	"proyectclosed.alert":"Impossibile eseguire operazioni su progetti già chiusi o sui contenuti ad essi correlati",
	"list.empty":"Non ci sono elementi da visualizzare",
	"list.cargando":"Caricamento in corso...",
	"impactSelect.sinSeleccion":"Nessuna selezione",
	"meta.ods.1.nombre.1":"1.1 Sradicare la povertà estrema per tutte le persone in tutto il mondo",
	"meta.ods.1.nombre.2":"1.2 Ridurre almeno della metà la quota di uomini, donne e bambini di tutte le età che vivono in povertà",
	"meta.ods.1.nombre.3":"1.3  Implementare a livello nazionale adeguati sistemi di protezione sociale e misure di sicurezza per tutti, compresi i livelli più bassi, e raggiungere una sostanziale copertura delle persone povere e vulnerabili",
	"meta.ods.1.nombre.4":"1.4 Assicurare che tutti gli uomini e le donne, in particolare i più poveri e vulnerabili, abbiano uguali diritti alle risorse economiche, insieme all'accesso ai servizi di base, proprietà privata, controllo su terreni e altre forme di proprietà, eredità, risorse naturali, nuove tecnologie appropriate e servizi finanziari, tra cui la microfinanza",
	"meta.ods.1.nombre.5":"1.5 Rinforzare la resilienza dei poveri e di coloro che si trovano in situazioni di vulnerabilità e ridurre la loro esposizione e vulnerabilità ad eventi climatici estremi, catastrofi e shock economici, sociali e ambientali.",
	"meta.ods.1.nombre.a":"1.a Garantire una significativa mobilitazione di risorse da diverse fonti al fine di fornire mezzi adeguati e affidabili per i paesi in via di sviluppo, in particolare i paesi meno sviluppati, attuando programmi e politiche per porre fine alla povertà in tutte le sue forme",
	"meta.ods.1.nombre.b":"1.b Creare solidi sistemi di politiche a livello nazionale, regionale e internazionale, basati su strategie di sviluppo a favore dei poveri e sensibili alle differenze di genere, per sostenere investimenti accelerati nelle azioni di lotta alla povertà",
	"meta.ods.2.nombre.1":"2.1 Porre fine alla fame e assicurare a tutte le persone un accesso sicuro a cibo nutriente e sufficiente per tutto l'anno",
	"meta.ods.2.nombre.2":"2.2 Porre fine a tutte le forme di malnutrizione e soddisfare le esigenze nutrizionali di ragazze adolescenti, donne in gravidanza e allattamento e delle persone anziane",
	"meta.ods.2.nombre.3":"2.3 Raddoppiare la produttività agricola e il reddito dei produttori di cibo su piccola scala, anche attraverso un accesso sicuro ed equo alla terra, altre risorse e stimoli produttivi, conoscenze, servizi finanziari, mercati e opportunità per creare valore aggiunto e occupazioni non agricole",
	"meta.ods.2.nombre.4":"2.4 Garantire sistemi di produzione alimentare sostenibili e implementare pratiche agricole resilienti che aumentino la produttività e la produzione, che aiutino a proteggere gli ecosistemi, che rafforzino la capacità di adattamento ai cambiamenti climatici, a condizioni meteorologiche estreme, siccità, inondazioni e altri disastri e che migliorino progressivamente la qualità del suolo",
	"meta.ods.2.nombre.5":"2.5 Assicurare la diversità genetica delle sementi, delle piante coltivate, degli animali da allevamento e domestici e delle specie selvatiche affini e promuovere l'accesso e la giusta ed equa ripartizione dei benefici derivanti dall'utilizzo delle risorse genetiche e della conoscenza tradizionale associata, come concordato a livello internazionale",
	"meta.ods.2.nombre.a":"2.a Aumentare gli investimenti in infrastrutture rurali, servizi di ricerca e di divulgazione agricola, sviluppo tecnologico e le banche di geni vegetali e animali, al fine di migliorare la capacità produttiva agricola nei paesi in via di sviluppo",
	"meta.ods.2.nombre.b":"2.b Correggere e prevenire restrizioni commerciali e distorsioni nei mercati agricoli mondiali",
	"meta.ods.2.nombre.c":"2.c Adottare misure per garantire il corretto funzionamento dei mercati delle materie prime alimentari e dei loro derivati ​​e facilitare l'accesso tempestivo alle informazioni di mercato, anche per quanto riguarda le riserve di cibo, al fine di contribuire a limitare l’instabilità estrema dei prezzi dei generi alimentari",
	"meta.ods.3.nombre.1":"3.1 Ridurre il tasso di mortalità materna globale",
	"meta.ods.3.nombre.2":"3.2 Porre fine alle morti prevenibili di neonati e bambini sotto i 5 anni di età",
	"meta.ods.3.nombre.3":"3.3 Porre fine alle epidemie di AIDS, tubercolosi, malaria e malattie tropicali trascurate e combattere l'epatite, le malattie di origine idrica e altre malattie trasmissibili",
	"meta.ods.3.nombre.4":"3.4 Ridurre di un terzo la mortalità prematura da malattie non trasmissibili attraverso la prevenzione e la cura e promuovere benessere e salute mentale",
	"meta.ods.3.nombre.5":"3.5 Rafforzare la prevenzione e il trattamento di abuso di sostanze, tra cui abuso di stupefacenti e il consumo nocivo di alcool",
	"meta.ods.3.nombre.6":"3.6 Dimezzare il numero globale di decessi e feriti a seguito di incidenti stradali",
	"meta.ods.3.nombre.7":"3.7 Garantire l'accesso universale ai servizi di assistenza sanitaria sessuale e riproduttiva, compresi quelli per la pianificazione familiare, l'informazione e l'educazione e l'integrazione della salute riproduttiva nelle strategie e nei programmi nazionali",
	"meta.ods.3.nombre.8":"3.8 Conseguire l'accesso a servizi essenziali di assistenza sanitaria di qualità e l’accesso sicuro, efficace, di qualità e a prezzi accessibili a medicinali di base e vaccini per tutti",
	"meta.ods.3.nombre.9":"3.9 Ridurre sostanzialmente il numero di decessi e malattie da sostanze chimiche pericolose e da inquinamento e contaminazione dell'aria, delle acque e del suolo",
	"meta.ods.3.nombre.a":"3.a Rafforzare l'attuazione del Quadro Normativo della Convenzione dell’Organizzazione Mondiale della Sanità sul Controllo del Tabacco in modo appropriato in tutti i paesi",
	"meta.ods.3.nombre.b":"3.b Sostenere la ricerca e lo sviluppo di vaccini e farmaci per le malattie trasmissibili e non trasmissibili che colpiscono soprattutto i paesi in via di sviluppo, fornire l'accesso ai farmaci essenziali e ai vaccini a prezzi accessibili",
	"meta.ods.3.nombre.c":"3.c Aumentare considerevolmente i fondi destinati alla sanità e alla selezione, sviluppo, formazione e mantenimento del personale sanitario nei paesi in via di sviluppo",
	"meta.ods.3.nombre.d":"3.d Rafforzare la capacità di tutti i paesi, in particolare i paesi in via di sviluppo, di segnalare in anticipo, ridurre e gestire i rischi legati alla salute, sia a livello nazionale che globale",
	"meta.ods.4.nombre.1":"4.1 Garantire ad ogni ragazza e ragazzo libertà, equità e qualità nel completamento dell'educazione primaria e secondaria che porti a risultati di apprendimento adeguati e concreti",
	"meta.ods.4.nombre.2":"4.2 Garantire che ogni ragazza e ragazzo abbiano uno sviluppo infantile di qualità, ed un accesso a cure ed istruzione pre-scolastiche così da essere pronti alla scuola primaria",
	"meta.ods.4.nombre.3":"4.3 Garantire ad ogni donna e uomo un accesso equo ad un’istruzione tecnica, professionale e terziaria -anche universitaria- che sia economicamente vantaggiosa e di qualità",
	"meta.ods.4.nombre.4":"4.4 Aumentare considerevolmente il numero di giovani e adulti con competenze specifiche -anche tecniche e professionali- per l’occupazione, posti di lavoro dignitosi e per l’imprenditoria",
	"meta.ods.4.nombre.5":"4.5 Eliminare entro il 2030 le disparità di genere nell'istruzione e garantire un accesso equo a tutti i livelli di istruzione e formazione professionale delle categorie protette",
	"meta.ods.4.nombre.6":"4.6 Garantire che tutti i giovani e gran parte degli adulti, sia uomini che donne, abbiano un livello di alfabetizzazione ed una capacità di calcolo",
	"meta.ods.4.nombre.7":"4.7 Garantire che tutti i discenti acquisiscano la conoscenza e le competenze necessarie a promuovere lo sviluppo sostenibile, anche tramite un educazione volta ad uno sviluppo e uno stile di vita sostenibile, ai diritti umani, alla parità di genere, alla promozione di una cultura pacifica e non violenta, alla cittadinanza globale e alla valorizzazione delle diversità culturali e del contributo della cultura allo sviluppo sostenibile",
	"meta.ods.4.nombre.a":"4.a Costruire e potenziare le strutture dell'istruzione che siano sensibili ai bisogni dell'infanzia, alle disabilità e alla parità di genere e predisporre ambienti dedicati all'apprendimento che siano sicuri, non violenti e inclusivi per tutti",
	"meta.ods.4.nombre.b":"4.b Espandere considerevolmente a livello globale il numero di borse di studio disponibili per i paesi in via di sviluppo, specialmente nei paesi meno sviluppati, nei piccoli stati insulari e negli stati africani, per garantire l'accesso all'istruzione superiore  - compresa la formazione professionale, le tecnologie dell'informazione e della comunicazione e i programmi tecnici, ingegneristici e scientifici -  sia nei paesi sviluppati che in quelli in via di sviluppo",
	"meta.ods.4.nombre.c":"4.c Aumentare considerevolmente la presenza di insegnanti qualificati, anche grazie alla cooperazione internazionale, per la loro attività di formazione negli stati in via di sviluppo",
	"meta.ods.5.nombre.1":"5.1 Porre fine, ovunque, a ogni forma di discriminazione nei confronti di donne e ragazze",
	"meta.ods.5.nombre.2":"5.2 Eliminare ogni forma di violenza nei confronti di donne e bambine, sia nella sfera privata che in quella pubblica",
	"meta.ods.5.nombre.3":"5.3 Eliminare ogni pratica abusiva come il matrimonio combinato, il fenomeno delle spose bambine e le mutilazioni genitali femminili",
	"meta.ods.5.nombre.4":"5.4 Promuovere responsabilità condivise all'interno del nucleo familiare",
	"meta.ods.5.nombre.5":"5.5 Garantire piena ed effettiva partecipazione femminile e pari opportunità di leadership ad ogni livello decisionale in ambito politico, economico e della vita pubblica",
	"meta.ods.5.nombre.6":"5.6 Garantire l'accesso universale alla salute sessuale e riproduttiva e ai diritti in ambito riproduttivo",
	"meta.ods.5.nombre.a":"5.a Avviare riforme per dare alle donne pari diritti di accesso alle risorse economiche, così come alla titolarità e al controllo della terra e altre forme di proprietà, ai servizi finanziari, eredità e risorse naturali",
	"meta.ods.5.nombre.b":"5.b Rafforzare l'utilizzo di tecnologie abilitanti, in particolare le tecnologie dell'informazione e della comunicazione, per promuovere l'emancipazione della donna",
	"meta.ods.5.nombre.c":"5.c Adottare e intensificare una politica sana ed una legislazione applicabile per la promozione della parità di genere e l'emancipazione di tutte le donne e bambine, a tutti i livelli",
	"meta.ods.6.nombre.1":"6.1 Ottenere l’accesso universale ed equo all'acqua potabile che sia sicura ed economica per tutti",
	"meta.ods.6.nombre.2":"6.2 Ottenere l'accesso ad impianti sanitari e igienici adeguati ed equi per tutti e porre fine alla defecazione all'aperto, prestando particolare attenzione ai bisogni di donne e bambine e a chi si trova in situazioni di vulnerabilità",
	"meta.ods.6.nombre.3":"6.3 Migliorare la qualità dell'acqua eliminando le discariche, riducendo l'inquinamento e il rilascio di prodotti chimici e scorie pericolose, dimezzando la quantità di acque reflue non trattate e aumentando considerevolmente il riciclaggio e il reimpiego sicuro a livello globale",
	"meta.ods.6.nombre.4":"6.4 Aumentare sostanzialmente l'efficienza nell'utilizzo dell'acqua in ogni settore e garantire approvvigionamenti e forniture sostenibili di acqua potabile, per affrontare la carenza idrica e ridurre in modo sostanzioso il numero di persone che ne subisce le conseguenze",
	"meta.ods.6.nombre.5":"6.5 Implementare una gestione delle risorse idriche integrata a tutti i livelli",
	"meta.ods.6.nombre.6":"6.6 Proteggere e risanare gli ecosistemi legati all'acqua, comprese le montagne, le foreste, le paludi, i fiumi, le falde acquifere e i laghi",
	"meta.ods.6.nombre.a":"6.a Espandere la cooperazione internazionale e il supporto per creare attività e programmi legati all'acqua e agli impianti igienici nei paesi in via di sviluppo",
	"meta.ods.6.nombre.b":"6.b Supportare e rafforzare la partecipazione delle comunità locali nel miglioramento della gestione dell'acqua e degli impianti igienici",
	"meta.ods.7.nombre.1":"7.1 Garantire l'accesso universale a servizi energetici affidabili e moderni a prezzi accessibili",
	"meta.ods.7.nombre.2":"7.2 Aumentare notevolmente la quota di energie rinnovabili nel consumo totale di energia",
	"meta.ods.7.nombre.3":"7.3 Raddoppiare il tasso globale di miglioramento dell'efficienza energetica",
	"meta.ods.7.nombre.a":"7.a Accrescere la cooperazione internazionale per facilitare l'accesso alla ricerca e alle tecnologie legate all’energia pulita e promuovere gli investimenti nelle infrastrutture energetiche e nelle tecnologie per l’energia pulita",
	"meta.ods.7.nombre.b":"7.b Implementare entro il 2030 le infrastrutture e migliorare le tecnologie per fornire servizi energetici moderni e sostenibili per tutti i paesi in via di sviluppo",
	"meta.ods.8.nombre.1":"8.1 Sostenere la crescita economica pro capite a seconda delle circostanze nazionali",
	"meta.ods.8.nombre.2":"8.2 Raggiungere livelli più elevati di produttività economica attraverso la diversificazione, l'aggiornamento tecnologico e l'innovazione",
	"meta.ods.8.nombre.3":"8.3 Promuovere le politiche orientate allo sviluppo al fine di supportare attività produttive, la creazione di lavori dignitosi e l’imprenditorialità, la creatività e l'innovazione, incoraggiare la formalizzazione e la crescita di micro, piccole e medie imprese, anche attraverso l'accesso ai servizi finanziari",
	"meta.ods.8.nombre.4":"8.4 Migliorare progressivamente l'efficienza dell’utilizzo delle risorse a livello mondiale nei processi di produzione e di consumo, e cercare di far sì che la crescita economica non conduca a un degrado dell’ambiente",
	"meta.ods.8.nombre.5":"8.5 Raggiungere la piena e produttiva occupazione e un lavoro dignitoso per tutte le donne e gli uomini, anche per i giovani e le persone con disabilità, e la parità di retribuzione per lavoro di pari valore",
	"meta.ods.8.nombre.6":"8.6 Ridurre sostanzialmente la percentuale di giovani disoccupati che non seguano un corso di studi o che non seguano corsi di formazione",
	"meta.ods.8.nombre.7":"8.7 Adottare misure immediate ed efficaci per eliminare il lavoro forzato, porre fine alla schiavitù moderna e alla tratta di esseri umani e garantire la proibizione ed eliminazione delle peggiori forme di lavoro minorile, compreso il reclutamento e l’impiego dei bambini soldato.",
	"meta.ods.8.nombre.8":"8.8 Proteggere i diritti del lavoro e promuovere un ambiente di lavoro sicuro e protetto per tutti i lavoratori, compresi i lavoratori migranti, in particolare le donne migranti, e quelli in lavoro precario",
	"meta.ods.8.nombre.9":"8.9 Elaborare e attuare politiche volte a promuovere il turismo sostenibile, che crei posti di lavoro e promuova la cultura e i prodotti locali",
	"meta.ods.8.nombre.10":"8.10 Rafforzare la capacità delle istituzioni finanziarie nazionali per incoraggiare e ampliare l'accesso ai servizi bancari, assicurativi e finanziari per tutti",
	"meta.ods.8.nombre.a":"8.a  Aumentare gli aiuti per il sostegno al commercio per i paesi in via di sviluppo",
	"meta.ods.8.nombre.b":"8.b Sviluppare e rendere operativa una strategia globale per l'occupazione giovanile",
	"meta.ods.9.nombre.1":"9.1 Sviluppare infrastrutture di qualità, affidabili, sostenibili e resilienti, comprese le infrastrutture regionali e transfrontaliere, per sostenere lo sviluppo economico e il benessere degli individui, con particolare attenzione alla possibilità di accesso equo per tutti",
	"meta.ods.9.nombre.2":"9.2 Promuovere l'industrializzazione inclusiva e sostenibile e aumentare in modo significativo le quote di occupazione nell'industria e il prodotto interno lordo e raddoppiare questa quota nei paesi meno sviluppati",
	"meta.ods.9.nombre.3":"9.3  Incrementare l'accesso delle piccole imprese industriali e non, in particolare nei paesi in via di sviluppo, ai servizi finanziari, compresi i prestiti a prezzi convenienti, e la loro integrazione nell'indotto e nei mercati",
	"meta.ods.9.nombre.4":"9.4 Migliorare le infrastrutture e riconfigurare in modo sostenibile le industrie, aumentando l'efficienza nell'utilizzo delle risorse e adottando tecnologie e processi industriali più puliti e sani per l'ambiente",
	"meta.ods.9.nombre.5":"9.5 Potenziare la ricerca scientifica, promuovere le capacità tecnologiche dei settori industriali in tutti i paesi, in particolare nei paesi in via di sviluppo, anche incoraggiando l'innovazione e incrementando in modo sostanziale il numero dei lavoratori del settore della ricerca e dello sviluppo",
	"meta.ods.9.nombre.a":"9.a Facilitare lo sviluppo sostenibile e resiliente delle infrastrutture nei paesi in via di sviluppo attraverso un maggiore sostegno finanziario, tecnologico e tecnico",
	"meta.ods.9.nombre.b":"9.b Sostenere lo sviluppo delle tecnologie nazionali, la ricerca e l’innovazione nei paesi in via di sviluppo",
	"meta.ods.9.nombre.c":"9.c Aumentare significativamente l'accesso alle tecnologie dell'informazione e della comunicazione e sforzarsi di fornire un accesso universale e a basso costo a Internet nei paesi meno sviluppati",
	"meta.ods.10.nombre.1":"10.1 Raggiungere progressivamente e sostenere la crescita del reddito del 40% della popolazione nello strato sociale più basso ad un tasso superiore rispetto alla media nazionale",
	"meta.ods.10.nombre.2":"10.2 Potenziare e promuovere l'inclusione sociale, economica e politica di tutti, a prescindere da età, sesso, disabilità, razza, etnia, origine, religione, status economico o altro",
	"meta.ods.10.nombre.3":"10.3 Assicurare pari opportunità e ridurre le disuguaglianze nei risultati, anche eliminando leggi, politiche e pratiche discriminatorie e promuovendo legislazioni, politiche e azioni appropriate a tale proposito.",
	"meta.ods.10.nombre.4":"10.4 Adottare politiche, in particolare fiscali, e politiche salariali e di protezione sociale, per raggiungere progressivamente una maggiore uguaglianza",
	"meta.ods.10.nombre.5":"10.5 Migliorare la regolamentazione e il monitoraggio di istituzioni e mercati finanziari globali e rafforzare l’attuazione di tali norme",
	"meta.ods.10.nombre.6":"10.6 Assicurare una migliore rappresentanza che dia voce ai paesi in via di sviluppo nelle istituzioni responsabili delle decisioni in materia di economia e finanza globale e internazionale",
	"meta.ods.10.nombre.7":"10.7 Facilitare la migrazione ordinata, sicura, regolare e responsabile e la mobilità delle persone, anche attraverso l'attuazione di politiche migratorie programmate e ben gestite",
	"meta.ods.10.nombre.a":"10.a Attuare il principio del trattamento speciale e differenziato per i paesi in via di sviluppo",
	"meta.ods.10.nombre.b":"10.b Promuovere l’aiuto pubblico allo sviluppo e i relativi flussi finanziari, compresi gli investimenti esteri diretti, agli Stati dove il bisogno è maggiore",
	"meta.ods.10.nombre.c":"10.c Ridurre a meno del 3% i costi di transazione delle rimesse dei migranti ed eliminare i corridoi di rimesse con costi oltre il 5%",
	"meta.ods.11.nombre.1":"11.1 Garantire a tutti l’accesso ad alloggi adeguati, sicuri e convenienti e ai servizi di base e riqualificare i quartieri poveri",
	"meta.ods.11.nombre.2":"11.2 Fornire l'accesso a sistemi di trasporto sicuri, sostenibili, e convenienti per tutti, migliorare la sicurezza stradale, in particolare ampliando i mezzi pubblici, con particolare attenzione alle esigenze di chi è più vulnerabile, le donne, i bambini, le persone con disabilità e gli anziani",
	"meta.ods.11.nombre.3":"11.3 Potenziare un’urbanizzazione inclusiva e sostenibile e la capacità di pianificazione e gestione partecipata e integrata dell’insediamento umano in tutti i paesi",
	"meta.ods.11.nombre.4":"11.4 Potenziare gli sforzi per proteggere e salvaguardare il patrimonio culturale e naturale del mondo",
	"meta.ods.11.nombre.5":"11.5 Ridurre in modo significativo il numero di morti e il numero di persone colpite da calamità, con una particolare attenzione alla protezione dei poveri e delle persone in situazioni di vulnerabilità",
	"meta.ods.11.nombre.6":"11.6 Ridurre l’impatto ambientale negativo pro capite delle città, in particolare riguardo alla qualità dell'aria e alla gestione dei rifiuti urbani e di altri rifiuti",
	"meta.ods.11.nombre.7":"11.7 Fornire l'accesso universale a spazi verdi pubblici sicuri, inclusivi e accessibili, in particolare per le donne e i bambini, gli anziani e le persone con disabilità",
	"meta.ods.11.nombre.a":"11.a Sostenere rapporti economici, sociali e ambientali positivi tra le zone urbane, periurbane e rurali, rafforzando la pianificazione dello sviluppo nazionale e regionale",
	"meta.ods.11.nombre.b":"11.b Aumentare notevolmente il numero di città e di insediamenti umani che adottino e attuino politiche e piani integrati verso l'inclusione, l'efficienza delle risorse, la mitigazione e l'adattamento ai cambiamenti climatici, la resilienza ai disastri",
	"meta.ods.11.nombre.c":"11.c Sostenere i paesi meno sviluppati nella costruzione di edifici sostenibili e resilienti che utilizzino materiali locali",
	"meta.ods.12.nombre.1":"12.1 Dare attuazione al Quadro decennale di programmi sul consumo e la produzione sostenibile",
	"meta.ods.12.nombre.2":"12.2 Raggiungere la gestione sostenibile e l'uso efficiente delle risorse naturali",
	"meta.ods.12.nombre.3":"12.3 Dimezzare lo spreco pro capite globale di rifiuti alimentari nella vendita al dettaglio e dei consumatori e ridurre le perdite di cibo lungo le filiere di produzione e fornitura, comprese le perdite post-raccolto",
	"meta.ods.12.nombre.4":"12.4 Ottenere la gestione ecocompatibile di sostanze chimiche e di tutti i rifiuti in tutto il loro ciclo di vita e ridurre significativamente il loro rilascio in aria, acqua e suolo, al fine di minimizzare i loro effetti negativi sulla salute umana e l'ambiente",
	"meta.ods.12.nombre.5":"12.5 Ridurre in modo sostanziale la produzione di rifiuti attraverso la prevenzione, la riduzione, il riciclaggio e il riutilizzo",
	"meta.ods.12.nombre.6":"12.6 Incoraggiare le imprese, soprattutto le aziende di grandi dimensioni e transnazionali, ad adottare pratiche sostenibili e integrare le informazioni sulla sostenibilità nei loro resoconti periodici",
	"meta.ods.12.nombre.7":"12.7 Promuovere pratiche in materia di appalti pubblici che siano sostenibili, in accordo con le politiche e le priorità nazionali",
	"meta.ods.12.nombre.8":"12.8 Accertarsi che tutte le persone, in ogni parte del mondo, abbiano le informazioni rilevanti e la giusta consapevolezza dello sviluppo sostenibile e di uno stile di vita in armonia con la natura",
	"meta.ods.12.nombre.a":"12.a Aiutare i paesi in via di sviluppo a rafforzare la loro capacità scientifiche e tecnologiche affinché avanzino verso modelli più sostenibili di consumo e di produzione",
	"meta.ods.12.nombre.b":"12.b Sviluppare e implementare strumenti per monitorare gli impatti dello sviluppo sostenibile per il turismo sostenibile, che crea posti di lavoro e promuove la cultura e i prodotti locali",
	"meta.ods.12.nombre.c":"12.c Razionalizzare i sussidi ai combustibili fossili inefficienti che incoraggiano lo spreco, eliminando le distorsioni del mercato",
	"meta.ods.13.nombre.1":"13.1 Rafforzare in tutti i paesi la capacità di ripresa e di adattamento ai rischi legati al clima e ai disastri naturali",
	"meta.ods.13.nombre.2":"13.2 Integrare le misure di cambiamento climatico nelle politiche, strategie e pianificazione nazionali",
	"meta.ods.13.nombre.3":"13.3 Migliorare l’istruzione, la sensibilizzazione e la capacità umana e istituzionale per quanto riguarda la mitigazione del cambiamento climatico, l’adattamento, la riduzione dell’impatto e l’allerta tempestiva",
	"meta.ods.13.nombre.a":"13.a Trasformare l’impegno preso dai paesi in via di sviluppo secondo la Convenzione Quadro delle Nazioni Unite sui Cambiamenti Climatici (UNFCCC) in un’azione pratica",
	"meta.ods.13.nombre.b":"13.b Promuovere meccanismi per aumentare la capacità effettiva di pianificazione e gestione di interventi inerenti al cambiamento climatico nei paesi meno sviluppati",
	"meta.ods.14.nombre.1":"14.1 Prevenire e ridurre in modo significativo ogni forma di inquinamento marino, in particolar modo quello derivante da attività esercitate sulla terraferma, compreso l’inquinamento dei detriti marini e delle sostanze nutritive",
	"meta.ods.14.nombre.2":"14.2 gestire in modo sostenibile e proteggere l’ecosistema marino e costiero per evitare impatti particolarmente negativi, anche rafforzando la loro resilienza, e agire per il loro ripristino in modo da ottenere oceani salubri e produttivi",
	"meta.ods.14.nombre.3":"14.3 Ridurre al minimo e affrontare gli effetti dell’acidificazione degli oceani, anche attraverso una maggiore collaborazione scientifica su tutti i livelli",
	"meta.ods.14.nombre.4":"14.4 Regolare in modo efficace la pesca e porre termine alla pesca eccessiva, illegale, non dichiarata e non regolamentata e ai metodi di pesca distruttivi. Implementare piani di gestione su base scientifica, così da ripristinare nel minor tempo possibile le riserve ittiche",
	"meta.ods.14.nombre.5":"14.5 Preservare almeno il 10% delle aree costiere e marine, in conformità al diritto nazionale e internazionale e basandosi sulle informazioni scientifiche disponibili più accurate",
	"meta.ods.14.nombre.6":"14.6  Vietare quelle forme di sussidi alla pesca che contribuiscono a un eccesso di capacità e alla pesca eccessiva, eliminare i sussidi che contribuiscono alla pesca illegale, non dichiarata e non regolamentata e astenersi dal reintrodurre tali sussidi",
	"meta.ods.14.nombre.7":"14.7 Aumentare i benefici economici dei piccoli stati insulari in via di sviluppo e dei paesi meno sviluppati, facendo ricorso a un utilizzo più sostenibile delle risorse marine, compresa la gestione sostenibile della pesca, dell’acquacoltura e del turismo",
	"meta.ods.14.nombre.a":"14.a Aumentare la conoscenza scientifica, sviluppare la capacità di ricerca e di trasmissione della tecnologia marina con lo scopo di migliorare la salute dell’oceano e di aumentare il contributo della biodiversità marina allo sviluppo dei paesi emergenti, in particolar modo dei piccoli stati insulari in via di sviluppo e dei paesi meno sviluppati",
	"meta.ods.14.nombre.b":"14.b Fornire l’accesso ai piccoli pescatori artigianali alle risorse e ai mercati marini",
	"meta.ods.14.nombre.c":"14.c Potenziare la conservazione e l’utilizzo sostenibile degli oceani e delle loro risorse applicando il diritto internazionale, come riportato nella Convenzione delle Nazioni Unite sul Diritto del Mare",
	"meta.ods.15.nombre.1":"15.1 Garantire la conservazione, il ripristino e l’utilizzo sostenibile degli ecosistemi di acqua dolce terrestri e dell’entroterra nonché dei loro servizi, in modo particolare delle foreste, delle paludi, delle montagne e delle zone aride",
	"meta.ods.15.nombre.2":"15.2 Promuovere una gestione sostenibile di tutti i tipi di foreste, arrestare la deforestazione, ripristinare le foreste degradate e aumentare ovunque, in modo significativo, la riforestazione e il rimboschimento",
	"meta.ods.15.nombre.3":"15.3 Combattere la desertificazione, ripristinare le terre degradate, comprese quelle colpite da desertificazione, siccità e inondazioni, e battersi per ottenere un mondo privo di degrado del suolo",
	"meta.ods.15.nombre.4":"15.4 Garantire la conservazione degli ecosistemi montuosi, incluse le loro biodiversità, al fine di migliorarne la capacità di produrre benefici essenziali per uno sviluppo sostenibile",
	"meta.ods.15.nombre.5":"15.5 Intraprendere azioni efficaci ed immediate per ridurre il degrado degli ambienti naturali, arrestare la distruzione della biodiversità e proteggere le specie a rischio di estinzione",
	"meta.ods.15.nombre.6":"15.6 Promuovere una distribuzione equa e giusta dei benefici derivanti dall’utilizzo delle risorse genetiche e promuovere un equo accesso a tali risorse",
	"meta.ods.15.nombre.7":"15.7 Agire per porre fine al bracconaggio e al traffico delle specie protette di flora e fauna e combattere il commercio illegale di specie selvatiche",
	"meta.ods.15.nombre.8":"15.8 Adottare misure per prevenire l’introduzione di specie diverse ed invasive nonché ridurre in maniera sostanziale il loro impatto sugli ecosistemi terrestri e acquatici e controllare o debellare le specie prioritarie",
	"meta.ods.15.nombre.9":"15.9 Integrare i principi di ecosistema e biodiversità nei progetti nazionali e locali, nei processi di sviluppo e nelle strategie e nei resoconti per la riduzione della povertà",
	"meta.ods.15.nombre.a":"15.a Mobilitare e incrementare in maniera significativa le risorse economiche da ogni fonte per preservare e usare in maniera sostenibile la biodiversità e gli ecosistemi",
	"meta.ods.15.nombre.b":"15.b Mobilitare risorse significative da ogni fonte e a tutti i livelli per finanziare la gestione sostenibile delle foreste e fornire incentivi adeguati ai paesi in via di sviluppo perché possano migliorare tale gestione e per la conservazione e la riforestazione",
	"meta.ods.15.nombre.c":"15.c Rafforzare il sostegno globale per combattere il bracconaggio e il traffico illegale delle specie protette, anche incrementando la capacità delle comunità locali ad utilizzare mezzi di sussistenza sostenibili",
	"meta.ods.16.nombre.1":"16.1 Ridurre ovunque e in maniera significativa tutte le forme di violenza e il tasso di mortalità ad esse correlato",
	"meta.ods.16.nombre.2":"16.2 Porre fine all’abuso, allo sfruttamento, al traffico di bambini e a tutte le forme di violenza e tortura nei loro confronti",
	"meta.ods.16.nombre.3":"16.3 Promuovere lo stato di diritto a livello nazionale e internazionale e garantire parità di accesso alla giustizia per tutti",
	"meta.ods.16.nombre.4":"16.4 ridurre in maniera significativa il finanziamento illecito e il traffico di armi, potenziare il recupero e la restituzione dei beni rubati e combattere tutte le forme di crimine organizzato",
	"meta.ods.16.nombre.5":"16.5 Ridurre sensibilmente la corruzione e gli abusi di potere in tutte le loro forme",
	"meta.ods.16.nombre.6":"16.6 Sviluppare istituzioni efficaci, responsabili e trasparenti a tutti i livelli",
	"meta.ods.16.nombre.7":"16.7 Assicurare un processo decisionale reattivo, inclusivo, partecipativo e rappresentativo a tutti i livelli",
	"meta.ods.16.nombre.8":"16.8 Ampliare e rafforzare la partecipazione dei paesi in via di sviluppo nelle istituzioni della governance globale",
	"meta.ods.16.nombre.9":"16.9 Garantire per tutti un’identità legale e la registrazione delle nascite",
	"meta.ods.16.nombre.10":"16.10 Garantire un pubblico accesso all’informazione e proteggere le libertà fondamentali, in conformità con la legislazione nazionale e con gli accordi internazionali",
	"meta.ods.16.nombre.a":"16.a Rafforzare la capacità delle istituzioni nazionali, anche attraverso la cooperazione internazionale in particolare nei paesi in via di sviluppo, di prevenire la violenza e di combattere il terrorismo e la criminalità",
	"meta.ods.16.nombre.b":"16.b Promuovere e far rispettare le leggi e le politiche non discriminatorie per uno sviluppo sostenibile",
	"meta.ods.17.nombre.1":"17.1 Rafforzare la mobilitazione delle risorse interne, anche attraverso il sostegno internazionale ai paesi in via di sviluppo, per migliorare la capacità interna di gestire le imposte e altre forme di riscossione delle entrate",
	"meta.ods.17.nombre.2":"17.2 Garantire l’intervento dei paesi sviluppati al fine di adempiere pienamente agli obblighi di assistenza allo sviluppo ufficiali",
	"meta.ods.17.nombre.3":"17.3 Mobilitare ulteriori risorse finanziarie per i paesi in via di sviluppo da più fonti",
	"meta.ods.17.nombre.4":"17.4 Aiutare i paesi in via di sviluppo a sostenere il debito a lungo termine attraverso politiche coordinate volte a stimolare il finanziamento, la riduzione e la ristrutturazione del debito, e affrontare il debito estero dei paesi più poveri e più fortemente indebitati al fine di ridurne il peso",
	"meta.ods.17.nombre.5":"17.5 Adottare e applicare i regimi di promozione degli investimenti a favore dei paesi meno sviluppati",
	"meta.ods.17.nombre.6":"17.6 Rafforzare la cooperazione Nord-Sud, Sud-Sud, la cooperazione triangolare regionale e internazionale e l’accesso alle scoperte scientifiche, alla tecnologia e alle innovazioni, e migliorare la condivisione della conoscenza sulla base di modalità concordate",
	"meta.ods.17.nombre.7":"17.7 Promuovere nei paesi in via di sviluppo la crescita, lo scambio e la diffusione di tecnologie rispettose dell’ambiente a condizioni favorevoli, attraverso patti agevolati e preferenziali stabiliti di comune accordo",
	"meta.ods.17.nombre.8":"17.8 Rendere operativo il meccanismo per il rafforzamento della tecnologia della banca e della scienza, della tecnologia e dell’innovazione per i paesi meno industrializzati e rafforzare l’uso della tecnologia avanzata in particolar modo nell’informazione e nelle comunicazioni",
	"meta.ods.17.nombre.9":"17.9 Accrescere il supporto internazionale per implementare nei paesi non industrializzati uno sviluppo delle capacità efficace e mirato al fine di sostenere i piani nazionali per la realizzazione di tutti gli obiettivi dello Sviluppo Sostenibile",
	"meta.ods.17.nombre.10":"17.10 Promuovere un sistema di scambio universale, regolamentato, aperto, senza discriminazioni e multilaterale sotto il controllo dell’Organizzazione Mondiale del Commercio",
	"meta.ods.17.nombre.11":"17.11 Incrementare considerevolmente le esportazioni dei paesi emergenti",
	"meta.ods.17.nombre.12":"17.12 Realizzare tempestivamente per i paesi meno sviluppati un accesso al mercato libero da dazi e quote su basi durevoli, coerente con quanto deciso dall’Organizzazione Mondiale del Commercio",
	"meta.ods.17.nombre.13":"17.13 Promuovere la stabilità macroeconomica globale attraverso il coordinamento e la coerenza politica",
	"meta.ods.17.nombre.14":"17.14 Accrescere la coerenza delle politiche per lo sviluppo sostenibile",
	"meta.ods.17.nombre.15":"17.15 Garantire il rispetto dello spazio politico e della leadership di ciascun paese per stabilire e attuare politiche di sradicamento della povertà e di sviluppo sostenibile",
	"meta.ods.17.nombre.16":"17.16 Migliorare il partenariato globale per lo sviluppo sostenibile, integrato da partenariati multilaterali che mobilitino e condividano le conoscenze, le competenze, le tecnologie e le risorse finanziarie",
	"meta.ods.17.nombre.17":"17.17 Incoraggiare e promuovere efficaci partenariati nel settore pubblico, tra pubblico e privato e nella società civile basandosi sull’esperienza delle partnership e sulla loro capacità di trovare risorse",
	"meta.ods.17.nombre.18":"17.18 Rafforzare il meccanismo di supporto delle capacità per i paesi in via di sviluppo, anche per i paesi meno sviluppati e i piccoli Stati insulari in via di sviluppo, per aumentare in modo significativo la disponibilità di dati di alta qualità",
	"meta.ods.17.nombre.19":"17.19 Costruire, sulle base iniziative esistenti, sistemi di misurazione dell’avanzamento verso lo sviluppo sostenibile",
	"sectorlbg.nombre.consumer":"Beni di consumo",
	"sectorlbg.nombre.engieering":"Ingegneria e produzione industriale",
	"sectorlbg.nombre.financial":"Servizi finanziari ",
	"sectorlbg.nombre.food":"Prodotti alimentari e bevande",
	"sectorlbg.nombre.legal":"Servizi Legali",
	"sectorlbg.nombre.medias":"Mezzi di comunicazione",
	"sectorlbg.nombre.natural":"Risorse naturali",
	"sectorlbg.nombre.pharmaceiutical":"Prodotti farmaceutici",
	"sectorlbg.nombre.professional":"Servizi professionali",
	"sectorlbg.nombre.construction":"Settore immobiliare, Costruzione edile, Edilizia abitativa, Impianti",
	"sectorlbg.nombre.retail":"Retail",
	"sectorlbg.nombre.technology":"Tecnologia e telecomunicazioni",
	"sectorlbg.nombre.travel":"Viaggi e ospitalità",
	"sectorlbg.nombre.utilites":"Società di servizi",
	"area.nombre.educacion":"Istruzione",
	"area.nombre.salud":"Salute",
	"area.nombre.desarrollo":"Sviluppo socio-economico",
	"area.nombre.medio.ambiente":"Tutela dell'ambiente",
	"area.nombre.arte":"Arte e Cultura",
	"area.nombre.social":"Benessere sociale",
	"area.nombre.ayuda":"Aiuti Umanitari",
	"area.nombre.autonomia":"Autonomia personale e vita indipendente",
	"area.nombre.empleo":"Occupazione e imprenditorialità",
	"area.nombre.institucional":"Consolidamento istituzionale",
	"area.nombre.ciudadania":"Promozione della cittadinanza attiva",
	"area.nombre.diversidad":"Diversità e rafforzamento della famiglia",
	"area.nombre.otros":"Altre aree",
	"grupo.interes.nombre.clientes":"Clienti/Consumatori",
	"grupo.interes.nombre.proveedores":"Fornitori/Distributori",
	"grupo.interes.nombre.donantes":"Donatori/Sponsor",
	"grupo.interes.nombre.otros":"Altri 'Gruppi di interesse'",
	"motivacion.nombre.puntual":"Apporto puntuale",
	"motivacion.nombre.inversion":"Investimento sociale",
	"motivacion.nombre.alineada":"Iniziativa in linea con l’attività principale",
	"motivacion.nombre.propia":"Propria",
	"motivacion.nombre.compartida":"Condivisa",
	"motivacion.nombre.apoyo":"Di supporto",
	"ods.nombre.pobreza":"1. Sconfiggere la povertà",
	"ods.nombre.hambre":"2. Sconfiggere la fame",
	"ods.nombre.salud":"3. Salute e benessere",
	"ods.nombre.educacion":"4. Istruzione di qualità",
	"ods.nombre.genero":"5. Parità di genere",
	"ods.nombre.saneamiento":"6. Acqua pulita e servizi igienico-sanitari",
	"ods.nombre.energia":"7. Energia pulita e accessibile",
	"ods.nombre.trabajo":"8. Lavoro dignitoso e crescita economica",
	"ods.nombre.industria":"9. Imprese, innovazioni e infrastrutture",
	"ods.nombre.desigualdad":"10. Ridurre le disuguaglianze",
	"ods.nombre.sostenibilidad":"11. Città e comunità sostenibili",
	"ods.nombre.consumo":"12. Consumo e produzione responsabili",
	"ods.nombre.clima":"13. Lotta contro il cambiamento climatico",
	"ods.nombre.vida.submarina":"14. Vita sott'acqua",
	"ods.nombre.vida.terrestre":"15. Vita sulla terra",
	"ods.nombre.paz":"16. Pace, giustizia e istituzioni solide",
	"ods.nombre.alianza":"17. Partnership per gli obiettivi",
	"razon.nombre.obligacion.legal":" Contributi per obbligo giuridico",
	"razon.nombre.obligacion.contractual":" Contributi per obbligo contrattuale",
	"razon.nombre.dirigidas.empleados":" Contributi diretti specificamente ai dipendenti",
	"razon.nombre.dirigidas.deporte":" Contributi diretti allo sport professionistico",
	"razon.nombrer.otros":"Altre motivazioni",
	"situacion.nombre.desempleo":"Disoccupati",
	"situacion.nombre.desempleo.larga":"Disoccupati a lungo termine",
	"situacion.nombre.dificultad.aprendizaje":"Con difficoltà di apprendimento",
	"situacion.nombre.analfabetos":"Con basso livello di istruzione o analfabetismo",
	"situacion.nombre.sin.hogar":"Senzatetto",
	"situacion.nombre.inmigrantes":"Migranti",
	"situacion.nombre.refugiados":"Rifugiati",
	"situacion.nombre.presidiario":"Detenuti o ex-detenuti",
	"situacion.nombre.ingresos.bajos":"A basso reddito",
	"situacion.nombre.minorias":"Appartenenti a minoranze etniche",
	"situacion.nombre.pais.desarrollo":"Residenti in paesi in via di sviluppo",
	"situacion.nombre.vulnerabilidad":"In situazione di vulnerabilità",
	"situacion.nombre.desastre.natural":"Vittime di catastrofi o calamità naturali",
	"situacion.nombre.enfermedad":"Affette da malattie",
	"situacion.nombre.discapacidad":"Con disabilità",
	"situacion.nombre.dependencia":"Con dipendenza (tossicodipendenza, alcoolismo o altre dipendenze)",
	"situacion.nombre.abusos":"Vittime di abusi/aggressioni sessuali",
	"situacion.nombre.beneficiario":"Beneficiari del progetto",
	"situacion.nombre.otros":"Altri profili",
	"tipo.empresa.empresa":"Impresa",
	"tipo.empresa.onl":"ONLUS",
	"tipo.empresa.fundacion":"Fondazioni d'impresa",
	"tipo.empresa.otras":"Altre tipologie",
	"tipo.empresa.ong":"ONG",
	"tipo.logro.mujeres.hombres.todos":"Donne e Uomini di tutte le età",
	"tipo.logro.hombres.todos":"Uomini (di tutte le età)",
	"tipo.logro.mujeres.todas":"Donne (di tutte le età)",
	"tipo.logro.niño.niñas.todos":"Bambini e bambine ( 0-16 anni)",
	"tipo.logro.niño":"Bambini (0-16 anni)",
	"tipo.logro.niña":"Bambine (0-16 anni)",
	"tipo.logro.jovenes.todos":"Ragazzi e ragazze (16 -18 anni)",
	"tipo.logro.joven.chico":"Ragazzi (16-18 anni)",
	"tipo.logro.joven.chica":"Ragazze (16-18 anni)",
	"tipo.logro.hombre.mujer.mayor.18":"Uomini e donne ( >18 anni)",
	"tipo.logro.hombre.mayor.18":"Uomini (>18 anni)",
	"tipo.logro.mujere.mayor.18":"Donne (>18 anni)",
	"tipo.logro.mujer.hombre.mayor.45":"Uomini e donne ( >45 anni)",
	"tipo.logro.hombre.mayor.45":"Uomini (>45 anni)",
	"tipo.logro.mujer.mayor.45":"Donne (>45 anni)",
	"tipo.logro.mujer.hombre.mayor.65":"Uomini e donne ( >65 anni)",
	"tipo.logro.hombre.mayor.65":"Uomini (>65 anni)",
	"tipo.logro.mujer.mayor.65":"Donne (>65 anni)",
	"pais.nombre.españa":"Spagna",
	"pais.nombre.francia":"Francia",
	"pais.nombre.reini.unido":"Regno Unito",
	"pais.nombre.alemania":"Germania",
	"pais.nombre.estados.unidos":"Stati Uniti",
	"pais.nombre.mexico":"Messico",
	"pais.nombre.abjasia":"Abcasia",
	"pais.nombre.afganistan":"Afganistan",
	"pais.nombre.albania":"Albania",
	"pais.nombre.andorra":"Andorra",
	"pais.nombre.angola":"Angola",
	"pais.nombre.anguila":"Anguilla",
	"pais.nombre.antigua":"Antigua e Barbuda",
	"pais.nombre.arabia":"Arabia Saudita",
	"pais.nombre.argelia":"Algeria",
	"pais.nombre.argentina":"Argentina",
	"pais.nombre.armenia":"Armenia",
	"pais.nombre.aruba":"Aruba",
	"pais.nombre.australia":"Australia",
	"pais.nombre.austria":"Austria",
	"pais.nombre.azerbaiyan":"Azerbaigian",
	"pais.nombre.bahamas":"Bahamas",
	"pais.nombre.bangladesh":"Bangladesh",
	"pais.nombre.barbados":"Barbados",
	"pais.nombre.barein":"Bahrein",
	"pais.nombre.belgica":"Belgio",
	"pais.nombre.belice":"Belize",
	"pais.nombre.benin":"Benin",
	"pais.nombre.bermudas":"Bermuda",
	"pais.nombre.bielorrusia":"Bielorussia",
	"pais.nombre.birmania":"Birmania",
	"pais.nombre.bolivia":"Bolivia",
	"pais.nombre.bosnia":"Bosnia ed Erzegovina",
	"pais.nombre.botsuana":"Botswana",
	"pais.nombre.brasil":"Brasile",
	"pais.nombre.brunei":"Brunei",
	"pais.nombre.bulgaria":"Bulgaria",
	"pais.nombre.burkina":"Burkina Faso",
	"pais.nombre.burundi":"Burundi",
	"pais.nombre.butan":"Bhutan",
	"pais.nombre.cabo":"Capo Verde",
	"pais.nombre.camboya":"Cambogia",
	"pais.nombre.camerun":"Camerun",
	"pais.nombre.canada":"Canada",
	"pais.nombre.caribe":"Caraibi olandesi",
	"pais.nombre.chad":"Ciad",
	"pais.nombre.chile":"Cile",
	"pais.nombre.china":"Cina",
	"pais.nombre.chipre":"Cipro",
	"pais.nombre.chipre.septentrional":"Cipro del Nord",
	"pais.nombre.ciudad.vaticano":"Città del Vaticano",
	"pais.nombre.colombia":"Colombia",
	"pais.nombre.comoras":"Comore",
	"pais.nombre.corea.norte":"Corea del Nord",
	"pais.nombre.corea.sur":"Corea del Sud",
	"pais.nombre.costa.marfil":"Costa di Avorio",
	"pais.nombre.costa.rica":"Costa Rica",
	"pais.nombre.croacia":"Croazia",
	"pais.nombre.cuba":"Cuba",
	"pais.nombre.curazao":"Curaçao",
	"pais.nombre.dinamarca":"Danimarca",
	"pais.nombre.dominica":"Dominica",
	"pais.nombre.ecuador":"Ecuador",
	"pais.nombre.egipto":"Egitto",
	"pais.nombre.elsalvador":"El Salvador",
	"pais.nombre.emiratos":"Emirati Arabi Uniti",
	"pais.nombre.eritrea":"Eritrea",
	"pais.nombre.eslovaquia":"Slovacchia",
	"pais.nombre.eslovenia":"Slovenia",
	"pais.nombre.estados.federados.micronesia":"Stati Federati di Micronesia",
	"pais.nombre.estonia":"Estonia",
	"pais.nombre.etiopia":"Etiopia",
	"pais.nombre.filipinas":"Filippine",
	"pais.nombre.finlandia":"Finlandia",
	"pais.nombre.fiyi":"Fiji",
	"pais.nombre.gabon":"Gabon",
	"pais.nombre.gambia":"Gambia",
	"pais.nombre.georgia":"Georgia",
	"pais.nombre.ghana":"Ghana",
	"pais.nombre.gibraltar":"Gibilterra",
	"pais.nombre.granada":"Grenada",
	"pais.nombre.grecia":"Grecia",
	"pais.nombre.groenlandia":"Groenlandia",
	"pais.nombre.guadalupe":"Guadalupe",
	"pais.nombre.guam":"Guam",
	"pais.nombre.guatemala":"Guatemala",
	"pais.nombre.guayana":"Guayana Francese",
	"pais.nombre.guernsey":"Guernsey",
	"pais.nombre.guinea":"Guinea",
	"pais.nombre.guinea.ecuatorial":"Guinea Equatoriale",
	"pais.nombre.guinea.bissau":"Guinea-Bissau",
	"pais.nombre.guyana":"Guyana",
	"pais.nombre.haiti":"Haiti",
	"pais.nombre.honduras":"Honduras",
	"pais.nombre.hong.kong":"Hong Kong",
	"pais.nombre.hungría":"Ungheria",
	"pais.nombre.india":"India",
	"pais.nombre.indonesia":"Indonesia",
	"pais.nombre.irak":"Irak",
	"pais.nombre.irán":"Iran",
	"pais.nombre.irlanda":"Irlanda",
	"pais.nombre.isla.ascension":"Isola dell'Ascensione",
	"pais.nombre.isla.navidad":"Isola di Natale",
	"pais.nombre.islandia":"Islandia",
	"pais.nombre.islas.caimán":"Isole Cayman",
	"pais.nombre.islas.marshall":"Isole Marshall",
	"pais.nombre.islas.salomón":"Isole Salomone",
	"pais.nombre.islas.turcas y Caicos":"Turks e Caicos",
	"pais.nombre.islas.virgenes.británicas":"Isole Vergini Britanniche",
	"pais.nombre.islas.virgenes.estados.unidos":"Isole Vergini Americane",
	"pais.nombre.israel":"Israele",
	"pais.nombre.italia":"Italia",
	"pais.nombre.jamaica":"Giamaica",
	"pais.nombre.japon":"Giappone",
	"pais.nombre.jordania":"Giordania",
	"pais.nombre.kazajistan":"Kazakistan",
	"pais.nombre.kenia":"Kenia",
	"pais.nombre.kirguistan":"Kirghizistan",
	"pais.nombre.kiribati":"Kiribati",
	"pais.nombre.kosovo":"Kossovo",
	"pais.nombre.kuwait":"Kuwait",
	"pais.nombre.laos":"Laos",
	"pais.nombre.lesoto":"Lesotho",
	"pais.nombre.letonia":"Lettonia",
	"pais.nombre.libano":"Libano",
	"pais.nombre.liberia":"Liberia",
	"pais.nombre.libia":"Libia",
	"pais.nombre.liechtenstein":"Liechtenstein",
	"pais.nombre.lituania":"Lituania",
	"pais.nombre.luxemburgo":"Lussemburgo",
	"pais.nombre.madagascar":"Madagascar",
	"pais.nombre.malasia":"Malesia",
	"pais.nombre.malaui":"Malawi",
	"pais.nombre.maldivas":"Maldive",
	"pais.nombre.mali":"Mali",
	"pais.nombre.malta":"Malta",
	"pais.nombre.marruecos":"Marocco",
	"pais.nombre.martinica":"Martinica",
	"pais.nombre.mauricio":"Mauritius",
	"pais.nombre.mauritania":"Mauritania",
	"pais.nombre.mayotte":"Mayotte",
	"pais.nombre.moldavia":"Moldavia",
	"pais.nombre.mongolia":"Mongolia",
	"pais.nombre.montserrat":"Montserrat",
	"pais.nombre.mozambique":"Mozambico",
	"pais.nombre.namibia":"Namibia",
	"pais.nombre.nauru":"Nauru",
	"pais.nombre.nepal":"Nepal",
	"pais.nombre.nicaragua":"Nicaragua",
	"pais.nombre.niger":"Niger",
	"pais.nombre.nigeria":"Nigeria",
	"pais.nombre.niue":"Niue",
	"pais.nombre.noruega":"Norvegia",
	"pais.nombre.nueva.zelanda":"Nuova Zelanda",
	"pais.nombre.oman":"Oman",
	"pais.nombre.paeses.bajos":"Paesi Bassi",
	"pais.nombre.pakistan":"Pakistán",
	"pais.nombre.palaos":"Palau",
	"pais.nombre.panama":"Panamá",
	"pais.nombre.papua":"Papua Nueva Guinea",
	"pais.nombre.paraguay":"Paraguay",
	"pais.nombre.peru":"Perù",
	"pais.nombre.polonia":"Polonia",
	"pais.nombre.portugal":"Portogallo",
	"pais.nombre.puerto.rico":"Portorico",
	"pais.nombre.qatar":"Qatar",
	"pais.nombre.republica.centroafricana":"Repubblica Centroafricana",
	"pais.nombre.republica.checa":"Repubblica Ceca",
	"pais.nombre.republica.macedonia":"Repubblica di Macedonia",
	"pais.nombre.republica.congo":"Repubblica del Congo",
	"pais.nombre.republica.democratica.congo":"Repubblica Democratica del Congo",
	"pais.nombre.republica.dominicana":"Repubblica Dominicana",
	"pais.nombre.reunion":"La Riunione",
	"pais.nombre.ruanda":"Ruanda",
	"pais.nombre.rumania":"Romania",
	"pais.nombre.rusia":"Russia",
	"pais.nombre.sahara.occidental":"Sahara Occidentale",
	"pais.nombre.samoa":"Samoa",
	"pais.nombre.san.bartolome":"Saint-Barthélemy (Collectivité de) ",
	"pais.nombre.san.cristobal":"Saint Kitts e Nevis",
	"pais.nombre.san.marino":"San Marino",
	"pais.nombre.san.martin":"Saint Martin",
	"pais.nombre.san.pedro":"Saint Pierre et Miquelon",
	"pais.nombre.san.vicente":"Saint Vincent e Grenadine",
	"pais.nombre.santa.helena":"Sant'Elena",
	"pais.nombre.santa.lucia":"Santa Lucia",
	"pais.nombre.santo.tome":"São Tomé e Príncipe",
	"pais.nombre.senegal":"Senegal",
	"pais.nombre.serbia":"Serbia",
	"pais.nombre.seychelles":"Seychelles",
	"pais.nombre.sierra Leona":"Sierra Leone",
	"pais.nombre.singapur":"Singapore",
	"pais.nombre.siria":"Siria",
	"pais.nombre.somalia":"Somalia",
	"pais.nombre.somaliland":"Somaliland",
	"pais.nombre.sri Lanka":"Sri Lanka",
	"pais.nombre.suazilandia":"Swaziland",
	"pais.nombre.sudafrica":"Sudafrica",
	"pais.nombre.sudan":"Sudan",
	"pais.nombre.sudan.sur":"Sudan del Sud",
	"pais.nombre.suecia":"Svezia",
	"pais.nombre.suiza":"Svizzera",
	"pais.nombre.surinam":"Suriname",
	"pais.nombre.tailandia":"Tailandia",
	"pais.nombre.tanzania":"Tanzania",
	"pais.nombre.tayikistan":"Tagikistan",
	"pais.nombre.togo":"Togo",
	"pais.nombre.tonga":"Tonga",
	"pais.nombre.trinidad y Tobago":"Trinidad e Tobago",
	"pais.nombre.trista.cunha":"Tristan da Cunha",
	"pais.nombre.tunez":"Tunisia",
	"pais.nombre.turkmenistan":"Turkmenistan",
	"pais.nombre.turquia":"Turchia",
	"pais.nombre.tuvalu":"Tuvalu",
	"pais.nombre.ucrania":"Ucrania",
	"pais.nombre.uganda":"Uganda",
	"pais.nombre.uruguay":"Uruguay",
	"pais.nombre.uzbekistan":"Uzbekistan",
	"pais.nombre.vanuau":"Vanuatu",
	"pais.nombre.venezuela":"Venezuela",
	"pais.nombre.vietnam":"Vietnam",
	"pais.nombre.wallis":"Wallis e Futuna",
	"pais.nombre.yemen":"Yemen",
	"pais.nombre.yibuti":"Gibuti",
	"pais.nombre.zambia":"Zambia",
	"pais.nombre.zimbabue":"Zimbawue",
	"pais.nombre.islas.turcas":"Turks e Caicos",
	"pais.nombre.sierra.leona":"Sierra Leone",
	"pais.nombre.sri.lanka":"Sri Lanka",
	"pais.nombre.trinidad.tobago":"Trinidad e Tobago",
	"login.entidad.desactivado":"La tua entità è al momento disattivata",
	"filterReport.byProjects":"Per progetti",	
	"filterReport.informes":"Reports",
	"filterReport.tipoInformes":"Tipologia Report",
	"filterReport.marcarTodos":"Seleziona tutti",
	"filterReport.desmarcarTodos":"Deseleziona tutti",
	"filterReport.divisa":"Valuta",
	"filterReport.seleccionaDivisa":"Si deve selezionare una valuta",
	"filterReport.proyectos":"Progetti",
	"filterReport.proyectState":"Stato dei progetti",
	"filterReport.divUdNegocio":"Divisione o Unità operativa",
	"filterReport.agrInterna":"Gruppi di lavoro",
	"filterReport.extractProyecto":"Estrai informazioni sui budget",
	"filterReport.proyectDate":"Progetti con data di inizio compresa",
	"filterReport.desde":"Dal",
	"filterReport.hasta":"Al",
	"filterReport.y":"E",
	"filterReport.proyectDateEnd":"Progetti con data di fine compresa",
	"filterReport.iniciativa":"Iniziativa",
	"filterReport.extraerLBG":"Estrai iniziative che soddisfino i requisiti LBG/ONLBG",
	"filterReport.pais":"Paese",
	"filterReport.region":"Regione",
	"filterReport.area":"Area di intervento",
	"filterReport.motivacion":"Motivazione",
	"filterReport.extractODS":"Estrai informazioni su SDG",
	"filterReport.ods":"SDG primario",
	"filterReport.odsError":"Si deve selezionare un SDG Primario se \"Estrai informazioni su SDG\" è impostato su \"Sì\"",
	"filterReport.extractInitiative":"Estrai informazioni su budget",
	"filterReport.initiativeDate":"Iniziative con data di inizio compresa",
	"filterReport.initiativeDateEnd":"Iniziative con data di fine compresa",
	"filterReport.contributions":"Contributi",
	"filterReport.validados":"Convalidati",
	"filterReport.atribucion":"Attribuzione",
	"filterReport.contributiontype":"Tipologia Contributo",
	"filterReport.contributionLey112018":"Contributo a una Foundaziones/ONLUS",
	"filterReport.logros":"Risultati",
	"filterReport.impactos":"Impatti",
	"filterReport.generate":"Genera Report",
	"filterReport.generateCharts":"Genera grafici",
	"filterReport.downloadCharts":"Scarica grafici",
	"filterReport.divisaDelInforme":"Seleziona la valuta per il Report",
	"filterReport.entre":"Tra",
	"filterReport.tipo.contribución":"Tipologia contributo",
	"filterReport.unidad.negocio":"Unità operativa",
	"filterReport.ambito.geografico":"Paese",
	"filterReport.pais.españa":"Paese Spagna",
	"filterReport.ODS":"SDG",
	"filterReport.empleados.y.voluntarios":"Dipendenti e volontari",
	"filterReport.efecto.multiplicador":"Effetto moltiplicatore",
	"filterReport.resultados.comunidad":"Risultato comunità",
	"filterReport.resultados.entidad":"Risultato ente",
	"filterReport.dinero":"Denaro",
	"filterReport.especie":"Natura",
	"filterReport.tiempo":"Tempo",
	"filterReport.probono":"Pro bono",
	"filterReport.coste.gestion":"Costi di gestione",
	"filterReport.todos":"Tutti",
	"filterReport.abiertos":"Aperti",
	"filterReport.cerrados":"Chiusi",
	"filterReport.si":"Sì",
	"filterReport.no":"No",
	"filterReport.entidad":"Entità",
	"filterReport.otros":"Altri",
	"filterReport.no.validados":"Non Convalidati",
	"filterReport.todas":"Tutte",
	"filterReport.si.cumplen":"Conformi",
	"filterReport.no.cumplen":"Non conformi",
	"filterReport.descarga":"Scarica dati",
	"filterReport.graficos":"Report sullo schermo",
	"reportChart.activeProyects":"Progetti attivi",
	"reportChart.activeInitiatives":"Iniziative attive",
	"reportChart.contributionProyects":"Progetti con contributo",
	"reportChart.contributionInitiatives":"Iniziative con contributo",
	"reportChart.entityContribution":"Contributo {{ITV}}",
	"reportChart.total":"Totali",
	"reportChart.header.contribType":"Tipologia contributo",
	"reportChart.header.udNegocio":"Unità operativa",
	"reportChart.header.agrInterna":"Gruppi di lavoro",
	"reportChart.header.motivation":"Motivazione",
	"reportChart.header.area":"Area di intervento",
	"reportChart.header.ambitoGeografico":"Paese",
	"reportChart.header.ES":"Paese Spagna",
	"reportChart.header.ods":"SDG",
	"reportChart.contrib.dinero":"Denaro",
	"reportChart.contrib.tiempo":"Tempo",
	"reportChart.contrib.especie":"Natura",
	"reportChart.contrib.probono":"Pro bono",
	"reportChart.contrib.costeGestion":"Costi di gestione",
	"reportChart.header.pais":"Spagna",
	"reportChart.proyectos":"Progetti",
	"reportChart.iniciativas":"Iniziative",
	"reportChart.proyectosConContribucion":"Progetti con contributo",
	"reportChart.contribucionTotal":"Contributo Totale",
	"reportChart.contribucionItv":"Contributo {{ITV}}",
	"reportChart.porcentajeContribucionItv":"Contributo {{ITV}} %",
	"reportChart.contribucionEntidad":"Contributo {{ITV}}",
	"reportChart.porcentajeContribucionEntidad":"Contributo {{ITV}} %",
	"reportChart.areaPrimaria":"Area primaria",
	"reportChart.areaSecundaria":"Area secondaria",
	"reportChart.odsPrimario":"SDG primario",
	"reportChart.odsSecundario":"SDG secondario",
	"reportChart.odsTerciarios":"SDG terziario",
	"reportChart.contributions":"Contributi",
	"reportChart.efectoMultiplicador":"Effetto moltiplicatore {{ITV}}",
	"reportChart.efectoMultiplicadorOtros":"Effetto moltiplicatore altri collaboratori",
	"reportChart.Participacion1":"Partecipanti appartenenti all'Entità: risultati",
	"reportChart.Participacion3":"Impatti sui dipendenti dell'Entità",
	"reportChart.Participacion4":"Partecipazione da parte di Altri collaboratori: Risultati",
	"reportChart.Participacion6":"Impatti sui dipendenti di Altri collaboratori",
	"reportChart.proyectosParticipacion":"Progetti con partecipazione",
	"reportChart.iniciativasParticipacion":"Iniziative con partecipazione",
	"reportChart.numPersonas":"Persone",
	"reportChart.numHoras":"Ore",
	"reportChart.porcentaje":"%",
	"reportChart.costeHora":"Costo",
	"reportChart.emplTotal":"Totale dipendenti",
	"reportChart.emplEval":"Dipendenti valutati",
	"reportChart.impactoAlto":"Impatto Alto",
	"reportChart.impactoMed":"Impatto Medio",
	"reportChart.impactoBajo":"Impatto Basso",
	"reportChart.impactoSinCamb":"Situazione invariata",
	"reportChart.nombreComunidad":"Comunità",
	"reportChart.numProyectosEfectoMult":"Progetti con effetto moltiplicatore",
	"reportChart.numIniciativasEfectoMult":"Iniziative con effetto moltiplicatore",
	"reportChart.cantidadEfectoMult":"Effetto moltiplicatore",
	"reportChart.cantidadEfectoMult":"Effetto moltiplicatore",
	"reportChart.proyectosConLogros":"Progetti valutati",
	"reportChart.iniciativasConLogros":"Iniziative valutate",
	"reportChart.totalBeneficiarios":"Nº",
	"reportChart.beneficariosTotales":"Totale Beneficiari",
	"reportChart.beneficariosEval":"Beneficiari valutati",
	"reportChart.totalPorGrado":"Nº",
	"reportChart.totalPorTipo":"Nº",
	"reportChart.proyectosEval":"Progetti valutati",
	"reportChart.iniciativasEval":"Iniziative valutate",
	"reportChart.totalOrganizaciones":"Nº Organizzazioni",
	"reportChart.organizBenefTotal":"Nº Totale Organizazzazioni",
	"reportChart.organizBenefEval":"Organizzazioni valutate",
	"reportChart.numLogros":"Nº",
	"reportChart.iniciativasSobreMA":"Impatti sull'ambiente",
	"reportChart.logros":"Risultati",
	"reportChart.impactosGrado":"Impatti - livello",
	"reportChart.impactosTipo":"Impatti - tipologia",
	"reportChart.logrosOrganizaciones":"Risultati delle organizzazioni beneficiarie",
	"reportChart.impactos":"Impatti",
	"reportChart.beneficiarios":"Beneficiari",
	"reportChart.organizacionesBeneficiarios":"Organizzazioni Beneficiarie",
	"reportChart.medioAmbiente":"Ambiente",
	"reportChart.logrosEntidad":"Risultati  {{ITV}}",	
	"salarytables.descripcion":"Descrizione",
	"collaborator.form.descripcion":"Descrizione",
	"contribuyente.colaborador":"Collaboratore",
	"contribuyente.payroll":"Libro paga",
	"contribuyente.otras.aportacion.empleado":"Dipendente",
	"contribuyente.otras.aportacion.cliente":"Cliente",
	"contribuyente.otros":"Altre tipologie",
	"contribuyente.especie":"Natura",
	"contribuyente.clientes":"Clienti",
	"contribuyente.empleado.remunerado":"Dipendente retribuito",
	"contribuyente.empleado.no.remunerado":"Dipendente non retribuito",
	"contribuyente.no.empleado.familiar":"Familiare",
	"contribuyente.no.empleado.voluntario":"Volontario",
	"contribuyente.tabla.salarial":"Tabella dei contribut",
	"contribuyente.probono":"Pro bono",
	"user.list.filter.active":"Attivo",
	"user.list.filter.inactive":"Inattivo",
	"placeholder.impact.select.write.here":"Scrivi qui per attivare filtro",
	"atribution.label.all":"Tutti",
	"atribution.label.e":"E",
	"atribution.label.ef":"EF",
	"atribution.label.o":"O",
	"contributions.num.persons":"Numero persone",
	"contributions.salary.table":"Tabella dei contribut",
	"contributions.atribution.entity":"Entità",
	"contributions.atribution.leverage":"Effetto Moltiplicatore",
	"contributions.atribution.others":"Altri",
	"contributions.atribution.entity.abbreviation":"E",
	"contributions.atribution.others.abbreviation":"O",
	"contributions.atribution.leverage.abbreviation":"EF",
	"contributions.atribution.all":"Tutti",
	"contributions.atribution.all.abbreviation":"Tutti",
	"manual-altaUsuarios":"https://manual.masimpact.com/it/3/menu_di_navigazione/2/gestione/2/registrazione_utenti",
	"manual-altaColaboradores":"https://manual.masimpact.com/it/3/menu_di_navigazione/2/gestione/3/nuovo_collaboratore",
	"manual-divisionYAgrupacion":"https://manual.masimpact.com/it/3/menu_di_navigazione/3/progetti/2/divisione_amministrativa_e_gruppo_interno",
	"manual-presupuesto":"https://manual.masimpact.com/it/3/menu_di_navigazione/3/progetti/3/budget_del_progetto",
	"manual-asignacionUtentes":"https://manual.masimpact.com/it/3/menu_di_navigazione/3/progetti/4/assegnazione_di_utenti_a_progetti",
	"manual-nuevaIniciativa":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/1/nuova_iniziativa",
	"manual-areaActuacion":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/2/area_di_intervento",
	"manual-motivacion":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/3/motivazione_dell%E2%80%99ente",
	"manual-entidadesQueContribuyen":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/4/collaborators_in_the_initiative",
	"manual-principiosLBG":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/5/principi_lbgonlbg",
	"manual-objetivosDesarrollo":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/6/obiettivi_di_sviluppo_sostenibile",
	"manual-presupuestosEntidades":"https://manual.masimpact.com/it/3/menu_di_navigazione/4/iniziative/7/budget_ente",
	"manual-contribuciones":"https://manual.masimpact.com/it/3/menu_di_navigazione/5/contributi",
	"manual-beneficiarios":"https://manual.masimpact.com/it/3/menu_di_navigazione/6/risultati/2/beneficiari",
	"manual-logrosMedioAmbiente":"https://manual.masimpact.com/it/3/menu_di_navigazione/6/risultati/3/risultati_in_termini_ambientali",
	"manual-organizacionesBeneficiarias":"https://manual.masimpact.com/it/3/menu_di_navigazione/6/risultati/4/organizzazioni_beneficiarie",
	"manual-logrosParaEntidades":"https://manual.masimpact.com/it/3/menu_di_navigazione/6/risultati/5/risultati_per_le_entit%C3%A0_partecipanti",
	"manual-cumplimientoObjetivos":"https://manual.masimpact.com/it/3/menu_di_navigazione/6/risultati/6/compliance_with_objectives",
	"manual-impactosSobreBeneficiarios":"https://manual.masimpact.com/it/3/menu_di_navigazione/7/impatti/2/impatti_sui_beneficiari",
	"manual-impactosSobreMedioAmbiente":"https://manual.masimpact.com/it/3/menu_di_navigazione/7/impatti/3/impatti_ambientali",
	"manual-impactosSobreVoluntarios":"https://manual.masimpact.com/it/3/menu_di_navigazione/7/impatti/4/impatti_su_dipendenti_o_volontari",
	"manual-impactosSobreEntidades":"https://manual.masimpact.com/it/3/menu_di_navigazione/7/impatti/5/impatti_sugli_enti",
	"activity-history.log-tipo.create":"Create",
	"activity-history.log-tipo.read":"Read",
	"activity-history.log-tipo.update":"Update",
	"activity-history.log-tipo.delete":"Delete",
	"activity-history.log-tipo.duplicate":"Duplicate",
	"activity-history.seleccion-tipo":"Type...",
	"activity-history.seleccion-tipo-explicacion":"Filter by type",
	"activity-history.seleccion-autor":"Author...",
	"activity-history.seleccion-acciones":"Actions...",
	"activity-history.seleccion-acciones-detalle":"Detailed action...",
	"activity-history.log-item.entidad":"Entity",
	"activity-history.log-item.usuario":"User",
	"activity-history.log-item.proyecto":"Project",
	"activity-history.log-item.proyecto_adjuntos":"Project attachments",
	"activity-history.log-item.proyecto_usuarios":"Project users",
	"activity-history.log-item.iniciativa":"Initiative",
	"activity-history.log-item.iniciativa_adjuntos":"Initiative attachments",
	"activity-history.log-item.iniciativa_usuarios":"Initiative users",
	"activity-history.log-item.contribucion":"Contribution",
	"activity-history.log-item.contribucion_adjuntos":"Contribution attachments",
	"activity-history.log-item.contribucion_permisos":"Contribution users",
	"activity-history.log-item.logro":"Output",
	"activity-history.log-item.logro_adjuntos":"Output attachments",
	"activity-history.log-item.logro_permisos":"Output users",
	"activity-history.log-item.impacto":"Impact",
	"activity-history.log-item.impacto_adjuntos":"Impact attachments",
	"activity-history.log-item.impacto_permisos":"Impact users",
	"activity-history.log-item.colaborador":"Collaborator",
	"activity-history.log-item.documento":"Attachment",
	"activity-history.log-item.tipocambio":"Exchange rates",
	"activity-history.log-item.tablasalarial":"Tabelle dei contribut",
	"activity-history.dialog.title":"Activity History",
	"descripcion.demasiado.larga":"Descrizione troppo lunga. Il numero massimo di caratteri è 16383",
	"session.autoExpire":"La sessione scadrà automaticamente tra",
	"session.autoExpireSeconds":"secondi.",
	"session.autoExpireContinue":"Continua la sessione",
	"contributions.saveContribucion":"Salva contributo",
	"contributions.saveContribucions":"Salva i contributi",
	"contributions.confirmationMessage":"Sono presenti modifiche non salvate. Vuoi partire?",	
	"contributions.mensajerevisar":"Rivedi e completa i campi non compilati.",
	"rating.showAllLanguages":"Mostra tutte le lingue",
	"user.usuariosDisponibles":"Utenti disponibili:",
	"plan.uso.requerido.usuarios":"Devi indicare il numero di utenti",
	"reportChart.mapReportEmpVolSubtitle1":"Partecipanti (dipendenti) dall'entità",
	"reportChart.mapReportEmpVolSubtitle2":"Partecipanti (nessun dipendente) da parte dell'entità",
	"reportChart.mapReportEmpVolSubtitle3":"Impatti sui dipendenti/volontari dell'entità",
	"reportChart.mapReportEmpVolSubtitle4":"Partecipanti di altri contributori",
	"reportChart.mapReportEmpVolSubtitle5":"Partecipanti esterni di altri contributori",
	"reportChart.mapReportEmpVolSubtitle6":"Impatti sui dipendenti/volontari di altri collaboratori",
	"reportChart.mapReportEfectoMul1":"Effetto moltiplicatore dell'entità",
	"reportChart.mapReportEfectoMul2":"Effetto moltiplicatore di altri collaboratori",
	"reportChart.mapReportrescom1":"Realizzazioni: Beneficiari",
	"reportChart.mapReportrescom2":"Impatti - Grado: Beneficiari",
	"reportChart.mapReportrescom3":"Impatti - Tipo: Beneficiari",
	"reportChart.mapReportrescom4":"Risultati: organizzazioni beneficiarie",
	"reportChart.mapReportrescom5":"Risultati delle Organizzazioni beneficiarie",
	"reportChart.mapReportrescom6":"Impatti: organizzazioni beneficiarie",
	"reportChart.mapReportrescom7":"Risultati: ambientali",
	"reportChart.mapReportrescom8":"Impatti: ambientali",
	"reportChart.mapReportresent1":"Obiettivi: nell'entità",
	"reportChart.mapReportresent2":"Impatti: sull'entità",
	"reportChart.header.logeimp":"Risultati: dipendenti e volontari",
	"reportChart.header.efecto":"Effetto moltiplicatore",
	"reportChart.header.rescom":"Risultati nella comunità: risultati e impatti",
	"reportChart.header.resent":"Risultati dell'entità: risultati e impatti",
	"reportChart.header.sensibilizados":"Sensibilizzazione",
	"reportChart.header.mejorados":"Migliorato",
	"reportChart.header.transformados":"Trasformato",
	"reportChart.mapReportEfectoMul.entidad.Otros":"Others {{ITV}}",
	"reportChart.mapReportEfectoMul.entidad.Total":"Total {{ITV}}",
	"reportChart.empleadosEntidad.tiempoRemunerado":"Dipendenti - durante l'orario di lavoro (pagato)",
	"reportChart.empleadosEntidad.tiempoNoRemunerado":"Dipendenti - durante l'orario di lavoro (non pagato)",
	"reportChart.empleadosEntidad.probono":"Dipendenti - probono",
	"reportChart.empleadosEntidad.costesGestion":"Dipendenti - costi di gestione",
	"reportChart.empleadosEntidad.participacionTotal":"Totale",
	"reportChart.empleadosOtros.tiempoRemunerado":"Dipendenti da altri - durante l'orario di lavoro (pagato)",
	"reportChart.empleadosOtros.tiempoNoRemunerado":"Dipendenti da altri - durante l'orario di lavoro (non pagati)",
	"reportChart.empleadosOtros.probono":"Dipendenti da altri - probono",
	"reportChart.empleadosOtros.costesGestion":"Dipendenti da altri - costi di gestione",
	"reportChart.empleadosOtros.participacionTotal":"Totale da altre organizzazioni",
	"reportChart.noEmpleadosEntidad.familiares":"Altri volontari (non dipendenti) - Famiglia, fornitori, clienti, altri volontari",
	"reportChart.noEmpleadosEntidad.voluntariadoEstructural":"Altri volontari (non dipendenti) - Volontariato strutturale",
	"reportChart.noEmpleadosEntidad.participacionTotal":"Totale (non dipendenti)",
	"reportChart.noEmpleadosOtros.familiares":"Altri volontari (non dipendenti) - Famiglia, fornitori, clienti, altri volontari",
	"reportChart.noEmpleadosOtros.voluntariadoEstructural":"Altri volontari (non dipendenti) - Volontariato strutturale",
	"reportChart.noEmpleadosOtros.participacionTotal":"Totale (non dipendenti)",
	"reportChart.impactoEmpleados.habilidades":"Sviluppato nuove competenze",
	"reportChart.impactoEmpleados.desarrollo":"Hanno aumentato il loro impatto personale",
	"reportChart.impactoEmpleados.comportamiento":"Cambiamento positivo nel comportamento o nell'atteggiamento",
	"reportChart.efectoMultiplicador.entidad.empleadosPayrollGiving":"Dare la busta paga ai dipendenti",
	"reportChart.efectoMultiplicador.entidad.empleadosOtrasDonaciones":"Altri contributi in contanti da parte dei dipendenti",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosCash":"Contributi in contanti da clienti, consumatori e altre fonti",
	"reportChart.efectoMultiplicador.entidad.empleadosEspecie":"Altri contributi in natura da parte dei dipendenti",
	"reportChart.efectoMultiplicador.entidad.clientesConsumidoresOtrosEspecie":"Contributi in natura da clienti, consumatori e altre fonti",
	"reportChart.efectoMultiplicador.entidad.Otros":"Altri (entità)",
	"reportChart.efectoMultiplicador.entidad.Total":"Totale (entità)",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosPayrollGiving":"Dipendenti di altre organizzazioni che danno il loro stipendio",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosOtrasDonaciones":"Altri contributi in contanti dei dipendenti di altre organizzazioni",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosCash":"Contributi in contanti da clienti, consumatori e altre fonti da altre organizzazioni",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosEspecie":"Altri contributi in natura di dipendenti di altre organizzazioni",
	"reportChart.efectoMultiplicador.entidad.empleadosValorTrabajoVoluntariado":"Dipendenti - Valore del lavoro volontario",
	"reportChart.efectoMultiplicador.entidad.noEmpleadosVoluntariadoEstructural":"Non dipendenti - volontariato strutturale",
	"reportChart.efectoMultiplicador.otrosColaboradores.empleadosValorTrabajoVoluntariado":"Impiegati altri collaboratori - apprezzano il lavoro volontario",
	"reportChart.efectoMultiplicador.otrosColaboradores.noEmpleadosVoluntariadoEstructural":"Non dipendenti - volontariato strutturale",
	"reportChart.efectoMultiplicador.otrosColaboradores.clientesConsumidoresOtrosEspecie":"Contributi in natura da clienti, consumatori e altre fonti da altre organizzazioni",
	"reportChart.efectoMultiplicador.otrosColaboradores.Otros":"Altri (Altre organizzazioni)",
	"reportChart.efectoMultiplicador.otrosColaboradores.Total":"Totale (Altre organizzazioni)",
	"reportChart.logros.grado.sinCambios":"Nessun cambiamento",
	"reportChart.logros.grado.sensibilizados":"Sensibilizzato come risultato di un'iniziativa",
	"reportChart.logros.grado.mejorados":"Migliorato come risultato di un'iniziativa",
	"reportChart.logros.grado.transformados":"Trasformato come risultato di un'iniziativa",
	"reportChart.logros.tipo.comportamiento":"Sperimentato un cambiamento positivo nel comportamento o nell'atteggiamento",
	"reportChart.logros.tipo.habilidades":"Ha sviluppato nuove abilità o un aumento della sua efficacia personale",
	"reportChart.logros.tipo.calidadDeVida":"Hanno sperimentato un impatto positivo diretto sulla loro qualità di vita",
	"reportChart.medioambiente.directos":"Nº di iniziative che hanno fornito benefici ambientali",
	"reportChart.medioambiente.indirectos":"Nº di iniziative che hanno cambiato il comportamento ambientale delle persone",
	"agrupacion.interna.requerido.nombre":"È necessario indicare il nome del raggruppamento",
	"err.mailExist":"L'e-mail indicata esiste già",
	"datos.proyecto.requerido.agrupacionInterna":"Debe indicar la agrupación interna",
	"entityform.obligatorioAgrupacionInterna":"Dovete indicare il raggruppamento interno",
	"entityform.obligatorioAgrupacionInternaIniciativa":"Dovete indicare il raggruppamento interno di iniziatives",
	"initiativeassign.contribuciones":"Contributi",
	"rating.agrupacionIniciativaInterna":"Raggruppamento interno di iniziative",
	"rating.nuevaAgrupacionIniciativa":"Nuovo raggruppamento interno di iniziative",
	"filterReport.agrIniciativaInterna":"Raggruppamento interno di iniziative",
	"reportChart.header.agrIniciativaInterna":"Raggruppamento interno di iniziative",
	"reportChart.areaActuacion":"Area di azione",
	"reportChart.Participacion2":"Partecipanti (non dipendenti) da parte dell'ente: realizzazioni",
	"manual-asignacionUsuarios":"https://manual.masimpact.com/it/3/menu_di_navigazione/3/progetti/4/assegnazione_di_utenti_a_progetti",
	"activity-history.log-item.iniciativa_lbg":"Iniziativa_LBG",
	"activity-history.log-action.newproject":"Nuovo progetto creato",
	"activity-history.log-action.loadproject":"Dati del progetto caricati",
	"activity-history.log-action.updateproject":"Progetto aggiornato",
	"activity-history.log-action.deleteproject":"Progetto cancellato",
	"activity-history.log-action.closeproject":"Progetto chiuso",
	"activity-history.log-action.deleteinitiative":"Iniziativa eliminata",
	"activity-history.log-action.updateinitiative":"Iniziativa aggiornata",
	"activity-history.log-action.loadinitiative":"Dati dell'iniziativa caricati",
	"activity-history.log-action.newinitiative":"Nuova iniziativa creata",
	"activity-history.log-action.listallinitiatives":"Lista piena di iniziative",
	"activity-history.log-action.listallprojects":"Elenco dei progetti caricati",
	"activity-history.log-action.attachfiletoentity":"File allegato all'entità",
	"activity-history.log-action.attachfiletocollaborator":"File allegato al collaboratore",
	"activity-history.log-action.attachfiletoproject":"File allegato al progetto",
	"activity-history.log-action.readattachedfile":"Leggi l'allegato",
	"activity-history.log-action.newcontribution":"Creato un nuovo contributo",
	"activity-history.log-action.loadcontribution":"Dati dei contributi caricati",
	"activity-history.log-action.updatecontribution":"Contributo aggiornato",
	"activity-history.log-action.deletecontribution":"Contributo cancellato",
	"activity-history.log-action.listallcontributions":"Elenco dei contributi caricati",
	"activity-history.log-action.listattachedfiles":"Elenco degli allegati caricati",
	"activity-history.log-action.deletefile":"Allegato cancellato",
	"activity-history.log-action.openproject":"Progetto aperto",
	"activity-history.log-action.attachfiletoinitiative":"File allegato all'iniziativa",
	"activity-history.log-action.attachfiletocontribution":"File allegato al contributo",
	"activity-history.log-action.attachfiletooutput":"File allegato alla realizzazione",
	"activity-history.log-action.attachfiletoimpact":"File allegato all'impatto",
	"activity-history.log-action.attachfiletosalarytable":"File allegato alla tabella degli stipendi",
	"activity-history.log-action.attachfiletosalaryexchangerate":"File allegato al tasso di cambio",
	"activity-history.log-action.invalidatecontribution":"Contributo non validato",
	"activity-history.log-action.validatecontribution":"Contributo convalidato",
	"activity-history.log-action.newoutput":"Nuovo risultato creato",
	"activity-history.log-action.loadoutput":"Dati dei risultati caricati",
	"activity-history.log-action.updateoutput":"Risultato aggiornato",
	"activity-history.log-action.deleteoutput":"Risultato cancellato",
	"activity-history.log-action.listalloutputs":"Elenco caricato delle conquiste",
	"activity-history.log-action.validateoutput":"Risultato convalidato",
	"activity-history.log-action.invalidateoutput":"Risultato invalidato",
	"activity-history.log-action.newimpact":"Nuovo impatto creato",
	"activity-history.log-action.loadimpact":"Dati di impatto caricati",
	"activity-history.log-action.updateimpact":"Lista d'impatto aggiornata",
	"activity-history.log-action.deleteimpact":"Impatto rimosso",
	"activity-history.log-action.listallimpacts":"Lista d'impatto caricata",
	"activity-history.log-action.validateimpact":"Impatto convalidato",
	"activity-history.log-action.invalidateimpact":"Impatto invalidato",
	"manual-descargaDatos":"https://manual.masimpact.com/it/3/menu_di_navigazione/8/reports/1/scarica_i_dati_in_excel",
	"manual-informesPantalla":"https://manual.masimpact.com/it/3/menu_di_navigazione/8/reports/2/rapporti_sullo_schermo",
	"pais.nombre.global":"Globale",
	"filterReport.byInitiatives":"Per iniziative",
	"pdf.download":"Scarica PDF",
	"initiativeform.changeproyect":"Sposta l'iniziativa del progetto",
	"contribucions.formajuridica":"Forma giuridica"
  })