(function () {
  'use strict';

  angular
    .module('app')
    .service('ContactService', ContactService);

  /* @ngInject */
  function ContactService($http, config, TokenService, UserService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.sendMail = function (asunto, contenido) {
      var url = api + "/api/contacto";
      var email = {asunto: asunto, contenido: contenido};
      return $http.post(url, email, {headers: headers}).then(function (res) {
        return res.data;
      })
    }

    return service;
  }
})();
