(function() {
  'use strict';

  angular
    .module('app')
    .component('sheetReport', {
      templateUrl: 'components/sheetReport/sheetReport.html',
      controller: sheetReportController,
      controllerAs: '$ctrl'
    });

  /* @ngInject */
  function sheetReportController($q, $window, UserService, SelectService, RatingService, ReportService, $log) {

    var $ctrl = this;
    var lodash = $window._
    $ctrl.firstTimePaisChange = true;
    $ctrl.firstTimeRegionChange = true;
	$ctrl.firstTimeProjectChange = true;
	$ctrl.firstTimeSituacionChange = true;
	$ctrl.firstTimeTipoChange = true;
	$ctrl.firstTimeOniciativaColaboradoresChange = true;
	
    $ctrl.getReport = function() {

      $log.log("Generando reporte: v1.13.24");

      $ctrl.loading = true

      var queryParams = $ctrl.getFormQuery();
      queryParams = cleanQueryParams(queryParams);

      ReportService.getExcelReportWithFilter(queryParams)
      .then(function() {
        $ctrl.loading = false
      })
    }
	
	$ctrl.getReportNew = function() {

      $log.log("Generando reporte: v1.13.24");

      $ctrl.loading = true

      var queryParams = $ctrl.getFormQuery();
      queryParams = cleanQueryParams(queryParams);

      ReportService.getExcelReportWithFilterNew(queryParams)
      .then(function() {
        $ctrl.loading = false
      })
    }

    $ctrl.getFormQuery = function() {
      var transformToIdIfId = function(filterItem) {
        return filterItem && angular.isDefined(filterItem.id) ? filterItem.id : filterItem;
      }

      var transformToIdIfArray = function(filterItem) {
        return lodash.isArray(filterItem) ? filterItem.map(lodash.property("id")) : transformToIdIfId(filterItem);
      }

      return {
        tipoInformes: lodash.map(lodash.filter($ctrl.checks, 'checked'), 'index'),
        //divisaInforme: transformToIdIfId($ctrl.filter.divisaInforme),
		divisaInforme: transformToIdIfId(UserService.getUserData().divisa),
        proyecto: lodash.mapValues($ctrl.filter.proyecto, transformToIdIfArray),
        extraerInformacionProyectos: transformToIdIfId($ctrl.filter.extraerInformacionProyectos),
        iniciativa: lodash.mapValues($ctrl.filter.iniciativa, transformToIdIfArray),
        extraerInformacionIniciativas: transformToIdIfId($ctrl.filter.extraerInformacionIniciativas),
        extraerInformacionODS: transformToIdIfId($ctrl.filter.extraerInformacionODS),
        contribucion: lodash.mapValues($ctrl.filter.contribucion, transformToIdIfId),
        logro: lodash.mapValues($ctrl.filter.logro, transformToIdIfId),
		logroSituacion: $ctrl.filter.logro.situacion.map(function(obj){return obj.id}),
		logroTipo: $ctrl.filter.logro.tipoLogro.map(function(obj){return obj.id}),
        impacto: lodash.mapValues($ctrl.filter.impacto, transformToIdIfId)
      }
    }


    $ctrl.$onInit = function() {
      /*SelectService.getBadges()
      .then(function(divisas) {
        $ctrl.divisas = divisas
        $ctrl.filter.divisaInforme = divisas[0]
      })*/
      RatingService.getAllAgrInterna()
      .then(function(agrsInternas) {
        $ctrl.agrsInternas = $ctrl.addOptionAll(agrsInternas, 'filterReport.todas')
        $ctrl.filter.proyecto.agrupacionInterna = [$ctrl.agrsInternas[0]]
      })
	  RatingService.getAllAgrIniciativaInterna()
      .then(function(agrsInternasIniciativa) {
        $ctrl.agrsInternasIniciativa = $ctrl.addOptionAll(agrsInternasIniciativa, 'filterReport.todas')
        $ctrl.filter.proyecto.agrupacionInternaIniciativa = [$ctrl.agrsInternasIniciativa[0]]
      })
	  RatingService.getAllIniciativaColaboradores()
      .then(function(iniciativaColaboradores) {
		iniciativaColaboradores.sort(function(a,b){
			return a.nombre - b. nombre;
	    });
        $ctrl.iniciativaColaboradores = $ctrl.addOptionAll(iniciativaColaboradores, 'filterReport.todos')
        $ctrl.filter.proyecto.iniciativaColaboradores = [$ctrl.iniciativaColaboradores[0]]
      })
      RatingService.getAllUdNegocio()
      .then(function(udsNegocio) {
        $ctrl.udsNegocio = $ctrl.addOptionAll(udsNegocio, 'filterReport.todas')
        $ctrl.filter.proyecto.unidadNegocio = [$ctrl.udsNegocio[0]]
      })
	  RatingService.getAllProjectOptions(null,null,null,null,null,null,null)
      .then(function(projectOption) {
        $ctrl.byProjects = $ctrl.addOptionAll(projectOption, 'filterReport.todos')
        $ctrl.filter.proyecto.byProject = [$ctrl.byProjects[0]]
      })
	  RatingService.getAllInitiativesOptions(null,null,null,null,null,null,null,null,null,null,null,null)
		  .then(function(initiativesOption) {
			$ctrl.byInitiatives = $ctrl.addOptionAll(initiativesOption, 'filterReport.todos')
			$ctrl.filter.proyecto.byInitiatives = [$ctrl.byInitiatives[0]]
		  })
      SelectService.getCountry()
      .then(function(countries) {
        $ctrl.countries = $ctrl.addOptionAll(countries, 'filterReport.todos')
        $ctrl.filter.iniciativa.pais = [$ctrl.countries[0]]
        $ctrl.filter.iniciativa.region = $ctrl.addOptionAll([], 'filterReport.todas')
      })
      SelectService.getMotivacion(null, "ENTIDAD")
      .then(function(motivations) {
        $ctrl.motivations = $ctrl.addOptionAll(motivations, 'filterReport.todas')
        $ctrl.filter.iniciativa.motivacion = [$ctrl.motivations[0]]
      })
      SelectService.getArea()
      .then(function(areas) {
        $ctrl.areas = $ctrl.addOptionAll(areas, 'filterReport.todas')
        $ctrl.filter.iniciativa.areaPrimaria = [$ctrl.areas[0]]
      })
      SelectService.getOds()
      .then(function(odss)  {
        $ctrl.odss = $ctrl.addOptionAll(odss, 'filterReport.todos')
        $ctrl.filter.iniciativa.odsPrimario = $ctrl.odss.slice(0, 1)
      })
	  SelectService.getSituacion().then(function (res) {
		$ctrl.situaciones = $ctrl.addOptionAll(res, 'filterReport.todos');
        $ctrl.filter.logro.situacionSelect = $ctrl.situaciones;
		$ctrl.filter.logro.situacion =  [$ctrl.filter.logro.situacionSelect[0]];
      })

      SelectService.getTipoLogro().then(function (res) {
		$ctrl.tiposLogros = $ctrl.addOptionAll(res, 'filterReport.todos');
        $ctrl.filter.logro.tipoLogroSelect = $ctrl.tiposLogros;
		$ctrl.filter.logro.tipoLogro =  [$ctrl.filter.logro.tipoLogroSelect[0]];
      })
      var me = UserService.getUserData()
      $ctrl.atributionSelect[1].nombre = me.entidad.nombre
      $ctrl.regions = $ctrl.addOptionAll([], 'filterReport.todas')
    }
    $ctrl.$onChanges = function(changesObj) { };
    $ctrl.$onDestroy = function() { };


	$ctrl.onChangeSelect = function(filtro, valores){
		  var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todas'});
		  if (filtro.length > 1) {
			var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todas'})
		  }
		  else if (filtro.length == 0) {
			var filter2 = [valores[0]]
		  }
		  else {
			var filter2 = filtro
		  }
		  return filter2
	}
		
	$ctrl.oniniciativaColaboradores = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeOniciativaColaboradoresChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeOniciativaColaboradoresChange = false
      }
      else if ((!$ctrl.firstTimeOniciativaColaboradoresChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.iniciativaColaboradores[0]]
        $ctrl.firstTimeOniciativaColaboradoresChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeOniciativaColaboradoresChange = false
      }
      $ctrl.filter.proyecto.iniciativaColaboradores = filter2
    }
	
    $ctrl.checks = [
      {checked: false, label: 'filterReport.tipo.contribución'},
      {checked: false, label: 'filterReport.unidad.negocio'},
      {checked: false, label: 'filterReport.agrIniciativaInterna'},
      {checked: false, label: 'filterReport.agrInterna'},
      {checked: false, label: 'filterReport.motivacion'},
      {checked: false, label: 'filterReport.area'},
      {checked: false, label: 'filterReport.ambito.geografico'},
      {checked: false, label: 'filterReport.pais.españa'},
      {checked: false, label: 'filterReport.ODS'},
      {checked: false, label: 'filterReport.empleados.y.voluntarios'},
      {checked: false, label: 'filterReport.efecto.multiplicador'},
      {checked: false, label: 'filterReport.resultados.comunidad'},
      {checked: false, label: 'filterReport.resultados.entidad'},
    ].map(function(check, index) {
      check.index = index
      return check
    })
    $ctrl.reportData = null;
    $ctrl.projectStates = [
      {id: null,      nombre: "filterReport.todos"},
      {id: "ABIERTO", nombre: "filterReport.abiertos"},
      {id: "CERRADO", nombre: "filterReport.cerrados"}
    ]
    $ctrl.yesNoSelect = [
      {id: true, nombre: "filterReport.si"},
      {id: false, nombre: "filterReport.no"}
    ]
    $ctrl.atributionSelect = [
      {id: null, nombre: "filterReport.todos"},
      {id: 0, nombre: "filterReport.entidad"},
      {id: 1, nombre: "filterReport.efecto.multiplicador"},
      {id: 2, nombre: "filterReport.otros"}
    ]
    $ctrl.validationSelect = [
      {id: null,  nombre: 'filterReport.todos'},
      {id: true,  nombre: "filterReport.validados"},
      {id: false, nombre: "filterReport.no.validados"}
    ]
    $ctrl.lbgSelect = [
      {id: null,  nombre: "filterReport.todas"},
      {id: true,  nombre: "filterReport.si.cumplen"},
      {id: false, nombre: "filterReport.no.cumplen"}
    ]
    $ctrl.filter = {
      proyecto: {
        estado: $ctrl.projectStates[0]
      },
      extraerInformacionProyectos: $ctrl.yesNoSelect[0],
      iniciativa: {
        cumpleLGB: $ctrl.lbgSelect[0]
      },
      extraerInformacionIniciativas: $ctrl.yesNoSelect[0],
      extraerInformacionODS: $ctrl.yesNoSelect[0],
      contribucion: {
        validado: $ctrl.validationSelect[0],
        atribucion: $ctrl.atributionSelect[0]
      },
      logro: {
        validado: $ctrl.validationSelect[0]
      },
      impacto: {
        validado: $ctrl.validationSelect[0]
      }
    }
    ////////////////

    $ctrl.getReportCharts = function() {

      $ctrl.loading = true
      var promises = $ctrl.checks.filter(function(check) {
        return check.checked && check.url
      }).map(function(check) {
        return ReportService.getReport(check.url, $ctrl.getFormQuery())
        .then(function(report) {
          report.index = check.index
          return report
        })
      })
      $q.all(promises).then(function(reports) {
        $ctrl.reportData = reports.map(function(report) {
          var mapper = ReportService.getMappers()[report.label] || angular.identity
          return mapper(report)
        })
      }).finally(function() {
        $ctrl.loading = false;
      });
    }

    $ctrl.checkAll = function() {
      $ctrl.checks = $ctrl.checks.map(function(check) {
        check.checked = true
        return check
      })
    }
    $ctrl.uncheckAll = function() {
      $ctrl.checks = $ctrl.checks.map(function(check) {
        check.checked = false
        return check
      })
    }
	$ctrl.onSituacionSelected = function(filtro) {
	  var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeSituacionChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeSituacionChange = false
      }
      else if ((!$ctrl.firstTimeSituacionChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.filter.logro.situacionSelect[0]]
        $ctrl.firstTimeSituacionChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeSituacionChange = false
      }
      $ctrl.filter.logro.situacion = filter2
    }
	$ctrl.onTipoSelected = function(filtro) {
	  var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeTipoChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeTipoChange = false
      }
      else if ((!$ctrl.firstTimeTipoChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.filter.logro.tipoLogroSelect[0]]
        $ctrl.firstTimeTipoChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeTipoChange = false
      }
      $ctrl.filter.logro.tipoLogro = filter2
    }
    $ctrl.onCountriesSelected = function(filter) {
      var hasAll = filter.find(function(o) {o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimePaisChange && filter.length > 1) {
        var filter2 = filter.filter(function(o) {o.nombre!='filterReport.todos'})
        $ctrl.firstTimePaisChange = false
      }
      else if ((!$ctrl.firstTimePaisChange && hasAll) || filter.length == 0) {
        var filter2 = [$ctrl.countries[0]]
        $ctrl.firstTimePaisChange = true
      }
      else {
        filter2 = filter
        $ctrl.firstTimePaisChange = false
      }
      $ctrl.filter.iniciativa.pais = filter2
      var promises = filter
        .filter(function(country) {
          return country.id && country.nombre
        })
        .map(SelectService.getStates)
      $q.all(promises)
        .then(lodash.flatten)
        .then(function(regionsFlat) {
          $ctrl.regions = $ctrl.addOptionAll(regionsFlat, 'filterReport.todas')
        })
    }
	$ctrl.updateProjects = function()
	{
		RatingService.getAllProjectOptions($ctrl.filter.proyecto.estado,$ctrl.filter.proyecto.fechaInicioDesde,$ctrl.filter.proyecto.fechaInicioHasta,$ctrl.filter.proyecto.fechaFinDesde,$ctrl.filter.proyecto.fechaFinHasta,$ctrl.filter.proyecto.unidadNegocio,$ctrl.filter.proyecto.agrupacionInterna)
		  .then(function(projectOption) {
			$ctrl.byProjects = $ctrl.addOptionAll(projectOption, 'filterReport.todos')
			$ctrl.filter.proyecto.byProject = [$ctrl.byProjects[0]]
		  })
		$ctrl.updateInitiatives();
	}
	$ctrl.updateInitiatives = function()
	{
		RatingService.getAllInitiativesOptions($ctrl.filter.proyecto.byProject,$ctrl.filter.iniciativa.cumpleLGB,$ctrl.filter.iniciativa.pais,$ctrl.filter.iniciativa.region,$ctrl.filter.iniciativa.areaPrimaria,$ctrl.filter.iniciativa.motivacion,$ctrl.filter.iniciativa.odsPrimario,$ctrl.filter.proyecto.agrupacionInternaIniciativa,$ctrl.filter.iniciativa.fechaInicioDesde,$ctrl.filter.iniciativa.fechaInicioHasta,$ctrl.filter.iniciativa.fechaFinDesde,$ctrl.filter.iniciativa.fechaFinHasta, $ctrl.filter.proyecto.iniciativaColaboradores)
		  .then(function(initiativesOption) {
			$ctrl.byInitiatives = $ctrl.addOptionAll(initiativesOption, 'filterReport.todos')
			$ctrl.filter.proyecto.byInitiatives = [$ctrl.byInitiatives[0]]
		  })	
	}
	$ctrl.onProjectSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeProjectChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeProjectChange = false
      }
      else if ((!$ctrl.firstTimeProjectChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.byProjects[0]]
        $ctrl.firstTimeProjectChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeProjectChange = false
      }
      $ctrl.filter.proyecto.byProject = filter2
	  $ctrl.updateInitiatives();
    }
	$ctrl.onInitiativeSelected = function(filtro) {
      var hasAll = filtro.find(function(o) {return o.nombre === 'filterReport.todos'});
      if ($ctrl.firstTimeInitiativeChange && filtro.length > 1) {
        var filter2 = filtro.filter(function(o) {return o.nombre !== 'filterReport.todos'})
        $ctrl.firstTimeInitiativeChange = false
      }
      else if ((!$ctrl.firstTimeInitiativeChange && hasAll) || filtro.length == 0) {
        var filter2 = [$ctrl.byInitiatives[0]]
        $ctrl.firstTimeInitiativeChange = true
      }
      else {
        filter2 = filtro
        $ctrl.firstTimeInitiativeChange = false
      }
      $ctrl.filter.proyecto.byInitiatives = filter2
    }
    $ctrl.onRegionsSelected = function(filter) {
      var hasAll = filter.find(function(o) {o.nombre === 'filterReport.todas'});
      if ($ctrl.firstTimeRegionChange && filter.length > 1) {
        var filter2 = filter.filter(function(o) {o.nombre!='filterReport.todas'})
        $ctrl.firstTimeRegionChange = false
      }
      else if ((!$ctrl.firstTimeRegionChange && hasAll) || filter.length == 0) {
        var filter2 = [$ctrl.countries[0]]
        $ctrl.firstTimeRegionChange = true
      }
      else {
        filter2 = filter
        $ctrl.firstTimeRegionChange = false
      }
      $ctrl.filter.iniciativa.region = filter2
    }
    $ctrl.addOptionAll = function(selectors, label) {
      return [{id: null, nombre: label}].concat(selectors)
    }


    $ctrl.getFormQuery = function() {
      var transformToIdIfId = function(filterItem) {
        return filterItem && angular.isDefined(filterItem.id) ? filterItem.id : filterItem;
      }

      var transformToIdIfArray = function(filterItem) {
        return lodash.isArray(filterItem) ? filterItem.map(lodash.property("id")) : transformToIdIfId(filterItem);
      }

      return {
        tipoInformes: lodash.map(lodash.filter($ctrl.checks, 'checked'), 'index'),
        //divisaInforme: transformToIdIfId($ctrl.filter.divisaInforme),
		divisaInforme: transformToIdIfId(UserService.getUserData().divisa),
        proyecto: lodash.mapValues($ctrl.filter.proyecto, transformToIdIfArray),
        extraerInformacionProyectos: transformToIdIfId($ctrl.filter.extraerInformacionProyectos),
        iniciativa: lodash.mapValues($ctrl.filter.iniciativa, transformToIdIfArray),
        extraerInformacionIniciativas: transformToIdIfId($ctrl.filter.extraerInformacionIniciativas),
        extraerInformacionODS: transformToIdIfId($ctrl.filter.extraerInformacionODS),
        contribucion: lodash.mapValues($ctrl.filter.contribucion, transformToIdIfId),
        logro: lodash.mapValues($ctrl.filter.logro, transformToIdIfId),
		logroSituacion: $ctrl.filter.logro.situacion.map(function(obj){return obj.id}),
		logroTipo: $ctrl.filter.logro.tipoLogro.map(function(obj){return obj.id}),
        impacto: lodash.mapValues($ctrl.filter.impacto, transformToIdIfId)
      }
    }
  }

  /**
   * Esta función se encarga de comprobar si hay algún parámetro nulo y si es así lo ignora para que no se envíe
   * en la petición
   *
   * @param queryParams Objeto con todos los filtros seleccionaos por el usuario
   * @returns {Object} Devuelve el objeto ya filtrado (no hay valores nulo en tal objeto)
   */
  function cleanQueryParams(queryParams){

    var queryParamsCleaned = new Object();

    if(queryParams.contribucion != null && queryParams.contribucion.atribucion != null){//OK
      if(queryParamsCleaned.contribucion == null){
        queryParamsCleaned.contribucion = new Object();
      }

      if(queryParams.contribucion.atribucion["id"] != null){
        queryParamsCleaned.contribucion.atribucion = queryParams.contribucion.atribucion["id"];
      }else{
        queryParamsCleaned.contribucion.atribucion = queryParams.contribucion.atribucion;
      }

    }

    if(queryParams.contribucion != null && queryParams.contribucion.tipoContribucion != null){//OK
      if(queryParamsCleaned.contribucion == null){
        queryParamsCleaned.contribucion = new Object();
      }
      queryParamsCleaned.contribucion.tipoContribucion = queryParams.contribucion.tipoContribucion;
    }

    //Contribución validado
    if(queryParams.contribucion != null && queryParams.contribucion.validado != null){//OK
      if(queryParamsCleaned.contribucion == null){
        queryParamsCleaned.contribucion = new Object();
      }

      if(queryParams.contribucion.validado["id"] != null){
        queryParamsCleaned.contribucion.validado = queryParams.contribucion.validado["id"];
      }else{
        queryParamsCleaned.contribucion.validado = queryParams.contribucion.validado;
      }
    }

    //Contribucion fecha desde
    if(queryParams.contribucion != null && queryParams.contribucion.fechaDesde != null){
      if(queryParamsCleaned.contribucion == null){
        queryParamsCleaned.contribucion = new Object();
      }
      queryParamsCleaned.contribucion.fechaDesde = moment(queryParams.contribucion.fechaDesde, "YYYY-MM-DD").toDate();
    }

    //Contribucion fecha hasta
    if(queryParams.contribucion != null && queryParams.contribucion.fechaHasta != null){
      if(queryParamsCleaned.contribucion == null){
        queryParamsCleaned.contribucion = new Object();
      }
      queryParamsCleaned.contribucion.fechaHasta = moment(queryParams.contribucion.fechaHasta, "YYYY-MM-DD").toDate();
    }



    if(queryParams.divisaInforme != null){//OK
      queryParamsCleaned.divisaInforme = queryParams.divisaInforme;
    }

    if(queryParams.extraerInformacionIniciativas != null){//OK
      queryParamsCleaned.extraerInformacionIniciativas = queryParams.extraerInformacionIniciativas;
    }

    if(queryParams.extraerInformacionODS != null){//OK
      queryParamsCleaned.extraerInformacionODS = queryParams.extraerInformacionODS;
    }

    if(queryParams.extraerInformacionProyectos != null){//OK
      queryParamsCleaned.extraerInformacionProyectos = queryParams.extraerInformacionProyectos;
    }

    //Impacto fecha desde
    if(queryParams.impacto != null && queryParams.impacto.fechaDesde != null){
      if(queryParamsCleaned.impacto == null){
        queryParamsCleaned.impacto = new Object();
      }
      queryParamsCleaned.impacto.fechaDesde = moment(queryParams.impacto.fechaDesde, "YYYY-MM-DD").toDate();
    }

    //Logro fecha hasta
    if(queryParams.impacto != null && queryParams.impacto.fechaHasta != null){
      if(queryParamsCleaned.impacto == null){
        queryParamsCleaned.impacto = new Object();
      }
      queryParamsCleaned.impacto.fechaHasta = moment(queryParams.impacto.fechaHasta, "YYYY-MM-DD").toDate();
    }

    //Impacto validación
    if(queryParams.impacto != null && queryParams.impacto.validado != null){//OK

      if(queryParamsCleaned.impacto == null){
        queryParamsCleaned.impacto = new Object();
      }

      if(queryParamsCleaned.impacto.validado == null){
        queryParamsCleaned.impacto.validado = new Object();
      }

      if(queryParams.impacto.validado["id"] != null){
        queryParamsCleaned.impacto.validado = queryParams.impacto.validado["id"];
      }else{
        queryParamsCleaned.impacto.validado = queryParams.impacto.validado;
      }

    }

    if(queryParams.iniciativa.areaPrimaria != null && queryParams.iniciativa.areaPrimaria[0] != null){//OK
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.areaPrimaria = queryParams.iniciativa.areaPrimaria;
    }

    //INICIATIVA LGB
    if(queryParams.iniciativa != null && queryParams.iniciativa.cumpleLGB != null){

      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }

      if(queryParams.iniciativa.cumpleLGB["id"] != null){
        queryParamsCleaned.iniciativa.cumpleLGB = queryParams.iniciativa.cumpleLGB["id"];
      }else{
        queryParamsCleaned.iniciativa.cumpleLGB = queryParams.iniciativa.cumpleLGB;
      }

    }

    if(queryParams.iniciativa != null && queryParams.iniciativa.motivacion != null && queryParams.iniciativa.motivacion[0] != null){//OK
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.motivacion = queryParams.iniciativa.motivacion;
    }

    if(queryParams.iniciativa != null && queryParams.iniciativa.odsPrimario != null && queryParams.iniciativa.odsPrimario[0] != null){//OK
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.odsPrimario = queryParams.iniciativa.odsPrimario;
    }

    if(queryParams.iniciativa != null && queryParams.iniciativa.pais != null && queryParams.iniciativa.pais[0] != null){//OK
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.pais = queryParams.iniciativa.pais;
    }

    if(queryParams.iniciativa != null && queryParams.iniciativa.region != null && queryParams.iniciativa.region[0] != null){//OK
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.region = queryParams.iniciativa.region;
    }

    //Iniciativa fecha inicio desde
    if(queryParams.iniciativa != null && queryParams.iniciativa.fechaInicioDesde != null){
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.fechaInicioDesde = moment(queryParams.iniciativa.fechaInicioDesde, "YYYY-MM-DD").toDate();
    }

    //Iniciativa fecha inicio hasta
    if(queryParams.iniciativa != null && queryParams.iniciativa.fechaInicioHasta != null){
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.fechaInicioHasta = moment(queryParams.iniciativa.fechaInicioHasta, "YYYY-MM-DD").toDate();
    }

    //Iniciativa fecha fin desde
    if(queryParams.iniciativa != null && queryParams.iniciativa.fechaFinDesde != null){
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.fechaFinDesde = moment(queryParams.iniciativa.fechaFinDesde, "YYYY-MM-DD").toDate();
    }

    //Iniciativa fecha inicio hasta
    if(queryParams.iniciativa != null && queryParams.iniciativa.fechaFinHasta != null){
      if(queryParamsCleaned.iniciativa == null){
        queryParamsCleaned.iniciativa = new Object();
      }
      queryParamsCleaned.iniciativa.fechaFinHasta = moment(queryParams.iniciativa.fechaFinHasta, "YYYY-MM-DD").toDate();
    }

    if(queryParams.logro != null && queryParams.logro.validado != null){//OK
      if(queryParamsCleaned.logro == null){
        queryParamsCleaned.logro = new Object();
      }

      if(queryParamsCleaned.logro.validado == null){
        queryParamsCleaned.logro.validado = new Object();
      }

      if(queryParams.logro.validado["id"] != null){
        queryParamsCleaned.logro.validado = queryParams.logro.validado["id"];
      }else{
        queryParamsCleaned.logro.validado = queryParams.logro.validado;
      }
    }

    //Logro fecha desde
    if(queryParams.logro != null && queryParams.logro.fechaDesde != null){
      if(queryParamsCleaned.logro == null){
        queryParamsCleaned.logro = new Object();
      }
      queryParamsCleaned.logro.fechaDesde = moment(queryParams.logro.fechaDesde, "YYYY-MM-DD").toDate();
    }

    //Logro fecha hasta
    if(queryParams.logro != null && queryParams.logro.fechaHasta != null){
      if(queryParamsCleaned.logro == null){
        queryParamsCleaned.logro = new Object();
      }
      queryParamsCleaned.logro.fechaHasta = moment(queryParams.logro.fechaHasta, "YYYY-MM-DD").toDate();
    }
	
	if(queryParams.logroSituacion != null){
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }

        queryParamsCleaned.logro.situacion = queryParams.logroSituacion;
      }
	  
	  if(queryParams.logroTipo != null){
        if(queryParamsCleaned.logro == null){
          queryParamsCleaned.logro = new Object();
        }

        queryParamsCleaned.logro.tipo = queryParams.logroTipo;
      }

    if(queryParams.proyecto != null && queryParams.proyecto.agrupacionInterna != null && queryParams.proyecto.agrupacionInterna[0] != null){//OK
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.agrupacionInterna = queryParams.proyecto.agrupacionInterna;
    }
	
	if(queryParams.proyecto != null && queryParams.proyecto.agrupacionInternaIniciativa != null && queryParams.proyecto.agrupacionInternaIniciativa[0] != null){//OK
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.agrupacionInternaIniciativa = queryParams.proyecto.agrupacionInternaIniciativa;
    }
	
	if(queryParams.proyecto != null && queryParams.proyecto.iniciativaColaboradores != null && queryParams.proyecto.iniciativaColaboradores[0] != null){//OK
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.iniciativaColaboradores = queryParams.proyecto.iniciativaColaboradores;
    }

    if(queryParams.proyecto != null && queryParams.proyecto.estado != null){//OK
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }

      if(queryParamsCleaned.proyecto.estado == null){
        queryParamsCleaned.proyecto.estado = new Object();
      }

      queryParamsCleaned.proyecto.estado = queryParams.proyecto.estado;
    }

    if(queryParams.proyecto != null && queryParams.proyecto.unidadNegocio != null && queryParams.proyecto.unidadNegocio[0] != null){//OK
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.unidadNegocio = queryParams.proyecto.unidadNegocio;
    }

	if(queryParams.proyecto != null && queryParams.proyecto.byProject != null){
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.byProject = queryParams.proyecto.byProject;
    }
	
	if(queryParams.proyecto != null && queryParams.proyecto.byInitiatives != null){
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.byInitiatives = queryParams.proyecto.byInitiatives;
    }
	
    if(queryParams.proyecto != null && queryParams.proyecto.fechaInicioDesde != null){
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.fechaInicioDesde = moment(queryParams.proyecto.fechaInicioDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    }

    if(queryParams.proyecto != null && queryParams.proyecto.fechaInicioHasta != null){
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.fechaInicioHasta = moment(queryParams.proyecto.fechaInicioHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    }

    if(queryParams.proyecto != null && queryParams.proyecto.fechaFinDesde != null){
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.fechaFinDesde = moment(queryParams.proyecto.fechaFinDesde).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    }

    if(queryParams.proyecto != null && queryParams.proyecto.fechaFinHasta != null){
      if(queryParamsCleaned.proyecto == null){
        queryParamsCleaned.proyecto = new Object();
      }
      queryParamsCleaned.proyecto.fechaFinHasta = moment(queryParams.proyecto.fechaFinHasta).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    }

    if(queryParams.tipoInformes != null){
      queryParamsCleaned.tipoInformes = queryParams.tipoInformes;
    }

    return queryParamsCleaned;

  }

})();
