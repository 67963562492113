(function () {
  'use strict';

  angular
    .module('app')
    .service('TokenService', TokenService);

  /* @ngInject */
  function TokenService() {
    var service = {
      setToken: setToken,
      getToken: getToken,
      getTokenData: getTokenData
    };

    function setToken(newToken) {
      localStorage.setItem("token", newToken)
    }

    function getToken() {
      return localStorage.getItem("token")
    }

    function getTokenData() {
      // var token = getToken();
      var token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      var tokenData = atob(token.split('.')[1]);
      return angular.fromJson(tokenData);
    }

    return service;
  }
})();
