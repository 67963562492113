(function () {
  angular
    .module('app')
    .component('exchangeRate', {
      templateUrl: 'components/exchangeRate/exchangeRate.html',
      controller: ExchangeRateController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ExchangeRateController(ExchangeRateService, config, $mdDialog, SelectService, UserService, BreadcrumService, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = 'nombre';
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;
    vm.disableInfinite = false;


    vm.cumplePeriodo = true;
    // if (UserService.getUserData()
    //   && UserService.getUserData().grupoLBG) {
    //   vm.cumplePeriodo = UserService.getUserData().grupoLBG.cumplePeriodo;
    // }

    ExchangeRateService.comprobarPeriodo().then(function (res) {
      vm.cumplePeriodo = res;
    })

    vm.data = [];
    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.name = "";
    vm.selectedFilters.description = "";
    vm.selectedFilters.lgbGroup = {};
    vm.selectedFilters.startDate = null;
    vm.selectedFilters.endDate = null;

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;
      ExchangeRateService.getExchangeRates(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.dataLoaded = true;
        vm.data = res;
        vm.busy = false;
      });
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.selectedFilters.lgbGroup = selectorObject;
      }

      vm.getData();

    }

    function fillFilters() {
      SelectService.getLGBGroup().then(function (res) {
        vm.selectorList.lgbGroup = res;
      });
    }

    var auxFilters = BreadcrumService.getFilters("exchangeRate");
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
      if (vm.selectedFilters.startDate) {
        vm.selectedFilters.startDate = moment(vm.selectedFilters.startDate).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
      if (vm.selectedFilters.endDate) {
        vm.selectedFilters.endDate = moment(vm.selectedFilters.endDate).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
    }
    vm.getData();
    fillFilters();

    vm.saveFilters = function () {
      BreadcrumService.saveFilters(vm.selectedFilters, "exchangeRate");
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        ExchangeRateService.deleteExchangeRate(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          })
        // .catch(function (res) {
        //   if (res.status == 403) {
        //     showAlert(ev, 'Error de Borrando', 'Tu usuario no tiene permiso para realizar esta acción', 'ok');
        //   }
        // })
        ;
      };

      ModalService.showConfirm(ev, 'exchangerate.borrarEntidad', 'exchangerate.estasSeguro', 'exchangerate.borrar', 'exchangerate.cancelar', whenOk);
    }

    vm.loadMore = function () {
      if (vm.busy) return;
      vm.busy = true;

      ExchangeRateService.getExchangeRates(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        vm.busy = false;
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        }
        else {
          vm.page++;
        }

        vm.disableInfinite = vm.busy | vm.allDataLoaded;
      });

      vm.disableInfinite = vm.busy | vm.allDataLoaded;

    };

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
        vm.abreCalendarioDesde = true;
    }

    vm.abreCalendarioHasta = false;
    vm.abrirCalendarioHasta = function () {
        vm.abreCalendarioHasta = true;
    }
  }
})();
