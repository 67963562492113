(function () {
  angular
    .module('app')
    .component('error', {
      templateUrl: 'components/error/error.html',
      controller: ErrorController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ErrorController($state, $scope, Translator) {
    var vm = this;

    var fechaMinima = new Date(new Date().getFullYear() - 5, 0, 1);
    var fechaMaxima = new Date(new Date().getFullYear() + 10, 11, 31);

    var prettyFechaMinima = moment(fechaMinima, "YYYY-MM-DD").format("DD/MM/YYYY");
    var prettyFechaMaxima = moment(fechaMaxima, "YYYY-MM-DD").format("DD/MM/YYYY");

    Translator.translate(['error.periodoNoCompletado', 'error.hasta'], function(translations) {
      vm.mensaje1 = translations["error.periodoNoCompletado"] + " " +
        prettyFechaMinima + " " + translations["error.hasta"] + " " + prettyFechaMaxima;
      vm.mensaje2 = localStorage.getItem("conversionMessage")
    });

  }
})();
