(function () {
  angular
    .module('app')
    .component('collaborators', {
      templateUrl: 'components/collaborators/collaborators.html',
      controller: CollaboratorsController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function CollaboratorsController($rootScope, $scope, CollaboratorsService, config, $mdDialog, SelectService, $state, BreadcrumService, ModalService) {
    var vm = this;

	vm.now = new Date().getFullYear();
    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;

    vm.busy = true;
    vm.allDataLoaded = false;
    vm.disableInfinite = false;

    vm.data = [];
    vm.selectorList = {};
    vm.selectedFilters = {};
    vm.selectedFilters.code = "";
    vm.selectedFilters.name = "";
    vm.selectedFilters.description = "";
    vm.selectedFilters.country = {};
    vm.selectedFilters.state = "";
    vm.selectedFilters.type = {};

    var destroyLangWatcher = $rootScope.$watch('selectedLang', function() {
      loadCountries();
    });
    $scope.$on('$destroy', function() {
      destroyLangWatcher();
    });

    vm.sort = function (field) {
      //reseteamos los valores
      vm.page = config.defaultInitialPage;
      vm.size = config.defaultPageSize;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;

      if (field == vm.sortField) {
        vm.sortAscOrder = !vm.sortAscOrder; //invertimos el orden
      }
      else {
        vm.sortField = field;
        vm.sortAscOrder = true;
      }
      vm.getData();
    };

    vm.dataLoaded = false;
    vm.getData = function () {
      vm.page = 0;
      vm.busy = true;
      vm.allDataLoaded = false;
      vm.disableInfinite = false;
      CollaboratorsService.getCollaborators(vm.page, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        vm.data = res;
        vm.dataLoaded = true;
        vm.busy = false;
      });
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.country) {
        vm.selectedFilters.country = selectorObject;
      }

      if (selectorType == SelectService.ENUM.type) {
        vm.selectedFilters.type = selectorObject;
      }

      vm.getData();

    }

    function fillFilters() {
      loadCountries();

      SelectService.getType().then(function (res) {
        vm.selectorList.type = res;
      });
    }

    function loadCountries() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });
    }

    var auxFilters = BreadcrumService.getFilters("collaborator");
    if (auxFilters != null) {
      vm.selectedFilters = auxFilters;
      vm.sortAscOrder = auxFilters.sortAscOrder;
      vm.sortField = auxFilters.sortField;
    }
    vm.getData();
    fillFilters();

    vm.saveFilters = function () {
      vm.selectedFilters.sortField = vm.sortField;
      vm.selectedFilters.sortAscOrder = vm.sortAscOrder;
      BreadcrumService.saveFilters(vm.selectedFilters, "collaborator");
    }

    //TODO funcion duplicada!
    vm.getSortIcon = function (field) {
      //TODO animar icono
      if (field == vm.sortField) {
        if (vm.sortAscOrder) {
          //ascendente
          return 'arrow_upward'
        }
        else {
          //descendente
          return 'arrow_downward'
        }
      }
      else {
        //no ordenamos por este campo
        return 'arrow_downward';
      }
    };

    //TODO funcion duplicada!
    vm.getSortIconColor = function (field) {
      if (field == vm.sortField) {
        return "ng-binding material-icons primaryColor iconoOrdenacion";
      }
      else {
        return "ng-binding material-icons iconoOrdenacion";
      }
    }

    vm.clickDelete = function (ev, index) {
      var whenOk = function () {
        CollaboratorsService.deleteCollaborator(vm.data[index])
          .then(function () {
            //TODO que hacemos despues de borrar una entidad? De momento "refrescamos
            vm.getData()
          })
          .catch(function (res) {
            if (res.status == 403) {
              showAlert(ev, 'collaborator.errorBorrando', 'collaborator.noPermission', 'collaborator.ok');
            }
          })
        ;
      };
      ModalService.showConfirm(ev, 'collaborator.borrarColaborador', 'collaborator.estasSeguro', 'collaborator.borrar', 'collaborator.cancelar', whenOk);
    }

    vm.goUpload = function (index) {
      vm.saveFilters();
      var element = vm.data[index];
      var uploadFormData = {id: element.id, tipoEntidad: 3, multi: true, idProyecto: null}
      $state.go("uploadform", uploadFormData);
    }

    //TODO generalizar
    function showAlert(ev, title, textContent, ok) {
      // TODO: this function is not implemented for some reason. Remember to translate the strings when implementing this.

      // $mdDialog.show(
      //   $mdDialog.alert()
      //   // .parent(angular.element(document.querySelector('#popupContainer')))
      //     .clickOutsideToClose(true)
      //     .title(title)
      //     .textContent(textContent)
      //     .ariaLabel('Alert Dialog Demo')
      //     .ok(ok)
      //     .targetEvent(ev)
      // );
    };

    vm.loadMore = function () {
      if (vm.busy || vm.allDataLoaded) return;
      vm.busy = true;

      CollaboratorsService.getCollaborators(vm.page + 1, vm.size, vm.sortField, vm.sortAscOrder, vm.selectedFilters).then(function (res) {
        res.forEach(function (element) {
          vm.data.push(element);
        });
        if (res.length < vm.size) {
          vm.allDataLoaded = true;
        }
        else {
          vm.page++;
        }

        vm.busy = false;
      });
    };

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
      vm.abreCalendarioDesde = true;
    }

    vm.abreCalendarioHasta = false;
    vm.abrirCalendarioHasta = function () {
      vm.abreCalendarioHasta = true;
    }
  }
})();
