(function () {
  angular
    .module('app')
    .component('data', {
      templateUrl: 'components/data/data.html',
      controller: DataController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function DataController(EntityService) {
    var vm = this;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";
    vm.data.abreviado = "";
    vm.data.getPlanUsoConsumido = {};
    vm.data.planUsoContratado = {};
    vm.data.responsable = {};
    vm.data.sector = {};
    vm.data.tipo = {};

    vm.nombreResponsable = "";

    EntityService.getEntityData().then(function (res) {
      vm.data = res;
      if (vm.data.responsable && vm.data.responsable.nombre && vm.data.responsable.apellidos) {
        vm.nombreResponsable = vm.data.responsable.nombre + " " + vm.data.responsable.apellidos;
      }
    });
  }
})();
