(function () {
  angular
    .module('app')
    .component('collaboratorsform', {
      templateUrl: 'components/collaborators/form/collaboratorsform.html',
      controller: CollaboratorsFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function CollaboratorsFormController($rootScope, $scope, SelectService, CollaboratorsService, $state, $stateParams, Translator, $log) {
    var vm = this;

    vm.validData = true;
    vm.errorMessage = "";

    vm.editId = $stateParams.id;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";

    vm.isSelect = false;

    vm.selectorList = {};

    if (vm.editId) {
      CollaboratorsService.getCollaborator(vm.editId).then(function (res) {
        vm.data = res;
        fillSelectors();
        checkIsSelect();
      });
    }

    var destroyLangWatcher = $rootScope.$watch('selectedLang', function() {
      loadCountries();
    });
    $scope.$on('$destroy', function() {
      destroyLangWatcher();
    });

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.country) {
        vm.data.pais = selectorObject;
        checkIsSelect();
        resetSpecialFields();
      }

      if (selectorType == SelectService.ENUM.type) {
        vm.data.tipo = selectorObject;
        vm.data.tipoId = selectorObject.id;
      }

      if (selectorType == SelectService.ENUM.state) {
        vm.data.region = selectorObject.nombre;
      }

    }

    checkIsSelect = function () {
      if (vm.data.pais && vm.data.pais.nombre == 'pais.nombre.españa') {
        vm.isSelect = true;
      }
      else {
        vm.isSelect = false;
      }
    }

    function fillSelectors(reset) {
      if (reset == null) {
        loadCountries();
      }

      if (reset == null) {
        SelectService.getType().then(function (res) {
          vm.selectorList.type = res;
        });
      }

      if ((reset == null || reset) && vm.data.pais) {
        if (vm.data.pais && vm.data.pais.id) {
          SelectService.getStates(vm.data.pais).then(function (res) {
            vm.selectorList.state = res;
          });
        }
      }
    }

    function loadCountries() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });
    }

    function resetSpecialFields() {
      vm.selectorList.state = [];
      vm.data.region = "";

      fillSelectors(true);
    }

    fillSelectors();

    vm.clickSave = function () {
      vm.validData = true;
      if (vm.editId) {
        CollaboratorsService.updateCollaborator(vm.data).then(function (res) {
          $state.go("manage.collaborators");
        })
          .catch(function (e) {
            showError(e);
          });
      }
      else {
        CollaboratorsService.createCollaborator(vm.data).then(function (res) {
          $state.go("manage.collaborators");
        })
          .catch(function (e) {
            showError(e);
          });
      }
    }

    //TODO generalizar
    function showError(e) {
      // TODO: the error message is not actually displayed. Since vm.validData is never set to false

      var translationKey = e && e.data && e.data.message ? e.data.message : 'err.genericError';
      Translator.translate(translationKey, function (translation) {
          vm.errorMessage = translation;
      });
    }

    vm.clickCancel = function () {
      $state.go("manage.collaborators");
    }
  }
})();
