(function () {
  angular
    .module('app')
    .component('achievementsform', {
      templateUrl: 'components/achievements/form/achievementsform.html',
      controller: AchievementsFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function AchievementsFormController(SelectService, AchievementsService, InitiativeService, $state, $stateParams, UserService, RoleService) {
    var vm = this;

    // vm.idproyect = $stateParams.idproyect;
    vm.idInitiative = $stateParams.id;
    vm.editId = $stateParams.editId;
    vm.duplicate = $stateParams.duplicate;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);

    vm.proyectClosed = false;

    InitiativeService.getProyectState($stateParams.idproyect).then(function (res) {
      vm.proyectClosed = !res;
    })

    vm.data = {};
    //inicializamos data

    // vm.data.iniciativaId = vm.idproyect;
    vm.data.datosIniciativaId = vm.idInitiative;
    vm.data.lstSituacionDirectos = [];
    vm.data.lstSituacionIndirectos = [];

    vm.data.lstOrganizacionesBeneficiariasDTO = [];
    vm.data.lstOrganizacionesBeneficiariasDTO.push(
      {
        id: UserService.getUserData().entidad.id,
        nombre: UserService.getUserData().entidad.nombre,
        abreviatura: UserService.getUserData().entidad.abreviatura,
      }
    );

    vm.idEntidadUserData = UserService.getUserData().entidad.id;


    vm.data.lstOrganizacionesBeneficiariasABorrar = [];

    vm.data.lstGruposInteres = [];
    vm.data.lstGruposInteresABorrar = [];

    vm.data.lstAlianzas = [];
    vm.data.lstAlianzasABorrar = [];

    vm.data.lstMedioComunicacion = [];
    vm.data.lstMedioComunicacionABorrar = [];

    vm.data.lstCumplimientoObjetivos = [];
    vm.data.lstCumplimientoObjetivosABorrar = [];

    //extras
    vm.nuevaOrganizacion = {};
    vm.nuevoGrupoInteres = {};
    vm.nuevaAlianza = {};
    vm.nuevoMedioComunicacion = {};
    vm.nuevoCumplimientoObjetivo = {};

	vm.data.lstOrganizacionesBeneficiariasNumber = 0;

    vm.selectorList = {};
    vm.selectorList.lstMotivacion = [];

    vm.currentEntityData = {};

    vm.newCollaborator = {};
    vm.collaborators = [];

    vm.newEntityBudget = {};
    vm.entityBudgets = [];

    // esta funcion es llamada por angular cuando se inicia el componente
    // ayuda a mantener el codigo de inicio contenido en una sola funcion
    vm.$onInit = function () {
      if (vm.editId) {
        if (vm.duplicate == 'false') {
          AchievementsService.getAchievement(vm.editId).then(function (res) {
            vm.data = prettyData(res);
            fillSelectors();
            setPrettyDates();
			vm.initToggleOption();
          });
        }
        else {
          AchievementsService.getAchievement(vm.editId).then(function (res) {
            vm.data = prettyData(res, true);
            fillSelectors();
            setPrettyDates();
			vm.initToggleOption();
          })
        }
      }
      fillSelectors();
    }

    function prettyData(data, isDuplicate) {
      if (isDuplicate) {
        data.id = null;

        data.lstOrganizacionesBeneficiariasDTO.map(deleteId)//TODO esto tambien hay que hacerlo?
        data.lstGruposInteres.map(deleteId)
        data.lstAlianzas.map(deleteId);
        data.lstMedioComunicacion.map(deleteId);
        data.lstCumplimientoObjetivos.map(deleteId);
      }
      data.datosIniciativaId = vm.idInitiative;

      data.eficienciaNombre = data.eficiencia;
      data.eficaciaNombre = data.eficacia;
      data.sostenibilidadNombre = data.sostenibilidad;
      data.alineacionObjetivosNombre = data.alineacionObjetivos;

      //ORGANIZACIONES
	  data.lstOrganizacionesBeneficiariasDTO.unshift(
        {
          id: UserService.getUserData().entidad.id,
          nombre: UserService.getUserData().entidad.nombre,
          abreviatura: UserService.getUserData().entidad.abreviatura,
        }
      );
	  
      data.lstOrganizacionesBeneficiariasDTO.map(function (elem) {
        elem.tipoNombre = elem.tipo;
        elem.beneficiariaNombre = elem.beneficiaria ? 'SI' : 'NO';
      })
	  
      data.lstGruposInteres.map(setNombreListas)
      data.lstAlianzas.map(setNombreListas);
      data.lstMedioComunicacion.map(setNombreListas);
      data.lstCumplimientoObjetivos.map(setNombreListas);
      data.lstOrganizacionesBeneficiariasDTO.map(function(element) {
        if (!element.abreviatura) {
          element.abreviatura = element.nombre;
        }
        return element;
      });

	  if (data.lstOrganizacionesBeneficiariasDTO.length > 1)
		$('#beneficiariasNumber').prop("disabled",true);
	  else
		$('#beneficiariasNumber').prop("disabled",false);
		
	  if (data.lstOrganizacionesBeneficiariasNumber == null || typeof(data.lstOrganizacionesBeneficiariasNumber) == 'undefined')
		  data.lstOrganizacionesBeneficiariasNumber = 0;
	  else if (parseInt(data.lstOrganizacionesBeneficiariasNumber) > 0)
			$('#saveBeneficiariaButton').prop("disabled",true);
	
      return data;
    }

    function deleteId(elem) {
      elem.id = null;
      return elem;
    }

    function setNombreListas(elem) {
      if (elem.colaborador) {
        elem.nombre = elem.colaborador.nombre;
        elem.abreviatura = elem.colaborador.nombre;
      } else if (elem.organizacionesBeneficiarias) {
        elem.nombre = elem.organizacionesBeneficiarias.nombre;
        elem.abreviatura = elem.organizacionesBeneficiarias.nombre;
      } else if (elem.organizacionesBeneficiarias == null && elem.colaborador == null) {
        elem.nombre = UserService.getUserData().entidad.nombre
        elem.abreviatura = UserService.getUserData().entidad.abreviatura
      }

      if (elem.generadoAlianza != null) {
        elem.generadoAlianzaNombre = elem.generadoAlianza ? 'SI' : 'NO';//generadoAlianza
      }

      if (elem.alineacion) {
        elem.alineacionNombre = elem.alineacion;
      }
      return elem;
    }

    function setPrettyDates() {
      if (vm.data.fechaEvaluacion) {
        vm.data.fechaEvaluacion = moment(vm.data.fechaEvaluacion).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
    }

    //ORGANIZACIONES
	vm.enableOrDisableBeneficiariasForm = function()
	{
		if (vm.data.lstOrganizacionesBeneficiariasNumber == null || typeof(vm.data.lstOrganizacionesBeneficiariasNumber) == 'undefined' || vm.data.lstOrganizacionesBeneficiariasNumber == '' || parseInt(vm.data.lstOrganizacionesBeneficiariasNumber) == 0)
		{
			$('#saveBeneficiariaButton').prop("disabled",false);
		}
		else
			$('#saveBeneficiariaButton').prop("disabled",true);
	}
		
    vm.clickNuevaOrganizacion = function (selectedObj, fieldName) {
      if (fieldName == "orgBeneficiaria") {
        if (vm.nuevaOrganizacion.tipo != selectedObj.enum) {
          vm.nuevaOrganizacion.nombre = "";
        }

        vm.nuevaOrganizacion.tipo = selectedObj.enum;

        //TODO changlai para ocultar el otro campo
        if (selectedObj.enum == "ORG_BENEFICIARIA") {
          vm.nuevaOrganizacion.beneficiaria = true;
        }
        else {
          vm.nuevaOrganizacion.beneficiaria = false;
        }
      }
      if (fieldName == "colaborador") {
        vm.nuevaOrganizacion.colaborador = selectedObj.colaborador;
        vm.nuevaOrganizacion.nombre = selectedObj.nombre;
      }
      if (fieldName == "beneficiaria") {
        if (selectedObj.enum == 'SI') {
          vm.nuevaOrganizacion.beneficiaria = true;
        }
        else {
          vm.nuevaOrganizacion.beneficiaria = false;
        }
        vm.nuevaOrganizacion.beneficiariaNombre = selectedObj.nombre;
      }
    }

    vm.addNuevaOrganizacion = function (object) {
      if (vm.nuevaOrganizacion.tipo && (vm.nuevaOrganizacion.nombre || vm.nuevaOrganizacion.colaborador)) {
        vm.nuevaOrganizacion.abreviatura = vm.nuevaOrganizacion.nombre;
        vm.nuevaOrganizacion.idTemporal = new Date().getTime().toString();

        vm.data.lstOrganizacionesBeneficiariasDTO.push(angular.copy(vm.nuevaOrganizacion));
        vm.nuevaOrganizacion = {};
		
		$('#beneficiariasNumber').prop("disabled",true);
      }
    }

    vm.clickTablaOrganizacion = function (selectedObj, fieldName, index) {
      if (fieldName == "beneficiaria") {
        if (selectedObj.enum == 'SI') {
          vm.data.lstOrganizacionesBeneficiariasDTO[index].beneficiaria = true;
        }
        else {
          vm.data.lstOrganizacionesBeneficiariasDTO[index].beneficiaria = false;
        }
        vm.data.lstOrganizacionesBeneficiariasDTO[index].beneficiariaNombre = selectedObj.nombre;
      }
    }

    //ngFocus
    var previousName;
    vm.setNameOrgBenef = function (prevName) {
      previousName = prevName;
    }

    vm.updateOrgBenef = function (orgBenef) {
      vm.data.lstGruposInteres.map(function (elem) {
        if (elem.nombre == previousName) {
          elem.nombre = orgBenef;
        }
        return elem;
      });
      vm.data.lstAlianzas.map(function (elem) {
        if (elem.nombre == previousName) {
          elem.nombre = orgBenef;
        }
        return elem;
      });
      vm.data.lstMedioComunicacion.map(function (elem) {
        if (elem.nombre == previousName) {
          elem.nombre = orgBenef;
        }
        return elem;
      });
      vm.data.lstCumplimientoObjetivos.map(function (elem) {
        if (elem.nombre == previousName) {
          elem.nombre = orgBenef;
        }
        return elem;
      });

      if (vm.nuevoGrupoInteres.nombre == previousName) {
        vm.nuevoGrupoInteres.nombre = orgBenef;
      }
      if (vm.nuevaAlianza.nombre == previousName) {
        vm.nuevaAlianza.nombre = orgBenef;
      }
      if (vm.nuevoMedioComunicacion.nombre == previousName) {
        vm.nuevoMedioComunicacion.nombre = orgBenef;
      }
      if (vm.nuevoCumplimientoObjetivo.nombre == previousName) {
        vm.nuevoCumplimientoObjetivo.nombre = orgBenef;
      }
    }

    vm.deleteOrganizacion = function (index) {
      if (vm.data.lstOrganizacionesBeneficiariasDTO[index].id) {
        vm.data.lstOrganizacionesBeneficiariasABorrar.push(vm.data.lstOrganizacionesBeneficiariasDTO[index].id)
      }

      var deletedObject = angular.copy(vm.data.lstOrganizacionesBeneficiariasDTO[index]);
      deleteOfAllList(deletedObject);

      vm.data.lstOrganizacionesBeneficiariasDTO.splice(index, 1);
	  
	  if (vm.data.lstOrganizacionesBeneficiariasDTO.length == 1)
		  $('#beneficiariasNumber').prop("disabled",false);
    }

    function deleteOfAllList(object) {
      //GRUPOS INTERES
      var gruposInteres = deleteOfList(vm.data.lstGruposInteres, vm.data.lstGruposInteresABorrar, object)
      vm.data.lstGruposInteres = gruposInteres[0]
      vm.data.lstGruposInteresABorrar = gruposInteres[1]

      //ALIANZAS
      var alianzas = deleteOfList(vm.data.lstAlianzas, vm.data.lstAlianzasABorrar, object)
      vm.data.lstAlianzas = alianzas[0]
      vm.data.lstAlianzasABorrar = alianzas[1]

      //MEDIOS COMUNICACION
      var mediosComunicacion = deleteOfList(vm.data.lstMedioComunicacion, vm.data.lstMedioComunicacionABorrar, object)
      vm.data.lstMedioComunicacion = mediosComunicacion[0]
      vm.data.lstMedioComunicacionABorrar = mediosComunicacion[1]

      //CUMPLIMIENTO OBJETIVOS
      var cumplimientoObjetivos = deleteOfList(vm.data.lstCumplimientoObjetivos, vm.data.lstCumplimientoObjetivosABorrar, object)
      vm.data.lstCumplimientoObjetivos = cumplimientoObjetivos[0]
      vm.data.lstCumplimientoObjetivosABorrar = cumplimientoObjetivos[1]
    }

    function deleteOfList(list, listDelete, object) {
      var indexToDelete = null;
      list.forEach(function (element, index) {
        if (indexToDelete == null &&
          object.colaborador && element.colaborador && element.colaborador.id && element.colaborador.id == object.colaborador.id) {
          indexToDelete = index;
          if (element.id) {
            listDelete.push(element.id)
          }
        }
        else if (indexToDelete == null && object.id && element.organizacionesBeneficiarias &&
          element.organizacionesBeneficiarias.id && element.organizacionesBeneficiarias.id == object.id) {
          indexToDelete = index;
          listDelete.push(element.id)
        }
        else if (indexToDelete == null && object.idTemporal && element.organizacionesBeneficiarias &&
          element.organizacionesBeneficiarias.idTemporal && element.organizacionesBeneficiarias.idTemporal == object.idTemporal) {
          indexToDelete = index;
        }


      })
      if (indexToDelete != null) {
        list.splice(indexToDelete, 1);
      }
      return [list, listDelete];
    }

    //GRUPO INTERES
    vm.clickGrupoInteres = function (selectedObj, fieldName) {
      if (selectedObj) {
        if (fieldName == "colaborador") {
          if (selectedObj.colaborador) {
            vm.nuevoGrupoInteres.colaborador = selectedObj.colaborador
            vm.nuevoGrupoInteres.nombre = selectedObj.colaborador.nombre
          } else {
            vm.nuevoGrupoInteres.organizacionesBeneficiarias = selectedObj
            vm.nuevoGrupoInteres.nombre = selectedObj.nombre
          }
          vm.nuevoGrupoInteres.abreviatura = selectedObj.abreviatura;
        }
      }

      if (fieldName == "grupoInteres") {
        vm.nuevoGrupoInteres.grupoInteres = selectedObj
      }
    }

    vm.addGrupoInteres = function (object) {
      vm.data.lstGruposInteres.push(angular.copy(vm.nuevoGrupoInteres));

      vm.nuevoGrupoInteres = {};
    }


    vm.clickTablaGrupoInteres = function (selectedObj, fieldName, index) {
      if (fieldName == "grupoInteres") {
        vm.data.lstGruposInteres[index].grupoInteres = selectedObj
      }
    }

    vm.deleteGrupoInteres = function (index) {
      if (vm.data.lstGruposInteres[index].id) {
        vm.data.lstGruposInteresABorrar.push(vm.data.lstGruposInteres[index].id)
      }
      vm.data.lstGruposInteres.splice(index, 1);
    }


    //ALIANZAS
    vm.clickAlianzas = function (selectedObj, fieldName) {
      if (selectedObj) {
        if (fieldName == "colaborador") {
          if (selectedObj.colaborador) {
            vm.nuevaAlianza.colaborador = selectedObj.colaborador
            vm.nuevaAlianza.nombre = selectedObj.colaborador.nombre
          } else {
            vm.nuevaAlianza.organizacionesBeneficiarias = selectedObj
            vm.nuevaAlianza.nombre = selectedObj.nombre
          }
          vm.nuevaAlianza.abreviatura = selectedObj.abreviatura;
        }
        if (fieldName == "generadoAlianza") {
          if (selectedObj.enum == 'SI') {
            vm.nuevaAlianza.generadoAlianza = true;

          }
          else {
            vm.nuevaAlianza.generadoAlianza = false;
          }
          vm.nuevaAlianza.generadoAlianzaNombre = selectedObj.nombre;
        }
      }
    }

    vm.addAlianza = function (object) {
      //TODO alguna validacion de campos??? que es obligatorio???
      vm.data.lstAlianzas.push(angular.copy(vm.nuevaAlianza));
      vm.nuevaAlianza = {};
    }


    vm.clickTablaAlianzas = function (selectedObj, fieldName, index) {
      if (fieldName == "generadoAlianza") {
        if (selectedObj.enum == 'SI') {
          vm.data.lstAlianzas[index].generadoAlianza = true;
        }
        else {
          vm.data.lstAlianzas[index].generadoAlianza = false;
        }
        vm.data.lstAlianzas[index].generadoAlianzaNombre = selectedObj.nombre;
      }
    }

    vm.deleteAlianza = function (index) {
      if (vm.data.lstAlianzas[index].id) {
        vm.data.lstAlianzasABorrar.push(vm.data.lstAlianzas[index].id)
      }
      vm.data.lstAlianzas.splice(index, 1);
    }


    //MEDIO COMUNICACION
    vm.clickMedioComunicacion = function (selectedObj, fieldName) {
      if (selectedObj) {
        if (fieldName == "colaborador") {
          if (selectedObj.colaborador) {
            vm.nuevoMedioComunicacion.colaborador = selectedObj.colaborador
            vm.nuevoMedioComunicacion.nombre = selectedObj.colaborador.nombre
          }
          else {
            vm.nuevoMedioComunicacion.organizacionesBeneficiarias = selectedObj
            vm.nuevoMedioComunicacion.nombre = selectedObj.nombre
          }
          vm.nuevoMedioComunicacion.abreviatura = selectedObj.abreviatura;
        }
      }

      if (fieldName == "divisa") {
        vm.nuevoMedioComunicacion.divisa = selectedObj;
      }
    }

    vm.addMedioComunicacion = function (object) {
      //TODO alguna validacion de campos??? que es obligatorio???
      vm.data.lstMedioComunicacion.push(angular.copy(vm.nuevoMedioComunicacion));
      vm.nuevoMedioComunicacion = {};
    }


    vm.clickTablaMedioComunicacion = function (selectedObj, fieldName, index) {
      if (fieldName == "divisa") {
        vm.data.lstMedioComunicacion[index].divisa = selectedObj;
      }
    }

    vm.deleteMedioComunicacion = function (index) {
      if (vm.data.lstMedioComunicacion[index].id) {
        vm.data.lstMedioComunicacionABorrar.push(vm.data.lstMedioComunicacion[index].id)
      }
      vm.data.lstMedioComunicacion.splice(index, 1);
    }


    //CUMPLIMIENTO OBJETIVOS
    vm.clickCumplimientoObjetivos = function (selectedObj, fieldName) {
      if (selectedObj) {
        if (fieldName == "colaborador") {
          if (selectedObj.colaborador) {
            vm.nuevoCumplimientoObjetivo.colaborador = selectedObj.colaborador
            vm.nuevoCumplimientoObjetivo.nombre = selectedObj.colaborador.nombre
          }
          else {
            vm.nuevoCumplimientoObjetivo.organizacionesBeneficiarias = selectedObj
            vm.nuevoCumplimientoObjetivo.nombre = selectedObj.nombre
          }
          vm.nuevoCumplimientoObjetivo.abreviatura = selectedObj.abreviatura;
        }
        if (fieldName == "alineacion") {
          vm.nuevoCumplimientoObjetivo.alineacion = selectedObj.enum;
          vm.nuevoCumplimientoObjetivo.alineacionNombre = selectedObj.nombre || selectedObj.enum;
        }
      }

    }

    vm.addCumplimientoObjetivos = function (object) {
      //TODO alguna validacion de campos??? que es obligatorio???
      vm.data.lstCumplimientoObjetivos.push(angular.copy(vm.nuevoCumplimientoObjetivo));
      vm.nuevoCumplimientoObjetivo = {};
    }

    vm.clickTablaCumplimientoObjetivos = function (selectedObj, fieldName, index) {
      if (fieldName == "alineacion") {
        vm.data.lstCumplimientoObjetivos[index].alineacion = selectedObj.enum;
        vm.data.lstCumplimientoObjetivos[index].alineacionNombre = selectedObj.nombre;
      }
    }

    vm.deleteCumplimientoObjetivos = function (index) {
      if (vm.data.lstCumplimientoObjetivos[index].id) {
        vm.data.lstCumplimientoObjetivosABorrar.push(vm.data.lstCumplimientoObjetivos[index].id)
      }
      vm.data.lstCumplimientoObjetivos.splice(index, 1);
    }

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == "tipoLogroDirecto") {
        vm.data.tipoDirectos = selectorObject;
      }

      if (selectorType == "tipoLogroDirecto") {
        vm.data.tipoDirectos = selectorObject;
      }

      if (selectorType == "tipoLogoIndirecto") {
        vm.data.tipoIndirectos = selectorObject;
      }

      if (selectorType == "eficiencia") {
        vm.data.eficiencia = selectorObject.enum;
        vm.data.eficienciaNombre = selectorObject.enum;
      }

      if (selectorType == "eficacia") {
        vm.data.eficacia = selectorObject.enum;
        vm.data.eficaciaNombre = selectorObject.enum;
      }

      if (selectorType == "sostenibilidad") {
        vm.data.sostenibilidad = selectorObject.enum;
        vm.data.sostenibilidadNombre = selectorObject.enum;
      }

      if (selectorType == "alineacionObjetivos") {
        vm.data.alineacionObjetivos = selectorObject.enum;
        vm.data.alineacionObjetivosNombre = selectorObject.enum;
      }
    }

    vm.clickSelectorMulti = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.razon) {
        vm.data.iniciativa.lstRazons = selectorObject;
      }
      if (selectorType == "situacionDirecta") {
        vm.data.lstSituacionDirectos = selectorObject;
      }

      if (selectorType == "situacionIndirecta") {
        vm.data.lstSituacionIndirectos = selectorObject;
      }
    }

    function fillSelectors() {
      vm.selectorList.grado = SelectService.getGrado();
      vm.selectorList.tipoOrgBeneficiaria = SelectService.getTipoOrgBeneficiaria();

      vm.selectorList.siNo = [
        {nombre: 'achievementsform.Si', enum: 'SI'},
        {nombre: 'achievementsform.No', enum: 'NO'},
      ]

      SelectService.getSituacion().then(function (res) {
        vm.selectorList.situacion = res;
      })

      SelectService.getTipoLogro().then(function (res) {
        vm.selectorList.tipoLogro = res;
      })

      SelectService.getColaboracionIniciativa(vm.idInitiative).then(function (res) {
        vm.selectorList.colaboradorIniciativa = res;
      })

      SelectService.getGrupoInteres().then(function (res) {
        vm.selectorList.grupoInteres = res;
      })

      SelectService.getBadges().then(function (res) {
        vm.selectorList.divisas = res;
      })

    }

    function fixDto(dto) {
      var tempList = dto.lstOrganizacionesBeneficiariasDTO.filter(function (elem) {
        if (elem.id == vm.idEntidadUserData) {
          return false;
        }
        else {
          return true;
        }
      })

      dto.lstOrganizacionesBeneficiariasDTO = tempList;

      return dto;
    }

    vm.savingData = false;
    vm.clickSave = function () {
      if (!vm.savingData) {
        vm.savingData = true;
        if (vm.editId && vm.duplicate == 'false') {
          AchievementsService.updateAchievement(fixDto(vm.data)).then(function (res) {
            vm.savingData = false
            $state.go("measurement.achievements");
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        } else {
          AchievementsService.createAchievement(fixDto(vm.data)).then(function (res) {
            vm.savingData = false
            $state.go("measurement.achievements");
          })
            .catch(function (e) {
              vm.savingData = false;
            })
        }
      }
    }

    vm.clickCancel = function () {
      // var initiative = {id: vm.idproyect};
      $state.go("measurement.achievements");
    }

    vm.abreCalendarioDesde = false;
    vm.abrirCalendarioDesde = function () {
      vm.abreCalendarioDesde = true;
    }

	vm.getToggleOption = function(optionName){
		return UserService.getToggleOption(optionName);
	}
	vm.toggleActive = false;
	vm.toggleActive2 = false;
	vm.toggleActive3 = false;
	vm.toggleActive4 = false;
	vm.toggleActive5 = false;
	vm.toggleActive6 = false;
	vm.initToggleOption = function(){		
		if (vm.getToggleOption('achievement_indirect_beneficiaries') == true)
		{
			if (vm.data.numBeneficiariosIndirectos != null && vm.data.numBeneficiariosIndirectos != "")
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");
				document.getElementById("divIndirectContainer").classList.remove("hidden");
				vm.toggleActive	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
				document.getElementById("divIndirectContainer").classList.add("hidden");
				vm.toggleActive = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
			document.getElementById("divIndirectContainer").classList.add("hidden");
			vm.toggleActive = false;
		}
		
		if (vm.getToggleOption('achievement_environmental') == true)
		{
			if (vm.data.numLogrosMA != null && vm.data.numLogrosMA != "")
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.add("active");
				document.getElementById("environmental1").classList.remove("hidden");
				document.getElementById("environmental2").classList.remove("hidden");
				vm.toggleActive2	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.remove("active");
				document.getElementById("environmental1").classList.add("hidden");
				document.getElementById("environmental2").classList.add("hidden");
				vm.toggleActive2 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton2").classList.remove("active");
			document.getElementById("environmental1").classList.add("hidden");
			document.getElementById("environmental2").classList.add("hidden");
			vm.toggleActive2 = false;
		}
		
		if (vm.getToggleOption('achievement_ggii') == true)
		{
			if (vm.data.lstGruposInteres.length > 0)
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.add("active");
				document.getElementById("ggii1").classList.remove("hidden");
				document.getElementById("ggii2").classList.remove("hidden");
				document.getElementById("ggii3").classList.remove("hidden");
				vm.toggleActive3	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.remove("active");
				document.getElementById("ggii1").classList.add("hidden");
				document.getElementById("ggii2").classList.add("hidden");
				document.getElementById("ggii3").classList.add("hidden");
				vm.toggleActive3 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton3").classList.remove("active");
			document.getElementById("ggii1").classList.add("hidden");
			document.getElementById("ggii2").classList.add("hidden");
			document.getElementById("ggii3").classList.add("hidden");
			vm.toggleActive3 = false;
		}
		
		if (vm.getToggleOption('achievement_alliances') == true)
		{
			if (vm.data.lstAlianzas.length > 0)
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.add("active");
				document.getElementById("alliances1").classList.remove("hidden");
				document.getElementById("alliances2").classList.remove("hidden");
				document.getElementById("alliances3").classList.remove("hidden");
				vm.toggleActive4	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.remove("active");
				document.getElementById("alliances1").classList.add("hidden");
				document.getElementById("alliances2").classList.add("hidden");
				document.getElementById("alliances3").classList.add("hidden");
				vm.toggleActive4 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton4").classList.remove("active");
			document.getElementById("alliances1").classList.add("hidden");
			document.getElementById("alliances2").classList.add("hidden");
			document.getElementById("alliances3").classList.add("hidden");
			vm.toggleActive4 = false;
		}
		
		if (vm.getToggleOption('achievement_media') == true)
		{
			if (vm.data.lstMedioComunicacion.length > 0)
			{
				document.getElementById("toggleEntityBudgetColumnButton5").classList.add("active");
				document.getElementById("media1").classList.remove("hidden");
				document.getElementById("media2").classList.remove("hidden");
				document.getElementById("media3").classList.remove("hidden");
				vm.toggleActive5	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton5").classList.remove("active");
				document.getElementById("media1").classList.add("hidden");
				document.getElementById("media2").classList.add("hidden");
				document.getElementById("media3").classList.add("hidden");
				vm.toggleActive5 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton5").classList.remove("active");
			document.getElementById("media1").classList.add("hidden");
			document.getElementById("media2").classList.add("hidden");
			document.getElementById("media3").classList.add("hidden");
			vm.toggleActive5 = false;
		}
		
		if (vm.getToggleOption('achievement_objetives') == true)
		{
			if (vm.data.lstCumplimientoObjetivos.length > 0)
			{
				document.getElementById("toggleEntityBudgetColumnButton6").classList.add("active");
				document.getElementById("objetives1").classList.remove("hidden");
				document.getElementById("objetives2").classList.remove("hidden");
				document.getElementById("objetives3").classList.remove("hidden");
				vm.toggleActive6	= true;
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton6").classList.remove("active");
				document.getElementById("objetives1").classList.add("hidden");
				document.getElementById("objetives2").classList.add("hidden");
				document.getElementById("objetives3").classList.add("hidden");
				vm.toggleActive6 = false;
			}
		}
		else
		{
			document.getElementById("toggleEntityBudgetColumnButton6").classList.remove("active");
			document.getElementById("objetives1").classList.add("hidden");
			document.getElementById("objetives2").classList.add("hidden");
			document.getElementById("objetives3").classList.add("hidden");
			vm.toggleActive6 = false;
		}
	}
	
	vm.changeToggleOption = function(optionName){
		if (optionName == 'achievement_indirect_beneficiaries')
		{
			vm.toggleActive = !vm.toggleActive;		
			var actualOption = vm.getToggleOption(optionName);

			if (vm.toggleActive != actualOption)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.add("active");
				document.getElementById("divIndirectContainer").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton").classList.remove("active");
				document.getElementById("divIndirectContainer").classList.add("hidden");
			}
		}
		else if (optionName == 'achievement_environmental')
		{
			vm.toggleActive2 = !vm.toggleActive2;		
			var actualOption2 = vm.getToggleOption(optionName);

			if (vm.toggleActive2 != actualOption2)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive2 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.add("active");
				document.getElementById("environmental1").classList.remove("hidden");
				document.getElementById("environmental2").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton2").classList.remove("active");
				document.getElementById("environmental1").classList.add("hidden");
				document.getElementById("environmental2").classList.add("hidden");
			}
		}
		else if (optionName == 'achievement_ggii')
		{
			vm.toggleActive3 = !vm.toggleActive3;		
			var actualOption3 = vm.getToggleOption(optionName);

			if (vm.toggleActive3 != actualOption3)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive3 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.add("active");
				document.getElementById("ggii1").classList.remove("hidden");
				document.getElementById("ggii2").classList.remove("hidden");
				document.getElementById("ggii3").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton3").classList.remove("active");
				document.getElementById("ggii1").classList.add("hidden");
				document.getElementById("ggii2").classList.add("hidden");
				document.getElementById("ggii3").classList.add("hidden");
			}
		}
		else if (optionName == 'achievement_alliances')
		{
			vm.toggleActive4 = !vm.toggleActive4;		
			var actualOption4 = vm.getToggleOption(optionName);

			if (vm.toggleActive4 != actualOption4)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive4 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.add("active");
				document.getElementById("alliances1").classList.remove("hidden");
				document.getElementById("alliances2").classList.remove("hidden");
				document.getElementById("alliances3").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton4").classList.remove("active");
				document.getElementById("alliances1").classList.add("hidden");
				document.getElementById("alliances2").classList.add("hidden");
				document.getElementById("alliances3").classList.add("hidden");
			}
		}
		else if (optionName == 'achievement_media')
		{
			vm.toggleActive5 = !vm.toggleActive5;		
			var actualOption5 = vm.getToggleOption(optionName);

			if (vm.toggleActive5 != actualOption5)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive5 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton5").classList.add("active");
				document.getElementById("media1").classList.remove("hidden");
				document.getElementById("media2").classList.remove("hidden");
				document.getElementById("media3").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton5").classList.remove("active");
				document.getElementById("media1").classList.add("hidden");
				document.getElementById("media2").classList.add("hidden");
				document.getElementById("media3").classList.add("hidden");
			}
		}
		else if (optionName == 'achievement_objetives')
		{
			vm.toggleActive6 = !vm.toggleActive6;		
			var actualOption6 = vm.getToggleOption(optionName);

			if (vm.toggleActive6 != actualOption6)					
				UserService.changeToggleOption(optionName);
			
			if (vm.toggleActive6 == true)
			{
				document.getElementById("toggleEntityBudgetColumnButton6").classList.add("active");
				document.getElementById("objetives1").classList.remove("hidden");
				document.getElementById("objetives2").classList.remove("hidden");
				document.getElementById("objetives3").classList.remove("hidden");
			}
			else
			{
				document.getElementById("toggleEntityBudgetColumnButton6").classList.remove("active");
				document.getElementById("objetives1").classList.add("hidden");
				document.getElementById("objetives2").classList.add("hidden");
				document.getElementById("objetives3").classList.add("hidden");
			}
		}
	}
	
	
    if (!vm.editId) {
		vm.initToggleOption();
	}
  }
})();
