(function () {
  angular
    .module('app')
    .component('measurement', {
      templateUrl: 'components/measurement/measurement.html',
      controller: MeasurementController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function MeasurementController(InitiativeService, $state, $scope, $rootScope, $stateParams, RoleService) {
    var vm = this;
    vm.$state = $state;

    vm.hideButton = false;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
    vm.proyectClosed = false;

    InitiativeService.getProyectState($stateParams.idproyect).then(function (res) {
      vm.proyectClosed = !res;
      if (vm.proyectClosed) {
        {
          vm.hideButton = true
        }
      }
    })

    vm.currentNavItem = 'contributions'; //Entidad a la que entramos por defecto

    vm.arrayButtons = [];
    vm.arrayButtons['contributions'] = {
      buttonName: '',
      newStateName: '',
      title: 'measurement.contribucionesMin',
    }
    vm.arrayButtons['achievements'] = {
      buttonName: 'measurement.nuevoLogro',
      newStateName: 'measurement.achievementsform',
      title: 'measurement.logrosMin',
    }
    vm.arrayButtons['impacts'] = {
      buttonName: 'measurement.nuevoImpacto',
      newStateName: 'measurement.impactsform',
      title: 'measurement.impactosMin',
    }

    // $rootScope.$on('noPermission', function (event, data) {
    //   vm.hideButton = data;
    // });

    vm.contador = [];
    //TODO implementar algun servicio, que nos de estos valores, de momento los ocultamos
    // vm.contador.contributions = 8;
    // vm.contador.achievements = 3;
    // vm.contador.impacts = 5;

    //TODO miga de pan, necesitamos la abreviatura del proyecto (o el nombre) y la abreviatura de la iniciativa (o el nombre)
    vm.migaPan = {}
    vm.migaPan.codigoProyecto = "PRO-2";
    vm.migaPan.codigoIniciativa = "INI-2";

    vm.clickNew = function () {
      $state.go(vm.arrayButtons[vm.currentNavItem].newStateName, {editId: null, duplicate: false});
    }

    $scope.$watch(function () {
      return $state.$current.name
    }, function (newVal, oldVal) {
      vm.isForm = newVal.toLowerCase().indexOf("form") > -1 ? true : false;
      //vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);
      //si accedemos a la raiz llevamos a la entidad por defecto
      if (newVal == "measurement") {
        $state.go('measurement.' + vm.currentNavItem);
      }
      else if (vm.isForm) {
        vm.currentNavItem = newVal.toLowerCase().split('form')[0].split('.')[1];
      }
      else if (newVal.split('.').length == 2) {
        vm.currentNavItem = newVal.split('.')[1];
      }
    })


    /********************************************/

    angular.module('app').factory('PermissionInterceptor', function ($window, $q, $state) {
      return {
        request: function (config) {
          return config || $q.when(config);
        },
        response: function (response) {
          if (response.status === 403) {
            vm.isForm = true;
          }
          return response || $q.when(response);
        }
      };
    });

    /********************************************/





  }
})();
