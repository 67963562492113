(function () {
  angular
    .module('app')
    .component('forgotpass', {
      templateUrl: 'components/forgotpass/forgotpass.html',
      controller: ForgotPassController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ForgotPassController(ForgotPassService, $mdToast, Translator) {
    var vm = this;

    vm.data = {};
    //inicializamos data
    vm.data.email = "";
    vm.emailSent = false;

    vm.clickSave = function () {
      if (!vm.data.email || !validateEmail(vm.data.email)) {
        Translator.translate('forgotpass.mailFormatoIncorrecto', function(translation) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(translation)
              .position('bottom right')
              .hideDelay(3000)
          );
        });
        return;
      }

      ForgotPassService.resetPassInit(vm.data.email).then(function (res) {
        //TODO algo me lleva al login, creo que es el inyector, hay que revisarlo al igual que para entrar en forgorpassnew
        vm.emailSent = true;
      }).catch(function (res) {
          if (!res || res.status != 400) {
            return;
          }
          Translator.translate('forgotpass.mailRegistrado', function(translation) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(translation)
                .position('bottom right')
                .hideDelay(3000)
            );
          });
        })
    }
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


})();
