(function() {
  'use strict';

  angular
    .module('app')
    .component('reportChart', {
      templateUrl: 'components/reportChart/reportChart.html',
      controller: ReportChartController,
      controllerAs: '$ctrl'
    });

  /* @ngInject */
  function ReportChartController(ReportService, $q) {
    var $ctrl = this;
    $ctrl.checks = []
    $ctrl.reportData = []
    $ctrl.loading = false

    ////////////////

    $ctrl.$onInit = function() { };
    $ctrl.$onChanges = function(changesObj) { };
    $ctrl.$onDestroy = function() { };

    $ctrl.getReport = function() {
      $ctrl.loading = true
      var promises = $ctrl.checks.filter(function(check) {
        return check.checked && check.url
      }).map(function(check) {
        return ReportService.getReport(check.url)
        .then(function(report) {
          report.index = check.index;
          report.label = check.label;
          return report
        })
      })
      $q.all(promises).then(function(reports) {
        $ctrl.reportData = reports.map(function(report) {
          var mapper = ReportService.getMappers()[report.label] || angular.identity
          return mapper(report)
        })
        $ctrl.loading = false
      })
    }
  }
})();
