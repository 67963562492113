(function () {
  angular
    .module('app')
    .component('uploadform', {
      templateUrl: 'components/uploadform/uploadform.html',
      controller: UploadFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UploadFormController(UploadFormService, config, $mdDialog, SelectService, RoleService, $stateParams, $state, $window, ModalService) {
    var vm = this;

    vm.validData = true;

    vm.archivo = {};
    vm.id = $stateParams.id;
    vm.tipoEntidad = $stateParams.tipoEntidad;
    vm.multi = $stateParams.multi;
    vm.idProyecto = $stateParams.idProyecto;
    vm.idIniciativa = $stateParams.idIniciativa;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    vm.isUploading = false;

    vm.fileList = [
    ];

    //TODO nada mas entrar tenemos que hacer algun tipo de llamada que nos devuelva todos los archivos para la condicion que tenemos

    vm.getData = function () {
      UploadFormService.getFiles(vm.tipoEntidad, $stateParams.id).then(function (res) {
        vm.fileList = res;
      })
    };

    vm.getData();

    vm.clickSave = function () {
      vm.isUploading = true;
      UploadFormService.uploadFile(vm.archivo, vm.tipoEntidad, vm.id).then(function (res) {
        if (vm.multi) {
          vm.archivo = {};//TODO no funciona? :S
          vm.getData();
        }
        else {
          goState();
        }
        vm.isUploading = false;
      })
        .catch(function (e) {
          vm.isUploading = false;
        })
      ;
    }

    vm.clickCancel = function () {
      goState();
    }

    vm.clickFile = function (index) {
      var file = vm.fileList[index];
      UploadFormService.getUrl(file.id).then(function (url) { //FORCE HTTPS URL FILE
        // console.log(url);
        $window.open(url);
      });
    }

    vm.deleteFile = function (ev, index) {
      var element = vm.fileList[index];

      var whenOk = function () {
        UploadFormService.deleteFile(vm.tipoEntidad, element.id).then(function (res) {
          vm.getData();
        })
      }
      ModalService.showConfirm(ev, 'uploadform.borrarArchivo', 'uploadform.estasSeguro', 'uploadform.borrar', 'uploadform.cancelar', whenOk);
    }

    function goState() {
      //0 = entidad
      if (vm.tipoEntidad == 0) {
        $state.go("manage.entity")
      }
      //1 = tabla salarial
      if (vm.tipoEntidad == 1) {
        $state.go("entitytoolbar.salarytables")
      }
      //3 = colaborador
      if (vm.tipoEntidad == 3) {
        $state.go("manage.collaborators")
      }
      //4 = proyecto
      if (vm.tipoEntidad == 4) {
        $state.go("proyect")
      }
      //5 = iniciativa
      if (vm.tipoEntidad == 5) {
        $state.go("initiative", {id: vm.idProyecto})
      }
      //6 = impacto
      if (vm.tipoEntidad == 6) {
        $state.go("measurement.impacts", {idproyect: vm.idProyecto, id: vm.idIniciativa})
      }
      //7 = contribucion
      if (vm.tipoEntidad == 7) {
        $state.go("measurement.contributions", {idproyect: vm.idProyecto, id: vm.idIniciativa})
      }
      //8 = logro
      if (vm.tipoEntidad == 8) {
        $state.go("measurement.achievements", {idproyect: vm.idProyecto, id: vm.idIniciativa})
      }
      //TODO ir añadiendo todos los tipos
    }

  }

})();
