(function(){
  'use strict';

  angular.module('app')
  .component('impactMultiSelect', {
    templateUrl: 'shared/impactMultiSelect/impactMultiSelect.html',
    controller: ImpactMultiSelectContoller,
    controllerAs: 'vm',
    bindings: {
      displayProperty: '@',
      placeholder: '@',
      elements: '<',
      values: '<',
      onChange: '&',

      // When elements are changed, values are replaced with the new elements. This is used to translate values when a new language is selected
      autoUpdateValues: '<',
    }
  })

  /* @ngInject */
  function ImpactMultiSelectContoller($window){
    var lodash = $window._
    var vm = this;
    vm.checkboxsModel = {};
    vm.values = [];

    vm.$onChanges = function(changes) {
      if(vm.autoUpdateValues && changes.elements && changes.elements.previousValue && changes.elements.currentValue) {
        var newValues = vm.getNewValues(changes);
        if (newValues.length === vm.values.length) {
          vm.setValues(newValues);
          vm.formatValues(vm.values);
        }
      }

      if(changes.values && changes.values.currentValue) {
        vm.formatValues(changes.values.currentValue);
      }
    }

    vm.getNewValues = function(changes) {
      var newValues = [];
      angular.forEach(vm.values, function(value, key) {
        var newElement = _.find(changes.elements.currentValue, function(o) {
          return o.id === value.id;
        });
        if (newElement) {
          newValues.push(newElement);
        }
      });
      return newValues;
    }

    vm.setValues = function(newValues) { // We set new values while keeping the original array
      vm.values.length = 0; // emtpy the original array
      angular.forEach(newValues, function(value) {
        vm.values.push(value);
      });
    }

    vm.formatValues = function(values) {
      vm.checkboxsModel = {};
	  var theValues = Array.from(vm.values);
      theValues.forEach(function(elem) {
        vm.checkboxsModel[elem.id] = true;
      })
      vm.valuesFormatted = theValues.map(function(val) {
        return val[vm.displayProperty] ? val[vm.displayProperty] : val.nombre;
      });
    }
    vm.select = function(elem) {
      vm.values = vm.values || [];
	  vm.values = Array.from(vm.values);
      var elemIsPresent = vm.checkboxsModel[elem.id];
      if(elemIsPresent) {
        // si el elemento esta presente lo borramos
        vm.values = vm.values.filter(function(val) {
          return val.id !== elem.id;
        })
      } else {
        // y si no, lo añadimos
        vm.values.push(lodash.assign({}, elem))
      }
      vm.formatValues(vm.values);
      vm.onChange({values: vm.values});
    }
  }
})();
