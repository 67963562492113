(function () {
  angular
    .module('app')
    .component('contributions', {
      templateUrl: 'components/contributions/contributions.html',
      controller: ContributionsController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ContributionsController(
    $q, $window, $scope, $rootScope,
    UserService, ContributionsService, InitiativeService,
    $stateParams, config, $mdDialog,
    SelectService, $filter, $state,
    BreadcrumService, $rootScope, ModalService,$transitions,Translator,RoleService, $translate
  ) {
    var lodash = $window._
    var vm = this;

	vm.now = new Date().getFullYear();
	var lanzadoUpdate = [];	
	var resendThisUpdate = [];
	var sendingThisUpdate = [];
	var resendThisInsert = [];
	var sendingThisInsert = [];

    vm.sortField = config.defaultSortField;
    vm.sortAscOrder = config.defaultSortOrder;
    vm.page = config.defaultInitialPage;
    vm.size = config.defaultPageSize;
	vm.isSoloVista = RoleService.hasRole(RoleService.getEnum().SOLOVISTA);
	
    vm.id = $stateParams.id;
    vm.idproyect = $stateParams.idproyect;
    vm.filterStorageName = "contributions";

    vm.allDataLoaded = false;
    vm.disableInfinite = false;
	vm.aportacionesLey112018NoVisible = false;
	
	ContributionsService.getIfActiveLey112018().then(function (res) {
	  if (res == true)
		vm.aportacionesLey112018NoVisible = false;
	  else
		vm.aportacionesLey112018NoVisible = true;
	});
	
    vm.data = [];

    vm.selectorList = {
      quien: {},
      atribucion: [

      ]
    };

    vm.filters = {};
    vm.filtroFecha;
	vm.isContributions = true;
	vm.confirmationMessage = '';
	Translator.translate('contributions.confirmationMessage', function (translation) {
		vm.confirmationMessage = translation;
	});
	
	vm.mensajerevisar = '';
	Translator.translate('contributions.mensajerevisar', function (translation) {
		vm.mensajerevisar = translation;
	});
	
    vm.filtroTipo = null;
    vm.filtroIdTipoContribuyente = null;
    vm.filtroIdColaborador = null;

    vm.sumatorio = null;

    vm.sinPermisos = false;

    vm.proyectClosed = false;

    // Al cambiar el idioma temenos que hacer algunas peticiones de nuevo a la API. Para recibir los datos en el nuevo idioma.
    var destroyLangWatcher = $rootScope.$watch('selectedLang', function () {
      if (vm.filters.tipoNombre) {
        SelectService.getQuien(vm.filters.tipoNombre, vm.id).then(function (res) {
          vm.selectorList.filtroQuien = res;
        });
      }
      vm.selectorList.quien = {};
      vm.getData();
    });
    $scope.$on('$destroy', function() { // Es importante destruir el watcher cuando el constructor es destruido.
      destroyLangWatcher();
    });
	
	
	
	function confirmLeavePage(e) {
		var confirmed = $window.confirm(vm.confirmationMessage);
		if (e && !confirmed)
			e.preventDefault();
	}
	
	$transitions.onStart({}, function($transition) {
		if (vm.isContributions == true)
		{
			var showConfirm = false;
			vm.algoActualizado.forEach(function (elem, index) {
				if (elem == true)			
					showConfirm = true;			
			})
			
			if (showConfirm == true)
			{
				document.querySelectorAll("main.entity-table > div > div.layout-row > button").forEach(function(e,i){
					e.style.display = "none";						
				});
					
				var confirmed = $window.confirm(vm.confirmationMessage);
				if (!confirmed) {
					document.body.focus();
					document.body.click();
					
					document.querySelectorAll('md-nav-ink-bar').forEach(function (elem, index) {
						elem.style.display = "none";
						elem.style.visibility = "hidden";
					});
					
					var newNode = document.createElement('div');
					newNode.innerHTML = '<md-nav-ink-bar ng-hide="ctrl.mdNoInkBar" aria-hidden="false" class="" style="left:12px;width:150px;"></md-nav-ink-bar>';
					var referenceNode = document.querySelector('main.entity-table div.md-nav-bar nav');
					referenceNode.parentNode.insertBefore(newNode.firstChild, referenceNode.nextSibling);					

					return false;
				}
				else
				{
					document.querySelectorAll('md-nav-ink-bar').forEach(function (elem, index) {
						if (elem.style.left == "12px" && elem.style.width == "150px")						
							elem.style.display = "none";						
						else
						{
							elem.style.display = "inherit";
							elem.style.visibility = "visible";
						}
					});
					vm.isContributions = false;
					document.querySelectorAll("main.entity-table > div > div.layout-row > button").forEach(function(e,i){
						e.style.display = "block";
					});
					$window.onbeforeunload = null;
					$window.removeEventListener('beforeunload', confirmLeavePage);
				}
			}
		}		
	});
	

    InitiativeService.getProyectState($stateParams.idproyect).then(function (res) {
      vm.proyectClosed = !res;
    })

    vm.abreCalendarioFiltro = false;

    vm.abrirCalendarioFiltro = function () {
      vm.abreCalendarioFiltro = true;
    }

    vm.auxData;
    vm.filtrarPorFecha = function () {
      if (vm.filtroFecha == null || vm.filtroFecha == "") {
        vm.data = angular.copy(vm.auxData)
      }
      else {
        if (!vm.auxData) {
          vm.auxData = angular.copy(vm.data);
        }
        vm.data = vm.auxData.filter(function (elem) {
          if (elem && elem.fecha && elem.fecha.getTime() == vm.filtroFecha.getTime()) {
            return true;
          }
          else {
            return false;
          }
        })
      }
    }

    vm.seleccionarTodos = function (seleccionarTodos, ev) {
      var whenOk = function () {
		if (seleccionarTodos == false)
		{
			ContributionsService.validateDevalidateAll(vm.id, seleccionarTodos).then(function (res) {
			  vm.data.map(function (elem) {
				elem.validado = seleccionarTodos;
				return elem;
			  })
			});
			vm.clickSaveAll();
		}
		else
		{
			var obtenidoSumatorio = false;
			var hayAlgunoValidado = false;
			var hayAlgunoNoValidado = false;
			vm.data.forEach(function (elem, index) {
				if (elem.validado == true)
				{
					hayAlgunoValidado = true;
					vm.updateData(index, false, false);
				}
				else
					hayAlgunoNoValidado = true;
			});
			
			if (hayAlgunoValidado == false)
				vm.clickSaveAll();
			else
			{
				vm.data.forEach(function (elem, index) {
					itemsProcessed++;
					
					if (elem.validado == false){	
						if (index === vm.data.length - 1){
							vm.updateData(index,false,true);
						}
						else{
							vm.updateData(index,false,false);
						}
					}					
					
					if (index === vm.data.length - 1) {						
						if (hayAlgunoNoValidado == false)
						{
							vm.updateData(index,false,true);
						}
						
						vm.getSumatorio();
						obtenidoSumatorio = true;
					}
				});
			}
			if (obtenidoSumatorio == false)
			{
				vm.getSumatorio();
			}
			
			ContributionsService.validateDevalidateAll(vm.id, seleccionarTodos).then(function (res) {
			  vm.data.map(function (elem) {
				elem.validado = seleccionarTodos;
				return elem;
			  })
			});
		}
      }

      //TODO cambiar el mensaje por uno mas especifico
      ModalService.showConfirm(ev,
        !seleccionarTodos ? 'contributions.desvalidarTodos' : 'contributions.validarTodos',
        'contributions.estasSeguro',
        !seleccionarTodos ? 'contributions.desvalidarTodos' + ' ' : 'contributions.validarTodos',
        'contributions.cancelar',
        whenOk);//TODO cambiar texto
    }

    //esta variable es el flag que "esconde" los campos en funcion del "como" elegido
    vm.hide = [];

    //esta variable es el flag que dice si un dato esta guardado en el server o no
    vm.sent = [];
	vm.algoActualizado = [];

    vm.selectedAtributions = []

    vm.clickNew = function () {
      vm.data.push({
        tipoNombre: "",
        contribuyente: {},
        cantidad: "",
        validado: false,
		aportacionesLey112018Active: false
      });
      vm.enableDisableFields(vm.data.length - 1);
    }

	vm.clickSave = function (index) {
	  if (vm.algoActualizado[index] == true)
	  {
		  vm.updateData(index, true, true);
	  }
    }
	
	vm.clickSaveAll = function () {
	  var hayAlgoActualizado = false;
	  vm.algoActualizado.forEach(function (elem, index) {
		if (elem == true)			
			hayAlgoActualizado = true;			
	  });
	  if (hayAlgoActualizado == true)
	  {
		  vm.data.forEach(function (elem, index) {
			if (vm.algoActualizado[index] == true)
			{
				if (vm.data[index].id)
				{
					if (index === vm.data.length - 1) {
						vm.updateData(index, false, true);
					}
					else{
						vm.updateData(index, false, false);
					}
				}
			}				
			
			if (index === vm.data.length - 1){
			  vm.getSumatorio();
			}
          });
		  
		  var inserts = [];
		  var indexes = [];
		  vm.data.forEach(function (elem, index) {
			if (vm.algoActualizado[index] == true)
			{
				if (!vm.data[index].id)
				{
					var isOk = vm.checkData(index);
					if (isOk == true)
					{
						vm.data[index].datosIniciativaId = vm.id;
						inserts.push(vm.data[index]);
						indexes.push(index);
					}
					else
					{
						alert(vm.mensajerevisar);
					}					
				}
			}
          });
		  
		  if (inserts.length > 0)
		  {
			vm.sendMultipleInserts(inserts,indexes);
		  }
	  }
    }
	
	vm.sendMultipleInserts = function (inserts, indexes) {
		if (!vm.proyectClosed) {
			var pos = 0;
			var object = inserts[pos];
			var thisIndex = indexes[pos];
			ContributionsService.createContribution(object).then(function (res) {
				vm.processInsert(inserts, indexes, thisIndex, res, pos);
			}).catch(function (ex) {
				indexes.forEach(function (elem) {
					sendingThisInsert[elem] = false;
					vm.sent[elem] = false;
					vm.algoActualizado[elem] = true;
					document.getElementById('botonGuardar-'+elem).style.color = "red";
					document.getElementById('botonGuardar-'+elem).style.cursor = "pointer";
				});
				document.getElementById('botonGuardar').style.backgroundColor = "#59d21c";
				document.getElementById('botonGuardar').style.cursor = "pointer";
			});;
		}
    }
	
	vm.processInsert = function(inserts, indexes, thisIndex, res, pos){
		sendingThisInsert[thisIndex] = false;
		vm.sent[thisIndex] = true;
		vm.algoActualizado[thisIndex] = false;
		document.getElementById('botonGuardar-'+thisIndex).style.color = "#59d21c";
		document.getElementById('botonGuardar-'+thisIndex).style.cursor = "default";
		
		vm.data[thisIndex].codigoCorto = res.codigoCorto;
		vm.data[thisIndex].id = res.id;			
		vm.data[thisIndex].atribucion = res.atribucion;
		
		pos++;
		if (inserts.length > pos){ 
			var object = inserts[pos]; 
			thisIndex = indexes[pos]; 
			ContributionsService.createContribution(object).then(function (response) { vm.processInsert(inserts, indexes, thisIndex, res, pos);});
		}
		else
		{
			vm.getSumatorio();
			
			var quedaAlgoPorActualizar = false;
			vm.algoActualizado.forEach(function (elem, index) {
				if (elem == true)			
					quedaAlgoPorActualizar = true;			
			});
			if (quedaAlgoPorActualizar == false)
			{
				document.getElementById('botonGuardar').style.backgroundColor = "rgba(158,158,158,0.2)";
				document.getElementById('botonGuardar').style.cursor = "default";
			}
			
			$window.onbeforeunload = null;
			$window.removeEventListener('beforeunload', confirmLeavePage);	
		}
	}
	
    vm.clickDuplicate = function (index) {
	  vm.updateData(index, true, true);
	  setTimeout(function(){
		  var aux = angular.copy(vm.data[index]);
		  aux.id = null; //eliminamos el id del que vamos a duplicar para evitar problemas
		  aux.codigoCorto = null;
		  vm.data.push(aux);

		  var lastIndex = vm.data.length - 1;
		  vm.enableDisableFields(lastIndex)
		  vm.checkValidData(lastIndex,true);
	  }, 2500);
    }

    vm.clickDelete = function (index, ev) {
      var whenOk = function () {
		if (vm.sent[index]) {
		  ContributionsService.deleteContribution(vm.data[index])
			.then(function () {
			  vm.data.splice(index, 1);
			  vm.getSumatorio()
			});
		}
		else {
		  vm.data.splice(index, 1);
		}
      };
      ModalService.showConfirm(ev, 'contributions.borrarContribucion',
        vm.data[index].tipo != "TIEMPO" ? 'contributions.estasSeguro' : 'contributions.estasSeguroTiempo',
        'contributions.borrar',
        'contributions.cancelar',
        whenOk);
    }

    vm.atributionsAllIsSelected = function() { // No queremos filtrar por atribucion si una de las selectionadas es 'Todas'
      return vm.selectedAtributions.filter(function (atribution) {
        return atribution.id === null;
      }).length > 0;
    }

    vm.getSelectedAtributionIds = function() {
      return vm.selectedAtributions
        .map(lodash.property('id'))
        .filter(lodash.isNumber);
    }


    vm.getSumatorio = function () {
      ContributionsService.getSumatorio(
        vm.id,
        vm.filtroTipo,
        vm.filtroIdTipoContribuyente,
        vm.filtroIdColaborador,
        vm.atributionsAllIsSelected() ? [] : vm.getSelectedAtributionIds()
      ).then(function (res) {
        vm.sumatorio = res.importeCambio;
      });
    }

    vm.dataLoaded = false;
    vm.getData = function () {
      ContributionsService.getContributions(vm.id).then(function (res) {
        vm.setDisplayData(res).then(function (procesedData) {
          vm.data = procesedData;

          vm.dataLoaded = true;

          //validamos los campos y ocultamos los que hagan falta
          vm.data.forEach(function (elem, index) {
            vm.enableDisableFields(index);
            vm.sent[index] = true;
			vm.algoActualizado[index] = false;
          })
        });
      })
        .catch(function (ex) {
          if (ex.status == 403) {
            vm.sinPermisos = true;
          }
        })
      ;

      vm.getSumatorio();
    }

    vm.goUpload = function (index) {
	  if (vm.data[index].validado != true)
	  {
		  vm.updateData(index, true, true);
	  
		  setTimeout(function(){
			  vm.saveFilters();
			  var element = vm.data[index];
			  var uploadFormData = {id: element.id, tipoEntidad: 7, multi: true, idProyecto: vm.idproyect, idIniciativa: vm.id}
			  $state.go("uploadform", uploadFormData);
		  }, 2500);
	  }
	  else
	  {
		  vm.saveFilters();
		  var element = vm.data[index];
		  var uploadFormData = {id: element.id, tipoEntidad: 7, multi: true, idProyecto: vm.idproyect, idIniciativa: vm.id}
		  $state.go("uploadform", uploadFormData);
	  }
    }

    vm.setDisplayData = function (data) {
      //TODO asegurarme antes de que las listas estan llenas

      var requestArray = [];

	  var tiposPedidos = [];
	  var tiposObtenidos = [];
      var listTipo = SelectService.getComo();
      listTipo.forEach(function (type) {
        var tipo = type.enum;
        if (!vm.selectorList.quien[tipo]) {
		  if (!tiposPedidos.includes(tipo))
		  {
			  tiposPedidos.push(tipo);
			  requestArray.push(
				SelectService.getQuien(tipo, vm.id).then(function (res) {
				  vm.selectorList.quien[tipo] = res;
				  tiposObtenidos[tipo] = res;
				})
			  )
		  }
		  else
		  {
			  vm.selectorList.quien[tipo] = tiposObtenidos[tipo];
		  }
        }
      })

      if (!vm.selectorList.salaryTables) {
        requestArray.push(
          SelectService.getSalaryTables(vm.id).then(function (res) {
			var elementNone = {};
			elementNone.id = -2;
			elementNone.nombre = $translate.instant("impactSelect.sinSeleccion");
			elementNone.descripcion = null;
			elementNone.totalCoste = null;
			elementNone.numPersonas = null;
			elementNone.horas = null;
			elementNone.costeHora = null;
			elementNone.agnoAplicacion = null;
			elementNone.divisaTotalCoste = null;
			elementNone.divisaCosteHora = null;
			elementNone.eliminado = false;
			elementNone.descripcionPublica = null;
			res.unshift(elementNone);
			
            vm.selectorList.salaryTables = res;
          })
        );
      }

      return $q.all(requestArray).then(function () {
        return data.map(function (elem) {
          if (vm.selectorList.salaryTables) {
            setNombreTablaSalarial(vm.selectorList.salaryTables);
          }

          function setNombreTablaSalarial(list) {
            list.forEach(function (st) {
              if (st.id == elem.tablaSalarialId && elem.tablaSalarialId != -2) {
                elem.nombreTablaSalarial = st.nombre;
              }
			  else if (elem.tablaSalarialId == -2 || elem.tablaSalarialId == null)
				  elem.nombreTablaSalarial = '';
            })
          }

          if (elem.tipo && vm.selectorList.quien[elem.tipo]) {
            setContribuyente(vm.selectorList.quien[elem.tipo]);
          }

          function setContribuyente(list) {
            list.forEach(function (quien) {
              if (quien.contribuyente.colaboradorId == elem.contribuyente.colaboradorId
                && quien.contribuyente.tipoContribuyente && elem.contribuyente.tipoContribuyente
                && quien.contribuyente.tipoContribuyente.codigo == elem.contribuyente.tipoContribuyente.codigo

              ) { //TODO cambiar por tipoContribuyente.codigo
                elem.contribuyente.nombre = quien.nombre
              }
            })
          }

          if (elem.tipo) {
            elem.tipoNombre = elem.tipo;
          }

          elem.fecha = moment(elem.fecha, "YYYY-MM-DD").toDate();

          return elem;

        });
      })
    }

    vm.getSelectors = function () {
      vm.selectorList.como = SelectService.getComo();

      SelectService.getBadges().then(function (res) {
        vm.selectorList.badge = res;
      });

      SelectService.getSalaryTables(vm.id).then(function (res) {
		  var elementNone = {};
			elementNone.id = -2;
			elementNone.nombre = $translate.instant("impactSelect.sinSeleccion");
			elementNone.descripcion = null;
			elementNone.totalCoste = null;
			elementNone.numPersonas = null;
			elementNone.horas = null;
			elementNone.costeHora = null;
			elementNone.agnoAplicacion = null;
			elementNone.divisaTotalCoste = null;
			elementNone.divisaCosteHora = null;
			elementNone.eliminado = false;
			elementNone.descripcionPublica = null;
			res.unshift(elementNone);
			
        vm.selectorList.salaryTables = res;
      });

      vm.selectorList.atribucion = [
        {id: null, nombre: 'contributions.atribution.all', abrev: 'contributions.atribution.all.abbreviation'},
        {id: 0, nombre: 'contributions.atribution.entity', abrev: 'contributions.atribution.entity.abbreviation'},
        {id: 1, nombre: 'contributions.atribution.leverage', abrev: 'contributions.atribution.leverage.abbreviation'},
        {id: 2, nombre: 'contributions.atribution.others', abrev: 'contributions.atribution.others.abbreviation'},
      ]
      vm.selectorList.atribucion[1].nombre = UserService.getUserData().entidad.nombre;
      vm.selectorList.atribucion[1].abrev = UserService.getUserData().entidad.abreviatura;
    }

    var auxFilters = BreadcrumService.getFilters(vm.filterStorageName);
    if ($stateParams.cleanFilter == 'true') {
      auxFilters = null;
    }
    if (auxFilters != null) {
      vm.filters = auxFilters;
      if (vm.filters.date) {
        vm.filters.date = moment(vm.filters.date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
    }

    //vm.getData();
    vm.getSelectors();

    vm.saveFilters = function () {
      BreadcrumService.saveFilters(vm.filters, vm.filterStorageName);
    }

    vm.getAtributionLabel = function(contribution) {
      var atribucionIndex = contribution.atribucion === null ? 0 : contribution.atribucion + 1
      var selectedAtrib = vm.selectorList.atribucion[atribucionIndex]
      if (!selectedAtrib) {
        return '';
      }
      return selectedAtrib.nombre;
    }
    vm.filterByAtribucion = function(value) {
      if (vm.atributionsAllIsSelected()) {
        return true;
      }

      var atribIds = vm.getSelectedAtributionIds()
      if(atribIds.length < 1) {
        return true
      }

      return atribIds.indexOf(value.atribucion) !== -1
    }

    vm.onFilterAtribution = function(values) {
      vm.selectedAtributions = values
      vm.getSumatorio();
    }

    vm.esTablaSalarial = function (index) {
      if (vm.data[index].contribuyente && vm.data[index].contribuyente.tipoContribuyente) {
        return vm.data[index].contribuyente.tipoContribuyente.codigo == 10;
      }
      else {
        return false;
      }
    }

    vm.esNoEmpleadoVoluntario = function (index) {
      if (vm.data[index].contribuyente && vm.data[index].contribuyente.tipoContribuyente) {
        return vm.data[index].contribuyente.tipoContribuyente.codigo == 3;
      }
      else {
        return false;
      }
    }

    vm.esNoEmpleadoFamiliares = function (index) {
      if (vm.data[index].contribuyente && vm.data[index].contribuyente.tipoContribuyente) {
        return vm.data[index].contribuyente.tipoContribuyente.codigo == 2;
      }
      else {
        return false;
      }
    }

    vm.esTiempoRemunerado = function (index) {
      if (vm.data[index].contribuyente && vm.data[index].contribuyente.tipoContribuyente) {
        return vm.data[index].contribuyente.tipoContribuyente.codigo == 1;
      }
      else {
        return false;
      }
    }

    vm.esTiempoNoRemunerado = function (index) {
      if (vm.data[index].contribuyente && vm.data[index].contribuyente.tipoContribuyente) {
        return vm.data[index].contribuyente.tipoContribuyente.codigo == 12;
      }
      else {
        return false;
      }
    }

    vm.onClickFiltroComo = function (filter) {
      if (filter) {
        vm.filters.tipoNombre = filter.enum;
        vm.filtroTipo = filter.enum;

        SelectService.getQuien(filter.enum, vm.id).then(function (res) {
          vm.selectorList.filtroQuien = res;
        })
      }
      else {
        vm.filters.tipoNombre = "";
        vm.filtroTipo = null;
        vm.selectorList.filtroQuien = [];
      }
      vm.getSumatorio();
    }

    vm.onClickFiltroQuien = function (filter) {
      if (filter) {
        vm.filters.contribuyente = {}
        vm.filters.contribuyente.nombre = filter.nombre;
        //TODO validar el caso de contribuyente a null
        if (filter.contribuyente) {
          vm.filtroIdColaborador = filter.contribuyente.colaboradorId
          if (filter.contribuyente.tipoContribuyente) {
            vm.filtroIdTipoContribuyente = filter.contribuyente.tipoContribuyente.id;
          }
        }
      }
      else {
        vm.filters.contribuyente = {}
        vm.filtroIdTipoContribuyente = null;
        vm.filtroIdColaborador = null
      }

      vm.getSumatorio();
    }

    vm.onClickComo = function (object, index) {
      vm.data[index].tipo = object.enum;
	  vm.data[index].contribuyente = false;
	  
	  if (vm.data[index].tipo == 'TIEMPO')
	  {
		  var esNoEmpleadoFamiliares = vm.esNoEmpleadoFamiliares(index);
		  var esTiempoRemunerado = vm.esTiempoRemunerado(index);
		  if (typeof(esNoEmpleadoFamiliares) !== 'undefined')
			vm.data[index].cantidad = 0;
		  if (typeof(esTiempoNoRemunerado) !== 'undefined')
			vm.data[index].cantidad = 0;
	  }

      //vm.data[index].cantidad = null;
      // vm.data[index].tipoNombre = object.nombre;
      vm.checkCachedList(object.enum);
      vm.enableDisableFields(index);
      vm.checkValidData(index,true);
    }

    vm.onClickQuien = function (object, index) {
      vm.data[index].contribuyente = object.contribuyente;
      vm.data[index].contribuyente.nombre = object.nombre;

	  if (vm.data[index].tipo == 'TIEMPO')
	  {
		  var esNoEmpleadoFamiliares = vm.esNoEmpleadoFamiliares(index);
		  var esTiempoRemunerado = vm.esTiempoRemunerado(index);
		  if (typeof(esNoEmpleadoFamiliares) !== 'undefined')
			vm.data[index].cantidad = 0;
		  if (typeof(esTiempoNoRemunerado) !== 'undefined')
			vm.data[index].cantidad = 0;
	  }
	  
      //vm.data[index].cantidad = null;

      vm.enableDisableFields(index);

      vm.checkValidData(index,true);
    }

    vm.onClickTablaSalarial = function (object, index) {
		if (object.id == -2)
			vm.data[index].tablaSalarialId = null;
		else
			vm.data[index].tablaSalarialId = object.id;

	  if (vm.data[index].tipo == 'TIEMPO' && vm.esTablaSalarial(index))
	  {
		  if (typeof(object.numPersonas) !== 'undefined' && object.numPersonas != null && object.numPersonas != '')
			vm.data[index].numPersonas = object.numPersonas;
		
		  if (typeof(object.horas) !== 'undefined' && object.horas != null && object.horas != '')
			vm.data[index].numHorasPersonas = object.horas;
		
		  if (typeof(object.costeHora) !== 'undefined' && object.costeHora != null && object.costeHora != '')
			vm.data[index].costeHora = object.costeHora;
		
		  if (typeof(object.divisaCosteHora) !== 'undefined' && object.divisaCosteHora != null && object.divisaCosteHora != '')
			vm.data[index].divisaCosteHora = object.divisaCosteHora;

		  //TODO preguntar si esto va
		  if (typeof(object.totalCoste) !== 'undefined' && object.totalCoste != null && object.totalCoste != '')
			vm.data[index].cantidad = object.totalCoste;
		
		  if (typeof(object.divisaTotalCoste) !== 'undefined' && object.divisaTotalCoste != null && object.divisaTotalCoste != '')
			vm.data[index].divisaCantidad = object.divisaTotalCoste;
		
		  if (typeof(object.descripcionPublica) !== 'undefined' && object.descripcionPublica != null && object.descripcionPublica != '')
			vm.data[index].descripcion = object.descripcionPublica;
	  }

      vm.checkValidData(index,true);
    }

    vm.onClickDivisaCantidad = function (object, index) {
      vm.data[index].divisaCantidad = object;
      vm.checkValidData(index,true);
    }

    vm.onClickDivisaCosteHora = function (object, index) {
      //divisaCosteHora
      vm.data[index].divisaCosteHora = object;
      vm.checkValidData(index,true);
    }

    vm.checkCachedList = function (object) {
      if (vm.selectorList.quien[object] == null) {
        SelectService.getQuien(object, vm.id).then(function (res) {
          vm.selectorList.quien[object] = res;
        })
      }
    }
	
    vm.sendRequest = function (object, index, actualizarSumatorio, recalcularContribucion) {
      if (!vm.proyectClosed) {
        object.datosIniciativaId = vm.id;
        if (object.id) {
			if (recalcularContribucion == true)
			{
			  ContributionsService.updateContribution(object).then(function (res) {
				vm.sent[index] = true;
				vm.algoActualizado[index] = false;
				document.getElementById('botonGuardar-'+index).style.color = "#59d21c";
				document.getElementById('botonGuardar-'+index).style.cursor = "default";
				var quedaAlgoPorActualizar = false;
				vm.algoActualizado.forEach(function (elem, index) {
					if (elem == true)			
						quedaAlgoPorActualizar = true;			
				});
				if (quedaAlgoPorActualizar == false)
				{
					document.getElementById('botonGuardar').style.backgroundColor = "rgba(158,158,158,0.2)";
					document.getElementById('botonGuardar').style.cursor = "default";
				}
				$window.onbeforeunload = null;
				$window.removeEventListener('beforeunload', confirmLeavePage);
				if (actualizarSumatorio == true)
					vm.getSumatorio();
			  }).catch(function (ex) {
				 vm.sent[index] = false;
				 vm.algoActualizado[index] = true;
				 document.getElementById('botonGuardar-'+index).style.color = "red";
				 document.getElementById('botonGuardar-'+index).style.cursor = "pointer";
				 document.getElementById('botonGuardar').style.backgroundColor = "#59d21c";
				 document.getElementById('botonGuardar').style.cursor = "pointer";
				 $window.onbeforeunload = function(){ return vm.confirmationMessage;}
				 $window.addEventListener('beforeunload', confirmLeavePage);
			  });
			}
			else
			{
				ContributionsService.updateContributionButNoRecalculate(object).then(function (res) {
					vm.sent[index] = true;
					vm.algoActualizado[index] = false;
					document.getElementById('botonGuardar-'+index).style.color = "#59d21c";
					document.getElementById('botonGuardar-'+index).style.cursor = "default";
					var quedaAlgoPorActualizar = false;
					vm.algoActualizado.forEach(function (elem, index) {
						if (elem == true)			
							quedaAlgoPorActualizar = true;			
					});
					if (quedaAlgoPorActualizar == false)
					{
						document.getElementById('botonGuardar').style.backgroundColor = "rgba(158,158,158,0.2)";
						document.getElementById('botonGuardar').style.cursor = "default";
					}
					$window.onbeforeunload = null;
					$window.removeEventListener('beforeunload', confirmLeavePage);
					if (actualizarSumatorio == true)
						vm.getSumatorio();
				  }).catch(function (ex) {
					 vm.sent[index] = false;
					 vm.algoActualizado[index] = true;
					 document.getElementById('botonGuardar-'+index).style.color = "red";
					 document.getElementById('botonGuardar-'+index).style.cursor = "pointer";
					 document.getElementById('botonGuardar').style.backgroundColor = "#59d21c";
					 document.getElementById('botonGuardar').style.cursor = "pointer";
					 $window.onbeforeunload = function(){ return vm.confirmationMessage;}
					 $window.addEventListener('beforeunload', confirmLeavePage);
				  });
			}				
        }
        else {
		  if (sendingThisInsert[index] == true)
			  return;

		  sendingThisInsert[index] = true;
          ContributionsService.createContribution(object).then(function (res) {            
			vm.sent[index] = true;
			vm.algoActualizado[index] = false;
			document.getElementById('botonGuardar-'+index).style.color = "#59d21c";
		    document.getElementById('botonGuardar-'+index).style.cursor = "default";
			var quedaAlgoPorActualizar = false;
			vm.algoActualizado.forEach(function (elem, index) {
				if (elem == true)			
					quedaAlgoPorActualizar = true;			
			});
			if (quedaAlgoPorActualizar == false)
			{
				document.getElementById('botonGuardar').style.backgroundColor = "rgba(158,158,158,0.2)";
				document.getElementById('botonGuardar').style.cursor = "default";
			}
			$window.onbeforeunload = null;
			$window.removeEventListener('beforeunload', confirmLeavePage);
            vm.data[index].codigoCorto = res.codigoCorto;
            vm.data[index].id = res.id;			
            vm.data[index].atribucion = res.atribucion;
			sendingThisInsert[index] = false;

			//vm.sendRequest(vm.data[index], index, true, true);
          }).catch(function (ex) {
			 sendingThisInsert[index] = false;
			 vm.sent[index] = false;
			 vm.algoActualizado[index] = true;
			 document.getElementById('botonGuardar-'+index).style.color = "red";
		     document.getElementById('botonGuardar-'+index).style.cursor = "pointer";
			 document.getElementById('botonGuardar').style.backgroundColor = "#59d21c";
			 document.getElementById('botonGuardar').style.cursor = "pointer";
		  });;
        }
      }
    }

	vm.checkValidData = function (index, oldValue) {
		if (oldValue != null)
		{
			vm.algoActualizado[index] = true;
			document.getElementById('botonGuardar-'+index).style.color = "red";
			document.getElementById('botonGuardar-'+index).style.cursor = "pointer";
			document.getElementById('botonGuardar').style.backgroundColor = "#59d21c";
			document.getElementById('botonGuardar').style.cursor = "pointer";
			vm.sent[index] = false;
			$window.onbeforeunload = function(){ return vm.confirmationMessage;}
			$window.addEventListener('beforeunload', confirmLeavePage);
		}
	}
	
    vm.updateData = function (index, actualizarSumatorio, recalcularContribucion) {
      var auxData = vm.data[index];
      var esTablaSalarial = vm.esTablaSalarial(index);
      /*
       vm.esNoEmpleadoVoluntario
       vm.esNoEmpleadoFamiliares
       vm.esTiempoRemunerado
       vm.esTiempoNoRemunerado
       */

      var esNoEmpleadoVoluntario = vm.esNoEmpleadoVoluntario(index);
      var esNoEmpleadoFamiliares = vm.esNoEmpleadoFamiliares(index);
      var esTiempoRemunerado = vm.esTiempoRemunerado(index);
      var esTiempoNoRemunerado = vm.esTiempoNoRemunerado(index);

      var tipo = auxData.tipo;

      if (tipo == "TIEMPO") {
        if (esTablaSalarial == true) {
          //CASO 1
          if (tipo
            && auxData.contribuyente
            && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
          }
          else {
            vm.sent[index] = false;
			alert(vm.mensajerevisar);
          }
        }
        else if (esNoEmpleadoVoluntario) {
          //CASO 7
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
          }
          else {
            vm.sent[index] = false;
			alert(vm.mensajerevisar);
          }
        }

        else if (esNoEmpleadoFamiliares) {
          //CASO 8
          //TODO no validar cuanto y divisa cuanto
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            // && auxData.cantidad
            // && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
          }
          else {
            vm.sent[index] = false;
			alert(vm.mensajerevisar);
          }
        }
        else if (esTiempoNoRemunerado) {
          //CASO 9
          //TODO no validar cuanto y divisa cuanto
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            // && auxData.cantidad
            // && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
          }
          else {
            vm.sent[index] = false;
			alert(vm.mensajerevisar);
          }
        }
        else if (esTiempoRemunerado) {

		//CASO 10
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
          // && auxData.descripcion
          // && auxData.numPersonas
          // && auxData.numHorasPersonas
          // && auxData.costeHora //TODO es obligatorio?
          // && auxData.divisaCosteHora //TODO es obligatorio?
          ) {
            vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
          }
          else {
            vm.sent[index] = false;
			alert(vm.mensajerevisar);
          }
        }

        else {
          //CASO 2
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
            // && auxData.descripcion
            && auxData.numPersonas
            && auxData.numHorasPersonas
            && auxData.costeHora
            && auxData.divisaCosteHora
          ) {
            vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
          }
          else {
            vm.sent[index] = false;
			alert(vm.mensajerevisar);
          }
        }
      }
      else if (tipo == "DINERO") {
        //CASO 3
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
        }
        else {
          vm.sent[index] = false;
		  alert(vm.mensajerevisar);
        }
      }
      else if (tipo == "ESPECIE") {
        //CASO 4
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
        }
        else {
          vm.sent[index] = false;
		  alert(vm.mensajerevisar);
        }
      }

      else if (tipo == "PROBONO") {
        //CASO 5
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
        }
        else {
          vm.sent[index] = false;
		  alert(vm.mensajerevisar);
        }
      }
      else if (tipo == "COSTE_GESTION") {
        //CASO 6
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          vm.sendRequest(auxData, index, actualizarSumatorio, recalcularContribucion);
        }
        else {
          vm.sent[index] = false;
		  alert(vm.mensajerevisar);
        }
      }

    }

	vm.checkData = function (index) {
      var auxData = vm.data[index];
      var esTablaSalarial = vm.esTablaSalarial(index);
      var esNoEmpleadoVoluntario = vm.esNoEmpleadoVoluntario(index);
      var esNoEmpleadoFamiliares = vm.esNoEmpleadoFamiliares(index);
      var esTiempoRemunerado = vm.esTiempoRemunerado(index);
      var esTiempoNoRemunerado = vm.esTiempoNoRemunerado(index);

      var tipo = auxData.tipo;

      if (tipo == "TIEMPO") {
        if (esTablaSalarial == true) {
          //CASO 1
          if (tipo
            && auxData.contribuyente
            && auxData.tablaSalarialId	
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            return true;
          }
          else {
            return false;
          }
        }
        else if (esNoEmpleadoVoluntario) {
          //CASO 7
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            return true;
          }
          else {
            return false;
          }
        }

        else if (esNoEmpleadoFamiliares) {
          //CASO 8
          //TODO no validar cuanto y divisa cuanto
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            // && auxData.cantidad
            // && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            return true;
          }
          else {
            return false;
          }
        }
        else if (esTiempoNoRemunerado) {
          //CASO 9
          //TODO no validar cuanto y divisa cuanto
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            // && auxData.cantidad
            // && auxData.divisaCantidad
            // && auxData.descripcion
            // && auxData.numPersonas
            && auxData.numHorasPersonas
          // && auxData.costeHora
          // && auxData.divisaCosteHora
          ) {
            return true;
          }
          else {
            return false;
          }
        }
        else if (esTiempoRemunerado) {

		//CASO 10
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
          // && auxData.descripcion
          // && auxData.numPersonas
          // && auxData.numHorasPersonas
          // && auxData.costeHora //TODO es obligatorio?
          // && auxData.divisaCosteHora //TODO es obligatorio?
          ) {
            return true;
          }
          else {
            return false;
          }
        }

        else {
          //CASO 2
          if (tipo
            && auxData.contribuyente
            // && auxData.tablaSalarialId
            && auxData.fecha
            && auxData.cantidad !== ''
			&& auxData.cantidad !== null
            && auxData.divisaCantidad
            // && auxData.descripcion
            && auxData.numPersonas
            && auxData.numHorasPersonas
            && auxData.costeHora
            && auxData.divisaCosteHora
          ) {
            return true;
          }
          else {
            return false;
          }
        }
      }
      else if (tipo == "DINERO") {
        //CASO 3
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (tipo == "ESPECIE") {
        //CASO 4
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (tipo == "PROBONO") {
        //CASO 5
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (tipo == "COSTE_GESTION") {
        //CASO 6
        if (tipo
          && auxData.contribuyente
          // && auxData.tablaSalarialId
          && auxData.fecha
          && auxData.cantidad !== ''
		  && auxData.cantidad !== null
          && auxData.divisaCantidad
        // && auxData.descripcion
        // && auxData.numPersonas
        // && auxData.numHorasPersonas
        // && auxData.costeHora
        // && auxData.divisaCosteHora
        ) {
          return true;
        }
        else {
          return false;
        }
      }
    }

    vm.enableDisableFields = function (index) {
      var esTablaSalarial = vm.esTablaSalarial(index);
      /*
       vm.esNoEmpleadoVoluntario
       vm.esNoEmpleadoFamiliares
       vm.esTiempoRemunerado
       vm.esTiempoNoRemunerado
       */
      var esNoEmpleadoVoluntario = vm.esNoEmpleadoVoluntario(index);
      var esNoEmpleadoFamiliares = vm.esNoEmpleadoFamiliares(index);
      var esTiempoRemunerado = vm.esTiempoRemunerado(index);
      var esTiempoNoRemunerado = vm.esTiempoNoRemunerado(index);


      var tipo = vm.data[index].tipo;
      vm.hide[index] = [];
	  vm.hide[index]['tablaSalarial'] = false;
      if (tipo == "TIEMPO") {
		vm.hide[index]['aportacionesLey112018'] = true;
        if (esTablaSalarial == true) {
          //CASO 1
          vm.hide[index]['coste'] = false;
          vm.hide[index]['divisaCoste'] = false;
          //vm.hide[index]['tablaSalarial'] = false;
          vm.hide[index]['numPersonas'] = false;
          vm.hide[index]['numHorasPersonas'] = false;
          vm.hide[index]['costeHora'] = false;
          vm.hide[index]['divisaCosteHora'] = false;
        }
        else if (esNoEmpleadoVoluntario) {
          //CASO 7
          vm.hide[index]['coste'] = false;
          vm.hide[index]['divisaCoste'] = false;
          //vm.hide[index]['tablaSalarial'] = true;
          vm.hide[index]['numPersonas'] = false;
          vm.hide[index]['numHorasPersonas'] = false;
          vm.hide[index]['costeHora'] = false;
          vm.hide[index]['divisaCosteHora'] = false;
        }
        else if (esNoEmpleadoFamiliares) {
          //CASO 8
          vm.hide[index]['coste'] = true;
          vm.hide[index]['divisaCoste'] = true;
          //vm.hide[index]['tablaSalarial'] = true;
          vm.hide[index]['numPersonas'] = false;
          vm.hide[index]['numHorasPersonas'] = false;
          vm.hide[index]['costeHora'] = true;
          vm.hide[index]['divisaCosteHora'] = true;
        }
        else if (esTiempoNoRemunerado) {
          //CASO 9
          vm.hide[index]['coste'] = false;
          vm.hide[index]['divisaCoste'] = false;
          //vm.hide[index]['tablaSalarial'] = true;
          vm.hide[index]['numPersonas'] = false;
          vm.hide[index]['numHorasPersonas'] = false;
          vm.hide[index]['costeHora'] = false;
          vm.hide[index]['divisaCosteHora'] = false;
        }
        else if (esTiempoRemunerado) {
          //CASO 10
          vm.hide[index]['coste'] = false;
          vm.hide[index]['divisaCoste'] = false;
          //vm.hide[index]['tablaSalarial'] = true;
          vm.hide[index]['numPersonas'] = false;
          vm.hide[index]['numHorasPersonas'] = false;
          vm.hide[index]['costeHora'] = false;
          vm.hide[index]['divisaCosteHora'] = false;
        }

        else {
          //CASO 2
          vm.hide[index]['coste'] = false;
          vm.hide[index]['divisaCoste'] = false;
          //vm.hide[index]['tablaSalarial'] = true;
          vm.hide[index]['numPersonas'] = false;
          vm.hide[index]['numHorasPersonas'] = false;
          vm.hide[index]['costeHora'] = false;
          vm.hide[index]['divisaCosteHora'] = false;
        }
      }
      else if (tipo == "DINERO") {
        //CASO 3
        vm.hide[index]['coste'] = false;
        vm.hide[index]['divisaCoste'] = false;
        //vm.hide[index]['tablaSalarial'] = true;
        vm.hide[index]['numPersonas'] = true;
        vm.hide[index]['numHorasPersonas'] = true;
        vm.hide[index]['costeHora'] = true;
        vm.hide[index]['divisaCosteHora'] = true; 
		vm.hide[index]['aportacionesLey112018'] = false;
      }
      else if (tipo == "ESPECIE") {
        //CASO 4
		vm.hide[index]['aportacionesLey112018'] = true;
        vm.hide[index]['coste'] = false;
        vm.hide[index]['divisaCoste'] = false;
        //vm.hide[index]['tablaSalarial'] = true;
        vm.hide[index]['numPersonas'] = true;
        vm.hide[index]['numHorasPersonas'] = true;
        vm.hide[index]['costeHora'] = true;
        vm.hide[index]['divisaCosteHora'] = true;
      }

      else if (tipo == "PROBONO") {
        //CASO 5
		vm.hide[index]['aportacionesLey112018'] = true;
        vm.hide[index]['coste'] = false;
        vm.hide[index]['divisaCoste'] = false;
        //vm.hide[index]['tablaSalarial'] = true;
        vm.hide[index]['numPersonas'] = false;
        vm.hide[index]['numHorasPersonas'] = false;
        vm.hide[index]['costeHora'] = false;
        vm.hide[index]['divisaCosteHora'] = false;
      }
      else if (tipo == "COSTE_GESTION") {
        //CASO 6
		vm.hide[index]['aportacionesLey112018'] = true;
        vm.hide[index]['coste'] = false;
        vm.hide[index]['divisaCoste'] = false;
        //vm.hide[index]['tablaSalarial'] = true;
        vm.hide[index]['numPersonas'] = false;
        vm.hide[index]['numHorasPersonas'] = false;
        vm.hide[index]['costeHora'] = false;
        vm.hide[index]['divisaCosteHora'] = false;
      }
      else {
        //CASO BASE
		vm.hide[index]['aportacionesLey112018'] = true;
        vm.hide[index]['coste'] = false;
        vm.hide[index]['divisaCoste'] = false;
        //vm.hide[index]['tablaSalarial'] = true;
        vm.hide[index]['numPersonas'] = false;
        vm.hide[index]['numHorasPersonas'] = false;
        vm.hide[index]['costeHora'] = false;
        vm.hide[index]['divisaCosteHora'] = false;
      }
	  
      //seteamos los campos ocultos a nulo
      vm.data[index].cantidad = vm.hide[index]['coste'] ? null : vm.data[index].cantidad;
      vm.data[index].divisaCantidad = vm.hide[index]['divisaCoste'] ? null : vm.data[index].divisaCantidad;
      vm.data[index].tablaSalarialId = vm.hide[index]['tablaSalarial'] ? null : vm.data[index].tablaSalarialId;
      vm.data[index].numPersonas = vm.hide[index]['numPersonas'] ? null : vm.data[index].numPersonas;
      vm.data[index].numHorasPersonas = vm.hide[index]['numHorasPersonas'] ? null : vm.data[index].numHorasPersonas;
      vm.data[index].costeHora = vm.hide[index]['costeHora'] ? null : vm.data[index].costeHora;
      vm.data[index].divisaCosteHora = vm.hide[index]['divisaCosteHora'] ? null : vm.data[index].divisaCosteHora;
    }

    vm.abreCalendario = [];
    vm.abrirCalendario = function (index) {
      vm.abreCalendario[index] = true;
    }

    vm.activeDesactive = function (ev, index) {
      if (!vm.proyectClosed) {
        var whenOk = function () {
			if (vm.data[index].validado == true)
			{
				ContributionsService.validateContribution(vm.data[index])
					.then(function (res) {
					  vm.data[index].validado = !vm.data[index].validado;
					  vm.updateData(index, true, true);
					})
				  ;
			}
			else
			{
				vm.updateData(index, true, true);
				setTimeout(function(){
					vm.data[index].validado = !vm.data[index].validado;
				}, 400);
				setTimeout(function(){
					ContributionsService.validateContribution(vm.data[index])
						.then(function (res) {
											  
						})
					  ;
				}, 600);
			}
        };
        ModalService.showConfirm(ev,
          vm.data[index].validado ? 'contributions.invalidarContribucion' : 'contributions.validarContribucion',
          'contributions.estasSeguro',
          vm.data[index].validado ? 'contributions.invalidarContribucion' : 'contributions.validarContribucion',
          'contributions.cancelar',
          whenOk);//TODO cambiar texto

      }
    }
	
	vm.aportacionesLey112018ActiveDesactive = function (ev, index) {
      if (!vm.isSoloVista){
		  if (!vm.proyectClosed) {
			 vm.data[index].aportacionesLey112018Active = !vm.data[index].aportacionesLey112018Active;
			 vm.checkValidData(index,true);		        
		  }
	  }
    };	
  }
})();
