(function () {
  'use strict';

  /** Componente de patrón numérico **/

  angular.module('app').directive('allowPattern', [allowPatternDirective]);

  function allowPatternDirective() {
    return {
      restrict: "A",
      require: 'ngModel',
      compile: function(tElement, tAttrs) {
        return function (scope, element, attrs, ngModel) {
          // I handle key events
          element.bind("keypress", function(event) {
            var keyCode = event.which || event.keyCode; // I safely get the keyCode pressed from the event.
            var keyCodeChar = String.fromCharCode(keyCode); // I determine the char from the keyCode.
            var allowPattern = "[0-9|\,]";

            // If the keyCode char does not match the allowed Regex Pattern, then don't allow the input into the field.
            if (!keyCodeChar.match(new RegExp(allowPattern, "i")) && keyCode != 8 && keyCode != 37 && keyCode != 39) {
              event.preventDefault();
              return false;
            }

          });

          scope.$watch(function () {
            return ngModel.$viewValue
          }, function (newVal, oldVal) {
            if (newVal && newVal.indexOf(".") !== -1) {
              ngModel.$setViewValue(newVal.replace(/\./g, ','));
              ngModel.$render();
            }
          })
        };
      }
    };
  }

})();
