(function () {
  'use strict';

  angular
    .module('app')
    .service('ImpactsService', ImpactsService);

  /* @ngInject */
  function ImpactsService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getImpacts = function (pageNumber, pageSize, fieldSort, orderSort, filters, idDatosIniciativa) {
      ////api/impactos
      var url = config.api + "/api/impactos";


      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroFecha = null;
      if (filters && filters.date) {
        filtroFecha = filters.date;
      }


      var params = $httpParamSerializer({
        datosIniciativa: idDatosIniciativa,
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        codigoCorto: filtroCodigo,
        fechaEvaluacion: filtroFecha,

      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            var prettyDate = moment(element.fechaEvaluacion, "YYYY-MM-DD").format("DD/MM/YYYY");
            element.fechaBonita = prettyDate
            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.createImpact = function (entity) {
      //post /api/entidads
      var url = api + "/api/impactos";
      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getImpact = function (id) {
      var url = api + "/api/impactos";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateImpact = function (entity) {
      var url = api + "/api/impactos";
      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    //api/impactos/validate/{id}
    service.activeDesactiveImpact = function (entity) {
      var url = api + "/api/impactos/validate";
      if (entity && entity.id != null) {
        url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteImpact = function (entity) {
      var url = api + "/api/impactos";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    service.getNumBeneficiarios = function (idDatosIniciativa) {
      //http://localhost:8080/api/logroes/num-beneficiario?datos-iniciativa=15
      var url = api + "/api/logroes/num-beneficiario";
      if (idDatosIniciativa != null) {
        url += "?datos-iniciativa=" + idDatosIniciativa;
      }
      return $http.get(url, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
