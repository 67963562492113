(function () {
  angular
    .module('app')
    .component('userform', {
      templateUrl: 'components/user/form/userform.html',
      controller: UserFormController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UserFormController($rootScope, $scope, SelectService, UserComponentService, RoleService, $state, $stateParams, Translator) {
    var vm = this;


    vm.validData = true;
    vm.errorMessage = "";

    vm.editId = $stateParams.id;

    vm.data = {};
    //inicializamos data
    vm.data.nombre = "";
    vm.data.apellidos = "";
    vm.data.email = "";
    vm.data.emailContacto = "";
    vm.data.rol = "";
    vm.data.activo = true;
    vm.data.entidadAltaName = "";
    vm.data.representante = {};
    vm.data.grupoLBG = {};
    vm.data.pais = {};

    vm.selectorList = {};

    vm.esCoordinadorGrupo = false;
    vm.esAdmin = false;

    vm.isRespOrLess = RoleService.hasRoleOrLess(RoleService.getEnum().RESPONSABLE);

    if (vm.editId) {
      UserComponentService.getUser(vm.editId).then(function (res) {
        vm.data = res;
        checkRol();
      });
    }

    var destroyLangWatcher = $rootScope.$watch('selectedLang', function() {
      loadCountries();
    });
    $scope.$on('$destroy', function() {
      destroyLangWatcher();
    });

    vm.clickSelector = function (selectorObject, selectorType) {
      if (selectorType == SelectService.ENUM.country) {
        vm.data.pais = selectorObject;
      }

      if (selectorType == SelectService.ENUM.responsibleEntity) {
        vm.data.entidadAltaName = selectorObject.nombre;
      }

      if (selectorType == SelectService.ENUM.role) {
        vm.data.rol = selectorObject.enum;
        checkRol();
      }

      if (selectorType == SelectService.ENUM.lgbGroup) {
        vm.data.grupoLBG = selectorObject;
      }

      if (selectorType == "representedEntity") {
        vm.data.representante = selectorObject;
      }
    }

    function checkRol() {
      if (vm.data.rol == RoleService.getEnum().COORDINADOR_GRUPO) {
        vm.esCoordinadorGrupo = true;
      } else {
        vm.esCoordinadorGrupo = false;
      }
      if (vm.data.rol == RoleService.getEnum().ADMINISTRADOR) {
        vm.esAdmin = true;
      } else {
        vm.esAdmin = false;
      }
    }

    function fillSelectors(reset) {
      if (reset == null) {
        loadCountries();
      }

      if (reset == null) {
        SelectService.getResponsibleEntity().then(function (res) {
          vm.selectorList.responsibleEntity = res;
        });
      }

      if (reset == null) {
        SelectService.getRepresentativeEntityEntities().then(function (res) {
          vm.selectorList.representativeEntity = res;
        });
      }


      if (!vm.isRespOrLess) {
        if (reset == null) {
          SelectService.getLGBGroup().then(function (res) {
            vm.selectorList.lgbGroup = res;
          });
        }
      }

      if (reset == null) {
        vm.selectorList.role = SelectService.getRoles();
        if (!vm.data.rol) {
          if (vm.selectorList.role && vm.selectorList.role[0]) {
            //si no hay ningun rol seleccionado seleccionamos el primero de la lista
            vm.clickSelector(vm.selectorList.role[0], SelectService.ENUM.role)
          }
        }
      }
    }

    function loadCountries() {
      SelectService.getCountry().then(function (res) {
        vm.selectorList.country = res;
      });
    }

    fillSelectors();

    vm.clickSave = function () {
      vm.validData = true;
      if (vm.editId) {
        UserComponentService.updateUser(vm.data).then(function (res) {
          $state.go("manage.user");
        })
          .catch(function (e) {
            showError(e)
          });
      }
      else {
        UserComponentService.createUser(vm.data).then(function (res) {
          $state.go("manage.user");
        })
          .catch(function (e) {
            showError(e)
          });
      }

      function showError(e) {
        var key = e.data.message ? e.data.message : 'err.genericError';
        Translator.translate(key, function(translation) {
          vm.errorMessage = translation;
        });
      }
    }

    vm.clickCancel = function () {
      $state.go("manage.user");
    }
  }
})();
