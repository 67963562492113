(function () {
  'use strict';

  angular
    .module('app')
    .service('CollaboratorsService', CollaboratorsService);

  /* @ngInject */
  function CollaboratorsService($http, $httpParamSerializer, config, TokenService) {
    var idToken = TokenService.getToken();
    var api = config.api;
    var authorization = "Bearer " + idToken;
    var headers = {
      "Authorization": authorization
    };

    var service = {};

    service.getCollaborators = function (pageNumber, pageSize, fieldSort, orderSort, filters) {
      var url = config.api + "/apiV2/colaboradors";


      var filtroCodigo = null;
      if (filters && filters.code) {
        filtroCodigo = filters.code;
      }

      var filtroNombre = null;
      if (filters && filters.name) {
        filtroNombre = filters.name;
      }

      var filtroDescripcion = null;
      if (filters && filters.description) {
        filtroDescripcion = filters.description;
      }

      var filtroPais = null;
      if (filters && filters.country && filters.country.id) {
        filtroPais = filters.country.id;
      }

      var filtroRegion = null;
      if (filters && filters.state) {
        filtroRegion = filters.state;
      }

      var filtroTipo = null;
      if (filters && filters.type && filters.type.id) {
        filtroTipo = filters.type.id;
      }

      /*
       code
       name
       description
       country
       state
       type
       */
      var params = $httpParamSerializer({
        size: pageSize,
        page: pageNumber, //antes habia que restarle 1
        sort: getSort(fieldSort, orderSort),
        codigoCorto: filtroCodigo,
        nombre: filtroNombre,
        descripcion: filtroDescripcion,
        pais: filtroPais,
        region: filtroRegion,
        tipo: filtroTipo,
      });
      url += "?" + params;

      return $http.get(url, {headers: headers}).then(function (response) {
        if (response.data) {
          return response.data.map(function (element) {
            if (element.fechaDesde) {
              element.fechaDesdeBonita = moment(element.fechaDesde, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
            if (element.fechaHasta) {
              element.fechaHastaBonita = moment(element.fechaHasta, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
            return element;
          });
        }
        else {
          return [];
        }

      });
    }

    service.createCollaborator = function (entity) {
      var url = api + "/api/colaboradors";
      return $http.post(url, entity, {headers: headers}).then(function (response) {
        return response.data;
      });
    }

    service.getCollaborator = function (id) {
      var url = api + "/api/colaboradors";
      if (id != null) {
        url += "/" + id;
        return $http.get(url, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.updateCollaborator = function (entity) {
      var url = api + "/api/colaboradors";
      if (entity && entity.id != null) {
        // url += "/" + entity.id;
        return $http.put(url, entity, {headers: headers}).then(function (response) {
          return response.data;
        });
      }
      else {
        return false;
      }
    }

    service.deleteCollaborator = function (entity) {
      var url = api + "/api/colaboradors";
      if (entity.id != null) {
        url += "/" + entity.id;
        return $http.delete(url, {headers: headers}).then(function (response) {
          return response;
        });
      }
      else {
        return false;
      }
    };

    return service;
  }


  function getSort(fieldSort, orderSort) {
    var sort = null;
    if (fieldSort) {
      sort = fieldSort + ",";
      if (orderSort) {
        sort += "asc"
      }
      else {
        sort += "desc"
      }
    }

    return sort;
  }

})();
