(function () {
  angular
    .module('app')
    .component('pass', {
      templateUrl: 'components/me/pass/pass.html',
      controller: PassController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function PassController(MeService, Toaster) {
    var vm = this;

    vm.passChanged = false;
    vm.changinPass = false;

    vm.data = {};
    //inicializamos data
    vm.data.oldPass = "";
    vm.data.newPass = "";
    vm.data.newPass2 = "";

    vm.clickSave = function () {
      if (!vm.data.oldPass || !vm.data.newPass || !vm.data.newPass2) {
        Toaster.simpleToast('mepass.todosCamposObligatorios');
        return;
      }
      if (vm.data.newPass != vm.data.newPass2) {
        Toaster.simpleToast('mepass.passNotEqual');
        return;
      }
      if (!MeService.validaContraseña(vm.data.newPass)) {
        Toaster.simpleToast('mepass.oldPassValidate');
        return;
      }

      vm.changinPass = true;
      MeService.changePassword(vm.data.oldPass, vm.data.newPass).then(function (res) {
        vm.changinPass = false;
        if (!res || res.status == 401) {
          Toaster.simpleToast('mepass.oldPassFail');
          return;
        }

        vm.passChanged = true;
        //resetamos los valores del form
        vm.data.oldPass = "";
        vm.data.newPass = "";
        vm.data.newPass2 = "";
      })
    }
  }
})();
