(function () {
  'use strict';

  angular
    .module('app')
    .service('ActivityHistoryService', ActivityHistoryService);

  /* @ngInject */
  function ActivityHistoryService(RoleService, $http, config, TokenService, $httpParamSerializer) {

    var service = {};
    
    service.log_activity_url = config.api + "/api/activity-log";
    service.headers = {"Authorization": "Bearer " + TokenService.getToken() };

    service.pageSize = 10;
    service.pageNumber = 0;
    service.fieldSort = 'fecha';
    service.sortFieldIsAsc = false;

    service.getActivityHistory = getActivityHistory;
    service.getAccessibleItemsInSelector = getAccessibleItemsInSelector;
    service.getAccessibleLogTypes = getAccessibleLogTypes;
    service.getSort = getSort;

    service.$onInit = initialize;


    function initialize(){

    }

    function getActivityHistory(itemType, itemId, options){
      options = angular.isDefined(options) ? options : {};
      var params = {}
      params.size = angular.isDefined(options.size) ? options.size : service.pageSize;
      params.page = angular.isDefined(options.page) ? options.page : service.pageNumber;
      params.sort = getSort(
        angular.isDefined(options.sortField) ? options.sortField : service.sortField,
        angular.isDefined(options.sortFieldIsAsc) ? options.sortFieldIsAsc : service.sortFieldIsAsc
      );
      params = $httpParamSerializer(params);
      if (angular.isDefined(itemType) && angular.isDefined(itemId)) {
        var url = service.log_activity_url + "/" + itemType + "/" + itemId + "?" + params;
        return $http.get(url, {headers: service.headers, params: options}).then(function (response) {
          return response.data;
        });
      }
      else{
        console.log('ITEM TYPE: ' + itemType);
        console.log('ITEM ID: ' + itemId);
      }
    }
    
    function getAccessibleLogTypes(){
      var accessibleLogTypes = [];
      accessibleLogTypes.push({key: config.logTypeClassification.create, value: 'activity-history.log-tipo.create'});
      accessibleLogTypes.push({key: config.logTypeClassification.read, value: 'activity-history.log-tipo.read'});
      accessibleLogTypes.push({key: config.logTypeClassification.update, value: 'activity-history.log-tipo.update'});
      accessibleLogTypes.push({key: config.logTypeClassification.delete, value: 'activity-history.log-tipo.delete'});
      accessibleLogTypes.push({key: config.logTypeClassification.duplicate, value: 'activity-history.log-tipo.duplicate'});
      return accessibleLogTypes;
    }

    function getAccessibleItemsInSelector(){
      var availableItems = [];
      if(RoleService.isAdmin() || RoleService.isGroupCordinator()){
        availableItems.push({key: config.logEntityClassification.proyecto, value:'breadcrumb.proyectos'});
        availableItems.push({key: config.logEntityClassification.iniciativa, value:'breadcrumb.iniciativas'});
        availableItems.push({key: config.logEntityClassification.contribucion, value:'breadcrumb.contribuciones'});
        availableItems.push({key: config.logEntityClassification.logro, value:'breadcrumb.logros'});
        availableItems.push({key: config.logEntityClassification.impacto, value:'breadcrumb.impactos'});
      }
      else if(RoleService.isResponsible() || RoleService.isManager()){
        availableItems.push({key: config.logEntityClassification.proyecto, value:'breadcrumb.proyectos'});
        availableItems.push({key: config.logEntityClassification.iniciativa, value:'breadcrumb.iniciativas'});
        availableItems.push({key: config.logEntityClassification.contribucion, value:'breadcrumb.contribuciones'});
        availableItems.push({key: config.logEntityClassification.logro, value:'breadcrumb.logros'});
        availableItems.push({key: config.logEntityClassification.impacto, value:'breadcrumb.impactos'});

      }
      else if(RoleService.isProjectSupervisor()){
        availableItems.push({key: config.logEntityClassification.proyecto, value:'breadcrumb.proyectos'});
        availableItems.push({key: config.logEntityClassification.iniciativa, value:'breadcrumb.iniciativas'});
        availableItems.push({key: config.logEntityClassification.contribucion, value:'breadcrumb.contribuciones'});
        availableItems.push({key: config.logEntityClassification.logro, value:'breadcrumb.logros'});
        availableItems.push({key: config.logEntityClassification.impacto, value:'breadcrumb.impactos'});

      }
      else if(RoleService.isInitiativeSupervisor()){
        availableItems.push({key: config.logEntityClassification.iniciativa, value:'breadcrumb.iniciativas'});
        availableItems.push({key: config.logEntityClassification.contribucion, value:'breadcrumb.contribuciones'});
        availableItems.push({key: config.logEntityClassification.logro, value:'breadcrumb.logros'});
        availableItems.push({key: config.logEntityClassification.impacto, value:'breadcrumb.impactos'});

      }
      else if(RoleService.isAgent()){

      }
      return availableItems;
    }

    function getSort(fieldSort, orderSort) {
      var sort = null;
      if (fieldSort) {
        sort = fieldSort + ",";
        if (orderSort) {
          sort += "asc"
        }
        else {
          sort += "desc"
        }
      }
      return sort;
    }

    return service;
  }

})();
