(function() {
  'use strict';

  angular
    .module('app')
    .component('reportChecker', {
      templateUrl: 'components/filterReport/reportChecker/reportChecker.html',
      controller: ReportCheckerController,
      controllerAs: '$ctrl',
      bindings: {
        onChanges: '&'
      }
    });

  /* @ngInject */
  function ReportCheckerController(UserService) {
    var $ctrl = this;

    $ctrl.$onInit = initialize;
    
    $ctrl.checks = [];

    function initialize(){
      $ctrl.checks = [
        {checked: false, label: "filterReport.tipo.contribución", url: '/contribucion2'},
        {checked: false, label: "filterReport.unidad.negocio", url: '/unidad_negocio'},
        {checked: false, label: "filterReport.agrInterna", url: '/agrupacion_interna'}];
      if (UserService.getUserData().entidad.activoIniciativasInternas){
        $ctrl.checks.push({checked: false, label: "filterReport.agrIniciativaInterna", url: '/agrupacion_iniciativa_interna'});
      }
      $ctrl.checks = $ctrl.checks.concat([
          {checked: false, label: "filterReport.motivacion", url: '/motivacion'},
          {checked: false, label: "filterReport.area", url: '/area'},
          {checked: false, label: "filterReport.ambito.geografico", url: '/pais'},
          {checked: false, label: "filterReport.pais.españa", url: '/pais_espania'},
          {checked: false, label: "filterReport.ODS", url: '/ods'},
          {checked: false, label: "filterReport.empleados.y.voluntarios", url: '/empleados_voluntarios'},
          {checked: false, label: "filterReport.efecto.multiplicador", url: '/efecto_multiplicador'},
          {checked: false, label: "filterReport.resultados.comunidad", url: '/resultados_comunidad'},
          {checked: false, label: "filterReport.resultados.entidad", url: '/resultados_entidad'},
      ]);
      $ctrl.checks.map(function(check, index) {
        check.index = index
        return check
      });
      
    }


    ////////////////

    $ctrl.checkAll = function() {
      $ctrl.checks = $ctrl.checks.map(function(check) {
        check.checked = true
        return check
      })
      $ctrl.onChanges({checks: $ctrl.checks})
    }
    $ctrl.uncheckAll = function() {
      $ctrl.checks = $ctrl.checks.map(function(check) {
        check.checked = false
        return check
      })
      $ctrl.onChanges({checks: $ctrl.checks})
    }

    $ctrl.$onChanges = function(changesObj) { };
    $ctrl.$onDestroy = function() { };
  }
})();
